// @flow
export const brevardCounty = [
  { lng: -80.98725, lat: 28.612997 },
  { lng: -80.986056, lat: 28.611996 },
  { lng: -80.984332, lat: 28.612378 },
  { lng: -80.983472, lat: 28.610533 },
  { lng: -80.981694, lat: 28.611201 },
  { lng: -80.980209, lat: 28.609778 },
  { lng: -80.977082, lat: 28.609169 },
  { lng: -80.975981, lat: 28.608343 },
  { lng: -80.975007, lat: 28.60854 },
  { lng: -80.973056, lat: 28.611317 },
  { lng: -80.972615, lat: 28.611624 },
  { lng: -80.971899, lat: 28.611462 },
  { lng: -80.970558, lat: 28.610833 },
  { lng: -80.970521, lat: 28.609123 },
  { lng: -80.968753, lat: 28.607705 },
  { lng: -80.968315, lat: 28.605779 },
  { lng: -80.964804, lat: 28.604955 },
  { lng: -80.963666, lat: 28.605334 },
  { lng: -80.960403, lat: 28.605045 },
  { lng: -80.959709, lat: 28.602917 },
  { lng: -80.957504, lat: 28.601915 },
  { lng: -80.956512, lat: 28.603659 },
  { lng: -80.954536, lat: 28.603769 },
  { lng: -80.953873, lat: 28.604856 },
  { lng: -80.952011, lat: 28.604792 },
  { lng: -80.953054, lat: 28.600539 },
  { lng: -80.950568, lat: 28.599668 },
  { lng: -80.949496, lat: 28.600444 },
  { lng: -80.949452, lat: 28.598496 },
  { lng: -80.94707, lat: 28.597395 },
  { lng: -80.947849, lat: 28.595546 },
  { lng: -80.946727, lat: 28.595679 },
  { lng: -80.943822, lat: 28.594 },
  { lng: -80.943728, lat: 28.592647 },
  { lng: -80.945131, lat: 28.592272 },
  { lng: -80.944027, lat: 28.591079 },
  { lng: -80.944632, lat: 28.588855 },
  { lng: -80.945592, lat: 28.587936 },
  { lng: -80.945505, lat: 28.586599 },
  { lng: -80.946671, lat: 28.587551 },
  { lng: -80.947156, lat: 28.584761 },
  { lng: -80.945143, lat: 28.583892 },
  { lng: -80.945729, lat: 28.582874 },
  { lng: -80.944052, lat: 28.581675 },
  { lng: -80.94437, lat: 28.58058 },
  { lng: -80.942624, lat: 28.579886 },
  { lng: -80.94162, lat: 28.577982 },
  { lng: -80.940386, lat: 28.577691 },
  { lng: -80.939383, lat: 28.575083 },
  { lng: -80.940318, lat: 28.574604 },
  { lng: -80.939393, lat: 28.57249 },
  { lng: -80.936468, lat: 28.571335 },
  { lng: -80.934497, lat: 28.569575 },
  { lng: -80.932933, lat: 28.566229 },
  { lng: -80.932001, lat: 28.566463 },
  { lng: -80.932028, lat: 28.564707 },
  { lng: -80.930043, lat: 28.563217 },
  { lng: -80.932181, lat: 28.561797 },
  { lng: -80.931556, lat: 28.559939 },
  { lng: -80.934086, lat: 28.559333 },
  { lng: -80.931741, lat: 28.555881 },
  { lng: -80.93383, lat: 28.554994 },
  { lng: -80.933661, lat: 28.552069 },
  { lng: -80.932625, lat: 28.551598 },
  { lng: -80.934145, lat: 28.547657 },
  { lng: -80.934203, lat: 28.544848 },
  { lng: -80.932806, lat: 28.54361 },
  { lng: -80.93233, lat: 28.541795 },
  { lng: -80.934077, lat: 28.540611 },
  { lng: -80.935855, lat: 28.54186 },
  { lng: -80.939033, lat: 28.541954 },
  { lng: -80.939648, lat: 28.540922 },
  { lng: -80.939056, lat: 28.539229 },
  { lng: -80.939587, lat: 28.535426 },
  { lng: -80.938913, lat: 28.534628 },
  { lng: -80.93774, lat: 28.534855 },
  { lng: -80.93753, lat: 28.536004 },
  { lng: -80.936239, lat: 28.536842 },
  { lng: -80.935374, lat: 28.534548 },
  { lng: -80.934127, lat: 28.533533 },
  { lng: -80.933037, lat: 28.533892 },
  { lng: -80.932176, lat: 28.5355 },
  { lng: -80.928714, lat: 28.53291 },
  { lng: -80.924185, lat: 28.532898 },
  { lng: -80.923182, lat: 28.532445 },
  { lng: -80.922618, lat: 28.530699 },
  { lng: -80.92052, lat: 28.527962 },
  { lng: -80.920934, lat: 28.525565 },
  { lng: -80.919501, lat: 28.524036 },
  { lng: -80.916039, lat: 28.522884 },
  { lng: -80.912848, lat: 28.523974 },
  { lng: -80.912405, lat: 28.524653 },
  { lng: -80.910473, lat: 28.523477 },
  { lng: -80.912256, lat: 28.523363 },
  { lng: -80.91191, lat: 28.52187 },
  { lng: -80.910968, lat: 28.521362 },
  { lng: -80.90951, lat: 28.521826 },
  { lng: -80.907546, lat: 28.520713 },
  { lng: -80.906967, lat: 28.519748 },
  { lng: -80.907641, lat: 28.517819 },
  { lng: -80.905863, lat: 28.517663 },
  { lng: -80.905519, lat: 28.515864 },
  { lng: -80.90713, lat: 28.513784 },
  { lng: -80.905954, lat: 28.512919 },
  { lng: -80.905872, lat: 28.511186 },
  { lng: -80.904132, lat: 28.510452 },
  { lng: -80.903234, lat: 28.51096 },
  { lng: -80.901515, lat: 28.510511 },
  { lng: -80.899758, lat: 28.51133 },
  { lng: -80.898646, lat: 28.509689 },
  { lng: -80.896435, lat: 28.509422 },
  { lng: -80.894882, lat: 28.509979 },
  { lng: -80.893543, lat: 28.511719 },
  { lng: -80.890331, lat: 28.511847 },
  { lng: -80.885902, lat: 28.510238 },
  { lng: -80.885048, lat: 28.509499 },
  { lng: -80.88475, lat: 28.507166 },
  { lng: -80.882555, lat: 28.5038 },
  { lng: -80.88084, lat: 28.503483 },
  { lng: -80.879838, lat: 28.500253 },
  { lng: -80.879829, lat: 28.497301 },
  { lng: -80.883802, lat: 28.495801 },
  { lng: -80.883977, lat: 28.495108 },
  { lng: -80.882433, lat: 28.491597 },
  { lng: -80.880476, lat: 28.49111 },
  { lng: -80.87942, lat: 28.490069 },
  { lng: -80.876338, lat: 28.489949 },
  { lng: -80.875367, lat: 28.489331 },
  { lng: -80.875954, lat: 28.488389 },
  { lng: -80.878043, lat: 28.487278 },
  { lng: -80.877975, lat: 28.486338 },
  { lng: -80.876442, lat: 28.486292 },
  { lng: -80.875542, lat: 28.485222 },
  { lng: -80.875511, lat: 28.48385 },
  { lng: -80.873688, lat: 28.482227 },
  { lng: -80.873947, lat: 28.480417 },
  { lng: -80.873146, lat: 28.47855 },
  { lng: -80.874275, lat: 28.476995 },
  { lng: -80.873108, lat: 28.47452 },
  { lng: -80.870796, lat: 28.471355 },
  { lng: -80.872665, lat: 28.471157 },
  { lng: -80.873817, lat: 28.470076 },
  { lng: -80.875511, lat: 28.469536 },
  { lng: -80.87603, lat: 28.470921 },
  { lng: -80.879669, lat: 28.469425 },
  { lng: -80.879814, lat: 28.468405 },
  { lng: -80.881012, lat: 28.468447 },
  { lng: -80.88166, lat: 28.470247 },
  { lng: -80.885338, lat: 28.468889 },
  { lng: -80.886551, lat: 28.469814 },
  { lng: -80.887505, lat: 28.468437 },
  { lng: -80.889496, lat: 28.466782 },
  { lng: -80.890564, lat: 28.466572 },
  { lng: -80.890701, lat: 28.46847 },
  { lng: -80.893105, lat: 28.467747 },
  { lng: -80.894798, lat: 28.466747 },
  { lng: -80.894318, lat: 28.464619 },
  { lng: -80.892815, lat: 28.464508 },
  { lng: -80.894287, lat: 28.463196 },
  { lng: -80.895447, lat: 28.463646 },
  { lng: -80.895081, lat: 28.460138 },
  { lng: -80.893417, lat: 28.459929 },
  { lng: -80.893997, lat: 28.458321 },
  { lng: -80.895996, lat: 28.4578 },
  { lng: -80.896484, lat: 28.456802 },
  { lng: -80.897377, lat: 28.457624 },
  { lng: -80.898346, lat: 28.455084 },
  { lng: -80.897186, lat: 28.454573 },
  { lng: -80.897139, lat: 28.452937 },
  { lng: -80.896835, lat: 28.452827 },
  { lng: -80.895473, lat: 28.450966 },
  { lng: -80.89288, lat: 28.449856 },
  { lng: -80.89138, lat: 28.447863 },
  { lng: -80.890224, lat: 28.445592 },
  { lng: -80.891382, lat: 28.444673 },
  { lng: -80.892937, lat: 28.44457 },
  { lng: -80.893999, lat: 28.443795 },
  { lng: -80.894156, lat: 28.442618 },
  { lng: -80.893274, lat: 28.441837 },
  { lng: -80.894264, lat: 28.440618 },
  { lng: -80.894682, lat: 28.438511 },
  { lng: -80.894258, lat: 28.437861 },
  { lng: -80.895487, lat: 28.436328 },
  { lng: -80.896411, lat: 28.437358 },
  { lng: -80.89816, lat: 28.436495 },
  { lng: -80.897032, lat: 28.435443 },
  { lng: -80.898664, lat: 28.434858 },
  { lng: -80.899355, lat: 28.433516 },
  { lng: -80.90118, lat: 28.433158 },
  { lng: -80.900116, lat: 28.43149 },
  { lng: -80.898448, lat: 28.431919 },
  { lng: -80.897838, lat: 28.429634 },
  { lng: -80.896894, lat: 28.428148 },
  { lng: -80.897057, lat: 28.426408 },
  { lng: -80.898698, lat: 28.426136 },
  { lng: -80.896484, lat: 28.425011 },
  { lng: -80.89687, lat: 28.423269 },
  { lng: -80.895759, lat: 28.423119 },
  { lng: -80.895547, lat: 28.420688 },
  { lng: -80.896373, lat: 28.418499 },
  { lng: -80.894862, lat: 28.418168 },
  { lng: -80.894456, lat: 28.41923 },
  { lng: -80.892364, lat: 28.418498 },
  { lng: -80.891073, lat: 28.41619 },
  { lng: -80.888244, lat: 28.415815 },
  { lng: -80.88791, lat: 28.412848 },
  { lng: -80.885024, lat: 28.410851 },
  { lng: -80.884755, lat: 28.408668 },
  { lng: -80.88299, lat: 28.408156 },
  { lng: -80.883199, lat: 28.406454 },
  { lng: -80.885032, lat: 28.406286 },
  { lng: -80.886403, lat: 28.403805 },
  { lng: -80.885504, lat: 28.402398 },
  { lng: -80.886669, lat: 28.400036 },
  { lng: -80.886248, lat: 28.39915 },
  { lng: -80.88442, lat: 28.398114 },
  { lng: -80.884507, lat: 28.396126 },
  { lng: -80.886877, lat: 28.396451 },
  { lng: -80.887085, lat: 28.394632 },
  { lng: -80.889659, lat: 28.392707 },
  { lng: -80.887969, lat: 28.388584 },
  { lng: -80.888388, lat: 28.385468 },
  { lng: -80.885542, lat: 28.384766 },
  { lng: -80.882487, lat: 28.382051 },
  { lng: -80.88144, lat: 28.380619 },
  { lng: -80.879604, lat: 28.380421 },
  { lng: -80.879418, lat: 28.377531 },
  { lng: -80.879782, lat: 28.376809 },
  { lng: -80.88207, lat: 28.37542 },
  { lng: -80.88152, lat: 28.374714 },
  { lng: -80.880083, lat: 28.374915 },
  { lng: -80.880299, lat: 28.37369 },
  { lng: -80.87929, lat: 28.372395 },
  { lng: -80.877833, lat: 28.372223 },
  { lng: -80.876687, lat: 28.373297 },
  { lng: -80.875314, lat: 28.37321 },
  { lng: -80.874465, lat: 28.371596 },
  { lng: -80.872598, lat: 28.370113 },
  { lng: -80.872169, lat: 28.367647 },
  { lng: -80.873169, lat: 28.365694 },
  { lng: -80.873703, lat: 28.361983 },
  { lng: -80.871937, lat: 28.35746 },
  { lng: -80.869333, lat: 28.35335 },
  { lng: -80.866277, lat: 28.349966 },
  { lng: -80.862908, lat: 28.347487 },
  { lng: -80.862785, lat: 28.347122 },
  { lng: -80.861398, lat: 28.252176 },
  { lng: -80.861576, lat: 28.246558 },
  { lng: -80.862827, lat: 28.091885 },
  { lng: -80.862877, lat: 28.084745 },
  { lng: -80.869313, lat: 28.084727 },
  { lng: -80.869283, lat: 28.065095 },
  { lng: -80.870135, lat: 28.057077 },
  { lng: -80.86953, lat: 28.048486 },
  { lng: -80.869973, lat: 27.982571 },
  { lng: -80.869597, lat: 27.977911 },
  { lng: -80.869402, lat: 27.96395 },
  { lng: -80.869358, lat: 27.950162 },
  { lng: -80.869566, lat: 27.947866 },
  { lng: -80.868881, lat: 27.822522 },
  { lng: -80.827508, lat: 27.822917 },
  { lng: -80.770115, lat: 27.823673 },
  { lng: -80.60711, lat: 27.82235 },
  { lng: -80.554462, lat: 27.822188 },
  { lng: -80.509075, lat: 27.822058 },
  { lng: -80.507333, lat: 27.827989 },
  { lng: -80.50534, lat: 27.828811 },
  { lng: -80.499763, lat: 27.834647 },
  { lng: -80.499156, lat: 27.835432 },
  { lng: -80.497076, lat: 27.83809 },
  { lng: -80.496901, lat: 27.838388 },
  { lng: -80.494387, lat: 27.845934 },
  { lng: -80.494787, lat: 27.850937 },
  { lng: -80.494421, lat: 27.851911 },
  { lng: -80.491079, lat: 27.854601 },
  { lng: -80.478642, lat: 27.854615 },
  { lng: -80.4549, lat: 27.854641 },
  { lng: -80.454033, lat: 27.854797 },
  { lng: -80.451385, lat: 27.856802 },
  { lng: -80.449497, lat: 27.859135 },
  { lng: -80.4470841049745, lat: 27.8607550370955 },
  { lng: -80.446973, lat: 27.861954 },
  { lng: -80.460479, lat: 27.886232 },
  { lng: -80.46713, lat: 27.897105 },
  { lng: -80.472734, lat: 27.906595 },
  { lng: -80.478623, lat: 27.916521 },
  { lng: -80.508871, lat: 27.970477 },
  { lng: -80.5110583346065, lat: 27.9748931955191 },
  { lng: -80.514033, lat: 27.980899 },
  { lng: -80.524253, lat: 28.000503 },
  { lng: -80.530964, lat: 28.013656 },
  { lng: -80.5360404913024, lat: 28.0243305753023 },
  { lng: -80.5399549903651, lat: 28.0325617752642 },
  { lng: -80.5443827134273, lat: 28.0418721552943 },
  { lng: -80.544588425206, lat: 28.0423047150569 },
  { lng: -80.5446429882398, lat: 28.0424194472957 },
  { lng: -80.547675, lat: 28.048795 },
  { lng: -80.5483977493293, lat: 28.0504303819074 },
  { lng: -80.5510738697633, lat: 28.0564857025592 },
  { lng: -80.5537249199218, lat: 28.0624842961091 },
  { lng: -80.557853, lat: 28.071825 },
  { lng: -80.5598968971501, lat: 28.0770842782699 },
  { lng: -80.5602520155699, lat: 28.077998055457 },
  { lng: -80.563375, lat: 28.086034 },
  { lng: -80.5654308916632, lat: 28.0918337009991 },
  { lng: -80.5667703653694, lat: 28.0956123762723 },
  { lng: -80.567001, lat: 28.096263 },
  { lng: -80.571995, lat: 28.111594 },
  { lng: -80.572499506868, lat: 28.113457027698 },
  { lng: -80.5793383618912, lat: 28.1387113446122 },
  { lng: -80.5804388169055, lat: 28.142775071594 },
  { lng: -80.5811160281565, lat: 28.1452758568119 },
  { lng: -80.5833727833671, lat: 28.1536095341737 },
  { lng: -80.5844504205073, lat: 28.157589 },
  { lng: -80.5863664381014, lat: 28.164664411801 },
  { lng: -80.5884340494623, lat: 28.172299624477 },
  { lng: -80.5899688662131, lat: 28.1779673493372 },
  { lng: -80.589975, lat: 28.17799 },
  { lng: -80.5920883857231, lat: 28.1870075832933 },
  { lng: -80.594164, lat: 28.195864 },
  { lng: -80.5944861031204, lat: 28.1979638055804 },
  { lng: -80.5945100739649, lat: 28.1981200726569 },
  { lng: -80.5953162468991, lat: 28.2033755524044 },
  { lng: -80.5953645924272, lat: 28.203690719204 },
  { lng: -80.5960796492372, lat: 28.2083522085985 },
  { lng: -80.5961303677466, lat: 28.2086828449768 },
  { lng: -80.5967380511664, lat: 28.2126443621276 },
  { lng: -80.598154, lat: 28.221875 },
  { lng: -80.603339, lat: 28.252555 },
  { lng: -80.603418, lat: 28.255908 },
  { lng: -80.6047702925853, lat: 28.2719909487624 },
  { lng: -80.6062251936612, lat: 28.289294230398 },
  { lng: -80.606823, lat: 28.296404 },
  { lng: -80.6068045189407, lat: 28.2987936009694 },
  { lng: -80.606683, lat: 28.314506 },
  { lng: -80.6067319589366, lat: 28.3201448118396 },
  { lng: -80.606792, lat: 28.32706 },
  { lng: -80.6063243900924, lat: 28.336566477612 },
  { lng: -80.606204, lat: 28.339014 },
  { lng: -80.6054620642001, lat: 28.3451064935606 },
  { lng: -80.6051472584138, lat: 28.3476915585031 },
  { lng: -80.604276, lat: 28.354846 },
  { lng: -80.6037577883075, lat: 28.3578751035412 },
  { lng: -80.60202, lat: 28.368033 },
  { lng: -80.6016381391416, lat: 28.369512626194 },
  { lng: -80.6012200744715, lat: 28.3711325341345 },
  { lng: -80.5990858606858, lat: 28.3794021395456 },
  { lng: -80.5989617744446, lat: 28.3798829462289 },
  { lng: -80.5989571999685, lat: 28.3799006713099 },
  { lng: -80.5983158030549, lat: 28.3823859421965 },
  { lng: -80.597508, lat: 28.385516 },
  { lng: -80.5954669981015, lat: 28.3914749477876 },
  { lng: -80.594149, lat: 28.395323 },
  { lng: -80.589801424675, lat: 28.4059808868438 },
  { lng: -80.58903, lat: 28.407872 },
  { lng: -80.5868260382169, lat: 28.4098932314643 },
  { lng: -80.5847, lat: 28.411843 },
  { lng: -80.582747, lat: 28.416862 },
  { lng: -80.578105, lat: 28.423075 },
  { lng: -80.572814, lat: 28.427751 },
  { lng: -80.563782, lat: 28.435798 },
  { lng: -80.557602, lat: 28.439142 },
  { lng: -80.542281, lat: 28.445714 },
  { lng: -80.53924, lat: 28.446886 },
  { lng: -80.535854, lat: 28.446979 },
  { lng: -80.528962, lat: 28.449696 },
  { lng: -80.526732, lat: 28.451705 },
  { lng: -80.525224, lat: 28.455029 },
  { lng: -80.525094, lat: 28.459454 },
  { lng: -80.536115, lat: 28.478647 },
  { lng: -80.545727, lat: 28.498791 },
  { lng: -80.554184, lat: 28.513817 },
  { lng: -80.560973, lat: 28.530736 },
  { lng: -80.564138, lat: 28.543067 },
  { lng: -80.567361, lat: 28.562353 },
  { lng: -80.5690258333067, lat: 28.5687139356439 },
  { lng: -80.570318, lat: 28.573651 },
  { lng: -80.57424, lat: 28.585339 },
  { lng: -80.583884, lat: 28.597705 },
  { lng: -80.595297, lat: 28.609486 },
  { lng: -80.61679, lat: 28.634561 },
  { lng: -80.6222231323186, lat: 28.6425275798302 },
  { lng: -80.643404, lat: 28.673585 },
  { lng: -80.647288, lat: 28.677875 },
  { lng: -80.709725, lat: 28.756692 },
  { lng: -80.7329248249869, lat: 28.7912445868159 },
  { lng: -80.805873, lat: 28.791246 },
  { lng: -80.806763, lat: 28.791325 },
  { lng: -80.842922, lat: 28.791148 },
  { lng: -80.880762, lat: 28.791136 },
  { lng: -80.882733, lat: 28.791201 },
  { lng: -80.899304, lat: 28.790968 },
  { lng: -80.967895, lat: 28.790197 },
  { lng: -80.964945, lat: 28.6252 },
  { lng: -80.964711, lat: 28.622404 },
  { lng: -80.964466, lat: 28.612992 },
  { lng: -80.967243, lat: 28.612694 },
  { lng: -80.974224, lat: 28.612671 },
  { lng: -80.980426, lat: 28.613077 },
  { lng: -80.984688, lat: 28.613075 },
  { lng: -80.98725, lat: 28.612997 },
];

export const indianRiverCounty = [
  { lng: -80.881566, lat: 27.822511 },
  { lng: -80.880073, lat: 27.733699 },
  { lng: -80.873795, lat: 27.733854 },
  { lng: -80.873599, lat: 27.69968 },
  { lng: -80.873486, lat: 27.68348 },
  { lng: -80.873357, lat: 27.665002 },
  { lng: -80.87315, lat: 27.642288 },
  { lng: -80.867024, lat: 27.642387 },
  { lng: -80.863778, lat: 27.642144 },
  { lng: -80.778047, lat: 27.643195 },
  { lng: -80.777502, lat: 27.620395 },
  { lng: -80.777166, lat: 27.558733 },
  { lng: -80.749781, lat: 27.558878 },
  { lng: -80.744997, lat: 27.558708 },
  { lng: -80.744348, lat: 27.558619 },
  { lng: -80.679821, lat: 27.55847 },
  { lng: -80.578576, lat: 27.558515 },
  { lng: -80.52939, lat: 27.558334 },
  { lng: -80.525704, lat: 27.558714 },
  { lng: -80.480353, lat: 27.558106 },
  { lng: -80.477894, lat: 27.558007 },
  { lng: -80.463961, lat: 27.558011 },
  { lng: -80.447536, lat: 27.557786 },
  { lng: -80.41457, lat: 27.557536 },
  { lng: -80.402259, lat: 27.557539 },
  { lng: -80.398029, lat: 27.557522 },
  { lng: -80.397969, lat: 27.557583 },
  { lng: -80.393768, lat: 27.557586 },
  { lng: -80.38983, lat: 27.557503 },
  { lng: -80.376316, lat: 27.55756 },
  { lng: -80.369891, lat: 27.557299 },
  { lng: -80.369048, lat: 27.557316 },
  { lng: -80.348422, lat: 27.557248 },
  { lng: -80.348167, lat: 27.557249 },
  { lng: -80.323161, lat: 27.557294 },
  { lng: -80.3211729745474, lat: 27.5573821223987 },
  { lng: -80.324699, lat: 27.569178 },
  { lng: -80.3265130182351, lat: 27.5774009501683 },
  { lng: -80.330956, lat: 27.597541 },
  { lng: -80.3313220139663, lat: 27.5982335042464 },
  { lng: -80.3314116556047, lat: 27.5984031076521 },
  { lng: -80.3318980674931, lat: 27.5993234066824 },
  { lng: -80.334225, lat: 27.603726 },
  { lng: -80.3397633645196, lat: 27.6110387421021 },
  { lng: -80.343926, lat: 27.616535 },
  { lng: -80.345889968672, lat: 27.6203407742563 },
  { lng: -80.3472952350806, lat: 27.6230638964718 },
  { lng: -80.347913, lat: 27.624261 },
  { lng: -80.3505044977114, lat: 27.6319743641673 },
  { lng: -80.350711, lat: 27.632589 },
  { lng: -80.3508775639608, lat: 27.6352860269291 },
  { lng: -80.3510507744892, lat: 27.6380906760087 },
  { lng: -80.351169, lat: 27.640005 },
  { lng: -80.352549114976, lat: 27.6449185293009 },
  { lng: -80.3530297077691, lat: 27.6466295510722 },
  { lng: -80.3549753042116, lat: 27.6535563255035 },
  { lng: -80.355137, lat: 27.654132 },
  { lng: -80.3554130659212, lat: 27.6548811916815 },
  { lng: -80.3560732424612, lat: 27.656672788304 },
  { lng: -80.357562, lat: 27.660713 },
  { lng: -80.35783814249, lat: 27.6622255077291 },
  { lng: -80.358398, lat: 27.665292 },
  { lng: -80.3591145734883, lat: 27.6675203393645 },
  { lng: -80.3614903886683, lat: 27.6749084475978 },
  { lng: -80.3616835144954, lat: 27.6755090138897 },
  { lng: -80.362855, lat: 27.679152 },
  { lng: -80.364427, lat: 27.684611 },
  { lng: -80.3664786732774, lat: 27.6905142083227 },
  { lng: -80.3670432275321, lat: 27.6921385806883 },
  { lng: -80.3734114338732, lat: 27.7104615998003 },
  { lng: -80.3756936751886, lat: 27.7170282135376 },
  { lng: -80.378498, lat: 27.725097 },
  { lng: -80.379681, lat: 27.730712 },
  { lng: -80.3810824190086, lat: 27.7341698732932 },
  { lng: -80.383455, lat: 27.740024 },
  { lng: -80.3874625558006, lat: 27.7475554269867 },
  { lng: -80.3908763877523, lat: 27.7539710646893 },
  { lng: -80.393265, lat: 27.75846 },
  { lng: -80.396489, lat: 27.763293 },
  { lng: -80.3994405330958, lat: 27.7694161727683 },
  { lng: -80.402939, lat: 27.776674 },
  { lng: -80.4037867786381, lat: 27.7782006103663 },
  { lng: -80.412408, lat: 27.793725 },
  { lng: -80.4138036135935, lat: 27.7963743017414 },
  { lng: -80.447179, lat: 27.859731 },
  { lng: -80.4470841049745, lat: 27.8607550370955 },
  { lng: -80.449497, lat: 27.859135 },
  { lng: -80.451385, lat: 27.856802 },
  { lng: -80.454033, lat: 27.854797 },
  { lng: -80.4549, lat: 27.854641 },
  { lng: -80.478642, lat: 27.854615 },
  { lng: -80.491079, lat: 27.854601 },
  { lng: -80.494421, lat: 27.851911 },
  { lng: -80.494787, lat: 27.850937 },
  { lng: -80.494387, lat: 27.845934 },
  { lng: -80.496901, lat: 27.838388 },
  { lng: -80.497076, lat: 27.83809 },
  { lng: -80.499156, lat: 27.835432 },
  { lng: -80.499763, lat: 27.834647 },
  { lng: -80.50534, lat: 27.828811 },
  { lng: -80.507333, lat: 27.827989 },
  { lng: -80.509075, lat: 27.822058 },
  { lng: -80.554462, lat: 27.822188 },
  { lng: -80.60711, lat: 27.82235 },
  { lng: -80.770115, lat: 27.823673 },
  { lng: -80.827508, lat: 27.822917 },
  { lng: -80.868881, lat: 27.822522 },
  { lng: -80.881566, lat: 27.822511 },
];
