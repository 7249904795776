// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import {
  BodyTextContainer,
  Col,
  Cols,
  Container,
  Heading,
  Section,
} from 'components';

import DifferenceItem from './DifferenceItem';

const BREAKPOINT = 800;

const AreaTop = styled('div')`
  margin-bottom: 40px;
  @media (max-width: ${BREAKPOINT}px) {
    margin-bottom: 30px;
    text-align: center;
    > a {
      display: none;
    }
  }
`;

type Difference = {
  title: string,
  image: string,
  text: string,
  last?: boolean,
};

type Props = {
  differences: Array<Difference>,
  textContent?: () => Node,
  isSpanish?: boolean,
};

const HomeDifference = ({ differences, textContent, isSpanish }: Props) => (
  <Section>
    <Container
      h="textSection"
      v="standalone"
      hPad={28}
      vBreakpoint={BREAKPOINT}
    >
      <AreaTop>
        <Heading>
          {isSpanish ? 'Nuestra Promesa ' : 'The Tulip Difference'}
        </Heading>
      </AreaTop>

      {!!textContent && <BodyTextContainer>{textContent()}</BodyTextContainer>}
      <Cols breakpoint={BREAKPOINT}>
        {differences.map(difference => (
          <Col key={difference.title}>
            <DifferenceItem
              title={difference.title}
              image={difference.image}
              breakpoint={BREAKPOINT}
              last={difference.last}
              href={difference.href}
            >
              {difference.text}
            </DifferenceItem>
          </Col>
        ))}
      </Cols>
    </Container>
  </Section>
);

export default HomeDifference;
