// @flow
import * as React from 'react';
import styled from 'styled-components';
import { Container, Heading, LinkButton, TextLink } from 'components';

const BREAKPOINT = 800;

const Cols = styled.div`
  display: flex;
  @media (max-width: ${BREAKPOINT}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ReadMoreButton = styled(LinkButton)`
  margin-top: auto;
  align-self: flex-start;

  @media (max-width: ${BREAKPOINT}px) {
    align-self: center;
  }
`;

const StyledTextLink = styled(TextLink)`
  margin-top: auto;
`;

const Col = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  &:first-child {
  }

  @media (min-width: ${BREAKPOINT}px) {
    &:not(:last-child) {
      border-right: 1px solid rgb(233, 241, 243);
    }

    &:first-child {
      width: 35%;

      border: none;
      padding: 0 20px 22px;
    }
  }

  @media (max-width: ${BREAKPOINT}px) {
    width: 80%;
    align-items: center;
    margin-bottom: 10px;
  }
`;

const P = styled.p`
  text-align: left;
  text-wrap: wrap;
  max-width: 330px;
  margin-bottom: 40px;
`;

const Box = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding: 0 20px 22px;
  flex-grow: 1;
  justify-content: flex-start;

  @media (max-width: ${BREAKPOINT}px) {
    padding-top: 20px;
    background: rgb(233, 241, 243);
    border-radius: 10px;
  }
`;

const BoxHeading = styled.h3`
  color: #1b2952;
  margin: 0 0 10px 0;
  text-align: left;
  font-weight: bold;
`;

const StyledHeading = styled(Heading)`
  margin: 0 0 10px;
`;

const HomeGuides = () => (
  <Container h="standard" v="standalone" vBreakpoint={BREAKPOINT}>
    <Cols breakpoint={BREAKPOINT}>
      <Col>
        <StyledHeading>The Tulip Guide</StyledHeading>
        <P>
          Read about direct cremation and find the answers to some of the most
          common questions families have.
        </P>
        <ReadMoreButton tertiary to="/cremation-guide" label="More articles" />
      </Col>
      <Col>
        <Box>
          <BoxHeading>Death Certificate Guide</BoxHeading>
          <P>
            Learn about death certificates, and use our calculator to find out
            how many you may need.
          </P>
          <StyledTextLink to="/cremation-guide/death-certificates">
            Read more
          </StyledTextLink>
        </Box>
      </Col>
      <Col>
        <Box>
          <BoxHeading>Next of Kin: Explained</BoxHeading>
          <P>
            Learn about Next of Kin and Durable Power of Attorney for Healthcare
            and why you need to know.
          </P>
          <StyledTextLink to="/cremation-guide/next-of-kin">
            Read more
          </StyledTextLink>
        </Box>
      </Col>
      <Col>
        <Box>
          <BoxHeading>What is Direct Cremation?</BoxHeading>
          <P>
            Our guide to direct cremation and why it is the fastest growing
            choice for American families.
          </P>
          <StyledTextLink to="/cremation-guide/direct-cremation">
            Read more
          </StyledTextLink>
        </Box>
      </Col>
    </Cols>
  </Container>
);

export default HomeGuides;
