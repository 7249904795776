// @flow
import React, { Fragment } from 'react';

import { BulletList, TextLink } from '../components';

export default {
  tulipDifferenceTextContent: function TulipDifferenceTextContent() {
    return (
      <Fragment>
        <p>
          Losing a loved one is stressful, especially for those responsible for
          making arrangements. Many people simply don’t know what to do next,
          and unanswered questions can make the grieving process that much
          harder. “What do I do when a loved one dies? Who issues the death
          certificate? How much will it cost to make funeral arrangements?”
        </p>
        <p>
          You deserve help from people with experience so you can make the best
          decisions for your loved one and yourself. If you’re looking for the
          most affordable service, direct cremation is right for you. Tulip is a
          direct cremation provider that offers the most affordable and
          comprehensive option in Riverside, CA. Our Family Care Team can help
          make this difficult time a bit easier.
        </p>
      </Fragment>
    );
  },
  tulipHowToPlanTitle: 'How To Plan a Low-Cost Cremation in Riverside',
  tulipHowToPlanText: function TulipHowToPlanText() {
    return (
      <Fragment>
        <p>
          Riverside funeral homes can cost thousands of dollars and put
          unnecessary time constraints on grieving families. Instead, more
          people are choosing direct cremation, a low-cost alternative that can
          ease the stress of making arrangements. Professional, direct cremation
          services can help you and your family focus on what really matters at
          this time.
        </p>
        <p>
          Our job is to do all the work and to make planning a cremation as easy
          as possible for you and your family. Within 15 minutes, our Family
          Care Team can help you with the paperwork by phone or, if you don’t
          feel like talking, you can make arrangements through our website. We
          will then pick your loved one up from their place of passing, conduct
          the cremation, and return their ashes to you within two weeks. It’s as
          simple as that.
        </p>
        <p>
          If your loved one passed in Riverside but you don’t live there, you
          won’t have to travel. Make arrangements for your loved one’s cremation
          online, from the comfort of your own home and without ever having to
          step foot in a funeral home. We will deliver your loved one’s ashes
          from Riverside, CA to any United States postal code by USPS Priority
          Mail Express.
        </p>
        <p>
          Finally, we make arranging a cremation easier by being transparent
          about our prices. We charge $700 as a baseline fee, and only add an
          additional fee{' '}
          <TextLink to="/faq" target="_blank">
            in the case of the following
          </TextLink>
          :
          <BulletList
            items={[
              'If your loved one has a pacemaker or other battery-operated implant',
              'If your loved one weighs over 300 pounds',
              'If your loved one passes in a private residence',
              'If you’d like their ashes hand delivered to your home',
            ]}
          />
          Everything else is included in our flat fee. Why is this important?
          Because some other cremation providers have been known to tack on
          additional fees without warning, or charge all their families for the
          above considerations while pocketing the difference. And when your
          family is grieving, the last thing you need is to get a bill for
          surprise charges you never asked for.
        </p>
      </Fragment>
    );
  },
  tulipBestCremationTitle:
    'The Best Cremation Service Offers Outstanding Family Care',
  tulipBestCremationText: function TulipBestCremationText() {
    return (
      <Fragment>
        <p>
          At Tulip, we put the needs and wellbeing of you and your family first.
          Deciding what to do when a loved one has passed is a big decision that
          you’ve got to make in a hurry. We want to support you in every way
          possible. That’s why we have Family Care Specialists who are
          experienced in the funeral industry available to answer your questions
          24 hours a day.
        </p>
        <p>
          We take great pride in going above and beyond for our families, and
          are proud that 95%{' '}
          <TextLink to="/reviews" target="_blank">
            highly recommend us
          </TextLink>{' '}
          to others who are going through the same thing.
        </p>
        <p>
          Simple direct cremation. Attentive service. Options that make
          arrangements easy, at an affordable price with no surprise added fees.
          This is the Tulip difference.
        </p>
      </Fragment>
    );
  },
};
