// @flow
import React from 'react';
import styled from 'styled-components';
import { mediaQueries, H1, H4, MobileOnly } from 'tulip-ui';
import PhoneNumber from 'components/PhoneNumber';
// import { DECEDENT_LOCATION_TYPE } from 'tulip-core';

import QuoteForm from '../../../components/QuoteForm';

const Wrapper = styled.div`
  background-image: ${({ heroImageURL }) => 'url("' + heroImageURL + '");'};
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 16px 27px;
  // margin-bottom: 72px;
  ${mediaQueries.small`
    padding: 44px 92px;
  `};

  ${mediaQueries.large`
    background-position: 0% 0%;
  `};

  ${mediaQueries.xlarge`
    padding: 25px 112px;
  `};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${mediaQueries.large`
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1360px;
  `};
`;

const TextWrapper = styled.div`
  flex: 1 1 0;
  margin-bottom: 16px;
  text-align: center;
  ${mediaQueries.small`
    max-width: 600px;
  `};

  ${mediaQueries.large`
    margin-bottom: 0;
    max-width: 680px;
    padding-right: 50px;
    text-align: left;
  `};

  ${mediaQueries.xlarge`
    padding-right: 100px;
    padding-top: 36px;
  `};
`;

const TitleH1 = styled(H1)`
  margin-bottom: 28px;
  font-size: 30px;
  ${mediaQueries.small`
    margin-bottom: 24px;
    font-size: 30px;
    max-width: 600px;
    line-height: 1.25;
  `};
  ${mediaQueries.large`
    margin-bottom: 24px;
    font-size: 52px;
    max-width: 600px;
    line-height: 1.25;
  `};
`;

const TextH4 = styled(H4)`
  margin-bottom: 18px;
  ${mediaQueries.large`
    margin-bottom: 30px;
  `};
`;

const PhoneBanner = styled('div')`
  height: ${p => p.height}px;
  background: ${({ theme }) => theme.faintBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PhoneBannerText = styled('div')`
  font-family: Brown;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.71;
  text-align: center;
  color: ${({ theme }) => theme.navy};
`;

const PhoneNumText = styled('div')`
  font-family: Brown;
  font-size: 17px;
  font-weight: 300;
  color: ${({ theme }) => theme.baseBlue};
  text-align: center;
`;

const PhoneNum = styled('span')`
  text-decoration: underline;
`;

const PhoneIcon = styled('img')`
  width: 13px;
  margin-right: 4px;
`;

const Call = styled('div')`
  color: #305881;
`;

type Props = {
  heading: string,
  text: string,
  specialText: string,
  heroImageURL: ?string,
  preNeedPhone: boolean,
  atNeedPhone: boolean,
  phoneNumber?: PhoneNumber,
};

const HomeHero = ({
  heroImageURL,
  heading,
  text,
  atNeedPhone,
  phoneNumber,
}: Props) => (
  <div>
    <MobileOnly>
      {atNeedPhone && (
        <PhoneBanner height={48}>
          <PhoneNumber
            render={({ formatted, href }) => (
              <PhoneNumText>
                <PhoneIcon src={'/img/brochure/phone.png'} /> Call to arrange{' '}
                <a href={href}>
                  {' '}
                  <PhoneNum>{formatted}</PhoneNum>
                </a>
              </PhoneNumText>
            )}
          />
        </PhoneBanner>
      )}
    </MobileOnly>
    <Wrapper heroImageURL={heroImageURL}>
      <ContentWrapper>
        <TextWrapper>
          <TitleH1>{heading}</TitleH1>
          <TextH4>{text}</TextH4>
        </TextWrapper>
        <QuoteForm referrer="HomeHeroNew" />
      </ContentWrapper>
    </Wrapper>
    <MobileOnly>
      {!atNeedPhone && (
        <PhoneBanner height={90}>
          <PhoneNumber
            phoneNumber={phoneNumber}
            render={({ formatted, href }) => (
              <PhoneNumText>
                <PhoneBannerText>
                  Our Family Care Team is available 24/7
                </PhoneBannerText>
                <Call>
                  <PhoneIcon src={'/img/brochure/phone.png'} /> Call{' '}
                  <a href={href}>
                    {' '}
                    <PhoneNum>{formatted}</PhoneNum>
                  </a>
                </Call>
              </PhoneNumText>
            )}
          />
        </PhoneBanner>
      )}
    </MobileOnly>
  </div>
);

export default HomeHero;
