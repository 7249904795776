// @flow
// County map: https://www.maps.com/california-counties-wall-map.html
// Line drawing tool: https://www.doogal.co.uk/polylines.php
// Perfect way: https://gis.stackexchange.com/questions/183248/getting-polygon-boundaries-of-city-in-json-from-google-maps-api

import * as northernCalifornia from './northernCalifornia';
import * as southernCalifornia from './southernCalifornia';
import washingtonSeattle from './washingtonSeattle';
import * as spaceCoast from './spaceCoast';
import * as gulfCoast from './gulfCoast';
import * as colorado from './colorado';

const coordinates = {
  ...northernCalifornia,
  ...southernCalifornia,
  washingtonSeattle,
  ...spaceCoast,
  ...gulfCoast,
  ...colorado,
};

export default {
  sfCenter: { lat: 37.27245, lng: -121.099548 },
  sfZoom: 7,

  laCenter: { lat: 34.242528, lng: -118.264494 },
  laZoom: 8,

  caliCenter: { lat: 37.653257, lng: -119.955514 },
  caliZoom: 6,

  caliTexasCenter: { lat: 34.72456055725045, lng: -108.18448141395172 },
  caliTexasZoom: 4.5,

  caliTexasFloridaCenter: { lat: 38, lng: -104 },
  caliTexasFloridaZoom: 4.25,

  washingtonCenter: { lat: 47.3865308, lng: -120.5259902 },
  coloradoCenter: { lat: 39.7392, lng: -104.9903 },
  floridaCenter: { lat: 28.0077513, lng: -81.7692203 },

  coordinates,

  serviceArea: Object.keys(coordinates).reduce(
    (acc, c) =>
      ['sacramentoCounty', 'santaClaraCounty', 'fresnoCounty'].includes(c)
        ? acc
        : [...acc, [...coordinates[c]]],
    []
  ),
};
