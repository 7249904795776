import styled from 'styled-components';

const TopHeading = styled.h2`
  font-family: Brown;
  font-size: 16px;
  font-weight: 100;
  text-align: center;
  margin: 0;
  color: ${({ theme }) => theme.navy};
`;

export default TopHeading;
