// @flow
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  title: string,
  children: React.Node,
  breakpoint: number,
  image?: string,
  first?: boolean,
  last?: boolean,
  href: string,
};

const Inner = styled.div`
  padding: 0 18%;
  border: 0 #d3e4e5 solid;
  border-right-width: ${p => (p.bordered && !p.last ? '2px' : '0')};

  > h3 {
    font-weight: normal;
    font-size: 18px;
    color: ${p => p.theme.textHard};
    margin: 0px 0 30px;
  }

  > a > img {
    width: 200px;
  }

  @media (max-width: ${p => p.breakpoint}px) {
    text-align: center;
    padding: 0;
    max-width: 400px;
    margin: 0 auto;
    border-width: 0;

    ${p =>
      p.bordered &&
      !p.last &&
      `
      border-bottom-width: 2px;
      padding-bottom: 25px;
      margin-bottom: 25px;
    `};
  }
`;

const ClickableHeadline = styled('a')`
  text-decoration: none;
`;

export default function DifferenceItem({
  title,
  image,
  children,
  href,
  ...rest
}: Props) {
  return (
    <React.Fragment>
      <Inner {...rest}>
        <ClickableHeadline href={href}>
          <img src={image || '/img/flower.png'} />
        </ClickableHeadline>
      </Inner>
      <Inner bordered {...rest}>
        <h3>
          <ClickableHeadline href={href}>{title}</ClickableHeadline>
        </h3>
        <p>{children}</p>
      </Inner>
    </React.Fragment>
  );
}
