export const pascoCounty = [
  { lng: -82.905, lat: 28.168 },
  { lng: -82.908, lat: 28.169 },
  { lng: -82.91, lat: 28.177 },
  { lng: -82.91, lat: 28.189 },
  { lng: -82.912, lat: 28.193 },
  { lng: -82.912, lat: 28.214 },
  { lng: -82.909, lat: 28.226 },
  { lng: -82.904, lat: 28.236 },
  { lng: -82.888, lat: 28.255 },
  { lng: -82.875, lat: 28.262 },
  { lng: -82.845, lat: 28.267 },
  { lng: -82.837, lat: 28.267 },
  { lng: -82.823, lat: 28.264 },
  { lng: -82.82, lat: 28.275 },
  { lng: -82.812, lat: 28.29 },
  { lng: -82.798, lat: 28.303 },
  { lng: -82.797, lat: 28.319 },
  { lng: -82.801, lat: 28.326 },
  { lng: -82.805, lat: 28.339 },
  { lng: -82.804, lat: 28.356 },
  { lng: -82.799, lat: 28.372 },
  { lng: -82.79, lat: 28.382 },
  { lng: -82.785, lat: 28.4 },
  { lng: -82.772, lat: 28.414 },
  { lng: -82.765, lat: 28.419 },
  { lng: -82.765, lat: 28.423 },
  { lng: -82.756, lat: 28.437 },
  { lng: -82.257, lat: 28.439 },
  { lng: -82.257, lat: 28.481 },
  { lng: -82.253, lat: 28.483 },
  { lng: -82.195, lat: 28.482 },
  { lng: -82.099, lat: 28.483 },
  { lng: -82.051, lat: 28.481 },
  { lng: -82.052, lat: 28.257 },
  { lng: -82.054, lat: 28.255 },
  { lng: -82.102, lat: 28.255 },
  { lng: -82.102, lat: 28.17 },
  { lng: -82.104, lat: 28.168 },
  { lng: -82.238, lat: 28.168 },
  { lng: -82.367, lat: 28.166 },
  { lng: -82.516, lat: 28.169 },
  { lng: -82.905, lat: 28.168 },
];

export const pinellasCounty = [
  { lng: -82.908, lat: 28.171 },
  { lng: -82.909, lat: 28.172 },
  { lng: -82.908, lat: 28.175 },
  { lng: -82.906, lat: 28.176 },
  { lng: -82.649, lat: 28.177 },
  { lng: -82.647, lat: 28.175 },
  { lng: -82.644, lat: 28.057 },
  { lng: -82.644, lat: 27.951 },
  { lng: -82.612, lat: 27.945 },
  { lng: -82.595, lat: 27.939 },
  { lng: -82.585, lat: 27.932 },
  { lng: -82.574, lat: 27.918 },
  { lng: -82.566, lat: 27.901 },
  { lng: -82.565, lat: 27.894 },
  { lng: -82.563, lat: 27.872 },
  { lng: -82.562, lat: 27.818 },
  { lng: -82.56, lat: 27.808 },
  { lng: -82.556, lat: 27.8 },
  { lng: -82.539, lat: 27.791 },
  { lng: -82.537, lat: 27.787 },
  { lng: -82.538, lat: 27.781 },
  { lng: -82.549, lat: 27.754 },
  { lng: -82.604, lat: 27.64 },
  { lng: -82.693, lat: 27.641 },
  { lng: -82.693, lat: 27.637 },
  { lng: -82.698, lat: 27.625 },
  { lng: -82.701, lat: 27.622 },
  { lng: -82.705, lat: 27.619 },
  { lng: -82.724, lat: 27.612 },
  { lng: -82.734, lat: 27.606 },
  { lng: -82.737, lat: 27.606 },
  { lng: -82.743, lat: 27.609 },
  { lng: -82.744, lat: 27.611 },
  { lng: -82.743, lat: 27.618 },
  { lng: -82.744, lat: 27.624 },
  { lng: -82.746, lat: 27.631 },
  { lng: -82.751, lat: 27.637 },
  { lng: -82.751, lat: 27.642 },
  { lng: -82.817, lat: 27.643 },
  { lng: -82.819, lat: 27.654 },
  { lng: -82.817, lat: 27.676 },
  { lng: -82.808, lat: 27.694 },
  { lng: -82.81, lat: 27.708 },
  { lng: -82.846, lat: 27.754 },
  { lng: -82.879, lat: 27.793 },
  { lng: -82.898, lat: 27.823 },
  { lng: -82.905, lat: 27.837 },
  { lng: -82.911, lat: 27.858 },
  { lng: -82.913, lat: 27.891 },
  { lng: -82.902, lat: 27.946 },
  { lng: -82.892, lat: 27.965 },
  { lng: -82.893, lat: 27.969 },
  { lng: -82.888, lat: 27.992 },
  { lng: -82.888, lat: 28 },
  { lng: -82.891, lat: 28.014 },
  { lng: -82.889, lat: 28.026 },
  { lng: -82.884, lat: 28.041 },
  { lng: -82.894, lat: 28.052 },
  { lng: -82.901, lat: 28.072 },
  { lng: -82.901, lat: 28.081 },
  { lng: -82.899, lat: 28.087 },
  { lng: -82.897, lat: 28.107 },
  { lng: -82.906, lat: 28.12 },
  { lng: -82.908, lat: 28.128 },
  { lng: -82.909, lat: 28.147 },
  { lng: -82.906, lat: 28.157 },
  { lng: -82.908, lat: 28.171 },
];

export const hillsboroughCounty = [
  { lng: -82.827, lat: 27.57 },
  { lng: -82.828, lat: 27.59 },
  { lng: -82.825, lat: 27.607 },
  { lng: -82.815, lat: 27.637 },
  { lng: -82.817, lat: 27.648 },
  { lng: -82.814, lat: 27.65 },
  { lng: -82.744, lat: 27.649 },
  { lng: -82.743, lat: 27.639 },
  { lng: -82.738, lat: 27.635 },
  { lng: -82.735, lat: 27.619 },
  { lng: -82.736, lat: 27.615 },
  { lng: -82.705, lat: 27.629 },
  { lng: -82.701, lat: 27.64 },
  { lng: -82.701, lat: 27.646 },
  { lng: -82.697, lat: 27.649 },
  { lng: -82.609, lat: 27.648 },
  { lng: -82.545, lat: 27.784 },
  { lng: -82.549, lat: 27.788 },
  { lng: -82.556, lat: 27.79 },
  { lng: -82.561, lat: 27.794 },
  { lng: -82.568, lat: 27.806 },
  { lng: -82.57, lat: 27.817 },
  { lng: -82.572, lat: 27.884 },
  { lng: -82.574, lat: 27.899 },
  { lng: -82.581, lat: 27.914 },
  { lng: -82.594, lat: 27.928 },
  { lng: -82.603, lat: 27.934 },
  { lng: -82.623, lat: 27.939 },
  { lng: -82.649, lat: 27.943 },
  { lng: -82.652, lat: 27.945 },
  { lng: -82.653, lat: 27.966 },
  { lng: -82.652, lat: 28.057 },
  { lng: -82.655, lat: 28.144 },
  { lng: -82.655, lat: 28.175 },
  { lng: -82.653, lat: 28.177 },
  { lng: -82.516, lat: 28.177 },
  { lng: -82.379, lat: 28.174 },
  { lng: -82.238, lat: 28.176 },
  { lng: -82.055, lat: 28.176 },
  { lng: -82.053, lat: 28.174 },
  { lng: -82.05, lat: 27.851 },
  { lng: -82.051, lat: 27.789 },
  { lng: -82.05, lat: 27.646 },
  { lng: -82.052, lat: 27.643 },
  { lng: -82.054, lat: 27.642 },
  { lng: -82.334, lat: 27.643 },
  { lng: -82.497, lat: 27.641 },
  { lng: -82.549, lat: 27.642 },
  { lng: -82.574, lat: 27.634 },
  { lng: -82.675, lat: 27.593 },
  { lng: -82.744, lat: 27.567 },
  { lng: -82.825, lat: 27.567 },
  { lng: -82.827, lat: 27.57 },
];

export const sarasotaCounty = [
  { lng: -82.705, lat: 27.394 },
  { lng: -82.445, lat: 27.392 },
  { lng: -82.443, lat: 27.39 },
  { lng: -82.351, lat: 27.391 },
  { lng: -82.249, lat: 27.389 },
  { lng: -82.248, lat: 27.375 },
  { lng: -82.25, lat: 27.213 },
  { lng: -82.056, lat: 27.212 },
  { lng: -82.053, lat: 27.21 },
  { lng: -82.052, lat: 27.208 },
  { lng: -82.054, lat: 27.135 },
  { lng: -82.053, lat: 27.12 },
  { lng: -82.054, lat: 27.03 },
  { lng: -82.056, lat: 27.028 },
  { lng: -82.251, lat: 27.029 },
  { lng: -82.251, lat: 26.949 },
  { lng: -82.252, lat: 26.943 },
  { lng: -82.255, lat: 26.941 },
  { lng: -82.439, lat: 26.942 },
  { lng: -82.463, lat: 26.978 },
  { lng: -82.472, lat: 26.998 },
  { lng: -82.503, lat: 27.041 },
  { lng: -82.511, lat: 27.061 },
  { lng: -82.516, lat: 27.079 },
  { lng: -82.527, lat: 27.095 },
  { lng: -82.531, lat: 27.118 },
  { lng: -82.538, lat: 27.135 },
  { lng: -82.554, lat: 27.164 },
  { lng: -82.559, lat: 27.178 },
  { lng: -82.562, lat: 27.193 },
  { lng: -82.568, lat: 27.203 },
  { lng: -82.583, lat: 27.219 },
  { lng: -82.609, lat: 27.235 },
  { lng: -82.62, lat: 27.247 },
  { lng: -82.627, lat: 27.259 },
  { lng: -82.629, lat: 27.273 },
  { lng: -82.635, lat: 27.293 },
  { lng: -82.641, lat: 27.301 },
  { lng: -82.667, lat: 27.325 },
  { lng: -82.678, lat: 27.339 },
  { lng: -82.682, lat: 27.342 },
  { lng: -82.705, lat: 27.378 },
  { lng: -82.709, lat: 27.388 },
  { lng: -82.709, lat: 27.392 },
  { lng: -82.705, lat: 27.394 },
];

export const manateeCounty = [
  { lng: -82.827, lat: 27.57 },
  { lng: -82.827, lat: 27.572 },
  { lng: -82.823, lat: 27.575 },
  { lng: -82.746, lat: 27.575 },
  { lng: -82.55, lat: 27.65 },
  { lng: -82.541, lat: 27.649 },
  { lng: -82.334, lat: 27.651 },
  { lng: -82.052, lat: 27.65 },
  { lng: -82.05, lat: 27.647 },
  { lng: -82.053, lat: 27.274 },
  { lng: -82.052, lat: 27.208 },
  { lng: -82.054, lat: 27.204 },
  { lng: -82.255, lat: 27.205 },
  { lng: -82.258, lat: 27.208 },
  { lng: -82.256, lat: 27.382 },
  { lng: -82.449, lat: 27.382 },
  { lng: -82.45, lat: 27.384 },
  { lng: -82.707, lat: 27.386 },
  { lng: -82.724, lat: 27.399 },
  { lng: -82.739, lat: 27.414 },
  { lng: -82.758, lat: 27.449 },
  { lng: -82.769, lat: 27.475 },
  { lng: -82.787, lat: 27.498 },
  { lng: -82.819, lat: 27.534 },
  { lng: -82.825, lat: 27.554 },
  { lng: -82.827, lat: 27.57 },
];

export const polkCounty = [
  { lng: -82.125, lat: 28.17 },
  { lng: -82.125, lat: 28.27 },
  { lng: -82.115, lat: 28.28 },
  { lng: -82.075, lat: 28.28 },
  { lng: -82.075, lat: 28.325 },
  { lng: -82.05, lat: 28.34 },
  { lng: -82, lat: 28.345 },
  { lng: -81.98, lat: 28.335 },
  { lng: -81.965, lat: 28.365 },
  { lng: -81.875, lat: 28.365 },
  { lng: -81.87, lat: 28.38 },
  { lng: -81.78, lat: 28.38 },
  { lng: -81.77, lat: 28.365 },
  { lng: -81.645, lat: 28.365 },
  { lng: -81.635, lat: 28.35 },
  { lng: -81.635, lat: 28.28 },
  { lng: -81.55, lat: 28.28 },
  { lng: -81.505, lat: 28.21 },
  { lng: -81.505, lat: 28.165 },
  { lng: -81.45, lat: 28.165 },
  { lng: -81.435, lat: 28.145 },
  { lng: -81.435, lat: 28.105 },
  { lng: -81.33, lat: 28.1 },
  { lng: -81.325, lat: 28.065 },
  { lng: -81.355, lat: 28.02 },
  { lng: -81.36, lat: 27.995 },
  { lng: -81.33, lat: 27.975 },
  { lng: -81.31, lat: 27.945 },
  { lng: -81.295, lat: 27.94 },
  { lng: -81.295, lat: 27.905 },
  { lng: -81.285, lat: 27.895 },
  { lng: -81.285, lat: 27.875 },
  { lng: -81.225, lat: 27.865 },
  { lng: -81.2, lat: 27.845 },
  { lng: -81.18, lat: 27.8 },
  { lng: -81.155, lat: 27.775 },
  { lng: -81.145, lat: 27.735 },
  { lng: -81.125, lat: 27.715 },
  { lng: -81.125, lat: 27.68 },
  { lng: -81.11, lat: 27.665 },
  { lng: -81.115, lat: 27.635 },
  { lng: -81.125, lat: 27.625 },
  { lng: -82.07, lat: 27.63 },
  { lng: -82.075, lat: 28.15 },
  { lng: -82.11, lat: 28.15 },
  { lng: -82.12, lat: 28.155 },
  { lng: -82.125, lat: 28.17 },
];
