// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { BulletListV2, Heading } from '../components';
import TopHeading from '../components/TopHeading';

const P = styled.p`
  font-family: Brown;
  font-size: 16px;
  font-weight: ${props => props.fontWeight || 300};
  line-height: 1.5;
  color: #4b637a;
  text-align: left;
`;
const MainHeading = styled(Heading)`
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 32px;
  line-height: 1.13;
`;
const texts = {
  heroHeader: 'Cremation services your family can trust',
  heroText:
    'Tulip provides a simple direct cremation package that you can arrange online or over the phone. No funeral home visit required.',

  tulipDifference: [
    {
      image: '/img/brochure/promise-simple.jpg',
      title: 'Simple process',
      text:
        'Make the arrangements online or over the phone in less than 15 minutes.',
    },
    {
      image: '/img/brochure/promise-transparent.jpg',
      title: 'Transparent prices',
      text:
        'We will never pressure you into purchasing products or services you don’t need.',
    },
    {
      image: '/img/brochure/promise-care.jpg',
      title: 'Exceptional care',
      text:
        'Our 24/7 family care team looks after you at each step of the process.',
      last: true,
    },
  ],

  packageContent: function PackageContent() {
    return (
      <Fragment>
        <TopHeading>OUR SERVICE</TopHeading>
        <MainHeading size="small">The Tulip Package</MainHeading>
        <P>
          Direct cremation takes place in the days immediately following the
          passing and without a funeral service beforehand.
        </P>
        <P fontWeight={400}>
          Our direct cremation package includes everything you need:
        </P>

        <BulletListV2
          items={[
            'Collection & care of your loved one',
            'Filing of necessary paperwork & permits',
            'Private cremation',
            'Return of your loved one’s ashes',
          ]}
        />
      </Fragment>
    );
  },
  benefitsContent: function BenefitsContent() {
    return (
      <Fragment>
        <TopHeading>OUR SERVICE</TopHeading>
        <MainHeading size="small">The benefits of direct cremation</MainHeading>
        <P>
          Direct cremation is the simplest funeral option, where the cremation
          takes place in the days immediately following the passing, and without
          a viewing or funeral service beforehand.
        </P>
        <P>
          Many families hold a memorial or celebration of life service at a
          later date - with a direct cremation you’re able to arrange a much
          more personalized service, without the time and cost-pressure of a
          traditional funeral home.
        </P>
      </Fragment>
    );
  },
  preNeedContent: function PreNeedContent() {
    return (
      <Fragment>
        <Heading>Plan Ahead with Tulip</Heading>
        <p>
          Losing a loved one is challenging enough. Ease the burden on family
          and friends through advanced planning and payment.
        </p>

        <BulletListV2
          items={[
            'Choose your funeral preference',
            'Lock in today’s prices to beat inflation and rising funeral costs',
            'Ease the burden on family and friends through prepayment',
            'Know that you’ll be in safe hands with Tulip',
          ]}
        />
      </Fragment>
    );
  },
};

export default texts;
