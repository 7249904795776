// @flow
import * as React from 'react';
import styled from 'styled-components';
import {
  Col,
  Cols,
  Container,
  Section,
  SideBubblePic,
  LinkButton,
} from 'components';

const BREAKPOINT = 920;

type Props = {
  content: Function,
};

const HomePreNeed = ({ content }: Props) => (
  <Section style={{ position: 'relative' }}>
    <SideBubblePic
      url="/img/brochure/photo-homepage-preneed.jpg"
      side="right"
      imageGravity="center top"
      breakpoint={BREAKPOINT}
    />
    <Container h="standard" v="standalone" vBreakpoint={BREAKPOINT}>
      <Cols breakpoint={BREAKPOINT}>
        <Col>
          <LeftWrapper>
            <CenterSolo>
              {content()}
              <LinkButton tertiary to="/plan-ahead" label="Learn more" />
            </CenterSolo>
          </LeftWrapper>
        </Col>
        <Col />
      </Cols>
    </Container>
  </Section>
);

const LeftWrapper = styled('div')`
  padding: 0 25% 0 10%;

  @media (max-width: ${BREAKPOINT}px) {
    padding: 0;
    max-width: 560px;
    margin: 0 auto;
  }
`;

const CenterSolo = styled('div')`
  @media (max-width: ${BREAKPOINT}px) {
    text-align: center;

    ul {
      text-align: left;
    }
  }
`;

export default HomePreNeed;
