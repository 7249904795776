// @flow
import * as React from 'react';
import styled from 'styled-components';
import {
  Col,
  Cols,
  Container,
  QuoteButton,
  Section,
  SideBubblePic,
} from 'components';

const BREAKPOINT = 920;

type Props = {
  content: Function,
  isSpanish?: boolean,
};

const HomePackage = ({ content, isSpanish }: Props) => (
  <Section style={{ position: 'relative' }}>
    <ClickablePic href="/our-service">
      <SideBubblePic
        url="/img/brochure/photo-holding-flowers.jpg"
        side="left"
        imageGravity="center top"
        breakpoint={BREAKPOINT}
      />
    </ClickablePic>
    <Container h="standard" v="standalone" vBreakpoint={BREAKPOINT}>
      <Cols breakpoint={BREAKPOINT}>
        <Col />
        <Col>
          <RightWrapper>
            <CenterSolo>
              {content()}
              <QuotePrompt>
                {!isSpanish && (
                  <QuoteButton
                    source="HomePackage"
                    inactiveLabel="Get an instant quote"
                  />
                )}
              </QuotePrompt>
            </CenterSolo>
          </RightWrapper>
        </Col>
      </Cols>
    </Container>
  </Section>
);

const ClickablePic = styled('a')`
  text-decoration: none;
`;

const RightWrapper = styled('div')`
  padding: 0 5%;
  @media (max-width: ${BREAKPOINT}px) {
    padding: 0;
    max-width: 560px;
    margin: 0 auto;
  }
  @media (min-width: 1320px) {
    padding-left: 15%;
  }
`;

const CenterSolo = styled('div')`
  @media (max-width: ${BREAKPOINT}px) {
    text-align: center;
  }
`;

const QuotePrompt = styled('div')`
  margin: 30px 0;
`;

export default HomePackage;
