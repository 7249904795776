// @flow
export const gilpin = [
  { lng: -105.700773, lat: 39.878942 },
  { lng: -105.699154, lat: 39.874141 },
  { lng: -105.696851, lat: 39.8717 },
  { lng: -105.697769, lat: 39.867986 },
  { lng: -105.696938, lat: 39.865501 },
  { lng: -105.696125, lat: 39.861516 },
  { lng: -105.693577, lat: 39.858228 },
  { lng: -105.692699, lat: 39.854993 },
  { lng: -105.690348, lat: 39.851996 },
  { lng: -105.688849, lat: 39.851404 },
  { lng: -105.686462, lat: 39.849229 },
  { lng: -105.682994, lat: 39.848365 },
  { lng: -105.681445, lat: 39.84749 },
  { lng: -105.677347, lat: 39.847431 },
  { lng: -105.67534, lat: 39.849763 },
  { lng: -105.671752, lat: 39.84798 },
  { lng: -105.667405, lat: 39.84758 },
  { lng: -105.663311, lat: 39.850036 },
  { lng: -105.660471, lat: 39.850568 },
  { lng: -105.654638, lat: 39.849228 },
  { lng: -105.651756, lat: 39.848261 },
  { lng: -105.64996, lat: 39.846772 },
  { lng: -105.648023, lat: 39.846315 },
  { lng: -105.643902, lat: 39.846472 },
  { lng: -105.640126, lat: 39.844937 },
  { lng: -105.636176, lat: 39.844554 },
  { lng: -105.633625, lat: 39.843764 },
  { lng: -105.631151, lat: 39.839338 },
  { lng: -105.631485, lat: 39.837678 },
  { lng: -105.630241, lat: 39.836562 },
  { lng: -105.627569, lat: 39.832087 },
  { lng: -105.625557, lat: 39.83121 },
  { lng: -105.623029, lat: 39.829141 },
  { lng: -105.621265, lat: 39.828962 },
  { lng: -105.616328, lat: 39.825541 },
  { lng: -105.61363, lat: 39.822689 },
  { lng: -105.611563, lat: 39.824734 },
  { lng: -105.604666, lat: 39.82199 },
  { lng: -105.602664, lat: 39.82044 },
  { lng: -105.602422, lat: 39.818052 },
  { lng: -105.59745, lat: 39.818813 },
  { lng: -105.594664, lat: 39.820136 },
  { lng: -105.592021, lat: 39.822694 },
  { lng: -105.588638, lat: 39.823028 },
  { lng: -105.5868, lat: 39.822025 },
  { lng: -105.584643, lat: 39.822458 },
  { lng: -105.583017, lat: 39.82284 },
  { lng: -105.578037, lat: 39.822268 },
  { lng: -105.574178, lat: 39.819013 },
  { lng: -105.576071, lat: 39.814329 },
  { lng: -105.578836, lat: 39.813715 },
  { lng: -105.579716, lat: 39.812833 },
  { lng: -105.578541, lat: 39.811 },
  { lng: -105.57805, lat: 39.805562 },
  { lng: -105.57192, lat: 39.802362 },
  { lng: -105.571353, lat: 39.801232 },
  { lng: -105.566412, lat: 39.800336 },
  { lng: -105.5641, lat: 39.800363 },
  { lng: -105.56157, lat: 39.795861 },
  { lng: -105.560411, lat: 39.794869 },
  { lng: -105.558322, lat: 39.795322 },
  { lng: -105.555266, lat: 39.794249 },
  { lng: -105.55434, lat: 39.792028 },
  { lng: -105.54598, lat: 39.786983 },
  { lng: -105.548762, lat: 39.784635 },
  { lng: -105.548184, lat: 39.781777 },
  { lng: -105.544675, lat: 39.77799 },
  { lng: -105.544255, lat: 39.77692 },
  { lng: -105.545076, lat: 39.773635 },
  { lng: -105.541308, lat: 39.77381 },
  { lng: -105.538605, lat: 39.77162 },
  { lng: -105.530208, lat: 39.769078 },
  { lng: -105.525589, lat: 39.768686 },
  { lng: -105.521806, lat: 39.767002 },
  { lng: -105.521284, lat: 39.766094 },
  { lng: -105.518501, lat: 39.765114 },
  { lng: -105.516509, lat: 39.764984 },
  { lng: -105.512221, lat: 39.765986 },
  { lng: -105.510188, lat: 39.765815 },
  { lng: -105.50829, lat: 39.764147 },
  { lng: -105.504083, lat: 39.763189 },
  { lng: -105.501389, lat: 39.763008 },
  { lng: -105.494253, lat: 39.763798 },
  { lng: -105.493861, lat: 39.762718 },
  { lng: -105.496717, lat: 39.760322 },
  { lng: -105.495971, lat: 39.75848 },
  { lng: -105.496883, lat: 39.757796 },
  { lng: -105.492967, lat: 39.756906 },
  { lng: -105.49071, lat: 39.757189 },
  { lng: -105.489511, lat: 39.758162 },
  { lng: -105.486365, lat: 39.758412 },
  { lng: -105.48403, lat: 39.759205 },
  { lng: -105.478935, lat: 39.759083 },
  { lng: -105.472408, lat: 39.757509 },
  { lng: -105.469781, lat: 39.756536 },
  { lng: -105.46569, lat: 39.756359 },
  { lng: -105.462654, lat: 39.757018 },
  { lng: -105.461298, lat: 39.755612 },
  { lng: -105.454629, lat: 39.75579 },
  { lng: -105.451042, lat: 39.75379 },
  { lng: -105.450328, lat: 39.752882 },
  { lng: -105.447353, lat: 39.753491 },
  { lng: -105.447285, lat: 39.75352 },
  { lng: -105.446103, lat: 39.753994 },
  { lng: -105.445071, lat: 39.754234 },
  { lng: -105.44139, lat: 39.755518 },
  { lng: -105.437978, lat: 39.755855 },
  { lng: -105.4302, lat: 39.754627 },
  { lng: -105.42735, lat: 39.755798 },
  { lng: -105.421994, lat: 39.755018 },
  { lng: -105.422435, lat: 39.75086 },
  { lng: -105.423552, lat: 39.749915 },
  { lng: -105.423258, lat: 39.748216 },
  { lng: -105.420875, lat: 39.745957 },
  { lng: -105.418267, lat: 39.747235 },
  { lng: -105.414982, lat: 39.749501 },
  { lng: -105.409672, lat: 39.749889 },
  { lng: -105.406576, lat: 39.751395 },
  { lng: -105.40323, lat: 39.750214 },
  { lng: -105.402165, lat: 39.748902 },
  { lng: -105.397949, lat: 39.747087 },
  { lng: -105.397947, lat: 39.748824 },
  { lng: -105.397949, lat: 39.814886 },
  { lng: -105.398043, lat: 39.816027 },
  { lng: -105.397949, lat: 39.839987 },
  { lng: -105.39745, lat: 39.853697 },
  { lng: -105.397503, lat: 39.854334 },
  { lng: -105.397949, lat: 39.860387 },
  { lng: -105.397568, lat: 39.866061 },
  { lng: -105.397999, lat: 39.871433 },
  { lng: -105.397949, lat: 39.881087 },
  { lng: -105.397649, lat: 39.883187 },
  { lng: -105.397822, lat: 39.908349 },
  { lng: -105.397849, lat: 39.912886 },
  { lng: -105.398167, lat: 39.929543 },
  { lng: -105.397955, lat: 39.932985 },
  { lng: -105.397971, lat: 39.934867 },
  { lng: -105.399224, lat: 39.934817 },
  { lng: -105.400687, lat: 39.935072 },
  { lng: -105.406039, lat: 39.931743 },
  { lng: -105.411525, lat: 39.93269 },
  { lng: -105.413172, lat: 39.934497 },
  { lng: -105.415027, lat: 39.933245 },
  { lng: -105.415506, lat: 39.93157 },
  { lng: -105.417326, lat: 39.930669 },
  { lng: -105.41911, lat: 39.928819 },
  { lng: -105.420814, lat: 39.930901 },
  { lng: -105.422063, lat: 39.931626 },
  { lng: -105.425886, lat: 39.931078 },
  { lng: -105.429325, lat: 39.931209 },
  { lng: -105.430831, lat: 39.931764 },
  { lng: -105.43255, lat: 39.933406 },
  { lng: -105.438404, lat: 39.937332 },
  { lng: -105.438472, lat: 39.937531 },
  { lng: -105.44042, lat: 39.938372 },
  { lng: -105.443416, lat: 39.93796 },
  { lng: -105.444779, lat: 39.937207 },
  { lng: -105.447997, lat: 39.936493 },
  { lng: -105.449, lat: 39.935516 },
  { lng: -105.449483, lat: 39.935206 },
  { lng: -105.453215, lat: 39.934229 },
  { lng: -105.455651, lat: 39.932286 },
  { lng: -105.457484, lat: 39.932187 },
  { lng: -105.501154, lat: 39.932388 },
  { lng: -105.510981, lat: 39.932294 },
  { lng: -105.515434, lat: 39.932387 },
  { lng: -105.518249, lat: 39.932387 },
  { lng: -105.519031, lat: 39.93229 },
  { lng: -105.520353, lat: 39.932387 },
  { lng: -105.625668, lat: 39.932386 },
  { lng: -105.62877, lat: 39.932086 },
  { lng: -105.675798, lat: 39.932445 },
  { lng: -105.677881, lat: 39.930717 },
  { lng: -105.676614, lat: 39.928565 },
  { lng: -105.67888, lat: 39.927653 },
  { lng: -105.682272, lat: 39.921908 },
  { lng: -105.683001, lat: 39.917254 },
  { lng: -105.685735, lat: 39.915827 },
  { lng: -105.686507, lat: 39.912838 },
  { lng: -105.687859, lat: 39.911701 },
  { lng: -105.688532, lat: 39.907149 },
  { lng: -105.687233, lat: 39.904193 },
  { lng: -105.690315, lat: 39.9019 },
  { lng: -105.691759, lat: 39.898043 },
  { lng: -105.691176, lat: 39.897215 },
  { lng: -105.694483, lat: 39.895917 },
  { lng: -105.697913, lat: 39.893999 },
  { lng: -105.699719, lat: 39.891499 },
  { lng: -105.699038, lat: 39.887894 },
  { lng: -105.699425, lat: 39.885873 },
  { lng: -105.697789, lat: 39.884455 },
  { lng: -105.698383, lat: 39.880956 },
  { lng: -105.700773, lat: 39.878942 },
];

export const weld = [
  { lng: -105.05672, lat: 40.349281 },
  { lng: -105.055135, lat: 40.335183 },
  { lng: -105.055221, lat: 40.321045 },
  { lng: -105.055326, lat: 40.319905 },
  { lng: -105.05512, lat: 40.308232 },
  { lng: -105.055145, lat: 40.305421 },
  { lng: -105.055166, lat: 40.296766 },
  { lng: -105.055175, lat: 40.290883 },
  { lng: -105.055089, lat: 40.269286 },
  { lng: -105.055089, lat: 40.261793 },
  { lng: -105.0551, lat: 40.25612 },
  { lng: -105.055074, lat: 40.247157 },
  { lng: -105.055184, lat: 40.20368 },
  { lng: -105.055201, lat: 40.191162 },
  { lng: -105.055214, lat: 40.189149 },
  { lng: -105.055203, lat: 40.18445 },
  { lng: -105.055202, lat: 40.182224 },
  { lng: -105.0552, lat: 40.182045 },
  { lng: -105.055199, lat: 40.181935 },
  { lng: -105.055198, lat: 40.181851 },
  { lng: -105.05523, lat: 40.177452 },
  { lng: -105.055251, lat: 40.174589 },
  { lng: -105.055332, lat: 40.160192 },
  { lng: -105.055336, lat: 40.155576 },
  { lng: -105.055355, lat: 40.15339 },
  { lng: -105.055333, lat: 40.152287 },
  { lng: -105.055334, lat: 40.152024 },
  { lng: -105.055376, lat: 40.14457 },
  { lng: -105.055393, lat: 40.138263 },
  { lng: -105.055432, lat: 40.087832 },
  { lng: -105.05543, lat: 40.087512 },
  { lng: -105.055466, lat: 40.069364 },
  { lng: -105.055471, lat: 40.065828 },
  { lng: -105.055477, lat: 40.062879 },
  { lng: -105.055476, lat: 40.051468 },
  { lng: -105.055465, lat: 40.046576 },
  { lng: -105.055469, lat: 40.046335 },
  { lng: -105.055462, lat: 40.036636 },
  { lng: -105.055372, lat: 40.029309 },
  { lng: -105.055392, lat: 40.021971 },
  { lng: -105.055448, lat: 40.014774 },
  { lng: -105.055509, lat: 40.009899 },
  { lng: -105.055515, lat: 40.00948 },
  { lng: -105.055569, lat: 40.005659 },
  { lng: -105.055642, lat: 40.00025 },
  { lng: -105.055518, lat: 40.000251 },
  { lng: -105.052823, lat: 40.00026 },
  { lng: -105.037067, lat: 40.000467 },
  { lng: -105.02239, lat: 40.000473 },
  { lng: -105.007906, lat: 40.000483 },
  { lng: -105.007932, lat: 40.002908 },
  { lng: -105.007954, lat: 40.00578 },
  { lng: -105.007995, lat: 40.007581 },
  { lng: -104.989647, lat: 40.007679 },
  { lng: -104.989614, lat: 40.01489 },
  { lng: -104.999065, lat: 40.014887 },
  { lng: -104.99907, lat: 40.016131 },
  { lng: -104.996176, lat: 40.019432 },
  { lng: -104.996784, lat: 40.02082 },
  { lng: -104.996742, lat: 40.023509 },
  { lng: -104.999005, lat: 40.023509 },
  { lng: -104.999007, lat: 40.025776 },
  { lng: -104.986543, lat: 40.025799 },
  { lng: -104.9865, lat: 40.029309 },
  { lng: -104.980048, lat: 40.029411 },
  { lng: -104.980041, lat: 40.040316 },
  { lng: -104.979905, lat: 40.042111 },
  { lng: -104.979242, lat: 40.043758 },
  { lng: -104.978526, lat: 40.043888 },
  { lng: -104.970727, lat: 40.044001 },
  { lng: -104.961157, lat: 40.044144 },
  { lng: -104.96132, lat: 40.014982 },
  { lng: -104.961538, lat: 40.000404 },
  { lng: -104.961405, lat: 40.000419 },
  { lng: -104.961405, lat: 40.000337 },
  { lng: -104.959306, lat: 40.00033 },
  { lng: -104.941144, lat: 40.000335 },
  { lng: -104.923315, lat: 40.000328 },
  { lng: -104.922153, lat: 40.00033 },
  { lng: -104.885967, lat: 40.000356 },
  { lng: -104.867116, lat: 40.00037 },
  { lng: -104.827414, lat: 40.000403 },
  { lng: -104.826659, lat: 40.000403 },
  { lng: -104.821965, lat: 40.000412 },
  { lng: -104.821478, lat: 40.000412 },
  { lng: -104.816959, lat: 40.000421 },
  { lng: -104.816048, lat: 40.000418 },
  { lng: -104.79705, lat: 40.000454 },
  { lng: -104.771199, lat: 40.000451 },
  { lng: -104.75762, lat: 40.000468 },
  { lng: -104.753728, lat: 40.000471 },
  { lng: -104.73073, lat: 40.000506 },
  { lng: -104.726572, lat: 40.000508 },
  { lng: -104.71621, lat: 40.000511 },
  { lng: -104.714754, lat: 40.000511 },
  { lng: -104.713619, lat: 40.000508 },
  { lng: -104.706544, lat: 40.000506 },
  { lng: -104.629424, lat: 40.000542 },
  { lng: -104.604649, lat: 40.000587 },
  { lng: -104.604064, lat: 40.00056 },
  { lng: -104.602364, lat: 40.000546 },
  { lng: -104.60186, lat: 40.000529 },
  { lng: -104.489757, lat: 40.000613 },
  { lng: -104.41463, lat: 40.000742 },
  { lng: -104.37683, lat: 40.000739 },
  { lng: -104.207951, lat: 40.000832 },
  { lng: -104.150332, lat: 40.000856 },
  { lng: -104.150159, lat: 40.108896 },
  { lng: -104.149802, lat: 40.180939 },
  { lng: -104.150089, lat: 40.231915 },
  { lng: -104.150097, lat: 40.23215 },
  { lng: -104.149975, lat: 40.24998 },
  { lng: -104.150145, lat: 40.290723 },
  { lng: -104.150172, lat: 40.297452 },
  { lng: -104.150195, lat: 40.302949 },
  { lng: -104.150206, lat: 40.30548 },
  { lng: -104.150207, lat: 40.305965 },
  { lng: -104.150321, lat: 40.326844 },
  { lng: -104.150419, lat: 40.334685 },
  { lng: -104.150494, lat: 40.350245 },
  { lng: -104.147623, lat: 40.350238 },
  { lng: -104.14754, lat: 40.351118 },
  { lng: -104.14655, lat: 40.437665 },
  { lng: -104.146747, lat: 40.437831 },
  { lng: -104.147698, lat: 40.517093 },
  { lng: -104.147714, lat: 40.524389 },
  { lng: -104.139721, lat: 40.524408 },
  { lng: -104.051431, lat: 40.524379 },
  { lng: -103.985771, lat: 40.524154 },
  { lng: -103.917859, lat: 40.523907 },
  { lng: -103.714818, lat: 40.522386 },
  { lng: -103.695926, lat: 40.522018 },
  { lng: -103.658241, lat: 40.522455 },
  { lng: -103.639093, lat: 40.522683 },
  { lng: -103.581837, lat: 40.52316 },
  { lng: -103.581844, lat: 40.523383 },
  { lng: -103.581759, lat: 40.55957 },
  { lng: -103.581713, lat: 40.567068 },
  { lng: -103.581462, lat: 40.610707 },
  { lng: -103.581608, lat: 40.613769 },
  { lng: -103.5814, lat: 40.615944 },
  { lng: -103.5809, lat: 40.653657 },
  { lng: -103.580371, lat: 40.702023 },
  { lng: -103.574301, lat: 40.702059 },
  { lng: -103.573387, lat: 40.833976 },
  { lng: -103.573727, lat: 40.891094 },
  { lng: -103.573489, lat: 40.898578 },
  { lng: -103.573699, lat: 40.901887 },
  { lng: -103.573821, lat: 40.923054 },
  { lng: -103.573569, lat: 40.925208 },
  { lng: -103.573925, lat: 40.963745 },
  { lng: -103.573337, lat: 40.978533 },
  { lng: -103.573773995352, lat: 41.0017201653792 },
  { lng: -103.574522, lat: 41.001721 },
  { lng: -103.592670714475, lat: 41.0017553428758 },
  { lng: -103.750498, lat: 41.002054 },
  { lng: -103.76333905125, lat: 41.0020096306832 },
  { lng: -103.858449, lat: 41.001681 },
  { lng: -103.877967, lat: 41.001673 },
  { lng: -103.896207, lat: 41.00175 },
  { lng: -103.906324, lat: 41.001387 },
  { lng: -103.914844319285, lat: 41.0014247268857 },
  { lng: -103.953525, lat: 41.001596 },
  { lng: -103.971373, lat: 41.001524 },
  { lng: -103.972642, lat: 41.001615 },
  { lng: -104.010959930078, lat: 41.0016166813115 },
  { lng: -104.018223, lat: 41.001617 },
  { lng: -104.023383, lat: 41.001887 },
  { lng: -104.039238, lat: 41.001502 },
  { lng: -104.053249, lat: 41.001406 },
  { lng: -104.066961, lat: 41.001504 },
  { lng: -104.086068, lat: 41.001563 },
  { lng: -104.10459, lat: 41.001543 },
  { lng: -104.123586, lat: 41.001626 },
  { lng: -104.211473, lat: 41.001591 },
  { lng: -104.214191, lat: 41.001568 },
  { lng: -104.214692, lat: 41.001657 },
  { lng: -104.371258, lat: 41.0015431248162 },
  { lng: -104.467672, lat: 41.001473 },
  { lng: -104.497058, lat: 41.001805 },
  { lng: -104.497149, lat: 41.001828 },
  { lng: -104.599525227751, lat: 41.0013294274847 },
  { lng: -104.600077973589, lat: 41.0013267356109 },
  { lng: -104.675999, lat: 41.000957 },
  { lng: -104.773027200167, lat: 40.9998906726903 },
  { lng: -104.829504, lat: 40.99927 },
  { lng: -104.855273, lat: 40.998048 },
  { lng: -104.943371, lat: 40.9980837238089 },
  { lng: -104.943337, lat: 40.783294 },
  { lng: -104.943543, lat: 40.696509 },
  { lng: -104.94311, lat: 40.696495 },
  { lng: -104.943244, lat: 40.682254 },
  { lng: -104.943484, lat: 40.653219 },
  { lng: -104.943862, lat: 40.638777 },
  { lng: -104.944001, lat: 40.616785 },
  { lng: -104.944091, lat: 40.595356 },
  { lng: -104.944095, lat: 40.58809 },
  { lng: -104.944112, lat: 40.580938 },
  { lng: -104.944108, lat: 40.566251 },
  { lng: -104.944151, lat: 40.551715 },
  { lng: -104.944186, lat: 40.542832 },
  { lng: -104.944205, lat: 40.537354 },
  { lng: -104.944258, lat: 40.522768 },
  { lng: -104.944247, lat: 40.522663 },
  { lng: -104.944214, lat: 40.519191 },
  { lng: -104.944213, lat: 40.519137 },
  { lng: -104.944164, lat: 40.508104 },
  { lng: -104.944154, lat: 40.504925 },
  { lng: -104.944147, lat: 40.486445 },
  { lng: -104.944096, lat: 40.4793 },
  { lng: -104.944108, lat: 40.47636 },
  { lng: -104.944116, lat: 40.473495 },
  { lng: -104.944116, lat: 40.473375 },
  { lng: -104.944186, lat: 40.457026 },
  { lng: -104.944222, lat: 40.435894 },
  { lng: -104.944427, lat: 40.414196 },
  { lng: -104.944562, lat: 40.407348 },
  { lng: -104.944568, lat: 40.406964 },
  { lng: -104.944583, lat: 40.40651 },
  { lng: -104.944744, lat: 40.398786 },
  { lng: -104.945229, lat: 40.370759 },
  { lng: -104.945346, lat: 40.363476 },
  { lng: -104.945551, lat: 40.349099 },
  { lng: -104.954798, lat: 40.349121 },
  { lng: -104.955039, lat: 40.349121 },
  { lng: -104.957356, lat: 40.349123 },
  { lng: -104.959956, lat: 40.349132 },
  { lng: -104.961729, lat: 40.349138 },
  { lng: -104.966444, lat: 40.349142 },
  { lng: -104.970387, lat: 40.349149 },
  { lng: -104.980379, lat: 40.349171 },
  { lng: -104.981291, lat: 40.349164 },
  { lng: -104.981677, lat: 40.349165 },
  { lng: -104.98346, lat: 40.349184 },
  { lng: -105.002429, lat: 40.349217 },
  { lng: -105.05672, lat: 40.349281 },
];

export const teller = [
  { lng: -105.330444, lat: 39.129685 },
  { lng: -105.329532, lat: 38.992851 },
  { lng: -105.329188, lat: 38.960242 },
  { lng: -105.32876, lat: 38.923848 },
  { lng: -105.32841, lat: 38.892794 },
  { lng: -105.328701, lat: 38.889469 },
  { lng: -105.328153, lat: 38.875011 },
  { lng: -105.329095, lat: 38.86902 },
  { lng: -105.328915, lat: 38.867671 },
  { lng: -105.328925, lat: 38.857561 },
  { lng: -105.328785, lat: 38.815963 },
  { lng: -105.32899, lat: 38.811216 },
  { lng: -105.3288, lat: 38.744558 },
  { lng: -105.329134, lat: 38.697205 },
  { lng: -105.250535, lat: 38.696484 },
  { lng: -105.242432, lat: 38.696981 },
  { lng: -105.237792, lat: 38.696901 },
  { lng: -105.239925, lat: 38.677002 },
  { lng: -105.240362, lat: 38.647595 },
  { lng: -105.220036, lat: 38.647652 },
  { lng: -105.190482, lat: 38.647717 },
  { lng: -105.109724, lat: 38.647531 },
  { lng: -105.071529, lat: 38.64855 },
  { lng: -105.066948, lat: 38.649761 },
  { lng: -105.057554, lat: 38.651693 },
  { lng: -105.053926, lat: 38.653096 },
  { lng: -105.035659, lat: 38.651393 },
  { lng: -105.000532, lat: 38.651183 },
  { lng: -104.99492, lat: 38.650874 },
  { lng: -104.942426, lat: 38.649882 },
  { lng: -104.942235, lat: 38.696199 },
  { lng: -104.939744, lat: 38.696142 },
  { lng: -104.939555, lat: 38.703645 },
  { lng: -104.939006, lat: 38.710526 },
  { lng: -104.939286, lat: 38.73698 },
  { lng: -104.938986, lat: 38.792013 },
  { lng: -104.938994, lat: 38.796884 },
  { lng: -104.985762, lat: 38.797635 },
  { lng: -105.005359, lat: 38.797396 },
  { lng: -105.011033, lat: 38.796987 },
  { lng: -105.030619, lat: 38.797133 },
  { lng: -105.053184, lat: 38.799129 },
  { lng: -105.072184, lat: 38.799382 },
  { lng: -105.072457, lat: 38.805007 },
  { lng: -105.072529, lat: 38.8084 },
  { lng: -105.07177, lat: 38.817928 },
  { lng: -105.068443, lat: 38.851147 },
  { lng: -105.06766, lat: 38.858991 },
  { lng: -105.067954, lat: 38.859688 },
  { lng: -105.067409, lat: 38.862153 },
  { lng: -105.06733, lat: 38.865305 },
  { lng: -105.0667, lat: 38.869508 },
  { lng: -105.028903, lat: 38.868907 },
  { lng: -105.028228, lat: 38.91228 },
  { lng: -105.028196, lat: 38.914433 },
  { lng: -105.02802, lat: 38.924872 },
  { lng: -105.027947, lat: 38.930032 },
  { lng: -105.027878, lat: 38.932774 },
  { lng: -105.027489, lat: 38.934914 },
  { lng: -105.027108, lat: 38.936174 },
  { lng: -105.027398, lat: 38.938475 },
  { lng: -105.027473, lat: 38.938945 },
  { lng: -105.027562, lat: 38.940971 },
  { lng: -105.02767, lat: 38.952262 },
  { lng: -105.0276, lat: 38.95672 },
  { lng: -105.027551, lat: 38.960294 },
  { lng: -105.027459, lat: 38.967305 },
  { lng: -105.027242, lat: 38.981864 },
  { lng: -105.0272, lat: 38.985335 },
  { lng: -105.027848, lat: 38.988803 },
  { lng: -105.029827, lat: 38.999404 },
  { lng: -105.028995, lat: 39.004073 },
  { lng: -105.027848, lat: 39.014358 },
  { lng: -105.027655, lat: 39.019542 },
  { lng: -105.026945, lat: 39.029831 },
  { lng: -105.027674, lat: 39.041844 },
  { lng: -105.028092, lat: 39.046515 },
  { lng: -105.028093, lat: 39.050892 },
  { lng: -105.028689, lat: 39.060782 },
  { lng: -105.029267, lat: 39.064521 },
  { lng: -105.029548, lat: 39.070973 },
  { lng: -105.030509, lat: 39.08036 },
  { lng: -105.030561, lat: 39.086398 },
  { lng: -105.031415, lat: 39.091922 },
  { lng: -105.03123, lat: 39.097901 },
  { lng: -105.031795, lat: 39.104235 },
  { lng: -105.032558, lat: 39.110081 },
  { lng: -105.032557, lat: 39.113765 },
  { lng: -105.032908, lat: 39.119081 },
  { lng: -105.032785, lat: 39.123069 },
  { lng: -105.033544, lat: 39.129819 },
  { lng: -105.157944, lat: 39.129479 },
  { lng: -105.16261, lat: 39.129479 },
  { lng: -105.32922, lat: 39.129689 },
  { lng: -105.330444, lat: 39.129685 },
];

export const pueblo = [
  { lng: -105.050678, lat: 38.113824 },
  { lng: -105.050393, lat: 38.095792 },
  { lng: -105.05019, lat: 38.000006 },
  { lng: -105.049978, lat: 37.985701 },
  { lng: -105.049973, lat: 37.985324 },
  { lng: -105.050124, lat: 37.98459 },
  { lng: -105.049917, lat: 37.915479 },
  { lng: -105.044561, lat: 37.911656 },
  { lng: -105.043905, lat: 37.90954 },
  { lng: -105.039826, lat: 37.908124 },
  { lng: -105.038665, lat: 37.904444 },
  { lng: -105.03599, lat: 37.904115 },
  { lng: -105.032954, lat: 37.905322 },
  { lng: -105.031573, lat: 37.904147 },
  { lng: -105.034575, lat: 37.896112 },
  { lng: -105.034803, lat: 37.894583 },
  { lng: -105.033626, lat: 37.89356 },
  { lng: -105.030435, lat: 37.892063 },
  { lng: -105.021748, lat: 37.887491 },
  { lng: -105.015751, lat: 37.88551 },
  { lng: -105.013763, lat: 37.883489 },
  { lng: -105.013729, lat: 37.881271 },
  { lng: -104.948214, lat: 37.885006 },
  { lng: -104.947012, lat: 37.885145 },
  { lng: -104.945777, lat: 37.885062 },
  { lng: -104.855995, lat: 37.88974 },
  { lng: -104.828794, lat: 37.891127 },
  { lng: -104.670416, lat: 37.899307 },
  { lng: -104.668695, lat: 37.899386 },
  { lng: -104.666059, lat: 37.899521 },
  { lng: -104.646383, lat: 37.900527 },
  { lng: -104.572023, lat: 37.87968 },
  { lng: -104.384158, lat: 37.826798 },
  { lng: -104.351109, lat: 37.817488 },
  { lng: -104.143179, lat: 37.75833 },
  { lng: -104.12552, lat: 37.753302 },
  { lng: -104.124763, lat: 37.753171 },
  { lng: -104.061132, lat: 37.734704 },
  { lng: -104.061727, lat: 37.750011 },
  { lng: -104.061891, lat: 37.822715 },
  { lng: -104.060155, lat: 37.822702 },
  { lng: -104.059426, lat: 37.996157 },
  { lng: -104.059077, lat: 38.093608 },
  { lng: -104.059047, lat: 38.103723 },
  { lng: -104.05881, lat: 38.105443 },
  { lng: -104.058404, lat: 38.126778 },
  { lng: -104.057995, lat: 38.141468 },
  { lng: -104.058242, lat: 38.146492 },
  { lng: -104.058402, lat: 38.260841 },
  { lng: -104.058168, lat: 38.261402 },
  { lng: -104.057736, lat: 38.261639 },
  { lng: -104.054725, lat: 38.261864 },
  { lng: -104.054193, lat: 38.519611 },
  { lng: -104.053921, lat: 38.522393 },
  { lng: -104.083468, lat: 38.522434 },
  { lng: -104.090179, lat: 38.522004 },
  { lng: -104.134086, lat: 38.521805 },
  { lng: -104.150152, lat: 38.521732 },
  { lng: -104.152501, lat: 38.521902 },
  { lng: -104.275054, lat: 38.521134 },
  { lng: -104.275471, lat: 38.521133 },
  { lng: -104.567087, lat: 38.520001 },
  { lng: -104.612424, lat: 38.520162 },
  { lng: -104.626137, lat: 38.519957 },
  { lng: -104.630682, lat: 38.519917 },
  { lng: -104.717994, lat: 38.51956 },
  { lng: -104.734088, lat: 38.519631 },
  { lng: -104.775787, lat: 38.519927 },
  { lng: -104.796104, lat: 38.520165 },
  { lng: -104.798122, lat: 38.519984 },
  { lng: -104.86615, lat: 38.519857 },
  { lng: -104.866674, lat: 38.520017 },
  { lng: -104.881971, lat: 38.520088 },
  { lng: -104.888343, lat: 38.519909 },
  { lng: -104.895683, lat: 38.5203 },
  { lng: -104.932949, lat: 38.520286 },
  { lng: -104.941533, lat: 38.519569 },
  { lng: -104.941415, lat: 38.503158 },
  { lng: -104.941211, lat: 38.500618 },
  { lng: -104.941312, lat: 38.499999 },
  { lng: -104.940902, lat: 38.417833 },
  { lng: -104.940889, lat: 38.372026 },
  { lng: -104.940749, lat: 38.338022 },
  { lng: -104.940749, lat: 38.337961 },
  { lng: -104.940366, lat: 38.258275 },
  { lng: -104.943163, lat: 38.258262 },
  { lng: -105.049215, lat: 38.257973 },
  { lng: -105.049223, lat: 38.250003 },
  { lng: -105.048731, lat: 38.239698 },
  { lng: -105.048287, lat: 38.127773 },
  { lng: -105.050678, lat: 38.113824 },
];

export const park = [
  { lng: -106.210173, lat: 39.103918 },
  { lng: -106.209535, lat: 39.103075 },
  { lng: -106.207852, lat: 39.103423 },
  { lng: -106.203399, lat: 39.102732 },
  { lng: -106.199888, lat: 39.104366 },
  { lng: -106.195768, lat: 39.104472 },
  { lng: -106.19293, lat: 39.103051 },
  { lng: -106.190422, lat: 39.102752 },
  { lng: -106.18786, lat: 39.103736 },
  { lng: -106.178556, lat: 39.103291 },
  { lng: -106.175389, lat: 39.098805 },
  { lng: -106.172961, lat: 39.097278 },
  { lng: -106.171996, lat: 39.096042 },
  { lng: -106.169867, lat: 39.090731 },
  { lng: -106.16941, lat: 39.086804 },
  { lng: -106.172043, lat: 39.085345 },
  { lng: -106.172795, lat: 39.083967 },
  { lng: -106.17569, lat: 39.082939 },
  { lng: -106.180328, lat: 39.077245 },
  { lng: -106.183589, lat: 39.076475 },
  { lng: -106.186109, lat: 39.074987 },
  { lng: -106.186524, lat: 39.071638 },
  { lng: -106.191577, lat: 39.067983 },
  { lng: -106.190481, lat: 39.063983 },
  { lng: -106.190696, lat: 39.061944 },
  { lng: -106.191733, lat: 39.059217 },
  { lng: -106.191006, lat: 39.056073 },
  { lng: -106.190093, lat: 39.054524 },
  { lng: -106.184815, lat: 39.050837 },
  { lng: -106.185702, lat: 39.046761 },
  { lng: -106.185353, lat: 39.04402 },
  { lng: -106.182782, lat: 39.040116 },
  { lng: -106.182501, lat: 39.038551 },
  { lng: -106.18497, lat: 39.036236 },
  { lng: -106.186907, lat: 39.033196 },
  { lng: -106.188725, lat: 39.033235 },
  { lng: -106.190327, lat: 39.03057 },
  { lng: -106.192077, lat: 39.029287 },
  { lng: -106.191277, lat: 39.025833 },
  { lng: -106.189809, lat: 39.023113 },
  { lng: -106.189473, lat: 39.021279 },
  { lng: -106.193105, lat: 39.019681 },
  { lng: -106.192616, lat: 39.017348 },
  { lng: -106.188253, lat: 39.015381 },
  { lng: -106.186077, lat: 39.015595 },
  { lng: -106.184949, lat: 39.012581 },
  { lng: -106.183052, lat: 39.011835 },
  { lng: -106.17947, lat: 39.011249 },
  { lng: -106.177923, lat: 39.009101 },
  { lng: -106.178025, lat: 39.007689 },
  { lng: -106.177009, lat: 39.005342 },
  { lng: -106.176962, lat: 39.001949 },
  { lng: -106.175918, lat: 38.999647 },
  { lng: -106.174916, lat: 38.999596 },
  { lng: -106.173165, lat: 38.997403 },
  { lng: -106.170067, lat: 38.994644 },
  { lng: -106.169134, lat: 38.991302 },
  { lng: -106.165661, lat: 38.992439 },
  { lng: -106.16342, lat: 38.993908 },
  { lng: -106.160514, lat: 38.994808 },
  { lng: -106.157804, lat: 38.994264 },
  { lng: -106.154078, lat: 38.996167 },
  { lng: -106.150666, lat: 38.997093 },
  { lng: -106.145414, lat: 38.997899 },
  { lng: -106.142659, lat: 38.995428 },
  { lng: -106.137778, lat: 38.994642 },
  { lng: -106.132568, lat: 38.995519 },
  { lng: -106.131717, lat: 38.994918 },
  { lng: -106.127518, lat: 38.994017 },
  { lng: -106.122468, lat: 38.990816 },
  { lng: -106.116771, lat: 38.988084 },
  { lng: -106.113239, lat: 38.98781 },
  { lng: -106.111871, lat: 38.986953 },
  { lng: -106.108953, lat: 38.987377 },
  { lng: -106.10951, lat: 38.985939 },
  { lng: -106.109479, lat: 38.981633 },
  { lng: -106.107607, lat: 38.979747 },
  { lng: -106.10649, lat: 38.976494 },
  { lng: -106.104379, lat: 38.974141 },
  { lng: -106.104968, lat: 38.970026 },
  { lng: -106.105583, lat: 38.969418 },
  { lng: -106.108285, lat: 38.963463 },
  { lng: -106.110617, lat: 38.962034 },
  { lng: -106.112193, lat: 38.960188 },
  { lng: -106.11106, lat: 38.957669 },
  { lng: -106.113757, lat: 38.955327 },
  { lng: -106.11344, lat: 38.952788 },
  { lng: -106.112043, lat: 38.950908 },
  { lng: -106.113114, lat: 38.945024 },
  { lng: -106.112665, lat: 38.941366 },
  { lng: -106.110995, lat: 38.940099 },
  { lng: -106.105758, lat: 38.940364 },
  { lng: -106.100971, lat: 38.9387 },
  { lng: -106.096461, lat: 38.938243 },
  { lng: -106.094862, lat: 38.936775 },
  { lng: -106.09355, lat: 38.937958 },
  { lng: -106.090653, lat: 38.936543 },
  { lng: -106.08532, lat: 38.93946 },
  { lng: -106.083587, lat: 38.937935 },
  { lng: -106.078628, lat: 38.935255 },
  { lng: -106.076189, lat: 38.934555 },
  { lng: -106.073963, lat: 38.934758 },
  { lng: -106.071481, lat: 38.933776 },
  { lng: -106.07048, lat: 38.932111 },
  { lng: -106.067825, lat: 38.932872 },
  { lng: -106.06591, lat: 38.931896 },
  { lng: -106.064751, lat: 38.934433 },
  { lng: -106.063532, lat: 38.93568 },
  { lng: -106.060149, lat: 38.937153 },
  { lng: -106.058449, lat: 38.939151 },
  { lng: -106.05825, lat: 38.941805 },
  { lng: -106.051701, lat: 38.943452 },
  { lng: -106.048613, lat: 38.942827 },
  { lng: -106.045779, lat: 38.941548 },
  { lng: -106.04379, lat: 38.941926 },
  { lng: -106.038873, lat: 38.940792 },
  { lng: -106.03683, lat: 38.940647 },
  { lng: -106.032287, lat: 38.941599 },
  { lng: -106.030797, lat: 38.942508 },
  { lng: -106.028138, lat: 38.942849 },
  { lng: -106.024617, lat: 38.93883 },
  { lng: -106.020529, lat: 38.934628 },
  { lng: -106.018063, lat: 38.934451 },
  { lng: -106.01501, lat: 38.932712 },
  { lng: -106.0126, lat: 38.929665 },
  { lng: -106.008219, lat: 38.92648 },
  { lng: -106.0064, lat: 38.924084 },
  { lng: -105.999183, lat: 38.918409 },
  { lng: -105.999343, lat: 38.916337 },
  { lng: -105.99817, lat: 38.915159 },
  { lng: -105.994449, lat: 38.914858 },
  { lng: -105.9861, lat: 38.918106 },
  { lng: -105.983816, lat: 38.916523 },
  { lng: -105.980343, lat: 38.915286 },
  { lng: -105.979913, lat: 38.914181 },
  { lng: -105.978448, lat: 38.914655 },
  { lng: -105.976532, lat: 38.914048 },
  { lng: -105.976033, lat: 38.911839 },
  { lng: -105.976644, lat: 38.910622 },
  { lng: -105.975083, lat: 38.910089 },
  { lng: -105.971886, lat: 38.908923 },
  { lng: -105.969464, lat: 38.908606 },
  { lng: -105.967757, lat: 38.906693 },
  { lng: -105.96577, lat: 38.905876 },
  { lng: -105.967447, lat: 38.904939 },
  { lng: -105.968661, lat: 38.90072 },
  { lng: -105.972407, lat: 38.899257 },
  { lng: -105.974073, lat: 38.89668 },
  { lng: -105.979418, lat: 38.894533 },
  { lng: -105.979667, lat: 38.893735 },
  { lng: -105.979175, lat: 38.888913 },
  { lng: -105.977144, lat: 38.885146 },
  { lng: -105.974297, lat: 38.882458 },
  { lng: -105.970637, lat: 38.883196 },
  { lng: -105.964965, lat: 38.885977 },
  { lng: -105.96356, lat: 38.885855 },
  { lng: -105.959969, lat: 38.884406 },
  { lng: -105.955643, lat: 38.883691 },
  { lng: -105.953815, lat: 38.884198 },
  { lng: -105.949139, lat: 38.883104 },
  { lng: -105.947964, lat: 38.883271 },
  { lng: -105.942773, lat: 38.885506 },
  { lng: -105.941238, lat: 38.885592 },
  { lng: -105.938875, lat: 38.883323 },
  { lng: -105.936026, lat: 38.881449 },
  { lng: -105.932975, lat: 38.878219 },
  { lng: -105.931159, lat: 38.874709 },
  { lng: -105.926846, lat: 38.874235 },
  { lng: -105.925123, lat: 38.872178 },
  { lng: -105.92709, lat: 38.871482 },
  { lng: -105.928411, lat: 38.869751 },
  { lng: -105.927819, lat: 38.868845 },
  { lng: -105.928438, lat: 38.866339 },
  { lng: -105.931448, lat: 38.866527 },
  { lng: -105.932518, lat: 38.864501 },
  { lng: -105.932054, lat: 38.863239 },
  { lng: -105.933007, lat: 38.861051 },
  { lng: -105.935015, lat: 38.859104 },
  { lng: -105.937114, lat: 38.858407 },
  { lng: -105.939515, lat: 38.856593 },
  { lng: -105.942801, lat: 38.852802 },
  { lng: -105.943227, lat: 38.85179 },
  { lng: -105.942887, lat: 38.850503 },
  { lng: -105.940569, lat: 38.849227 },
  { lng: -105.939147, lat: 38.846046 },
  { lng: -105.940156, lat: 38.843528 },
  { lng: -105.941241, lat: 38.842493 },
  { lng: -105.941845, lat: 38.84039 },
  { lng: -105.936398, lat: 38.836571 },
  { lng: -105.935054, lat: 38.834301 },
  { lng: -105.932249, lat: 38.832731 },
  { lng: -105.928067, lat: 38.828125 },
  { lng: -105.926013, lat: 38.826491 },
  { lng: -105.924246, lat: 38.824193 },
  { lng: -105.920673, lat: 38.82163 },
  { lng: -105.917232, lat: 38.817525 },
  { lng: -105.913997, lat: 38.816063 },
  { lng: -105.912584, lat: 38.813899 },
  { lng: -105.910727, lat: 38.812368 },
  { lng: -105.910687, lat: 38.810481 },
  { lng: -105.906587, lat: 38.806962 },
  { lng: -105.910454, lat: 38.804877 },
  { lng: -105.911493, lat: 38.802931 },
  { lng: -105.910552, lat: 38.801972 },
  { lng: -105.911794, lat: 38.799879 },
  { lng: -105.912908, lat: 38.795513 },
  { lng: -105.914464, lat: 38.793731 },
  { lng: -105.913704, lat: 38.792459 },
  { lng: -105.915139, lat: 38.790437 },
  { lng: -105.91805, lat: 38.790245 },
  { lng: -105.921123, lat: 38.78936 },
  { lng: -105.921637, lat: 38.788105 },
  { lng: -105.923686, lat: 38.786246 },
  { lng: -105.926709, lat: 38.785508 },
  { lng: -105.925161, lat: 38.782667 },
  { lng: -105.929563, lat: 38.78139 },
  { lng: -105.929535, lat: 38.779722 },
  { lng: -105.93055, lat: 38.778541 },
  { lng: -105.929642, lat: 38.777236 },
  { lng: -105.929289, lat: 38.774547 },
  { lng: -105.931428, lat: 38.772749 },
  { lng: -105.936287, lat: 38.771622 },
  { lng: -105.939296, lat: 38.771988 },
  { lng: -105.939893, lat: 38.770675 },
  { lng: -105.942511, lat: 38.768512 },
  { lng: -105.94454, lat: 38.768105 },
  { lng: -105.939702, lat: 38.764764 },
  { lng: -105.938758, lat: 38.763022 },
  { lng: -105.938063, lat: 38.755212 },
  { lng: -105.938306, lat: 38.753685 },
  { lng: -105.941433, lat: 38.748687 },
  { lng: -105.941337, lat: 38.745605 },
  { lng: -105.942107, lat: 38.744788 },
  { lng: -105.94786, lat: 38.743823 },
  { lng: -105.947947, lat: 38.741689 },
  { lng: -105.944268, lat: 38.737065 },
  { lng: -105.944752, lat: 38.735766 },
  { lng: -105.943017, lat: 38.734532 },
  { lng: -105.943493, lat: 38.730818 },
  { lng: -105.944333, lat: 38.730533 },
  { lng: -105.94483, lat: 38.728365 },
  { lng: -105.946108, lat: 38.727435 },
  { lng: -105.943469, lat: 38.723144 },
  { lng: -105.944434, lat: 38.721747 },
  { lng: -105.946106, lat: 38.721644 },
  { lng: -105.947565, lat: 38.720189 },
  { lng: -105.946185, lat: 38.716724 },
  { lng: -105.950164, lat: 38.714375 },
  { lng: -105.953039, lat: 38.713655 },
  { lng: -105.957284, lat: 38.708978 },
  { lng: -105.961823, lat: 38.706055 },
  { lng: -105.963647, lat: 38.701329 },
  { lng: -105.965375, lat: 38.698979 },
  { lng: -105.967723, lat: 38.697135 },
  { lng: -105.968996, lat: 38.697186 },
  { lng: -105.969899, lat: 38.694403 },
  { lng: -105.96975, lat: 38.693551 },
  { lng: -105.944488, lat: 38.693089 },
  { lng: -105.89839, lat: 38.693769 },
  { lng: -105.888912, lat: 38.695153 },
  { lng: -105.881574, lat: 38.694118 },
  { lng: -105.875567, lat: 38.694306 },
  { lng: -105.81432, lat: 38.694499 },
  { lng: -105.75058, lat: 38.694691 },
  { lng: -105.702075, lat: 38.69471 },
  { lng: -105.531547, lat: 38.695935 },
  { lng: -105.514458, lat: 38.69659 },
  { lng: -105.510123, lat: 38.696499 },
  { lng: -105.500548, lat: 38.696641 },
  { lng: -105.498448, lat: 38.69708 },
  { lng: -105.498246, lat: 38.697124 },
  { lng: -105.329134, lat: 38.697205 },
  { lng: -105.3288, lat: 38.744558 },
  { lng: -105.32899, lat: 38.811216 },
  { lng: -105.328785, lat: 38.815963 },
  { lng: -105.328925, lat: 38.857561 },
  { lng: -105.328915, lat: 38.867671 },
  { lng: -105.329095, lat: 38.86902 },
  { lng: -105.328153, lat: 38.875011 },
  { lng: -105.328701, lat: 38.889469 },
  { lng: -105.32841, lat: 38.892794 },
  { lng: -105.32876, lat: 38.923848 },
  { lng: -105.329188, lat: 38.960242 },
  { lng: -105.329532, lat: 38.992851 },
  { lng: -105.330444, lat: 39.129685 },
  { lng: -105.397875, lat: 39.129564 },
  { lng: -105.397759, lat: 39.158624 },
  { lng: -105.397881, lat: 39.203277 },
  { lng: -105.3979, lat: 39.24999 },
  { lng: -105.396712, lat: 39.264883 },
  { lng: -105.398114, lat: 39.315776 },
  { lng: -105.398652, lat: 39.326189 },
  { lng: -105.398353, lat: 39.327789 },
  { lng: -105.398552, lat: 39.37809 },
  { lng: -105.39795, lat: 39.388791 },
  { lng: -105.39815, lat: 39.399407 },
  { lng: -105.398154, lat: 39.40127 },
  { lng: -105.398051, lat: 39.424159 },
  { lng: -105.398064, lat: 39.452251 },
  { lng: -105.398552, lat: 39.463488 },
  { lng: -105.398341, lat: 39.464173 },
  { lng: -105.397898, lat: 39.466957 },
  { lng: -105.398139, lat: 39.468725 },
  { lng: -105.398251, lat: 39.499167 },
  { lng: -105.398518, lat: 39.52475 },
  { lng: -105.398851, lat: 39.535588 },
  { lng: -105.398949, lat: 39.566056 },
  { lng: -105.401051, lat: 39.565988 },
  { lng: -105.444922, lat: 39.565988 },
  { lng: -105.587986, lat: 39.568197 },
  { lng: -105.623875, lat: 39.567036 },
  { lng: -105.721601, lat: 39.565269 },
  { lng: -105.723545, lat: 39.565351 },
  { lng: -105.776642, lat: 39.56492 },
  { lng: -105.829662, lat: 39.564865 },
  { lng: -105.828521, lat: 39.563453 },
  { lng: -105.828906, lat: 39.561547 },
  { lng: -105.826061, lat: 39.557442 },
  { lng: -105.825771, lat: 39.555678 },
  { lng: -105.822337, lat: 39.553012 },
  { lng: -105.818724, lat: 39.552074 },
  { lng: -105.815807, lat: 39.551976 },
  { lng: -105.816308, lat: 39.54815 },
  { lng: -105.818928, lat: 39.545815 },
  { lng: -105.819066, lat: 39.544064 },
  { lng: -105.817631, lat: 39.53831 },
  { lng: -105.819268, lat: 39.537572 },
  { lng: -105.821234, lat: 39.532468 },
  { lng: -105.823206, lat: 39.529953 },
  { lng: -105.823931, lat: 39.53056 },
  { lng: -105.830016, lat: 39.532043 },
  { lng: -105.836057, lat: 39.529285 },
  { lng: -105.838571, lat: 39.527821 },
  { lng: -105.844329, lat: 39.525992 },
  { lng: -105.845377, lat: 39.52375 },
  { lng: -105.847211, lat: 39.52484 },
  { lng: -105.851285, lat: 39.526121 },
  { lng: -105.854562, lat: 39.527868 },
  { lng: -105.857732, lat: 39.532058 },
  { lng: -105.859507, lat: 39.531536 },
  { lng: -105.861662, lat: 39.528605 },
  { lng: -105.862975, lat: 39.526943 },
  { lng: -105.863525, lat: 39.523955 },
  { lng: -105.864701, lat: 39.522115 },
  { lng: -105.868065, lat: 39.51949 },
  { lng: -105.866889, lat: 39.516702 },
  { lng: -105.864681, lat: 39.515065 },
  { lng: -105.863479, lat: 39.514312 },
  { lng: -105.862393, lat: 39.513499 },
  { lng: -105.859947, lat: 39.512148 },
  { lng: -105.859457, lat: 39.509225 },
  { lng: -105.857017, lat: 39.50706 },
  { lng: -105.854772, lat: 39.506401 },
  { lng: -105.854117, lat: 39.50452 },
  { lng: -105.856392, lat: 39.50513 },
  { lng: -105.860395, lat: 39.50471 },
  { lng: -105.863584, lat: 39.502305 },
  { lng: -105.864214, lat: 39.499986 },
  { lng: -105.866565, lat: 39.496663 },
  { lng: -105.866584, lat: 39.494351 },
  { lng: -105.869905, lat: 39.494953 },
  { lng: -105.872414, lat: 39.494808 },
  { lng: -105.874714, lat: 39.493521 },
  { lng: -105.876966, lat: 39.490602 },
  { lng: -105.878348, lat: 39.489618 },
  { lng: -105.879173, lat: 39.486824 },
  { lng: -105.879087, lat: 39.483767 },
  { lng: -105.884677, lat: 39.484138 },
  { lng: -105.889185, lat: 39.48335 },
  { lng: -105.891974, lat: 39.484173 },
  { lng: -105.892199, lat: 39.481044 },
  { lng: -105.894725, lat: 39.477517 },
  { lng: -105.897327, lat: 39.477137 },
  { lng: -105.896726, lat: 39.475657 },
  { lng: -105.898888, lat: 39.472295 },
  { lng: -105.901612, lat: 39.469619 },
  { lng: -105.901258, lat: 39.467213 },
  { lng: -105.901835, lat: 39.464248 },
  { lng: -105.905959, lat: 39.459657 },
  { lng: -105.908351, lat: 39.459428 },
  { lng: -105.910511, lat: 39.458376 },
  { lng: -105.911637, lat: 39.456736 },
  { lng: -105.914316, lat: 39.458203 },
  { lng: -105.917309, lat: 39.45813 },
  { lng: -105.921952, lat: 39.458455 },
  { lng: -105.926674, lat: 39.459417 },
  { lng: -105.929823, lat: 39.459338 },
  { lng: -105.934657, lat: 39.457888 },
  { lng: -105.938663, lat: 39.458498 },
  { lng: -105.940612, lat: 39.456524 },
  { lng: -105.94217, lat: 39.452773 },
  { lng: -105.9425, lat: 39.450484 },
  { lng: -105.941853, lat: 39.448246 },
  { lng: -105.943698, lat: 39.446183 },
  { lng: -105.944155, lat: 39.44091 },
  { lng: -105.947317, lat: 39.440523 },
  { lng: -105.953619, lat: 39.43854 },
  { lng: -105.956436, lat: 39.438401 },
  { lng: -105.958192, lat: 39.439677 },
  { lng: -105.961752, lat: 39.440091 },
  { lng: -105.964395, lat: 39.439532 },
  { lng: -105.966786, lat: 39.438035 },
  { lng: -105.965013, lat: 39.433436 },
  { lng: -105.960726, lat: 39.429018 },
  { lng: -105.95631, lat: 39.427841 },
  { lng: -105.954837, lat: 39.42622 },
  { lng: -105.955394, lat: 39.422851 },
  { lng: -105.955092, lat: 39.421551 },
  { lng: -105.953875, lat: 39.420479 },
  { lng: -105.953652, lat: 39.418381 },
  { lng: -105.951698, lat: 39.414619 },
  { lng: -105.952325, lat: 39.414106 },
  { lng: -105.959244, lat: 39.412473 },
  { lng: -105.96281, lat: 39.411991 },
  { lng: -105.96872, lat: 39.410678 },
  { lng: -105.973206, lat: 39.409189 },
  { lng: -105.976867, lat: 39.40857 },
  { lng: -105.977967, lat: 39.407269 },
  { lng: -105.981923, lat: 39.405974 },
  { lng: -105.983025, lat: 39.404431 },
  { lng: -105.983621, lat: 39.402441 },
  { lng: -105.985018, lat: 39.401667 },
  { lng: -105.98693, lat: 39.401507 },
  { lng: -105.990528, lat: 39.402202 },
  { lng: -105.993025, lat: 39.401561 },
  { lng: -105.993224, lat: 39.399868 },
  { lng: -105.995928, lat: 39.395001 },
  { lng: -105.997845, lat: 39.39401 },
  { lng: -105.999529, lat: 39.393825 },
  { lng: -106.003339, lat: 39.391766 },
  { lng: -106.004012, lat: 39.391058 },
  { lng: -106.005, lat: 39.389293 },
  { lng: -106.008401, lat: 39.387356 },
  { lng: -106.013851, lat: 39.386898 },
  { lng: -106.016364, lat: 39.38439 },
  { lng: -106.018383, lat: 39.381403 },
  { lng: -106.017453, lat: 39.379204 },
  { lng: -106.016794, lat: 39.374988 },
  { lng: -106.017473, lat: 39.373383 },
  { lng: -106.017619, lat: 39.365503 },
  { lng: -106.021966, lat: 39.361713 },
  { lng: -106.02433, lat: 39.361147 },
  { lng: -106.02672, lat: 39.361333 },
  { lng: -106.029651, lat: 39.35986 },
  { lng: -106.034717, lat: 39.358376 },
  { lng: -106.03747, lat: 39.358465 },
  { lng: -106.043926, lat: 39.357378 },
  { lng: -106.046317, lat: 39.357434 },
  { lng: -106.050922, lat: 39.358061 },
  { lng: -106.056096, lat: 39.358431 },
  { lng: -106.058335, lat: 39.360052 },
  { lng: -106.062616, lat: 39.36182 },
  { lng: -106.064048, lat: 39.362758 },
  { lng: -106.068471, lat: 39.364429 },
  { lng: -106.072857, lat: 39.367917 },
  { lng: -106.074192, lat: 39.36863 },
  { lng: -106.078324, lat: 39.36951 },
  { lng: -106.080241, lat: 39.370435 },
  { lng: -106.081207, lat: 39.369199 },
  { lng: -106.084446, lat: 39.369283 },
  { lng: -106.085632, lat: 39.369723 },
  { lng: -106.094312, lat: 39.376511 },
  { lng: -106.097206, lat: 39.377987 },
  { lng: -106.099401, lat: 39.377733 },
  { lng: -106.101943, lat: 39.378054 },
  { lng: -106.106038, lat: 39.377931 },
  { lng: -106.10729, lat: 39.377454 },
  { lng: -106.11198, lat: 39.377053 },
  { lng: -106.114813, lat: 39.376496 },
  { lng: -106.120308, lat: 39.37659 },
  { lng: -106.123961, lat: 39.378021 },
  { lng: -106.127447, lat: 39.378877 },
  { lng: -106.129494, lat: 39.378767 },
  { lng: -106.131433, lat: 39.379511 },
  { lng: -106.135529, lat: 39.379546 },
  { lng: -106.136552, lat: 39.37884 },
  { lng: -106.138441, lat: 39.374988 },
  { lng: -106.138505, lat: 39.372241 },
  { lng: -106.141294, lat: 39.370914 },
  { lng: -106.14165, lat: 39.36997 },
  { lng: -106.144361, lat: 39.36812 },
  { lng: -106.148795, lat: 39.36835 },
  { lng: -106.152909, lat: 39.362479 },
  { lng: -106.150672, lat: 39.359732 },
  { lng: -106.148448, lat: 39.358613 },
  { lng: -106.147181, lat: 39.353628 },
  { lng: -106.147214, lat: 39.349904 },
  { lng: -106.146369, lat: 39.348287 },
  { lng: -106.14458, lat: 39.346852 },
  { lng: -106.144407, lat: 39.344773 },
  { lng: -106.143417, lat: 39.343125 },
  { lng: -106.139926, lat: 39.339553 },
  { lng: -106.140274, lat: 39.338809 },
  { lng: -106.142193, lat: 39.33689 },
  { lng: -106.143203, lat: 39.334435 },
  { lng: -106.146547, lat: 39.329938 },
  { lng: -106.147153, lat: 39.327605 },
  { lng: -106.148953, lat: 39.326469 },
  { lng: -106.150485, lat: 39.32435 },
  { lng: -106.151246, lat: 39.320694 },
  { lng: -106.153573, lat: 39.320418 },
  { lng: -106.162326, lat: 39.321628 },
  { lng: -106.167805, lat: 39.321643 },
  { lng: -106.169474, lat: 39.322232 },
  { lng: -106.174181, lat: 39.318276 },
  { lng: -106.175054, lat: 39.314418 },
  { lng: -106.176428, lat: 39.312075 },
  { lng: -106.180038, lat: 39.311195 },
  { lng: -106.181836, lat: 39.311227 },
  { lng: -106.183709, lat: 39.310283 },
  { lng: -106.183972, lat: 39.309354 },
  { lng: -106.183038, lat: 39.303272 },
  { lng: -106.183051, lat: 39.301112 },
  { lng: -106.180911, lat: 39.2988 },
  { lng: -106.182578, lat: 39.29306 },
  { lng: -106.181887, lat: 39.289968 },
  { lng: -106.185458, lat: 39.285751 },
  { lng: -106.184939, lat: 39.283391 },
  { lng: -106.186288, lat: 39.280666 },
  { lng: -106.186759, lat: 39.279851 },
  { lng: -106.185748, lat: 39.275809 },
  { lng: -106.185665, lat: 39.273286 },
  { lng: -106.184142, lat: 39.26886 },
  { lng: -106.181453, lat: 39.265366 },
  { lng: -106.181643, lat: 39.263293 },
  { lng: -106.180482, lat: 39.261097 },
  { lng: -106.180649, lat: 39.258686 },
  { lng: -106.181591, lat: 39.257005 },
  { lng: -106.180951, lat: 39.252445 },
  { lng: -106.181746, lat: 39.250643 },
  { lng: -106.181066, lat: 39.248161 },
  { lng: -106.181566, lat: 39.247084 },
  { lng: -106.18512, lat: 39.242694 },
  { lng: -106.183985, lat: 39.240478 },
  { lng: -106.182787, lat: 39.239608 },
  { lng: -106.17993, lat: 39.239447 },
  { lng: -106.17684, lat: 39.238556 },
  { lng: -106.172935, lat: 39.236805 },
  { lng: -106.169498, lat: 39.233758 },
  { lng: -106.168304, lat: 39.23072 },
  { lng: -106.168912, lat: 39.22876 },
  { lng: -106.16992, lat: 39.228101 },
  { lng: -106.169658, lat: 39.225622 },
  { lng: -106.170158, lat: 39.222994 },
  { lng: -106.174273, lat: 39.220727 },
  { lng: -106.178216, lat: 39.219132 },
  { lng: -106.177181, lat: 39.218147 },
  { lng: -106.178409, lat: 39.212978 },
  { lng: -106.179274, lat: 39.212288 },
  { lng: -106.182051, lat: 39.209943 },
  { lng: -106.184744, lat: 39.209308 },
  { lng: -106.183487, lat: 39.206714 },
  { lng: -106.181324, lat: 39.204753 },
  { lng: -106.179506, lat: 39.199998 },
  { lng: -106.17977, lat: 39.194906 },
  { lng: -106.17932, lat: 39.193493 },
  { lng: -106.182217, lat: 39.189673 },
  { lng: -106.185526, lat: 39.186587 },
  { lng: -106.183326, lat: 39.182443 },
  { lng: -106.182816, lat: 39.180403 },
  { lng: -106.179175, lat: 39.175988 },
  { lng: -106.17841, lat: 39.17458 },
  { lng: -106.178128, lat: 39.168884 },
  { lng: -106.178559, lat: 39.165968 },
  { lng: -106.179447, lat: 39.165036 },
  { lng: -106.178862, lat: 39.163335 },
  { lng: -106.176853, lat: 39.160316 },
  { lng: -106.17651, lat: 39.157886 },
  { lng: -106.175349, lat: 39.156298 },
  { lng: -106.175542, lat: 39.152918 },
  { lng: -106.174296, lat: 39.149364 },
  { lng: -106.174813, lat: 39.148018 },
  { lng: -106.173461, lat: 39.144796 },
  { lng: -106.173486, lat: 39.143595 },
  { lng: -106.174821, lat: 39.141423 },
  { lng: -106.172946, lat: 39.138765 },
  { lng: -106.173086, lat: 39.138102 },
  { lng: -106.173847, lat: 39.137076 },
  { lng: -106.17842, lat: 39.13477 },
  { lng: -106.181919, lat: 39.131481 },
  { lng: -106.182296, lat: 39.13029 },
  { lng: -106.1858, lat: 39.129249 },
  { lng: -106.185841, lat: 39.126839 },
  { lng: -106.187631, lat: 39.124597 },
  { lng: -106.188262, lat: 39.121429 },
  { lng: -106.189302, lat: 39.120575 },
  { lng: -106.19147, lat: 39.120869 },
  { lng: -106.199491, lat: 39.115508 },
  { lng: -106.203483, lat: 39.114089 },
  { lng: -106.203895, lat: 39.111288 },
  { lng: -106.2063, lat: 39.110436 },
  { lng: -106.210173, lat: 39.103918 },
];

export const larimer = [
  { lng: -106.195372, lat: 40.989938 },
  { lng: -106.19494, lat: 40.986936 },
  { lng: -106.191124, lat: 40.98603 },
  { lng: -106.190553, lat: 40.984255 },
  { lng: -106.192139, lat: 40.982836 },
  { lng: -106.19004, lat: 40.97864 },
  { lng: -106.190405, lat: 40.976098 },
  { lng: -106.18973, lat: 40.973089 },
  { lng: -106.187097, lat: 40.971167 },
  { lng: -106.186751, lat: 40.968052 },
  { lng: -106.187701, lat: 40.964906 },
  { lng: -106.184844, lat: 40.963831 },
  { lng: -106.185253, lat: 40.961032 },
  { lng: -106.187391, lat: 40.95872 },
  { lng: -106.186829, lat: 40.957425 },
  { lng: -106.184226, lat: 40.955207 },
  { lng: -106.185629, lat: 40.952248 },
  { lng: -106.187651, lat: 40.950166 },
  { lng: -106.189293, lat: 40.945893 },
  { lng: -106.187166, lat: 40.940825 },
  { lng: -106.187599, lat: 40.937745 },
  { lng: -106.18617, lat: 40.936727 },
  { lng: -106.185307, lat: 40.933973 },
  { lng: -106.179945, lat: 40.931521 },
  { lng: -106.175893, lat: 40.928254 },
  { lng: -106.173435, lat: 40.924783 },
  { lng: -106.170028, lat: 40.923804 },
  { lng: -106.169084, lat: 40.922868 },
  { lng: -106.169485, lat: 40.920315 },
  { lng: -106.16817, lat: 40.918398 },
  { lng: -106.163044, lat: 40.916727 },
  { lng: -106.161166, lat: 40.915107 },
  { lng: -106.158905, lat: 40.914266 },
  { lng: -106.159577, lat: 40.912766 },
  { lng: -106.158551, lat: 40.910619 },
  { lng: -106.154593, lat: 40.909421 },
  { lng: -106.152198, lat: 40.906789 },
  { lng: -106.15069, lat: 40.906715 },
  { lng: -106.147881, lat: 40.904638 },
  { lng: -106.144069, lat: 40.9049 },
  { lng: -106.139286, lat: 40.902402 },
  { lng: -106.136908, lat: 40.902028 },
  { lng: -106.136225, lat: 40.899228 },
  { lng: -106.134102, lat: 40.898709 },
  { lng: -106.131251, lat: 40.896608 },
  { lng: -106.130734, lat: 40.895494 },
  { lng: -106.13143, lat: 40.892865 },
  { lng: -106.128987, lat: 40.889281 },
  { lng: -106.127618, lat: 40.88489 },
  { lng: -106.128251, lat: 40.882002 },
  { lng: -106.12998, lat: 40.878213 },
  { lng: -106.132425, lat: 40.876553 },
  { lng: -106.130671, lat: 40.873868 },
  { lng: -106.127691, lat: 40.871785 },
  { lng: -106.126893, lat: 40.869556 },
  { lng: -106.124647, lat: 40.868808 },
  { lng: -106.123176, lat: 40.867501 },
  { lng: -106.120803, lat: 40.867632 },
  { lng: -106.118971, lat: 40.865776 },
  { lng: -106.116536, lat: 40.866441 },
  { lng: -106.113192, lat: 40.864279 },
  { lng: -106.108404, lat: 40.863258 },
  { lng: -106.107763, lat: 40.861368 },
  { lng: -106.105092, lat: 40.860514 },
  { lng: -106.104281, lat: 40.859263 },
  { lng: -106.106284, lat: 40.854075 },
  { lng: -106.108069, lat: 40.852507 },
  { lng: -106.102442, lat: 40.852334 },
  { lng: -106.098515, lat: 40.849736 },
  { lng: -106.100138, lat: 40.84698 },
  { lng: -106.101261, lat: 40.843563 },
  { lng: -106.09878, lat: 40.841699 },
  { lng: -106.095537, lat: 40.841645 },
  { lng: -106.092686, lat: 40.840839 },
  { lng: -106.092494, lat: 40.839781 },
  { lng: -106.090502, lat: 40.837181 },
  { lng: -106.088596, lat: 40.836133 },
  { lng: -106.087914, lat: 40.833403 },
  { lng: -106.083221, lat: 40.831177 },
  { lng: -106.080861, lat: 40.830903 },
  { lng: -106.077828, lat: 40.828498 },
  { lng: -106.076392, lat: 40.827925 },
  { lng: -106.075306, lat: 40.825609 },
  { lng: -106.073125, lat: 40.824633 },
  { lng: -106.071316, lat: 40.822331 },
  { lng: -106.064831, lat: 40.821567 },
  { lng: -106.062533, lat: 40.819509 },
  { lng: -106.057715, lat: 40.81712 },
  { lng: -106.057798, lat: 40.814438 },
  { lng: -106.055097, lat: 40.813631 },
  { lng: -106.053779, lat: 40.812662 },
  { lng: -106.053288, lat: 40.810417 },
  { lng: -106.051502, lat: 40.809141 },
  { lng: -106.05235, lat: 40.805862 },
  { lng: -106.049587, lat: 40.805507 },
  { lng: -106.047971, lat: 40.804643 },
  { lng: -106.046471, lat: 40.802447 },
  { lng: -106.046694, lat: 40.800791 },
  { lng: -106.044896, lat: 40.797682 },
  { lng: -106.045979, lat: 40.796373 },
  { lng: -106.045371, lat: 40.791857 },
  { lng: -106.04281, lat: 40.79013 },
  { lng: -106.039652, lat: 40.78576 },
  { lng: -106.035211, lat: 40.785551 },
  { lng: -106.03197, lat: 40.783767 },
  { lng: -106.031236, lat: 40.782281 },
  { lng: -106.029685, lat: 40.781562 },
  { lng: -106.031033, lat: 40.77787 },
  { lng: -106.028908, lat: 40.777515 },
  { lng: -106.029566, lat: 40.775068 },
  { lng: -106.029247, lat: 40.773436 },
  { lng: -106.024488, lat: 40.771264 },
  { lng: -106.026643, lat: 40.768315 },
  { lng: -106.024338, lat: 40.766296 },
  { lng: -106.02335, lat: 40.763534 },
  { lng: -106.02388, lat: 40.761328 },
  { lng: -106.020673, lat: 40.758429 },
  { lng: -106.020651, lat: 40.757201 },
  { lng: -106.022255, lat: 40.754689 },
  { lng: -106.015266, lat: 40.752816 },
  { lng: -106.015447, lat: 40.750603 },
  { lng: -106.013978, lat: 40.746644 },
  { lng: -106.012215, lat: 40.743946 },
  { lng: -106.01036, lat: 40.742401 },
  { lng: -106.0107, lat: 40.741041 },
  { lng: -106.00839, lat: 40.740064 },
  { lng: -106.006008, lat: 40.737619 },
  { lng: -106.004026, lat: 40.736794 },
  { lng: -106.000569, lat: 40.734337 },
  { lng: -105.998677, lat: 40.734223 },
  { lng: -105.99627, lat: 40.733126 },
  { lng: -105.991965, lat: 40.728919 },
  { lng: -105.99058, lat: 40.72718 },
  { lng: -105.990939, lat: 40.724801 },
  { lng: -105.989284, lat: 40.72352 },
  { lng: -105.988079, lat: 40.721368 },
  { lng: -105.990077, lat: 40.718313 },
  { lng: -105.989763, lat: 40.713982 },
  { lng: -105.988739, lat: 40.709605 },
  { lng: -105.986021, lat: 40.707627 },
  { lng: -105.982448, lat: 40.706692 },
  { lng: -105.980521, lat: 40.703842 },
  { lng: -105.977085, lat: 40.702648 },
  { lng: -105.972196, lat: 40.694811 },
  { lng: -105.97376, lat: 40.691129 },
  { lng: -105.973584, lat: 40.689274 },
  { lng: -105.970026, lat: 40.686265 },
  { lng: -105.971873, lat: 40.681856 },
  { lng: -105.969392, lat: 40.67774 },
  { lng: -105.966664, lat: 40.675681 },
  { lng: -105.966455, lat: 40.673455 },
  { lng: -105.965114, lat: 40.671418 },
  { lng: -105.965039, lat: 40.668207 },
  { lng: -105.962292, lat: 40.666584 },
  { lng: -105.958639, lat: 40.66301 },
  { lng: -105.957838, lat: 40.660874 },
  { lng: -105.955821, lat: 40.658854 },
  { lng: -105.956686, lat: 40.654964 },
  { lng: -105.954765, lat: 40.65021 },
  { lng: -105.953844, lat: 40.646977 },
  { lng: -105.951079, lat: 40.645878 },
  { lng: -105.950816, lat: 40.643289 },
  { lng: -105.948485, lat: 40.642318 },
  { lng: -105.948062, lat: 40.635986 },
  { lng: -105.948573, lat: 40.633678 },
  { lng: -105.948667, lat: 40.630185 },
  { lng: -105.949261, lat: 40.62732 },
  { lng: -105.947199, lat: 40.623746 },
  { lng: -105.945495, lat: 40.622741 },
  { lng: -105.94511, lat: 40.621191 },
  { lng: -105.947021, lat: 40.616689 },
  { lng: -105.944742, lat: 40.614231 },
  { lng: -105.944535, lat: 40.612092 },
  { lng: -105.942064, lat: 40.609077 },
  { lng: -105.938681, lat: 40.608811 },
  { lng: -105.934711, lat: 40.60643 },
  { lng: -105.930207, lat: 40.60619 },
  { lng: -105.928953, lat: 40.603794 },
  { lng: -105.929, lat: 40.601202 },
  { lng: -105.92813, lat: 40.600058 },
  { lng: -105.927697, lat: 40.591709 },
  { lng: -105.926543, lat: 40.590589 },
  { lng: -105.924388, lat: 40.582146 },
  { lng: -105.924977, lat: 40.580934 },
  { lng: -105.921248, lat: 40.572559 },
  { lng: -105.918444, lat: 40.5707 },
  { lng: -105.915032, lat: 40.570724 },
  { lng: -105.91321, lat: 40.568313 },
  { lng: -105.910892, lat: 40.56642 },
  { lng: -105.909784, lat: 40.562134 },
  { lng: -105.909914, lat: 40.558806 },
  { lng: -105.911833, lat: 40.552688 },
  { lng: -105.90951, lat: 40.54793 },
  { lng: -105.909577, lat: 40.544982 },
  { lng: -105.907049, lat: 40.542888 },
  { lng: -105.90715, lat: 40.540578 },
  { lng: -105.905098, lat: 40.535361 },
  { lng: -105.905914, lat: 40.530519 },
  { lng: -105.907868, lat: 40.52621 },
  { lng: -105.909764, lat: 40.523452 },
  { lng: -105.911175, lat: 40.522181 },
  { lng: -105.91078, lat: 40.520179 },
  { lng: -105.907857, lat: 40.515493 },
  { lng: -105.904507, lat: 40.514119 },
  { lng: -105.899111, lat: 40.518225 },
  { lng: -105.894576, lat: 40.520196 },
  { lng: -105.891104, lat: 40.521003 },
  { lng: -105.883852, lat: 40.521751 },
  { lng: -105.881954, lat: 40.522853 },
  { lng: -105.879041, lat: 40.523174 },
  { lng: -105.878048, lat: 40.522465 },
  { lng: -105.871805, lat: 40.521748 },
  { lng: -105.869091, lat: 40.520738 },
  { lng: -105.867513, lat: 40.519714 },
  { lng: -105.866369, lat: 40.517257 },
  { lng: -105.863109, lat: 40.515521 },
  { lng: -105.8619, lat: 40.514449 },
  { lng: -105.860621, lat: 40.511549 },
  { lng: -105.858203, lat: 40.509629 },
  { lng: -105.857058, lat: 40.507856 },
  { lng: -105.854732, lat: 40.507789 },
  { lng: -105.852303, lat: 40.506553 },
  { lng: -105.852297, lat: 40.503534 },
  { lng: -105.851755, lat: 40.500475 },
  { lng: -105.852026, lat: 40.497569 },
  { lng: -105.854773, lat: 40.495106 },
  { lng: -105.855787, lat: 40.492682 },
  { lng: -105.856105, lat: 40.490412 },
  { lng: -105.855822, lat: 40.487738 },
  { lng: -105.854926, lat: 40.486252 },
  { lng: -105.847308, lat: 40.483931 },
  { lng: -105.846967, lat: 40.482791 },
  { lng: -105.843064, lat: 40.482994 },
  { lng: -105.841082, lat: 40.483618 },
  { lng: -105.837823, lat: 40.483033 },
  { lng: -105.834605, lat: 40.479869 },
  { lng: -105.830608, lat: 40.479568 },
  { lng: -105.827956, lat: 40.478289 },
  { lng: -105.822463, lat: 40.476829 },
  { lng: -105.820114, lat: 40.475972 },
  { lng: -105.81542, lat: 40.475339 },
  { lng: -105.812793, lat: 40.475855 },
  { lng: -105.808543, lat: 40.475061 },
  { lng: -105.806793, lat: 40.473913 },
  { lng: -105.807133, lat: 40.47241 },
  { lng: -105.808865, lat: 40.471078 },
  { lng: -105.810669, lat: 40.471087 },
  { lng: -105.80818, lat: 40.468868 },
  { lng: -105.805691, lat: 40.4665 },
  { lng: -105.804479, lat: 40.464224 },
  { lng: -105.805136, lat: 40.459233 },
  { lng: -105.807614, lat: 40.452262 },
  { lng: -105.80569, lat: 40.447882 },
  { lng: -105.806557, lat: 40.446202 },
  { lng: -105.810399, lat: 40.443231 },
  { lng: -105.811728, lat: 40.442853 },
  { lng: -105.813019, lat: 40.439161 },
  { lng: -105.815784, lat: 40.437434 },
  { lng: -105.816366, lat: 40.435519 },
  { lng: -105.814337, lat: 40.431334 },
  { lng: -105.814456, lat: 40.428522 },
  { lng: -105.817231, lat: 40.426488 },
  { lng: -105.817266, lat: 40.424323 },
  { lng: -105.816423, lat: 40.423991 },
  { lng: -105.81534, lat: 40.421486 },
  { lng: -105.811126, lat: 40.419399 },
  { lng: -105.808265, lat: 40.414954 },
  { lng: -105.801294, lat: 40.410175 },
  { lng: -105.800423, lat: 40.407218 },
  { lng: -105.798265, lat: 40.405835 },
  { lng: -105.794182, lat: 40.401882 },
  { lng: -105.793815, lat: 40.4008 },
  { lng: -105.790951, lat: 40.39864 },
  { lng: -105.791664, lat: 40.394335 },
  { lng: -105.790545, lat: 40.38964 },
  { lng: -105.791232, lat: 40.387863 },
  { lng: -105.790729, lat: 40.386263 },
  { lng: -105.788561, lat: 40.384282 },
  { lng: -105.78725, lat: 40.380872 },
  { lng: -105.784997, lat: 40.379715 },
  { lng: -105.78182, lat: 40.37699 },
  { lng: -105.78195, lat: 40.373759 },
  { lng: -105.776437, lat: 40.369545 },
  { lng: -105.774219, lat: 40.366759 },
  { lng: -105.774105, lat: 40.365233 },
  { lng: -105.772596, lat: 40.364411 },
  { lng: -105.769133, lat: 40.364474 },
  { lng: -105.767699, lat: 40.359764 },
  { lng: -105.764441, lat: 40.359263 },
  { lng: -105.763028, lat: 40.357118 },
  { lng: -105.759765, lat: 40.355779 },
  { lng: -105.759271, lat: 40.353307 },
  { lng: -105.757117, lat: 40.35165 },
  { lng: -105.753782, lat: 40.35152 },
  { lng: -105.752383, lat: 40.349093 },
  { lng: -105.747757, lat: 40.347508 },
  { lng: -105.742441, lat: 40.349263 },
  { lng: -105.739613, lat: 40.347108 },
  { lng: -105.736345, lat: 40.346615 },
  { lng: -105.736444, lat: 40.344906 },
  { lng: -105.735441, lat: 40.342984 },
  { lng: -105.733108, lat: 40.340774 },
  { lng: -105.729944, lat: 40.339624 },
  { lng: -105.728786, lat: 40.337935 },
  { lng: -105.729088, lat: 40.335278 },
  { lng: -105.724533, lat: 40.3327 },
  { lng: -105.721438, lat: 40.332018 },
  { lng: -105.713127, lat: 40.325568 },
  { lng: -105.706773, lat: 40.325051 },
  { lng: -105.70168, lat: 40.324309 },
  { lng: -105.70202, lat: 40.321711 },
  { lng: -105.698719, lat: 40.32078 },
  { lng: -105.697648, lat: 40.318273 },
  { lng: -105.700167, lat: 40.316364 },
  { lng: -105.701507, lat: 40.314445 },
  { lng: -105.702109, lat: 40.312083 },
  { lng: -105.700909, lat: 40.310715 },
  { lng: -105.697764, lat: 40.310939 },
  { lng: -105.694834, lat: 40.309323 },
  { lng: -105.691863, lat: 40.309373 },
  { lng: -105.69014, lat: 40.308648 },
  { lng: -105.691278, lat: 40.306164 },
  { lng: -105.689295, lat: 40.303479 },
  { lng: -105.686177, lat: 40.303075 },
  { lng: -105.688272, lat: 40.297334 },
  { lng: -105.687368, lat: 40.293733 },
  { lng: -105.684758, lat: 40.292383 },
  { lng: -105.681165, lat: 40.291945 },
  { lng: -105.68365, lat: 40.29119 },
  { lng: -105.686403, lat: 40.288918 },
  { lng: -105.686346, lat: 40.287486 },
  { lng: -105.685046, lat: 40.286409 },
  { lng: -105.680579, lat: 40.28446 },
  { lng: -105.680732, lat: 40.282927 },
  { lng: -105.679262, lat: 40.278912 },
  { lng: -105.681469, lat: 40.279046 },
  { lng: -105.680347, lat: 40.274668 },
  { lng: -105.682233, lat: 40.273164 },
  { lng: -105.681508, lat: 40.271684 },
  { lng: -105.678903, lat: 40.269869 },
  { lng: -105.678187, lat: 40.26812 },
  { lng: -105.67559, lat: 40.267267 },
  { lng: -105.672055, lat: 40.268101 },
  { lng: -105.667909, lat: 40.267718 },
  { lng: -105.665236, lat: 40.266894 },
  { lng: -105.662378, lat: 40.264124 },
  { lng: -105.66072, lat: 40.264228 },
  { lng: -105.657235, lat: 40.262886 },
  { lng: -105.653321, lat: 40.260457 },
  { lng: -105.56174, lat: 40.261507 },
  { lng: -105.542801, lat: 40.26134 },
  { lng: -105.542003, lat: 40.261327 },
  { lng: -105.539455, lat: 40.261284 },
  { lng: -105.535354, lat: 40.261284 },
  { lng: -105.518083, lat: 40.260753 },
  { lng: -105.500481, lat: 40.260277 },
  { lng: -105.495321, lat: 40.259726 },
  { lng: -105.465394, lat: 40.259357 },
  { lng: -105.462752, lat: 40.259384 },
  { lng: -105.390996, lat: 40.257884 },
  { lng: -105.375226, lat: 40.261291 },
  { lng: -105.354747, lat: 40.261784 },
  { lng: -105.336352, lat: 40.261685 },
  { lng: -105.336248, lat: 40.261685 },
  { lng: -105.335177, lat: 40.261781 },
  { lng: -105.289224, lat: 40.262781 },
  { lng: -105.237319, lat: 40.261895 },
  { lng: -105.21483, lat: 40.261299 },
  { lng: -105.212623, lat: 40.261185 },
  { lng: -105.208144, lat: 40.261185 },
  { lng: -105.15906, lat: 40.261219 },
  { lng: -105.142904, lat: 40.26113 },
  { lng: -105.131059, lat: 40.261079 },
  { lng: -105.130179, lat: 40.261584 },
  { lng: -105.109974, lat: 40.261284 },
  { lng: -105.055089, lat: 40.261793 },
  { lng: -105.055089, lat: 40.269286 },
  { lng: -105.055175, lat: 40.290883 },
  { lng: -105.055166, lat: 40.296766 },
  { lng: -105.055145, lat: 40.305421 },
  { lng: -105.05512, lat: 40.308232 },
  { lng: -105.055326, lat: 40.319905 },
  { lng: -105.055221, lat: 40.321045 },
  { lng: -105.055135, lat: 40.335183 },
  { lng: -105.05672, lat: 40.349281 },
  { lng: -105.002429, lat: 40.349217 },
  { lng: -104.98346, lat: 40.349184 },
  { lng: -104.981677, lat: 40.349165 },
  { lng: -104.981291, lat: 40.349164 },
  { lng: -104.980379, lat: 40.349171 },
  { lng: -104.970387, lat: 40.349149 },
  { lng: -104.966444, lat: 40.349142 },
  { lng: -104.961729, lat: 40.349138 },
  { lng: -104.959956, lat: 40.349132 },
  { lng: -104.957356, lat: 40.349123 },
  { lng: -104.955039, lat: 40.349121 },
  { lng: -104.954798, lat: 40.349121 },
  { lng: -104.945551, lat: 40.349099 },
  { lng: -104.945346, lat: 40.363476 },
  { lng: -104.945229, lat: 40.370759 },
  { lng: -104.944744, lat: 40.398786 },
  { lng: -104.944583, lat: 40.40651 },
  { lng: -104.944568, lat: 40.406964 },
  { lng: -104.944562, lat: 40.407348 },
  { lng: -104.944427, lat: 40.414196 },
  { lng: -104.944222, lat: 40.435894 },
  { lng: -104.944186, lat: 40.457026 },
  { lng: -104.944116, lat: 40.473375 },
  { lng: -104.944116, lat: 40.473495 },
  { lng: -104.944108, lat: 40.47636 },
  { lng: -104.944096, lat: 40.4793 },
  { lng: -104.944147, lat: 40.486445 },
  { lng: -104.944154, lat: 40.504925 },
  { lng: -104.944164, lat: 40.508104 },
  { lng: -104.944213, lat: 40.519137 },
  { lng: -104.944214, lat: 40.519191 },
  { lng: -104.944247, lat: 40.522663 },
  { lng: -104.944258, lat: 40.522768 },
  { lng: -104.944205, lat: 40.537354 },
  { lng: -104.944186, lat: 40.542832 },
  { lng: -104.944151, lat: 40.551715 },
  { lng: -104.944108, lat: 40.566251 },
  { lng: -104.944112, lat: 40.580938 },
  { lng: -104.944095, lat: 40.58809 },
  { lng: -104.944091, lat: 40.595356 },
  { lng: -104.944001, lat: 40.616785 },
  { lng: -104.943862, lat: 40.638777 },
  { lng: -104.943484, lat: 40.653219 },
  { lng: -104.943244, lat: 40.682254 },
  { lng: -104.94311, lat: 40.696495 },
  { lng: -104.943543, lat: 40.696509 },
  { lng: -104.943337, lat: 40.783294 },
  { lng: -104.943371, lat: 40.9980837238089 },
  { lng: -105.254779, lat: 40.99821 },
  { lng: -105.256527, lat: 40.998191 },
  { lng: -105.276859991921, lat: 40.99817324279 },
  { lng: -105.277138, lat: 40.998173 },
  { lng: -105.412718025682, lat: 40.9977904880995 },
  { lng: -105.724804, lat: 40.99691 },
  { lng: -105.730421, lat: 40.996886 },
  { lng: -105.77056285729, lat: 40.9968997139614 },
  { lng: -106.061181, lat: 40.996999 },
  { lng: -106.190553863626, lat: 40.9976070173843 },
  { lng: -106.192357, lat: 40.993688 },
  { lng: -106.194231, lat: 40.99197 },
  { lng: -106.195372, lat: 40.989938 },
];

export const jefferson = [
  { lng: -105.399089, lat: 39.582072 },
  { lng: -105.39915, lat: 39.581588 },
  { lng: -105.398949, lat: 39.566056 },
  { lng: -105.398851, lat: 39.535588 },
  { lng: -105.398518, lat: 39.52475 },
  { lng: -105.398251, lat: 39.499167 },
  { lng: -105.398139, lat: 39.468725 },
  { lng: -105.397898, lat: 39.466957 },
  { lng: -105.398341, lat: 39.464173 },
  { lng: -105.398552, lat: 39.463488 },
  { lng: -105.398064, lat: 39.452251 },
  { lng: -105.398051, lat: 39.424159 },
  { lng: -105.398154, lat: 39.40127 },
  { lng: -105.39815, lat: 39.399407 },
  { lng: -105.39795, lat: 39.388791 },
  { lng: -105.398552, lat: 39.37809 },
  { lng: -105.398353, lat: 39.327789 },
  { lng: -105.398652, lat: 39.326189 },
  { lng: -105.398114, lat: 39.315776 },
  { lng: -105.396712, lat: 39.264883 },
  { lng: -105.3979, lat: 39.24999 },
  { lng: -105.397881, lat: 39.203277 },
  { lng: -105.397759, lat: 39.158624 },
  { lng: -105.397875, lat: 39.129564 },
  { lng: -105.330444, lat: 39.129685 },
  { lng: -105.32922, lat: 39.129689 },
  { lng: -105.327393, lat: 39.130873 },
  { lng: -105.324774, lat: 39.133863 },
  { lng: -105.322757, lat: 39.135595 },
  { lng: -105.321962, lat: 39.136967 },
  { lng: -105.321778, lat: 39.139017 },
  { lng: -105.322782, lat: 39.141114 },
  { lng: -105.322147, lat: 39.142958 },
  { lng: -105.323285, lat: 39.144992 },
  { lng: -105.321556, lat: 39.146191 },
  { lng: -105.319916, lat: 39.145754 },
  { lng: -105.315634, lat: 39.145801 },
  { lng: -105.315347, lat: 39.146712 },
  { lng: -105.316193, lat: 39.148855 },
  { lng: -105.315579, lat: 39.149911 },
  { lng: -105.315949, lat: 39.151302 },
  { lng: -105.31531, lat: 39.154895 },
  { lng: -105.313087, lat: 39.157077 },
  { lng: -105.313557, lat: 39.159121 },
  { lng: -105.313063, lat: 39.160019 },
  { lng: -105.310692, lat: 39.161526 },
  { lng: -105.309982, lat: 39.163519 },
  { lng: -105.307059, lat: 39.165356 },
  { lng: -105.305197, lat: 39.164024 },
  { lng: -105.304454, lat: 39.164281 },
  { lng: -105.302271, lat: 39.166689 },
  { lng: -105.301022, lat: 39.166777 },
  { lng: -105.300214, lat: 39.168017 },
  { lng: -105.302014, lat: 39.169246 },
  { lng: -105.301529, lat: 39.170612 },
  { lng: -105.29944, lat: 39.172529 },
  { lng: -105.294216, lat: 39.172977 },
  { lng: -105.293635, lat: 39.173975 },
  { lng: -105.294748, lat: 39.174838 },
  { lng: -105.289498, lat: 39.177494 },
  { lng: -105.287377, lat: 39.179442 },
  { lng: -105.287331, lat: 39.181255 },
  { lng: -105.289236, lat: 39.183416 },
  { lng: -105.289182, lat: 39.184172 },
  { lng: -105.28639, lat: 39.186314 },
  { lng: -105.285928, lat: 39.189585 },
  { lng: -105.284702, lat: 39.192703 },
  { lng: -105.2851, lat: 39.194588 },
  { lng: -105.282929, lat: 39.199953 },
  { lng: -105.281261, lat: 39.201757 },
  { lng: -105.278814, lat: 39.201811 },
  { lng: -105.277696, lat: 39.202801 },
  { lng: -105.276291, lat: 39.207216 },
  { lng: -105.27316, lat: 39.207624 },
  { lng: -105.272176, lat: 39.208651 },
  { lng: -105.269519, lat: 39.209419 },
  { lng: -105.267546, lat: 39.209225 },
  { lng: -105.263194, lat: 39.208005 },
  { lng: -105.26212, lat: 39.209918 },
  { lng: -105.259735, lat: 39.210905 },
  { lng: -105.260086, lat: 39.212558 },
  { lng: -105.26152, lat: 39.214758 },
  { lng: -105.260576, lat: 39.216688 },
  { lng: -105.262868, lat: 39.219463 },
  { lng: -105.263528, lat: 39.221169 },
  { lng: -105.261302, lat: 39.223269 },
  { lng: -105.262425, lat: 39.22483 },
  { lng: -105.26166, lat: 39.226805 },
  { lng: -105.263568, lat: 39.228811 },
  { lng: -105.261784, lat: 39.231482 },
  { lng: -105.257228, lat: 39.233547 },
  { lng: -105.25432, lat: 39.237923 },
  { lng: -105.25503, lat: 39.240274 },
  { lng: -105.253536, lat: 39.241372 },
  { lng: -105.253567, lat: 39.243156 },
  { lng: -105.252428, lat: 39.243695 },
  { lng: -105.247275, lat: 39.240425 },
  { lng: -105.246017, lat: 39.240566 },
  { lng: -105.243403, lat: 39.242655 },
  { lng: -105.240559, lat: 39.244057 },
  { lng: -105.239741, lat: 39.243635 },
  { lng: -105.235631, lat: 39.244261 },
  { lng: -105.234367, lat: 39.245562 },
  { lng: -105.233938, lat: 39.246868 },
  { lng: -105.234781, lat: 39.249297 },
  { lng: -105.237336, lat: 39.2495 },
  { lng: -105.237678, lat: 39.25047 },
  { lng: -105.235256, lat: 39.251313 },
  { lng: -105.234202, lat: 39.252413 },
  { lng: -105.23458, lat: 39.255091 },
  { lng: -105.231807, lat: 39.253962 },
  { lng: -105.228121, lat: 39.253766 },
  { lng: -105.226972, lat: 39.25635 },
  { lng: -105.2236, lat: 39.25599 },
  { lng: -105.223402, lat: 39.258417 },
  { lng: -105.22536, lat: 39.259792 },
  { lng: -105.225105, lat: 39.260525 },
  { lng: -105.219598, lat: 39.259641 },
  { lng: -105.217833, lat: 39.260115 },
  { lng: -105.217204, lat: 39.261175 },
  { lng: -105.217784, lat: 39.263548 },
  { lng: -105.21875, lat: 39.265349 },
  { lng: -105.218699, lat: 39.267042 },
  { lng: -105.220776, lat: 39.268063 },
  { lng: -105.220778, lat: 39.268662 },
  { lng: -105.219179, lat: 39.270503 },
  { lng: -105.21558, lat: 39.272232 },
  { lng: -105.215662, lat: 39.273349 },
  { lng: -105.217507, lat: 39.274596 },
  { lng: -105.215526, lat: 39.276788 },
  { lng: -105.216386, lat: 39.277618 },
  { lng: -105.215438, lat: 39.278574 },
  { lng: -105.213897, lat: 39.278693 },
  { lng: -105.211325, lat: 39.280967 },
  { lng: -105.211334, lat: 39.282915 },
  { lng: -105.212473, lat: 39.284484 },
  { lng: -105.21017, lat: 39.28774 },
  { lng: -105.208042, lat: 39.289612 },
  { lng: -105.208736, lat: 39.290616 },
  { lng: -105.207908, lat: 39.291525 },
  { lng: -105.206498, lat: 39.290678 },
  { lng: -105.205531, lat: 39.291681 },
  { lng: -105.206981, lat: 39.293202 },
  { lng: -105.205527, lat: 39.29399 },
  { lng: -105.20393, lat: 39.293056 },
  { lng: -105.202576, lat: 39.293321 },
  { lng: -105.202887, lat: 39.294752 },
  { lng: -105.205231, lat: 39.294899 },
  { lng: -105.207173, lat: 39.296228 },
  { lng: -105.207681, lat: 39.298194 },
  { lng: -105.206793, lat: 39.299196 },
  { lng: -105.204979, lat: 39.299798 },
  { lng: -105.201368, lat: 39.299269 },
  { lng: -105.201283, lat: 39.30077 },
  { lng: -105.200212, lat: 39.302029 },
  { lng: -105.197231, lat: 39.30172 },
  { lng: -105.196, lat: 39.302082 },
  { lng: -105.196522, lat: 39.303949 },
  { lng: -105.197824, lat: 39.304988 },
  { lng: -105.19668, lat: 39.308544 },
  { lng: -105.198397, lat: 39.30928 },
  { lng: -105.198156, lat: 39.310432 },
  { lng: -105.194662, lat: 39.312434 },
  { lng: -105.193487, lat: 39.312582 },
  { lng: -105.190197, lat: 39.316194 },
  { lng: -105.188587, lat: 39.317384 },
  { lng: -105.189123, lat: 39.319105 },
  { lng: -105.188814, lat: 39.321153 },
  { lng: -105.187803, lat: 39.322853 },
  { lng: -105.186292, lat: 39.323304 },
  { lng: -105.185486, lat: 39.324405 },
  { lng: -105.185883, lat: 39.326155 },
  { lng: -105.188054, lat: 39.326902 },
  { lng: -105.188184, lat: 39.327961 },
  { lng: -105.186057, lat: 39.32829 },
  { lng: -105.182305, lat: 39.329797 },
  { lng: -105.182268, lat: 39.331374 },
  { lng: -105.185572, lat: 39.333003 },
  { lng: -105.187432, lat: 39.333256 },
  { lng: -105.187837, lat: 39.334186 },
  { lng: -105.183956, lat: 39.33549 },
  { lng: -105.182144, lat: 39.338588 },
  { lng: -105.1801, lat: 39.339155 },
  { lng: -105.178729, lat: 39.340786 },
  { lng: -105.179535, lat: 39.342309 },
  { lng: -105.179174, lat: 39.343462 },
  { lng: -105.177158, lat: 39.34471 },
  { lng: -105.176532, lat: 39.347515 },
  { lng: -105.177193, lat: 39.349579 },
  { lng: -105.175157, lat: 39.35049 },
  { lng: -105.171853, lat: 39.35345 },
  { lng: -105.172357, lat: 39.355817 },
  { lng: -105.171517, lat: 39.35722 },
  { lng: -105.169127, lat: 39.358934 },
  { lng: -105.16786, lat: 39.359017 },
  { lng: -105.166574, lat: 39.361754 },
  { lng: -105.166435, lat: 39.362958 },
  { lng: -105.167792, lat: 39.366371 },
  { lng: -105.168922, lat: 39.367265 },
  { lng: -105.169114, lat: 39.369098 },
  { lng: -105.167636, lat: 39.370426 },
  { lng: -105.167099, lat: 39.3722 },
  { lng: -105.1679, lat: 39.37398 },
  { lng: -105.170165, lat: 39.374518 },
  { lng: -105.17033, lat: 39.375347 },
  { lng: -105.17226, lat: 39.376411 },
  { lng: -105.172997, lat: 39.377722 },
  { lng: -105.17125, lat: 39.379638 },
  { lng: -105.169785, lat: 39.380342 },
  { lng: -105.17013, lat: 39.38125 },
  { lng: -105.170856, lat: 39.382261 },
  { lng: -105.173284, lat: 39.382317 },
  { lng: -105.173515, lat: 39.383203 },
  { lng: -105.172978, lat: 39.385745 },
  { lng: -105.171219, lat: 39.386804 },
  { lng: -105.169968, lat: 39.389096 },
  { lng: -105.16566, lat: 39.391178 },
  { lng: -105.164337, lat: 39.391136 },
  { lng: -105.165495, lat: 39.394008 },
  { lng: -105.167258, lat: 39.394308 },
  { lng: -105.167642, lat: 39.395748 },
  { lng: -105.166972, lat: 39.398187 },
  { lng: -105.167194, lat: 39.399753 },
  { lng: -105.169408, lat: 39.40159 },
  { lng: -105.169441, lat: 39.403326 },
  { lng: -105.170482, lat: 39.404602 },
  { lng: -105.171278, lat: 39.406854 },
  { lng: -105.170963, lat: 39.407825 },
  { lng: -105.169042, lat: 39.408936 },
  { lng: -105.166671, lat: 39.40851 },
  { lng: -105.16262, lat: 39.411929 },
  { lng: -105.161261, lat: 39.413758 },
  { lng: -105.159937, lat: 39.414323 },
  { lng: -105.155977, lat: 39.413802 },
  { lng: -105.151767, lat: 39.415681 },
  { lng: -105.14942, lat: 39.4176 },
  { lng: -105.144923, lat: 39.418996 },
  { lng: -105.141408, lat: 39.421136 },
  { lng: -105.140567, lat: 39.422401 },
  { lng: -105.138206, lat: 39.42307 },
  { lng: -105.136973, lat: 39.425421 },
  { lng: -105.135425, lat: 39.426779 },
  { lng: -105.133071, lat: 39.427439 },
  { lng: -105.131564, lat: 39.429235 },
  { lng: -105.128424, lat: 39.431757 },
  { lng: -105.123522, lat: 39.434561 },
  { lng: -105.124674, lat: 39.435879 },
  { lng: -105.126144, lat: 39.440187 },
  { lng: -105.124704, lat: 39.442736 },
  { lng: -105.124742, lat: 39.44402 },
  { lng: -105.123214, lat: 39.447831 },
  { lng: -105.123728, lat: 39.449464 },
  { lng: -105.123062, lat: 39.450847 },
  { lng: -105.124184, lat: 39.454985 },
  { lng: -105.125017, lat: 39.456367 },
  { lng: -105.126696, lat: 39.4572 },
  { lng: -105.127474, lat: 39.458467 },
  { lng: -105.130399, lat: 39.459527 },
  { lng: -105.132941, lat: 39.461912 },
  { lng: -105.132577, lat: 39.464321 },
  { lng: -105.133132, lat: 39.466312 },
  { lng: -105.132625, lat: 39.467908 },
  { lng: -105.134993, lat: 39.470785 },
  { lng: -105.130357, lat: 39.47368 },
  { lng: -105.128398, lat: 39.477388 },
  { lng: -105.127081, lat: 39.478185 },
  { lng: -105.121017, lat: 39.481454 },
  { lng: -105.118535, lat: 39.480207 },
  { lng: -105.116276, lat: 39.481451 },
  { lng: -105.115883, lat: 39.482535 },
  { lng: -105.118266, lat: 39.482988 },
  { lng: -105.117646, lat: 39.485392 },
  { lng: -105.114694, lat: 39.4858 },
  { lng: -105.113154, lat: 39.483904 },
  { lng: -105.110685, lat: 39.483553 },
  { lng: -105.106942, lat: 39.484719 },
  { lng: -105.103313, lat: 39.484715 },
  { lng: -105.100359, lat: 39.485487 },
  { lng: -105.098707, lat: 39.485394 },
  { lng: -105.09333, lat: 39.488054 },
  { lng: -105.091353, lat: 39.48928 },
  { lng: -105.088514, lat: 39.492392 },
  { lng: -105.086751, lat: 39.493423 },
  { lng: -105.086109, lat: 39.494923 },
  { lng: -105.087185, lat: 39.496061 },
  { lng: -105.086148, lat: 39.497633 },
  { lng: -105.086495, lat: 39.499133 },
  { lng: -105.084841, lat: 39.50121 },
  { lng: -105.085227, lat: 39.50543 },
  { lng: -105.084665, lat: 39.506585 },
  { lng: -105.081133, lat: 39.509667 },
  { lng: -105.079858, lat: 39.510001 },
  { lng: -105.078402, lat: 39.51149 },
  { lng: -105.078859, lat: 39.51285 },
  { lng: -105.077945, lat: 39.517495 },
  { lng: -105.079, lat: 39.519142 },
  { lng: -105.080096, lat: 39.523156 },
  { lng: -105.079288, lat: 39.523893 },
  { lng: -105.079461, lat: 39.525888 },
  { lng: -105.076434, lat: 39.531624 },
  { lng: -105.076106, lat: 39.532691 },
  { lng: -105.076571, lat: 39.534968 },
  { lng: -105.077745, lat: 39.536706 },
  { lng: -105.076419, lat: 39.540055 },
  { lng: -105.074594, lat: 39.541652 },
  { lng: -105.074111, lat: 39.544268 },
  { lng: -105.073045, lat: 39.545265 },
  { lng: -105.066735, lat: 39.550499 },
  { lng: -105.065672, lat: 39.553346 },
  { lng: -105.06424, lat: 39.554188 },
  { lng: -105.06113, lat: 39.556906 },
  { lng: -105.060714, lat: 39.559941 },
  { lng: -105.058257, lat: 39.560379 },
  { lng: -105.057252, lat: 39.562306 },
  { lng: -105.05306, lat: 39.563109 },
  { lng: -105.051366, lat: 39.563257 },
  { lng: -105.04874, lat: 39.566088 },
  { lng: -105.05414, lat: 39.566088 },
  { lng: -105.054145, lat: 39.567085 },
  { lng: -105.054169, lat: 39.56771 },
  { lng: -105.05424, lat: 39.569788 },
  { lng: -105.054211, lat: 39.570856 },
  { lng: -105.054176, lat: 39.572183 },
  { lng: -105.054104, lat: 39.573774 },
  { lng: -105.053968, lat: 39.574768 },
  { lng: -105.05404, lat: 39.575768 },
  { lng: -105.05395, lat: 39.580739 },
  { lng: -105.053889, lat: 39.587404 },
  { lng: -105.053703, lat: 39.59522 },
  { lng: -105.053816, lat: 39.600821 },
  { lng: -105.05382, lat: 39.600836 },
  { lng: -105.053836, lat: 39.600885 },
  { lng: -105.053807, lat: 39.601387 },
  { lng: -105.053625, lat: 39.609665 },
  { lng: -105.053641, lat: 39.613976 },
  { lng: -105.053653, lat: 39.6159 },
  { lng: -105.053437, lat: 39.620984 },
  { lng: -105.056995, lat: 39.621072 },
  { lng: -105.056252, lat: 39.620082 },
  { lng: -105.05808, lat: 39.617503 },
  { lng: -105.058125, lat: 39.61443 },
  { lng: -105.064199, lat: 39.614574 },
  { lng: -105.091405, lat: 39.614455 },
  { lng: -105.09691, lat: 39.614515 },
  { lng: -105.096955, lat: 39.617969 },
  { lng: -105.09696, lat: 39.618362 },
  { lng: -105.093172, lat: 39.618324 },
  { lng: -105.091362, lat: 39.618336 },
  { lng: -105.084297, lat: 39.618584 },
  { lng: -105.074796, lat: 39.618511 },
  { lng: -105.065141, lat: 39.618582 },
  { lng: -105.065915, lat: 39.62084 },
  { lng: -105.076001, lat: 39.624225 },
  { lng: -105.076278, lat: 39.624962 },
  { lng: -105.080181, lat: 39.629419 },
  { lng: -105.081581, lat: 39.630086 },
  { lng: -105.08167, lat: 39.631326 },
  { lng: -105.08178, lat: 39.631325 },
  { lng: -105.083712, lat: 39.631327 },
  { lng: -105.083735, lat: 39.630155 },
  { lng: -105.081811, lat: 39.63015 },
  { lng: -105.08173, lat: 39.627313 },
  { lng: -105.083813, lat: 39.627318 },
  { lng: -105.083789, lat: 39.628527 },
  { lng: -105.08558, lat: 39.628527 },
  { lng: -105.085626, lat: 39.626107 },
  { lng: -105.087391, lat: 39.626046 },
  { lng: -105.087384, lat: 39.62709 },
  { lng: -105.088626, lat: 39.627087 },
  { lng: -105.091164, lat: 39.627081 },
  { lng: -105.091256, lat: 39.624198 },
  { lng: -105.098516, lat: 39.624222 },
  { lng: -105.107503, lat: 39.624218 },
  { lng: -105.107496, lat: 39.627133 },
  { lng: -105.109617, lat: 39.627153 },
  { lng: -105.109883, lat: 39.629897 },
  { lng: -105.109902, lat: 39.634246 },
  { lng: -105.109874, lat: 39.635186 },
  { lng: -105.109558, lat: 39.635188 },
  { lng: -105.100841, lat: 39.635187 },
  { lng: -105.099641, lat: 39.634987 },
  { lng: -105.091582, lat: 39.635178 },
  { lng: -105.091023, lat: 39.638718 },
  { lng: -105.085844, lat: 39.6387 },
  { lng: -105.081676, lat: 39.638759 },
  { lng: -105.081565, lat: 39.636166 },
  { lng: -105.080344, lat: 39.636216 },
  { lng: -105.079722, lat: 39.636705 },
  { lng: -105.073307, lat: 39.636563 },
  { lng: -105.073271, lat: 39.63874 },
  { lng: -105.076803, lat: 39.638735 },
  { lng: -105.074454, lat: 39.644104 },
  { lng: -105.072326, lat: 39.64535 },
  { lng: -105.072049, lat: 39.645815 },
  { lng: -105.063223, lat: 39.645992 },
  { lng: -105.063197, lat: 39.649633 },
  { lng: -105.057078, lat: 39.649699 },
  { lng: -105.05703, lat: 39.651203 },
  { lng: -105.053727, lat: 39.651159 },
  { lng: -105.053754, lat: 39.651727 },
  { lng: -105.05715, lat: 39.652946 },
  { lng: -105.058227, lat: 39.651279 },
  { lng: -105.062053, lat: 39.651264 },
  { lng: -105.063926, lat: 39.64962 },
  { lng: -105.071029, lat: 39.649579 },
  { lng: -105.07094, lat: 39.650486 },
  { lng: -105.067026, lat: 39.653327 },
  { lng: -105.067283, lat: 39.653503 },
  { lng: -105.067288, lat: 39.656879 },
  { lng: -105.070144, lat: 39.656874 },
  { lng: -105.070016, lat: 39.659071 },
  { lng: -105.071972, lat: 39.659951 },
  { lng: -105.076676, lat: 39.659312 },
  { lng: -105.079163, lat: 39.662041 },
  { lng: -105.081453, lat: 39.662056 },
  { lng: -105.081455, lat: 39.665551 },
  { lng: -105.081451, lat: 39.667743 },
  { lng: -105.081348, lat: 39.670133 },
  { lng: -105.07667, lat: 39.669061 },
  { lng: -105.074504, lat: 39.67025 },
  { lng: -105.071997, lat: 39.669344 },
  { lng: -105.071991, lat: 39.667763 },
  { lng: -105.067297, lat: 39.667775 },
  { lng: -105.06011, lat: 39.66778 },
  { lng: -105.053256, lat: 39.667785 },
  { lng: -105.053261, lat: 39.67328 },
  { lng: -105.053234, lat: 39.674299 },
  { lng: -105.053251, lat: 39.675037 },
  { lng: -105.062622, lat: 39.675008 },
  { lng: -105.06262, lat: 39.67928 },
  { lng: -105.061005, lat: 39.678041 },
  { lng: -105.060335, lat: 39.677954 },
  { lng: -105.057794, lat: 39.678697 },
  { lng: -105.057797, lat: 39.682272 },
  { lng: -105.057265, lat: 39.682272 },
  { lng: -105.053221, lat: 39.682291 },
  { lng: -105.053183, lat: 39.683498 },
  { lng: -105.053266, lat: 39.689559 },
  { lng: -105.053194, lat: 39.691364 },
  { lng: -105.053225, lat: 39.696769 },
  { lng: -105.053225, lat: 39.696889 },
  { lng: -105.053245, lat: 39.703268 },
  { lng: -105.053247, lat: 39.703985 },
  { lng: -105.053246, lat: 39.705814 },
  { lng: -105.05325, lat: 39.711343 },
  { lng: -105.053271, lat: 39.715004 },
  { lng: -105.053278, lat: 39.718427 },
  { lng: -105.053271, lat: 39.720414 },
  { lng: -105.053253, lat: 39.7258 },
  { lng: -105.053253, lat: 39.725828 },
  { lng: -105.053227, lat: 39.73311 },
  { lng: -105.055588, lat: 39.733105 },
  { lng: -105.055591, lat: 39.734508 },
  { lng: -105.055567, lat: 39.734977 },
  { lng: -105.053228, lat: 39.734965 },
  { lng: -105.053206, lat: 39.740411 },
  { lng: -105.05321, lat: 39.743273 },
  { lng: -105.053212, lat: 39.74398 },
  { lng: -105.053219, lat: 39.747616 },
  { lng: -105.053227, lat: 39.751249 },
  { lng: -105.053236, lat: 39.754848 },
  { lng: -105.053239, lat: 39.758472 },
  { lng: -105.053244, lat: 39.762084 },
  { lng: -105.053241, lat: 39.763903 },
  { lng: -105.053243, lat: 39.764814 },
  { lng: -105.05324, lat: 39.769333 },
  { lng: -105.053241, lat: 39.772938 },
  { lng: -105.053245, lat: 39.776583 },
  { lng: -105.053184, lat: 39.783533 },
  { lng: -105.053183, lat: 39.783797 },
  { lng: -105.062659, lat: 39.783847 },
  { lng: -105.062944, lat: 39.788087 },
  { lng: -105.065091, lat: 39.78836 },
  { lng: -105.064541, lat: 39.79108 },
  { lng: -105.063298, lat: 39.790081 },
  { lng: -105.06148, lat: 39.791075 },
  { lng: -105.053246, lat: 39.791063 },
  { lng: -105.053164, lat: 39.799109 },
  { lng: -105.053162, lat: 39.799239 },
  { lng: -105.053102, lat: 39.799788 },
  { lng: -105.052949, lat: 39.800437 },
  { lng: -105.052886, lat: 39.800863 },
  { lng: -105.052839, lat: 39.802535 },
  { lng: -105.053256, lat: 39.806497 },
  { lng: -105.05325, lat: 39.81277 },
  { lng: -105.05327, lat: 39.820028 },
  { lng: -105.053282, lat: 39.821163 },
  { lng: -105.053281, lat: 39.821859 },
  { lng: -105.053271, lat: 39.823666 },
  { lng: -105.053262, lat: 39.827273 },
  { lng: -105.053266, lat: 39.834527 },
  { lng: -105.053259, lat: 39.839969 },
  { lng: -105.053236, lat: 39.841807 },
  { lng: -105.05321, lat: 39.845763 },
  { lng: -105.053188, lat: 39.849649 },
  { lng: -105.0529, lat: 39.852042 },
  { lng: -105.052847, lat: 39.853229 },
  { lng: -105.053339, lat: 39.855978 },
  { lng: -105.053289, lat: 39.856764 },
  { lng: -105.053228, lat: 39.857111 },
  { lng: -105.053298, lat: 39.858762 },
  { lng: -105.053263, lat: 39.859948 },
  { lng: -105.053284, lat: 39.860436 },
  { lng: -105.053191, lat: 39.86362 },
  { lng: -105.05323, lat: 39.869395 },
  { lng: -105.053091, lat: 39.885356 },
  { lng: -105.053036, lat: 39.892187 },
  { lng: -105.053005, lat: 39.899806 },
  { lng: -105.052968, lat: 39.907049 },
  { lng: -105.052892, lat: 39.914224 },
  { lng: -105.052971, lat: 39.914224 },
  { lng: -105.052979, lat: 39.913722 },
  { lng: -105.055319, lat: 39.913725 },
  { lng: -105.055294, lat: 39.912426 },
  { lng: -105.060042, lat: 39.912453 },
  { lng: -105.060042, lat: 39.913977 },
  { lng: -105.062384, lat: 39.913987 },
  { lng: -105.062386, lat: 39.912528 },
  { lng: -105.062386, lat: 39.91159 },
  { lng: -105.062392, lat: 39.907025 },
  { lng: -105.071775, lat: 39.907018 },
  { lng: -105.071733, lat: 39.904579 },
  { lng: -105.071688, lat: 39.899832 },
  { lng: -105.073916, lat: 39.899785 },
  { lng: -105.08025, lat: 39.899819 },
  { lng: -105.080424, lat: 39.899819 },
  { lng: -105.081055, lat: 39.899821 },
  { lng: -105.08423, lat: 39.899837 },
  { lng: -105.084285, lat: 39.898957 },
  { lng: -105.090624, lat: 39.898903 },
  { lng: -105.090626, lat: 39.899677 },
  { lng: -105.096692, lat: 39.899788 },
  { lng: -105.096681, lat: 39.900787 },
  { lng: -105.100102, lat: 39.902206 },
  { lng: -105.100641, lat: 39.908638 },
  { lng: -105.106951, lat: 39.911729 },
  { lng: -105.108178, lat: 39.91281 },
  { lng: -105.108609, lat: 39.914137 },
  { lng: -105.146531, lat: 39.914032 },
  { lng: -105.147121, lat: 39.91389 },
  { lng: -105.147396, lat: 39.900094 },
  { lng: -105.13145, lat: 39.900099 },
  { lng: -105.128918, lat: 39.900255 },
  { lng: -105.128943, lat: 39.892801 },
  { lng: -105.128895, lat: 39.890353 },
  { lng: -105.130209, lat: 39.89058 },
  { lng: -105.130885, lat: 39.892697 },
  { lng: -105.132179, lat: 39.892487 },
  { lng: -105.133105, lat: 39.892012 },
  { lng: -105.136308, lat: 39.89202 },
  { lng: -105.136457, lat: 39.890946 },
  { lng: -105.137138, lat: 39.890831 },
  { lng: -105.134852, lat: 39.889292 },
  { lng: -105.14744, lat: 39.889297 },
  { lng: -105.164423, lat: 39.891408 },
  { lng: -105.165554, lat: 39.890898 },
  { lng: -105.165689, lat: 39.910503 },
  { lng: -105.162846, lat: 39.91131 },
  { lng: -105.158458, lat: 39.911596 },
  { lng: -105.153313, lat: 39.913039 },
  { lng: -105.147143, lat: 39.913889 },
  { lng: -105.164097, lat: 39.913891 },
  { lng: -105.165349, lat: 39.914133 },
  { lng: -105.16615, lat: 39.914047 },
  { lng: -105.167072, lat: 39.913893 },
  { lng: -105.191895, lat: 39.914097 },
  { lng: -105.192439, lat: 39.914107 },
  { lng: -105.196647, lat: 39.914186 },
  { lng: -105.199494, lat: 39.914028 },
  { lng: -105.200796, lat: 39.913994 },
  { lng: -105.201254, lat: 39.914002 },
  { lng: -105.20345, lat: 39.91404 },
  { lng: -105.212475, lat: 39.914236 },
  { lng: -105.231343, lat: 39.914085 },
  { lng: -105.238933, lat: 39.914367 },
  { lng: -105.24527, lat: 39.914084 },
  { lng: -105.263945, lat: 39.913986 },
  { lng: -105.264945, lat: 39.914186 },
  { lng: -105.282656, lat: 39.913687 },
  { lng: -105.306845, lat: 39.913986 },
  { lng: -105.314702, lat: 39.913883 },
  { lng: -105.339547, lat: 39.913786 },
  { lng: -105.342281, lat: 39.913737 },
  { lng: -105.357666, lat: 39.913485 },
  { lng: -105.397849, lat: 39.912886 },
  { lng: -105.397822, lat: 39.908349 },
  { lng: -105.397649, lat: 39.883187 },
  { lng: -105.397949, lat: 39.881087 },
  { lng: -105.397999, lat: 39.871433 },
  { lng: -105.397568, lat: 39.866061 },
  { lng: -105.397949, lat: 39.860387 },
  { lng: -105.397503, lat: 39.854334 },
  { lng: -105.39745, lat: 39.853697 },
  { lng: -105.397949, lat: 39.839987 },
  { lng: -105.398043, lat: 39.816027 },
  { lng: -105.397949, lat: 39.814886 },
  { lng: -105.397947, lat: 39.748824 },
  { lng: -105.397949, lat: 39.747087 },
  { lng: -105.397949, lat: 39.745856 },
  { lng: -105.39819, lat: 39.726234 },
  { lng: -105.398248, lat: 39.717487 },
  { lng: -105.398349, lat: 39.717147 },
  { lng: -105.398348, lat: 39.714121 },
  { lng: -105.398549, lat: 39.686294 },
  { lng: -105.398249, lat: 39.650788 },
  { lng: -105.398248, lat: 39.649949 },
  { lng: -105.39825, lat: 39.636588 },
  { lng: -105.39835, lat: 39.635888 },
  { lng: -105.39875, lat: 39.615418 },
  { lng: -105.39895, lat: 39.588444 },
  { lng: -105.399089, lat: 39.582072 },
];

export const fremont = [
  { lng: -106.012304, lat: 38.445357 },
  { lng: -106.012751, lat: 38.440123 },
  { lng: -106.011016, lat: 38.437231 },
  { lng: -106.008662, lat: 38.435459 },
  { lng: -106.007934, lat: 38.433715 },
  { lng: -106.006477, lat: 38.432518 },
  { lng: -106.001563, lat: 38.43111 },
  { lng: -106.000303, lat: 38.42702 },
  { lng: -106.000814, lat: 38.425809 },
  { lng: -105.999788, lat: 38.423622 },
  { lng: -105.997751, lat: 38.422031 },
  { lng: -105.996768, lat: 38.420303 },
  { lng: -105.993932, lat: 38.419375 },
  { lng: -105.992008, lat: 38.419569 },
  { lng: -105.987308, lat: 38.418802 },
  { lng: -105.984298, lat: 38.419723 },
  { lng: -105.980409, lat: 38.417277 },
  { lng: -105.979222, lat: 38.414127 },
  { lng: -105.976919, lat: 38.41298 },
  { lng: -105.973389, lat: 38.409459 },
  { lng: -105.970226, lat: 38.41004 },
  { lng: -105.967933, lat: 38.408772 },
  { lng: -105.964721, lat: 38.40626 },
  { lng: -105.963391, lat: 38.40475 },
  { lng: -105.960669, lat: 38.403095 },
  { lng: -105.95982, lat: 38.401278 },
  { lng: -105.959945, lat: 38.398023 },
  { lng: -105.959495, lat: 38.397095 },
  { lng: -105.955668, lat: 38.394437 },
  { lng: -105.950654, lat: 38.393733 },
  { lng: -105.949969, lat: 38.392336 },
  { lng: -105.947606, lat: 38.390526 },
  { lng: -105.947067, lat: 38.385495 },
  { lng: -105.945432, lat: 38.38274 },
  { lng: -105.944637, lat: 38.378638 },
  { lng: -105.940433, lat: 38.376973 },
  { lng: -105.938536, lat: 38.374074 },
  { lng: -105.93577, lat: 38.373219 },
  { lng: -105.932624, lat: 38.370432 },
  { lng: -105.92701, lat: 38.36872 },
  { lng: -105.924638, lat: 38.368737 },
  { lng: -105.92407, lat: 38.367936 },
  { lng: -105.919851, lat: 38.367156 },
  { lng: -105.918406, lat: 38.363874 },
  { lng: -105.916969, lat: 38.362941 },
  { lng: -105.912489, lat: 38.361539 },
  { lng: -105.910804, lat: 38.359241 },
  { lng: -105.910236, lat: 38.356413 },
  { lng: -105.908447, lat: 38.354478 },
  { lng: -105.906307, lat: 38.353268 },
  { lng: -105.902602, lat: 38.352385 },
  { lng: -105.899831, lat: 38.350721 },
  { lng: -105.897647, lat: 38.350051 },
  { lng: -105.894365, lat: 38.346618 },
  { lng: -105.89364, lat: 38.346559 },
  { lng: -105.892574, lat: 38.343342 },
  { lng: -105.890765, lat: 38.341374 },
  { lng: -105.888766, lat: 38.340811 },
  { lng: -105.888291, lat: 38.337886 },
  { lng: -105.888866, lat: 38.337315 },
  { lng: -105.88876, lat: 38.33436 },
  { lng: -105.88692, lat: 38.332545 },
  { lng: -105.885142, lat: 38.32889 },
  { lng: -105.883532, lat: 38.328167 },
  { lng: -105.882247, lat: 38.327492 },
  { lng: -105.881404, lat: 38.324881 },
  { lng: -105.880044, lat: 38.323222 },
  { lng: -105.879292, lat: 38.320628 },
  { lng: -105.876718, lat: 38.318802 },
  { lng: -105.876376, lat: 38.315951 },
  { lng: -105.87391, lat: 38.315142 },
  { lng: -105.872008, lat: 38.310835 },
  { lng: -105.868612, lat: 38.308725 },
  { lng: -105.866817, lat: 38.3057 },
  { lng: -105.861623, lat: 38.303422 },
  { lng: -105.858315, lat: 38.298359 },
  { lng: -105.853233, lat: 38.296412 },
  { lng: -105.850176, lat: 38.294456 },
  { lng: -105.849631, lat: 38.29312 },
  { lng: -105.846629, lat: 38.290287 },
  { lng: -105.842566, lat: 38.293177 },
  { lng: -105.84083, lat: 38.29246 },
  { lng: -105.837202, lat: 38.288411 },
  { lng: -105.832825, lat: 38.286741 },
  { lng: -105.830769, lat: 38.285062 },
  { lng: -105.828244, lat: 38.28113 },
  { lng: -105.827123, lat: 38.278568 },
  { lng: -105.821474, lat: 38.276117 },
  { lng: -105.818973, lat: 38.273597 },
  { lng: -105.817868, lat: 38.273916 },
  { lng: -105.814604, lat: 38.272668 },
  { lng: -105.810874, lat: 38.2725 },
  { lng: -105.806642, lat: 38.270917 },
  { lng: -105.804015, lat: 38.270891 },
  { lng: -105.800013, lat: 38.269922 },
  { lng: -105.797671, lat: 38.266757 },
  { lng: -105.796897, lat: 38.265047 },
  { lng: -105.765552, lat: 38.265008 },
  { lng: -105.76295, lat: 38.264892 },
  { lng: -105.656089, lat: 38.265079 },
  { lng: -105.652032, lat: 38.26498 },
  { lng: -105.640539, lat: 38.265191 },
  { lng: -105.612555, lat: 38.264871 },
  { lng: -105.591947, lat: 38.264905 },
  { lng: -105.556234, lat: 38.264032 },
  { lng: -105.547577, lat: 38.264294 },
  { lng: -105.536963, lat: 38.263369 },
  { lng: -105.50112, lat: 38.262778 },
  { lng: -105.499923, lat: 38.262388 },
  { lng: -105.492927, lat: 38.262461 },
  { lng: -105.368889, lat: 38.259399 },
  { lng: -105.304045, lat: 38.258225 },
  { lng: -105.285282, lat: 38.258516 },
  { lng: -105.279202, lat: 38.258661 },
  { lng: -105.262914, lat: 38.259048 },
  { lng: -105.21631, lat: 38.258621 },
  { lng: -105.150945, lat: 38.25783 },
  { lng: -105.125544, lat: 38.257548 },
  { lng: -105.12328, lat: 38.257685 },
  { lng: -105.077875, lat: 38.257819 },
  { lng: -105.075472, lat: 38.257829 },
  { lng: -105.07446, lat: 38.25806 },
  { lng: -105.049215, lat: 38.257973 },
  { lng: -104.943163, lat: 38.258262 },
  { lng: -104.940366, lat: 38.258275 },
  { lng: -104.940749, lat: 38.337961 },
  { lng: -104.940749, lat: 38.338022 },
  { lng: -104.940889, lat: 38.372026 },
  { lng: -104.940902, lat: 38.417833 },
  { lng: -104.941312, lat: 38.499999 },
  { lng: -104.941211, lat: 38.500618 },
  { lng: -104.941415, lat: 38.503158 },
  { lng: -104.941533, lat: 38.519569 },
  { lng: -104.941933, lat: 38.567791 },
  { lng: -104.942359, lat: 38.608606 },
  { lng: -104.942426, lat: 38.649882 },
  { lng: -104.99492, lat: 38.650874 },
  { lng: -105.000532, lat: 38.651183 },
  { lng: -105.035659, lat: 38.651393 },
  { lng: -105.053926, lat: 38.653096 },
  { lng: -105.057554, lat: 38.651693 },
  { lng: -105.066948, lat: 38.649761 },
  { lng: -105.071529, lat: 38.64855 },
  { lng: -105.109724, lat: 38.647531 },
  { lng: -105.190482, lat: 38.647717 },
  { lng: -105.220036, lat: 38.647652 },
  { lng: -105.240362, lat: 38.647595 },
  { lng: -105.239925, lat: 38.677002 },
  { lng: -105.237792, lat: 38.696901 },
  { lng: -105.242432, lat: 38.696981 },
  { lng: -105.250535, lat: 38.696484 },
  { lng: -105.329134, lat: 38.697205 },
  { lng: -105.498246, lat: 38.697124 },
  { lng: -105.498448, lat: 38.69708 },
  { lng: -105.500548, lat: 38.696641 },
  { lng: -105.510123, lat: 38.696499 },
  { lng: -105.514458, lat: 38.69659 },
  { lng: -105.531547, lat: 38.695935 },
  { lng: -105.702075, lat: 38.69471 },
  { lng: -105.75058, lat: 38.694691 },
  { lng: -105.81432, lat: 38.694499 },
  { lng: -105.875567, lat: 38.694306 },
  { lng: -105.881574, lat: 38.694118 },
  { lng: -105.888912, lat: 38.695153 },
  { lng: -105.89839, lat: 38.693769 },
  { lng: -105.944488, lat: 38.693089 },
  { lng: -105.96975, lat: 38.693551 },
  { lng: -105.964751, lat: 38.692427 },
  { lng: -105.962473, lat: 38.691195 },
  { lng: -105.958902, lat: 38.691619 },
  { lng: -105.957519, lat: 38.690562 },
  { lng: -105.954918, lat: 38.690161 },
  { lng: -105.951002, lat: 38.690916 },
  { lng: -105.948343, lat: 38.691014 },
  { lng: -105.945309, lat: 38.690228 },
  { lng: -105.945386, lat: 38.688078 },
  { lng: -105.944275, lat: 38.684852 },
  { lng: -105.942236, lat: 38.683982 },
  { lng: -105.939493, lat: 38.683726 },
  { lng: -105.936714, lat: 38.682649 },
  { lng: -105.932324, lat: 38.681875 },
  { lng: -105.930797, lat: 38.680761 },
  { lng: -105.926213, lat: 38.679907 },
  { lng: -105.924585, lat: 38.677513 },
  { lng: -105.922641, lat: 38.677005 },
  { lng: -105.92232, lat: 38.675081 },
  { lng: -105.924972, lat: 38.672568 },
  { lng: -105.924105, lat: 38.670388 },
  { lng: -105.925751, lat: 38.669384 },
  { lng: -105.925484, lat: 38.666365 },
  { lng: -105.920235, lat: 38.664314 },
  { lng: -105.919743, lat: 38.662802 },
  { lng: -105.920903, lat: 38.659794 },
  { lng: -105.917135, lat: 38.659565 },
  { lng: -105.914104, lat: 38.656 },
  { lng: -105.91293, lat: 38.653797 },
  { lng: -105.909172, lat: 38.653641 },
  { lng: -105.907673, lat: 38.65193 },
  { lng: -105.909459, lat: 38.649162 },
  { lng: -105.910962, lat: 38.648359 },
  { lng: -105.911202, lat: 38.647015 },
  { lng: -105.913174, lat: 38.645583 },
  { lng: -105.910551, lat: 38.641694 },
  { lng: -105.91115, lat: 38.639164 },
  { lng: -105.913564, lat: 38.63806 },
  { lng: -105.915528, lat: 38.635638 },
  { lng: -105.917327, lat: 38.634521 },
  { lng: -105.917739, lat: 38.633255 },
  { lng: -105.915665, lat: 38.630048 },
  { lng: -105.911975, lat: 38.629078 },
  { lng: -105.909646, lat: 38.627419 },
  { lng: -105.906668, lat: 38.62714 },
  { lng: -105.90499, lat: 38.626146 },
  { lng: -105.904295, lat: 38.624831 },
  { lng: -105.902551, lat: 38.625274 },
  { lng: -105.900555, lat: 38.624738 },
  { lng: -105.899679, lat: 38.621973 },
  { lng: -105.896348, lat: 38.622654 },
  { lng: -105.894358, lat: 38.623723 },
  { lng: -105.887149, lat: 38.621329 },
  { lng: -105.885972, lat: 38.620626 },
  { lng: -105.88491, lat: 38.618731 },
  { lng: -105.885747, lat: 38.615549 },
  { lng: -105.884175, lat: 38.612863 },
  { lng: -105.879176, lat: 38.607312 },
  { lng: -105.878545, lat: 38.605232 },
  { lng: -105.878033, lat: 38.600581 },
  { lng: -105.880596, lat: 38.594863 },
  { lng: -105.883839, lat: 38.592248 },
  { lng: -105.88539, lat: 38.590106 },
  { lng: -105.88982, lat: 38.587098 },
  { lng: -105.891138, lat: 38.583957 },
  { lng: -105.890517, lat: 38.581966 },
  { lng: -105.890953, lat: 38.579089 },
  { lng: -105.890526, lat: 38.577356 },
  { lng: -105.891299, lat: 38.574883 },
  { lng: -105.89249, lat: 38.573289 },
  { lng: -105.888469, lat: 38.571978 },
  { lng: -105.885524, lat: 38.569782 },
  { lng: -105.885876, lat: 38.568219 },
  { lng: -105.884202, lat: 38.565501 },
  { lng: -105.884639, lat: 38.56227 },
  { lng: -105.885429, lat: 38.561312 },
  { lng: -105.89061, lat: 38.561053 },
  { lng: -105.893225, lat: 38.558703 },
  { lng: -105.892179, lat: 38.554576 },
  { lng: -105.89245, lat: 38.552134 },
  { lng: -105.893527, lat: 38.550935 },
  { lng: -105.897141, lat: 38.550794 },
  { lng: -105.900245, lat: 38.549444 },
  { lng: -105.903411, lat: 38.548554 },
  { lng: -105.903349, lat: 38.543049 },
  { lng: -105.903929, lat: 38.539351 },
  { lng: -105.90505, lat: 38.536725 },
  { lng: -105.904594, lat: 38.534154 },
  { lng: -105.905733, lat: 38.532358 },
  { lng: -105.903852, lat: 38.531429 },
  { lng: -105.903417, lat: 38.528527 },
  { lng: -105.904411, lat: 38.527634 },
  { lng: -105.904373, lat: 38.526151 },
  { lng: -105.90834, lat: 38.522489 },
  { lng: -105.908759, lat: 38.516882 },
  { lng: -105.907872, lat: 38.51489 },
  { lng: -105.905666, lat: 38.512913 },
  { lng: -105.905054, lat: 38.511663 },
  { lng: -105.906074, lat: 38.507595 },
  { lng: -105.906854, lat: 38.506266 },
  { lng: -105.908717, lat: 38.505631 },
  { lng: -105.93804, lat: 38.50347 },
  { lng: -105.938188, lat: 38.503349 },
  { lng: -105.938957, lat: 38.50273 },
  { lng: -106.010751, lat: 38.446566 },
  { lng: -106.012304, lat: 38.445357 },
];

export const elbert = [
  { lng: -104.663198, lat: 39.362054 },
  { lng: -104.663202, lat: 39.289376 },
  { lng: -104.662891, lat: 39.245822 },
  { lng: -104.662956, lat: 39.216714 },
  { lng: -104.662874, lat: 39.173156 },
  { lng: -104.662896, lat: 39.129527 },
  { lng: -104.645089, lat: 39.129526 },
  { lng: -104.624279, lat: 39.129485 },
  { lng: -104.622311, lat: 39.129796 },
  { lng: -104.613444, lat: 39.129441 },
  { lng: -104.593699, lat: 39.129417 },
  { lng: -104.589251, lat: 39.129396 },
  { lng: -104.551765, lat: 39.129427 },
  { lng: -104.504886, lat: 39.129527 },
  { lng: -104.497228, lat: 39.129553 },
  { lng: -104.466774, lat: 39.129653 },
  { lng: -104.463172, lat: 39.129801 },
  { lng: -104.461151, lat: 39.129543 },
  { lng: -104.385994, lat: 39.129067 },
  { lng: -104.385774, lat: 39.129065 },
  { lng: -104.289605, lat: 39.128736 },
  { lng: -104.241151, lat: 39.128825 },
  { lng: -104.236398, lat: 39.128644 },
  { lng: -104.199565, lat: 39.128598 },
  { lng: -104.180506, lat: 39.128736 },
  { lng: -104.174172, lat: 39.128402 },
  { lng: -104.16704, lat: 39.128534 },
  { lng: -104.125515, lat: 39.128653 },
  { lng: -104.124979, lat: 39.12889 },
  { lng: -104.051568, lat: 39.128138 },
  { lng: -104.05326, lat: 38.984385 },
  { lng: -104.053122, lat: 38.95402 },
  { lng: -104.053439, lat: 38.941081 },
  { lng: -104.055528, lat: 38.868868 },
  { lng: -103.98083, lat: 38.868754 },
  { lng: -103.980235, lat: 38.868684 },
  { lng: -103.940473, lat: 38.868226 },
  { lng: -103.862828, lat: 38.867123 },
  { lng: -103.861563, lat: 38.866821 },
  { lng: -103.860789, lat: 38.867015 },
  { lng: -103.832929, lat: 38.866947 },
  { lng: -103.719664, lat: 38.866827 },
  { lng: -103.719418, lat: 38.874994 },
  { lng: -103.720136, lat: 38.903232 },
  { lng: -103.720869, lat: 38.951102 },
  { lng: -103.721465, lat: 38.997304 },
  { lng: -103.721029, lat: 39.009008 },
  { lng: -103.720896, lat: 39.026119 },
  { lng: -103.720885, lat: 39.040099 },
  { lng: -103.721882, lat: 39.084614 },
  { lng: -103.721913, lat: 39.084972 },
  { lng: -103.720824, lat: 39.098742 },
  { lng: -103.721152, lat: 39.128307 },
  { lng: -103.715254, lat: 39.128246 },
  { lng: -103.715116, lat: 39.178149 },
  { lng: -103.71492, lat: 39.179022 },
  { lng: -103.714894, lat: 39.244187 },
  { lng: -103.714967, lat: 39.26348 },
  { lng: -103.714933, lat: 39.269661 },
  { lng: -103.714913, lat: 39.273029 },
  { lng: -103.714912, lat: 39.27347 },
  { lng: -103.714911, lat: 39.273645 },
  { lng: -103.714908, lat: 39.280846 },
  { lng: -103.715458, lat: 39.30234 },
  { lng: -103.715077, lat: 39.309885 },
  { lng: -103.714849, lat: 39.373883 },
  { lng: -103.715252, lat: 39.374987 },
  { lng: -103.715044, lat: 39.433681 },
  { lng: -103.714497, lat: 39.483903 },
  { lng: -103.714515, lat: 39.485192 },
  { lng: -103.714355, lat: 39.566331 },
  { lng: -103.978639, lat: 39.56636 },
  { lng: -103.981891, lat: 39.566267 },
  { lng: -103.985114, lat: 39.566312 },
  { lng: -103.986976, lat: 39.566151 },
  { lng: -104.014407, lat: 39.565999 },
  { lng: -104.174259, lat: 39.565278 },
  { lng: -104.193813, lat: 39.565232 },
  { lng: -104.285044, lat: 39.564718 },
  { lng: -104.296062, lat: 39.564657 },
  { lng: -104.328272, lat: 39.564395 },
  { lng: -104.361934, lat: 39.564306 },
  { lng: -104.37598, lat: 39.563949 },
  { lng: -104.381131, lat: 39.564097 },
  { lng: -104.398707, lat: 39.56395 },
  { lng: -104.421404, lat: 39.563694 },
  { lng: -104.427452, lat: 39.563601 },
  { lng: -104.442895, lat: 39.56376 },
  { lng: -104.456156, lat: 39.563949 },
  { lng: -104.457367, lat: 39.563976 },
  { lng: -104.474295, lat: 39.564102 },
  { lng: -104.531676, lat: 39.564678 },
  { lng: -104.569154, lat: 39.565283 },
  { lng: -104.60084, lat: 39.565846 },
  { lng: -104.642125, lat: 39.56589 },
  { lng: -104.660626, lat: 39.565909 },
  { lng: -104.661245, lat: 39.536016 },
  { lng: -104.661179, lat: 39.535281 },
  { lng: -104.661342, lat: 39.521504 },
  { lng: -104.662112, lat: 39.480365 },
  { lng: -104.662306, lat: 39.463516 },
  { lng: -104.662398, lat: 39.456014 },
  { lng: -104.662806, lat: 39.449001 },
  { lng: -104.662666, lat: 39.434398 },
  { lng: -104.662897, lat: 39.390957 },
  { lng: -104.663198, lat: 39.362054 },
];

export const elPaso = [
  { lng: -105.072529, lat: 38.8084 },
  { lng: -105.072457, lat: 38.805007 },
  { lng: -105.072184, lat: 38.799382 },
  { lng: -105.053184, lat: 38.799129 },
  { lng: -105.030619, lat: 38.797133 },
  { lng: -105.011033, lat: 38.796987 },
  { lng: -105.005359, lat: 38.797396 },
  { lng: -104.985762, lat: 38.797635 },
  { lng: -104.938994, lat: 38.796884 },
  { lng: -104.938986, lat: 38.792013 },
  { lng: -104.939286, lat: 38.73698 },
  { lng: -104.939006, lat: 38.710526 },
  { lng: -104.939555, lat: 38.703645 },
  { lng: -104.939744, lat: 38.696142 },
  { lng: -104.942235, lat: 38.696199 },
  { lng: -104.942426, lat: 38.649882 },
  { lng: -104.942359, lat: 38.608606 },
  { lng: -104.941933, lat: 38.567791 },
  { lng: -104.941533, lat: 38.519569 },
  { lng: -104.932949, lat: 38.520286 },
  { lng: -104.895683, lat: 38.5203 },
  { lng: -104.888343, lat: 38.519909 },
  { lng: -104.881971, lat: 38.520088 },
  { lng: -104.866674, lat: 38.520017 },
  { lng: -104.86615, lat: 38.519857 },
  { lng: -104.798122, lat: 38.519984 },
  { lng: -104.796104, lat: 38.520165 },
  { lng: -104.775787, lat: 38.519927 },
  { lng: -104.734088, lat: 38.519631 },
  { lng: -104.717994, lat: 38.51956 },
  { lng: -104.630682, lat: 38.519917 },
  { lng: -104.626137, lat: 38.519957 },
  { lng: -104.612424, lat: 38.520162 },
  { lng: -104.567087, lat: 38.520001 },
  { lng: -104.275471, lat: 38.521133 },
  { lng: -104.275054, lat: 38.521134 },
  { lng: -104.152501, lat: 38.521902 },
  { lng: -104.150152, lat: 38.521732 },
  { lng: -104.134086, lat: 38.521805 },
  { lng: -104.090179, lat: 38.522004 },
  { lng: -104.083468, lat: 38.522434 },
  { lng: -104.053921, lat: 38.522393 },
  { lng: -104.054127, lat: 38.523087 },
  { lng: -104.05334, lat: 38.681637 },
  { lng: -104.052982, lat: 38.694341 },
  { lng: -104.051942, lat: 38.694347 },
  { lng: -104.052475, lat: 38.709235 },
  { lng: -104.054262, lat: 38.778509 },
  { lng: -104.054577, lat: 38.782369 },
  { lng: -104.055132, lat: 38.840093 },
  { lng: -104.055528, lat: 38.868868 },
  { lng: -104.053439, lat: 38.941081 },
  { lng: -104.053122, lat: 38.95402 },
  { lng: -104.05326, lat: 38.984385 },
  { lng: -104.051568, lat: 39.128138 },
  { lng: -104.124979, lat: 39.12889 },
  { lng: -104.125515, lat: 39.128653 },
  { lng: -104.16704, lat: 39.128534 },
  { lng: -104.174172, lat: 39.128402 },
  { lng: -104.180506, lat: 39.128736 },
  { lng: -104.199565, lat: 39.128598 },
  { lng: -104.236398, lat: 39.128644 },
  { lng: -104.241151, lat: 39.128825 },
  { lng: -104.289605, lat: 39.128736 },
  { lng: -104.385774, lat: 39.129065 },
  { lng: -104.385994, lat: 39.129067 },
  { lng: -104.461151, lat: 39.129543 },
  { lng: -104.463172, lat: 39.129801 },
  { lng: -104.466774, lat: 39.129653 },
  { lng: -104.497228, lat: 39.129553 },
  { lng: -104.504886, lat: 39.129527 },
  { lng: -104.551765, lat: 39.129427 },
  { lng: -104.589251, lat: 39.129396 },
  { lng: -104.593699, lat: 39.129417 },
  { lng: -104.613444, lat: 39.129441 },
  { lng: -104.622311, lat: 39.129796 },
  { lng: -104.624279, lat: 39.129485 },
  { lng: -104.645089, lat: 39.129526 },
  { lng: -104.662896, lat: 39.129527 },
  { lng: -104.692497, lat: 39.129536 },
  { lng: -104.766571, lat: 39.129674 },
  { lng: -104.76987, lat: 39.129661 },
  { lng: -104.830585, lat: 39.129764 },
  { lng: -104.863922, lat: 39.129791 },
  { lng: -104.89503, lat: 39.129857 },
  { lng: -104.910277, lat: 39.129848 },
  { lng: -104.923669, lat: 39.129822 },
  { lng: -105.029549, lat: 39.129831 },
  { lng: -105.033544, lat: 39.129819 },
  { lng: -105.032785, lat: 39.123069 },
  { lng: -105.032908, lat: 39.119081 },
  { lng: -105.032557, lat: 39.113765 },
  { lng: -105.032558, lat: 39.110081 },
  { lng: -105.031795, lat: 39.104235 },
  { lng: -105.03123, lat: 39.097901 },
  { lng: -105.031415, lat: 39.091922 },
  { lng: -105.030561, lat: 39.086398 },
  { lng: -105.030509, lat: 39.08036 },
  { lng: -105.029548, lat: 39.070973 },
  { lng: -105.029267, lat: 39.064521 },
  { lng: -105.028689, lat: 39.060782 },
  { lng: -105.028093, lat: 39.050892 },
  { lng: -105.028092, lat: 39.046515 },
  { lng: -105.027674, lat: 39.041844 },
  { lng: -105.026945, lat: 39.029831 },
  { lng: -105.027655, lat: 39.019542 },
  { lng: -105.027848, lat: 39.014358 },
  { lng: -105.028995, lat: 39.004073 },
  { lng: -105.029827, lat: 38.999404 },
  { lng: -105.027848, lat: 38.988803 },
  { lng: -105.0272, lat: 38.985335 },
  { lng: -105.027242, lat: 38.981864 },
  { lng: -105.027459, lat: 38.967305 },
  { lng: -105.027551, lat: 38.960294 },
  { lng: -105.0276, lat: 38.95672 },
  { lng: -105.02767, lat: 38.952262 },
  { lng: -105.027562, lat: 38.940971 },
  { lng: -105.027473, lat: 38.938945 },
  { lng: -105.027398, lat: 38.938475 },
  { lng: -105.027108, lat: 38.936174 },
  { lng: -105.027489, lat: 38.934914 },
  { lng: -105.027878, lat: 38.932774 },
  { lng: -105.027947, lat: 38.930032 },
  { lng: -105.02802, lat: 38.924872 },
  { lng: -105.028196, lat: 38.914433 },
  { lng: -105.028228, lat: 38.91228 },
  { lng: -105.028903, lat: 38.868907 },
  { lng: -105.0667, lat: 38.869508 },
  { lng: -105.06733, lat: 38.865305 },
  { lng: -105.067409, lat: 38.862153 },
  { lng: -105.067954, lat: 38.859688 },
  { lng: -105.06766, lat: 38.858991 },
  { lng: -105.068443, lat: 38.851147 },
  { lng: -105.07177, lat: 38.817928 },
  { lng: -105.072529, lat: 38.8084 },
];

export const douglas = [
  { lng: -105.327393, lat: 39.130873 },
  { lng: -105.32922, lat: 39.129689 },
  { lng: -105.16261, lat: 39.129479 },
  { lng: -105.157944, lat: 39.129479 },
  { lng: -105.033544, lat: 39.129819 },
  { lng: -105.029549, lat: 39.129831 },
  { lng: -104.923669, lat: 39.129822 },
  { lng: -104.910277, lat: 39.129848 },
  { lng: -104.89503, lat: 39.129857 },
  { lng: -104.863922, lat: 39.129791 },
  { lng: -104.830585, lat: 39.129764 },
  { lng: -104.76987, lat: 39.129661 },
  { lng: -104.766571, lat: 39.129674 },
  { lng: -104.692497, lat: 39.129536 },
  { lng: -104.662896, lat: 39.129527 },
  { lng: -104.662874, lat: 39.173156 },
  { lng: -104.662956, lat: 39.216714 },
  { lng: -104.662891, lat: 39.245822 },
  { lng: -104.663202, lat: 39.289376 },
  { lng: -104.663198, lat: 39.362054 },
  { lng: -104.662897, lat: 39.390957 },
  { lng: -104.662666, lat: 39.434398 },
  { lng: -104.662806, lat: 39.449001 },
  { lng: -104.662398, lat: 39.456014 },
  { lng: -104.662306, lat: 39.463516 },
  { lng: -104.662112, lat: 39.480365 },
  { lng: -104.661342, lat: 39.521504 },
  { lng: -104.661179, lat: 39.535281 },
  { lng: -104.661245, lat: 39.536016 },
  { lng: -104.660626, lat: 39.565909 },
  { lng: -104.679306, lat: 39.565991 },
  { lng: -104.679505, lat: 39.565995 },
  { lng: -104.686759, lat: 39.565988 },
  { lng: -104.707023, lat: 39.565988 },
  { lng: -104.707781, lat: 39.565988 },
  { lng: -104.708327, lat: 39.565988 },
  { lng: -104.717781, lat: 39.565984 },
  { lng: -104.73584, lat: 39.565888 },
  { lng: -104.736628, lat: 39.565888 },
  { lng: -104.745929, lat: 39.566022 },
  { lng: -104.746043, lat: 39.566025 },
  { lng: -104.765886, lat: 39.56609 },
  { lng: -104.784965, lat: 39.566088 },
  { lng: -104.785308, lat: 39.566088 },
  { lng: -104.785432, lat: 39.566088 },
  { lng: -104.791368, lat: 39.566087 },
  { lng: -104.800612, lat: 39.565893 },
  { lng: -104.800939, lat: 39.565887 },
  { lng: -104.801095, lat: 39.565885 },
  { lng: -104.801371, lat: 39.565887 },
  { lng: -104.813131, lat: 39.565988 },
  { lng: -104.815686, lat: 39.565988 },
  { lng: -104.832934, lat: 39.565988 },
  { lng: -104.836228, lat: 39.565988 },
  { lng: -104.857406, lat: 39.566029 },
  { lng: -104.867124, lat: 39.56598 },
  { lng: -104.8695, lat: 39.565983 },
  { lng: -104.872366, lat: 39.565997 },
  { lng: -104.88571, lat: 39.565979 },
  { lng: -104.888861, lat: 39.565991 },
  { lng: -104.897067, lat: 39.565971 },
  { lng: -104.904175, lat: 39.565982 },
  { lng: -104.904415, lat: 39.565982 },
  { lng: -104.911872, lat: 39.566024 },
  { lng: -104.913831, lat: 39.566029 },
  { lng: -104.922963, lat: 39.566053 },
  { lng: -104.929106, lat: 39.566038 },
  { lng: -104.941705, lat: 39.566025 },
  { lng: -104.941883, lat: 39.566026 },
  { lng: -104.945072, lat: 39.566035 },
  { lng: -104.946466, lat: 39.566082 },
  { lng: -104.951697, lat: 39.566077 },
  { lng: -104.955644, lat: 39.566111 },
  { lng: -104.960469, lat: 39.566129 },
  { lng: -104.971802, lat: 39.566064 },
  { lng: -104.973255, lat: 39.566069 },
  { lng: -104.977449, lat: 39.566083 },
  { lng: -104.979187, lat: 39.566091 },
  { lng: -104.982365, lat: 39.566102 },
  { lng: -104.988529, lat: 39.566124 },
  { lng: -104.992942, lat: 39.566117 },
  { lng: -104.993298, lat: 39.566096 },
  { lng: -105.002068, lat: 39.566233 },
  { lng: -105.015621, lat: 39.566096 },
  { lng: -105.021377, lat: 39.566147 },
  { lng: -105.030292, lat: 39.566187 },
  { lng: -105.03232, lat: 39.566184 },
  { lng: -105.033315, lat: 39.566187 },
  { lng: -105.035936, lat: 39.566189 },
  { lng: -105.039633, lat: 39.566189 },
  { lng: -105.040285, lat: 39.56619 },
  { lng: -105.04085, lat: 39.566145 },
  { lng: -105.04874, lat: 39.566088 },
  { lng: -105.051366, lat: 39.563257 },
  { lng: -105.05306, lat: 39.563109 },
  { lng: -105.057252, lat: 39.562306 },
  { lng: -105.058257, lat: 39.560379 },
  { lng: -105.060714, lat: 39.559941 },
  { lng: -105.06113, lat: 39.556906 },
  { lng: -105.06424, lat: 39.554188 },
  { lng: -105.065672, lat: 39.553346 },
  { lng: -105.066735, lat: 39.550499 },
  { lng: -105.073045, lat: 39.545265 },
  { lng: -105.074111, lat: 39.544268 },
  { lng: -105.074594, lat: 39.541652 },
  { lng: -105.076419, lat: 39.540055 },
  { lng: -105.077745, lat: 39.536706 },
  { lng: -105.076571, lat: 39.534968 },
  { lng: -105.076106, lat: 39.532691 },
  { lng: -105.076434, lat: 39.531624 },
  { lng: -105.079461, lat: 39.525888 },
  { lng: -105.079288, lat: 39.523893 },
  { lng: -105.080096, lat: 39.523156 },
  { lng: -105.079, lat: 39.519142 },
  { lng: -105.077945, lat: 39.517495 },
  { lng: -105.078859, lat: 39.51285 },
  { lng: -105.078402, lat: 39.51149 },
  { lng: -105.079858, lat: 39.510001 },
  { lng: -105.081133, lat: 39.509667 },
  { lng: -105.084665, lat: 39.506585 },
  { lng: -105.085227, lat: 39.50543 },
  { lng: -105.084841, lat: 39.50121 },
  { lng: -105.086495, lat: 39.499133 },
  { lng: -105.086148, lat: 39.497633 },
  { lng: -105.087185, lat: 39.496061 },
  { lng: -105.086109, lat: 39.494923 },
  { lng: -105.086751, lat: 39.493423 },
  { lng: -105.088514, lat: 39.492392 },
  { lng: -105.091353, lat: 39.48928 },
  { lng: -105.09333, lat: 39.488054 },
  { lng: -105.098707, lat: 39.485394 },
  { lng: -105.100359, lat: 39.485487 },
  { lng: -105.103313, lat: 39.484715 },
  { lng: -105.106942, lat: 39.484719 },
  { lng: -105.110685, lat: 39.483553 },
  { lng: -105.113154, lat: 39.483904 },
  { lng: -105.114694, lat: 39.4858 },
  { lng: -105.117646, lat: 39.485392 },
  { lng: -105.118266, lat: 39.482988 },
  { lng: -105.115883, lat: 39.482535 },
  { lng: -105.116276, lat: 39.481451 },
  { lng: -105.118535, lat: 39.480207 },
  { lng: -105.121017, lat: 39.481454 },
  { lng: -105.127081, lat: 39.478185 },
  { lng: -105.128398, lat: 39.477388 },
  { lng: -105.130357, lat: 39.47368 },
  { lng: -105.134993, lat: 39.470785 },
  { lng: -105.132625, lat: 39.467908 },
  { lng: -105.133132, lat: 39.466312 },
  { lng: -105.132577, lat: 39.464321 },
  { lng: -105.132941, lat: 39.461912 },
  { lng: -105.130399, lat: 39.459527 },
  { lng: -105.127474, lat: 39.458467 },
  { lng: -105.126696, lat: 39.4572 },
  { lng: -105.125017, lat: 39.456367 },
  { lng: -105.124184, lat: 39.454985 },
  { lng: -105.123062, lat: 39.450847 },
  { lng: -105.123728, lat: 39.449464 },
  { lng: -105.123214, lat: 39.447831 },
  { lng: -105.124742, lat: 39.44402 },
  { lng: -105.124704, lat: 39.442736 },
  { lng: -105.126144, lat: 39.440187 },
  { lng: -105.124674, lat: 39.435879 },
  { lng: -105.123522, lat: 39.434561 },
  { lng: -105.128424, lat: 39.431757 },
  { lng: -105.131564, lat: 39.429235 },
  { lng: -105.133071, lat: 39.427439 },
  { lng: -105.135425, lat: 39.426779 },
  { lng: -105.136973, lat: 39.425421 },
  { lng: -105.138206, lat: 39.42307 },
  { lng: -105.140567, lat: 39.422401 },
  { lng: -105.141408, lat: 39.421136 },
  { lng: -105.144923, lat: 39.418996 },
  { lng: -105.14942, lat: 39.4176 },
  { lng: -105.151767, lat: 39.415681 },
  { lng: -105.155977, lat: 39.413802 },
  { lng: -105.159937, lat: 39.414323 },
  { lng: -105.161261, lat: 39.413758 },
  { lng: -105.16262, lat: 39.411929 },
  { lng: -105.166671, lat: 39.40851 },
  { lng: -105.169042, lat: 39.408936 },
  { lng: -105.170963, lat: 39.407825 },
  { lng: -105.171278, lat: 39.406854 },
  { lng: -105.170482, lat: 39.404602 },
  { lng: -105.169441, lat: 39.403326 },
  { lng: -105.169408, lat: 39.40159 },
  { lng: -105.167194, lat: 39.399753 },
  { lng: -105.166972, lat: 39.398187 },
  { lng: -105.167642, lat: 39.395748 },
  { lng: -105.167258, lat: 39.394308 },
  { lng: -105.165495, lat: 39.394008 },
  { lng: -105.164337, lat: 39.391136 },
  { lng: -105.16566, lat: 39.391178 },
  { lng: -105.169968, lat: 39.389096 },
  { lng: -105.171219, lat: 39.386804 },
  { lng: -105.172978, lat: 39.385745 },
  { lng: -105.173515, lat: 39.383203 },
  { lng: -105.173284, lat: 39.382317 },
  { lng: -105.170856, lat: 39.382261 },
  { lng: -105.17013, lat: 39.38125 },
  { lng: -105.169785, lat: 39.380342 },
  { lng: -105.17125, lat: 39.379638 },
  { lng: -105.172997, lat: 39.377722 },
  { lng: -105.17226, lat: 39.376411 },
  { lng: -105.17033, lat: 39.375347 },
  { lng: -105.170165, lat: 39.374518 },
  { lng: -105.1679, lat: 39.37398 },
  { lng: -105.167099, lat: 39.3722 },
  { lng: -105.167636, lat: 39.370426 },
  { lng: -105.169114, lat: 39.369098 },
  { lng: -105.168922, lat: 39.367265 },
  { lng: -105.167792, lat: 39.366371 },
  { lng: -105.166435, lat: 39.362958 },
  { lng: -105.166574, lat: 39.361754 },
  { lng: -105.16786, lat: 39.359017 },
  { lng: -105.169127, lat: 39.358934 },
  { lng: -105.171517, lat: 39.35722 },
  { lng: -105.172357, lat: 39.355817 },
  { lng: -105.171853, lat: 39.35345 },
  { lng: -105.175157, lat: 39.35049 },
  { lng: -105.177193, lat: 39.349579 },
  { lng: -105.176532, lat: 39.347515 },
  { lng: -105.177158, lat: 39.34471 },
  { lng: -105.179174, lat: 39.343462 },
  { lng: -105.179535, lat: 39.342309 },
  { lng: -105.178729, lat: 39.340786 },
  { lng: -105.1801, lat: 39.339155 },
  { lng: -105.182144, lat: 39.338588 },
  { lng: -105.183956, lat: 39.33549 },
  { lng: -105.187837, lat: 39.334186 },
  { lng: -105.187432, lat: 39.333256 },
  { lng: -105.185572, lat: 39.333003 },
  { lng: -105.182268, lat: 39.331374 },
  { lng: -105.182305, lat: 39.329797 },
  { lng: -105.186057, lat: 39.32829 },
  { lng: -105.188184, lat: 39.327961 },
  { lng: -105.188054, lat: 39.326902 },
  { lng: -105.185883, lat: 39.326155 },
  { lng: -105.185486, lat: 39.324405 },
  { lng: -105.186292, lat: 39.323304 },
  { lng: -105.187803, lat: 39.322853 },
  { lng: -105.188814, lat: 39.321153 },
  { lng: -105.189123, lat: 39.319105 },
  { lng: -105.188587, lat: 39.317384 },
  { lng: -105.190197, lat: 39.316194 },
  { lng: -105.193487, lat: 39.312582 },
  { lng: -105.194662, lat: 39.312434 },
  { lng: -105.198156, lat: 39.310432 },
  { lng: -105.198397, lat: 39.30928 },
  { lng: -105.19668, lat: 39.308544 },
  { lng: -105.197824, lat: 39.304988 },
  { lng: -105.196522, lat: 39.303949 },
  { lng: -105.196, lat: 39.302082 },
  { lng: -105.197231, lat: 39.30172 },
  { lng: -105.200212, lat: 39.302029 },
  { lng: -105.201283, lat: 39.30077 },
  { lng: -105.201368, lat: 39.299269 },
  { lng: -105.204979, lat: 39.299798 },
  { lng: -105.206793, lat: 39.299196 },
  { lng: -105.207681, lat: 39.298194 },
  { lng: -105.207173, lat: 39.296228 },
  { lng: -105.205231, lat: 39.294899 },
  { lng: -105.202887, lat: 39.294752 },
  { lng: -105.202576, lat: 39.293321 },
  { lng: -105.20393, lat: 39.293056 },
  { lng: -105.205527, lat: 39.29399 },
  { lng: -105.206981, lat: 39.293202 },
  { lng: -105.205531, lat: 39.291681 },
  { lng: -105.206498, lat: 39.290678 },
  { lng: -105.207908, lat: 39.291525 },
  { lng: -105.208736, lat: 39.290616 },
  { lng: -105.208042, lat: 39.289612 },
  { lng: -105.21017, lat: 39.28774 },
  { lng: -105.212473, lat: 39.284484 },
  { lng: -105.211334, lat: 39.282915 },
  { lng: -105.211325, lat: 39.280967 },
  { lng: -105.213897, lat: 39.278693 },
  { lng: -105.215438, lat: 39.278574 },
  { lng: -105.216386, lat: 39.277618 },
  { lng: -105.215526, lat: 39.276788 },
  { lng: -105.217507, lat: 39.274596 },
  { lng: -105.215662, lat: 39.273349 },
  { lng: -105.21558, lat: 39.272232 },
  { lng: -105.219179, lat: 39.270503 },
  { lng: -105.220778, lat: 39.268662 },
  { lng: -105.220776, lat: 39.268063 },
  { lng: -105.218699, lat: 39.267042 },
  { lng: -105.21875, lat: 39.265349 },
  { lng: -105.217784, lat: 39.263548 },
  { lng: -105.217204, lat: 39.261175 },
  { lng: -105.217833, lat: 39.260115 },
  { lng: -105.219598, lat: 39.259641 },
  { lng: -105.225105, lat: 39.260525 },
  { lng: -105.22536, lat: 39.259792 },
  { lng: -105.223402, lat: 39.258417 },
  { lng: -105.2236, lat: 39.25599 },
  { lng: -105.226972, lat: 39.25635 },
  { lng: -105.228121, lat: 39.253766 },
  { lng: -105.231807, lat: 39.253962 },
  { lng: -105.23458, lat: 39.255091 },
  { lng: -105.234202, lat: 39.252413 },
  { lng: -105.235256, lat: 39.251313 },
  { lng: -105.237678, lat: 39.25047 },
  { lng: -105.237336, lat: 39.2495 },
  { lng: -105.234781, lat: 39.249297 },
  { lng: -105.233938, lat: 39.246868 },
  { lng: -105.234367, lat: 39.245562 },
  { lng: -105.235631, lat: 39.244261 },
  { lng: -105.239741, lat: 39.243635 },
  { lng: -105.240559, lat: 39.244057 },
  { lng: -105.243403, lat: 39.242655 },
  { lng: -105.246017, lat: 39.240566 },
  { lng: -105.247275, lat: 39.240425 },
  { lng: -105.252428, lat: 39.243695 },
  { lng: -105.253567, lat: 39.243156 },
  { lng: -105.253536, lat: 39.241372 },
  { lng: -105.25503, lat: 39.240274 },
  { lng: -105.25432, lat: 39.237923 },
  { lng: -105.257228, lat: 39.233547 },
  { lng: -105.261784, lat: 39.231482 },
  { lng: -105.263568, lat: 39.228811 },
  { lng: -105.26166, lat: 39.226805 },
  { lng: -105.262425, lat: 39.22483 },
  { lng: -105.261302, lat: 39.223269 },
  { lng: -105.263528, lat: 39.221169 },
  { lng: -105.262868, lat: 39.219463 },
  { lng: -105.260576, lat: 39.216688 },
  { lng: -105.26152, lat: 39.214758 },
  { lng: -105.260086, lat: 39.212558 },
  { lng: -105.259735, lat: 39.210905 },
  { lng: -105.26212, lat: 39.209918 },
  { lng: -105.263194, lat: 39.208005 },
  { lng: -105.267546, lat: 39.209225 },
  { lng: -105.269519, lat: 39.209419 },
  { lng: -105.272176, lat: 39.208651 },
  { lng: -105.27316, lat: 39.207624 },
  { lng: -105.276291, lat: 39.207216 },
  { lng: -105.277696, lat: 39.202801 },
  { lng: -105.278814, lat: 39.201811 },
  { lng: -105.281261, lat: 39.201757 },
  { lng: -105.282929, lat: 39.199953 },
  { lng: -105.2851, lat: 39.194588 },
  { lng: -105.284702, lat: 39.192703 },
  { lng: -105.285928, lat: 39.189585 },
  { lng: -105.28639, lat: 39.186314 },
  { lng: -105.289182, lat: 39.184172 },
  { lng: -105.289236, lat: 39.183416 },
  { lng: -105.287331, lat: 39.181255 },
  { lng: -105.287377, lat: 39.179442 },
  { lng: -105.289498, lat: 39.177494 },
  { lng: -105.294748, lat: 39.174838 },
  { lng: -105.293635, lat: 39.173975 },
  { lng: -105.294216, lat: 39.172977 },
  { lng: -105.29944, lat: 39.172529 },
  { lng: -105.301529, lat: 39.170612 },
  { lng: -105.302014, lat: 39.169246 },
  { lng: -105.300214, lat: 39.168017 },
  { lng: -105.301022, lat: 39.166777 },
  { lng: -105.302271, lat: 39.166689 },
  { lng: -105.304454, lat: 39.164281 },
  { lng: -105.305197, lat: 39.164024 },
  { lng: -105.307059, lat: 39.165356 },
  { lng: -105.309982, lat: 39.163519 },
  { lng: -105.310692, lat: 39.161526 },
  { lng: -105.313063, lat: 39.160019 },
  { lng: -105.313557, lat: 39.159121 },
  { lng: -105.313087, lat: 39.157077 },
  { lng: -105.31531, lat: 39.154895 },
  { lng: -105.315949, lat: 39.151302 },
  { lng: -105.315579, lat: 39.149911 },
  { lng: -105.316193, lat: 39.148855 },
  { lng: -105.315347, lat: 39.146712 },
  { lng: -105.315634, lat: 39.145801 },
  { lng: -105.319916, lat: 39.145754 },
  { lng: -105.321556, lat: 39.146191 },
  { lng: -105.323285, lat: 39.144992 },
  { lng: -105.322147, lat: 39.142958 },
  { lng: -105.322782, lat: 39.141114 },
  { lng: -105.321778, lat: 39.139017 },
  { lng: -105.321962, lat: 39.136967 },
  { lng: -105.322757, lat: 39.135595 },
  { lng: -105.324774, lat: 39.133863 },
  { lng: -105.327393, lat: 39.130873 },
];

export const clearCreek = [
  { lng: -105.92703, lat: 39.676703 },
  { lng: -105.92629, lat: 39.672985 },
  { lng: -105.922285, lat: 39.670123 },
  { lng: -105.919955, lat: 39.669434 },
  { lng: -105.914866, lat: 39.66578 },
  { lng: -105.910753, lat: 39.662294 },
  { lng: -105.903691, lat: 39.664435 },
  { lng: -105.896812, lat: 39.664664 },
  { lng: -105.893945, lat: 39.659418 },
  { lng: -105.888215, lat: 39.658427 },
  { lng: -105.884861, lat: 39.657084 },
  { lng: -105.879613, lat: 39.663638 },
  { lng: -105.878926, lat: 39.663843 },
  { lng: -105.87364, lat: 39.665861 },
  { lng: -105.868105, lat: 39.666906 },
  { lng: -105.864767, lat: 39.667218 },
  { lng: -105.862968, lat: 39.664957 },
  { lng: -105.862026, lat: 39.662276 },
  { lng: -105.859475, lat: 39.658702 },
  { lng: -105.856773, lat: 39.65783 },
  { lng: -105.856781, lat: 39.655888 },
  { lng: -105.854341, lat: 39.649081 },
  { lng: -105.848665, lat: 39.644156 },
  { lng: -105.846102, lat: 39.644087 },
  { lng: -105.841546, lat: 39.646184 },
  { lng: -105.839474, lat: 39.645595 },
  { lng: -105.836691, lat: 39.64295 },
  { lng: -105.83395, lat: 39.642222 },
  { lng: -105.82874, lat: 39.641949 },
  { lng: -105.826265, lat: 39.640928 },
  { lng: -105.822696, lat: 39.642329 },
  { lng: -105.820992, lat: 39.642565 },
  { lng: -105.819163, lat: 39.639659 },
  { lng: -105.818636, lat: 39.63491 },
  { lng: -105.817239, lat: 39.633555 },
  { lng: -105.810939, lat: 39.635348 },
  { lng: -105.806227, lat: 39.634947 },
  { lng: -105.800657, lat: 39.635571 },
  { lng: -105.798275, lat: 39.636354 },
  { lng: -105.792732, lat: 39.636114 },
  { lng: -105.791191, lat: 39.634237 },
  { lng: -105.788017, lat: 39.632393 },
  { lng: -105.784603, lat: 39.631147 },
  { lng: -105.782674, lat: 39.629024 },
  { lng: -105.782228, lat: 39.62433 },
  { lng: -105.782837, lat: 39.622656 },
  { lng: -105.782706, lat: 39.617957 },
  { lng: -105.781206, lat: 39.61596 },
  { lng: -105.777327, lat: 39.612757 },
  { lng: -105.776143, lat: 39.61108 },
  { lng: -105.776241, lat: 39.608338 },
  { lng: -105.775702, lat: 39.606528 },
  { lng: -105.77627, lat: 39.604959 },
  { lng: -105.777567, lat: 39.604417 },
  { lng: -105.781388, lat: 39.600897 },
  { lng: -105.782745, lat: 39.600145 },
  { lng: -105.791664, lat: 39.597891 },
  { lng: -105.794374, lat: 39.59483 },
  { lng: -105.796105, lat: 39.593938 },
  { lng: -105.800433, lat: 39.593211 },
  { lng: -105.801371, lat: 39.591244 },
  { lng: -105.803222, lat: 39.589819 },
  { lng: -105.805962, lat: 39.585838 },
  { lng: -105.808083, lat: 39.583395 },
  { lng: -105.813793, lat: 39.582406 },
  { lng: -105.818091, lat: 39.58084 },
  { lng: -105.819897, lat: 39.582229 },
  { lng: -105.820623, lat: 39.580925 },
  { lng: -105.822719, lat: 39.580094 },
  { lng: -105.826544, lat: 39.5802 },
  { lng: -105.827834, lat: 39.579069 },
  { lng: -105.831542, lat: 39.57808 },
  { lng: -105.833678, lat: 39.576135 },
  { lng: -105.838369, lat: 39.572952 },
  { lng: -105.838595, lat: 39.572298 },
  { lng: -105.836599, lat: 39.56879 },
  { lng: -105.830615, lat: 39.56646 },
  { lng: -105.829662, lat: 39.564865 },
  { lng: -105.776642, lat: 39.56492 },
  { lng: -105.723545, lat: 39.565351 },
  { lng: -105.721601, lat: 39.565269 },
  { lng: -105.623875, lat: 39.567036 },
  { lng: -105.587986, lat: 39.568197 },
  { lng: -105.444922, lat: 39.565988 },
  { lng: -105.401051, lat: 39.565988 },
  { lng: -105.398949, lat: 39.566056 },
  { lng: -105.39915, lat: 39.581588 },
  { lng: -105.399089, lat: 39.582072 },
  { lng: -105.39895, lat: 39.588444 },
  { lng: -105.39875, lat: 39.615418 },
  { lng: -105.39835, lat: 39.635888 },
  { lng: -105.39825, lat: 39.636588 },
  { lng: -105.398248, lat: 39.649949 },
  { lng: -105.398249, lat: 39.650788 },
  { lng: -105.398549, lat: 39.686294 },
  { lng: -105.398348, lat: 39.714121 },
  { lng: -105.398349, lat: 39.717147 },
  { lng: -105.398248, lat: 39.717487 },
  { lng: -105.39819, lat: 39.726234 },
  { lng: -105.397949, lat: 39.745856 },
  { lng: -105.397949, lat: 39.747087 },
  { lng: -105.402165, lat: 39.748902 },
  { lng: -105.40323, lat: 39.750214 },
  { lng: -105.406576, lat: 39.751395 },
  { lng: -105.409672, lat: 39.749889 },
  { lng: -105.414982, lat: 39.749501 },
  { lng: -105.418267, lat: 39.747235 },
  { lng: -105.420875, lat: 39.745957 },
  { lng: -105.423258, lat: 39.748216 },
  { lng: -105.423552, lat: 39.749915 },
  { lng: -105.422435, lat: 39.75086 },
  { lng: -105.421994, lat: 39.755018 },
  { lng: -105.42735, lat: 39.755798 },
  { lng: -105.4302, lat: 39.754627 },
  { lng: -105.437978, lat: 39.755855 },
  { lng: -105.44139, lat: 39.755518 },
  { lng: -105.445071, lat: 39.754234 },
  { lng: -105.446103, lat: 39.753994 },
  { lng: -105.447285, lat: 39.75352 },
  { lng: -105.447353, lat: 39.753491 },
  { lng: -105.450328, lat: 39.752882 },
  { lng: -105.451042, lat: 39.75379 },
  { lng: -105.454629, lat: 39.75579 },
  { lng: -105.461298, lat: 39.755612 },
  { lng: -105.462654, lat: 39.757018 },
  { lng: -105.46569, lat: 39.756359 },
  { lng: -105.469781, lat: 39.756536 },
  { lng: -105.472408, lat: 39.757509 },
  { lng: -105.478935, lat: 39.759083 },
  { lng: -105.48403, lat: 39.759205 },
  { lng: -105.486365, lat: 39.758412 },
  { lng: -105.489511, lat: 39.758162 },
  { lng: -105.49071, lat: 39.757189 },
  { lng: -105.492967, lat: 39.756906 },
  { lng: -105.496883, lat: 39.757796 },
  { lng: -105.495971, lat: 39.75848 },
  { lng: -105.496717, lat: 39.760322 },
  { lng: -105.493861, lat: 39.762718 },
  { lng: -105.494253, lat: 39.763798 },
  { lng: -105.501389, lat: 39.763008 },
  { lng: -105.504083, lat: 39.763189 },
  { lng: -105.50829, lat: 39.764147 },
  { lng: -105.510188, lat: 39.765815 },
  { lng: -105.512221, lat: 39.765986 },
  { lng: -105.516509, lat: 39.764984 },
  { lng: -105.518501, lat: 39.765114 },
  { lng: -105.521284, lat: 39.766094 },
  { lng: -105.521806, lat: 39.767002 },
  { lng: -105.525589, lat: 39.768686 },
  { lng: -105.530208, lat: 39.769078 },
  { lng: -105.538605, lat: 39.77162 },
  { lng: -105.541308, lat: 39.77381 },
  { lng: -105.545076, lat: 39.773635 },
  { lng: -105.544255, lat: 39.77692 },
  { lng: -105.544675, lat: 39.77799 },
  { lng: -105.548184, lat: 39.781777 },
  { lng: -105.548762, lat: 39.784635 },
  { lng: -105.54598, lat: 39.786983 },
  { lng: -105.55434, lat: 39.792028 },
  { lng: -105.555266, lat: 39.794249 },
  { lng: -105.558322, lat: 39.795322 },
  { lng: -105.560411, lat: 39.794869 },
  { lng: -105.56157, lat: 39.795861 },
  { lng: -105.5641, lat: 39.800363 },
  { lng: -105.566412, lat: 39.800336 },
  { lng: -105.571353, lat: 39.801232 },
  { lng: -105.57192, lat: 39.802362 },
  { lng: -105.57805, lat: 39.805562 },
  { lng: -105.578541, lat: 39.811 },
  { lng: -105.579716, lat: 39.812833 },
  { lng: -105.578836, lat: 39.813715 },
  { lng: -105.576071, lat: 39.814329 },
  { lng: -105.574178, lat: 39.819013 },
  { lng: -105.578037, lat: 39.822268 },
  { lng: -105.583017, lat: 39.82284 },
  { lng: -105.584643, lat: 39.822458 },
  { lng: -105.5868, lat: 39.822025 },
  { lng: -105.588638, lat: 39.823028 },
  { lng: -105.592021, lat: 39.822694 },
  { lng: -105.594664, lat: 39.820136 },
  { lng: -105.59745, lat: 39.818813 },
  { lng: -105.602422, lat: 39.818052 },
  { lng: -105.602664, lat: 39.82044 },
  { lng: -105.604666, lat: 39.82199 },
  { lng: -105.611563, lat: 39.824734 },
  { lng: -105.61363, lat: 39.822689 },
  { lng: -105.616328, lat: 39.825541 },
  { lng: -105.621265, lat: 39.828962 },
  { lng: -105.623029, lat: 39.829141 },
  { lng: -105.625557, lat: 39.83121 },
  { lng: -105.627569, lat: 39.832087 },
  { lng: -105.630241, lat: 39.836562 },
  { lng: -105.631485, lat: 39.837678 },
  { lng: -105.631151, lat: 39.839338 },
  { lng: -105.633625, lat: 39.843764 },
  { lng: -105.636176, lat: 39.844554 },
  { lng: -105.640126, lat: 39.844937 },
  { lng: -105.643902, lat: 39.846472 },
  { lng: -105.648023, lat: 39.846315 },
  { lng: -105.64996, lat: 39.846772 },
  { lng: -105.651756, lat: 39.848261 },
  { lng: -105.654638, lat: 39.849228 },
  { lng: -105.660471, lat: 39.850568 },
  { lng: -105.663311, lat: 39.850036 },
  { lng: -105.667405, lat: 39.84758 },
  { lng: -105.671752, lat: 39.84798 },
  { lng: -105.67534, lat: 39.849763 },
  { lng: -105.677347, lat: 39.847431 },
  { lng: -105.681445, lat: 39.84749 },
  { lng: -105.682994, lat: 39.848365 },
  { lng: -105.686462, lat: 39.849229 },
  { lng: -105.688849, lat: 39.851404 },
  { lng: -105.690348, lat: 39.851996 },
  { lng: -105.691311, lat: 39.851338 },
  { lng: -105.693542, lat: 39.847853 },
  { lng: -105.693795, lat: 39.845475 },
  { lng: -105.694618, lat: 39.843712 },
  { lng: -105.695747, lat: 39.839482 },
  { lng: -105.696345, lat: 39.838919 },
  { lng: -105.701266, lat: 39.837126 },
  { lng: -105.703271, lat: 39.836712 },
  { lng: -105.708142, lat: 39.836724 },
  { lng: -105.713369, lat: 39.838212 },
  { lng: -105.716981, lat: 39.835472 },
  { lng: -105.71934, lat: 39.831051 },
  { lng: -105.719243, lat: 39.827544 },
  { lng: -105.723279, lat: 39.825353 },
  { lng: -105.724245, lat: 39.824073 },
  { lng: -105.724558, lat: 39.821986 },
  { lng: -105.724125, lat: 39.819018 },
  { lng: -105.725176, lat: 39.817328 },
  { lng: -105.731426, lat: 39.814319 },
  { lng: -105.733457, lat: 39.812542 },
  { lng: -105.734981, lat: 39.810231 },
  { lng: -105.736149, lat: 39.805945 },
  { lng: -105.735808, lat: 39.805283 },
  { lng: -105.74142, lat: 39.804489 },
  { lng: -105.74449, lat: 39.803375 },
  { lng: -105.747074, lat: 39.801325 },
  { lng: -105.749682, lat: 39.800944 },
  { lng: -105.750561, lat: 39.801303 },
  { lng: -105.752667, lat: 39.79994 },
  { lng: -105.756781, lat: 39.798222 },
  { lng: -105.76096, lat: 39.797101 },
  { lng: -105.76281, lat: 39.795617 },
  { lng: -105.764601, lat: 39.794892 },
  { lng: -105.765958, lat: 39.794855 },
  { lng: -105.76946, lat: 39.795704 },
  { lng: -105.773374, lat: 39.797237 },
  { lng: -105.777187, lat: 39.798165 },
  { lng: -105.779712, lat: 39.79905 },
  { lng: -105.782314, lat: 39.801177 },
  { lng: -105.784662, lat: 39.802434 },
  { lng: -105.79094, lat: 39.803101 },
  { lng: -105.793731, lat: 39.801944 },
  { lng: -105.795804, lat: 39.802919 },
  { lng: -105.799555, lat: 39.803282 },
  { lng: -105.801265, lat: 39.801452 },
  { lng: -105.80194, lat: 39.799336 },
  { lng: -105.804861, lat: 39.799033 },
  { lng: -105.807517, lat: 39.798054 },
  { lng: -105.80972, lat: 39.794963 },
  { lng: -105.810511, lat: 39.791475 },
  { lng: -105.814177, lat: 39.790298 },
  { lng: -105.818413, lat: 39.790013 },
  { lng: -105.821416, lat: 39.788064 },
  { lng: -105.822954, lat: 39.785605 },
  { lng: -105.825687, lat: 39.786859 },
  { lng: -105.829997, lat: 39.788219 },
  { lng: -105.833419, lat: 39.787889 },
  { lng: -105.838609, lat: 39.788132 },
  { lng: -105.844088, lat: 39.789518 },
  { lng: -105.847823, lat: 39.789194 },
  { lng: -105.851363, lat: 39.788033 },
  { lng: -105.850901, lat: 39.789091 },
  { lng: -105.853357, lat: 39.792274 },
  { lng: -105.854942, lat: 39.796154 },
  { lng: -105.855761, lat: 39.795505 },
  { lng: -105.860115, lat: 39.796944 },
  { lng: -105.868735, lat: 39.796629 },
  { lng: -105.872306, lat: 39.796692 },
  { lng: -105.875566, lat: 39.799258 },
  { lng: -105.878139, lat: 39.7986 },
  { lng: -105.883358, lat: 39.798009 },
  { lng: -105.887622, lat: 39.7966 },
  { lng: -105.887902, lat: 39.790134 },
  { lng: -105.887695, lat: 39.789022 },
  { lng: -105.890739, lat: 39.786384 },
  { lng: -105.892346, lat: 39.784108 },
  { lng: -105.892657, lat: 39.782638 },
  { lng: -105.89209, lat: 39.7808 },
  { lng: -105.890029, lat: 39.77864 },
  { lng: -105.889638, lat: 39.773658 },
  { lng: -105.887544, lat: 39.770477 },
  { lng: -105.886716, lat: 39.766003 },
  { lng: -105.885106, lat: 39.764155 },
  { lng: -105.882849, lat: 39.763383 },
  { lng: -105.880481, lat: 39.759303 },
  { lng: -105.882121, lat: 39.755769 },
  { lng: -105.884588, lat: 39.752859 },
  { lng: -105.885001, lat: 39.749986 },
  { lng: -105.883166, lat: 39.747635 },
  { lng: -105.882343, lat: 39.745579 },
  { lng: -105.883266, lat: 39.743807 },
  { lng: -105.882375, lat: 39.740328 },
  { lng: -105.883714, lat: 39.737012 },
  { lng: -105.88944, lat: 39.731548 },
  { lng: -105.891755, lat: 39.730387 },
  { lng: -105.899959, lat: 39.728781 },
  { lng: -105.904729, lat: 39.728779 },
  { lng: -105.906724, lat: 39.727532 },
  { lng: -105.90828, lat: 39.726246 },
  { lng: -105.90746, lat: 39.724045 },
  { lng: -105.910499, lat: 39.720713 },
  { lng: -105.912014, lat: 39.716155 },
  { lng: -105.911775, lat: 39.715295 },
  { lng: -105.916765, lat: 39.713576 },
  { lng: -105.918065, lat: 39.712084 },
  { lng: -105.921407, lat: 39.709936 },
  { lng: -105.92186, lat: 39.708125 },
  { lng: -105.924546, lat: 39.703423 },
  { lng: -105.924061, lat: 39.701574 },
  { lng: -105.924618, lat: 39.698972 },
  { lng: -105.920042, lat: 39.693888 },
  { lng: -105.920473, lat: 39.692538 },
  { lng: -105.919581, lat: 39.690287 },
  { lng: -105.921329, lat: 39.68431 },
  { lng: -105.924653, lat: 39.680156 },
  { lng: -105.92703, lat: 39.676703 },
];

export const boulder = [
  { lng: -105.693662, lat: 39.951154 },
  { lng: -105.694362, lat: 39.949684 },
  { lng: -105.691796, lat: 39.946241 },
  { lng: -105.693453, lat: 39.944017 },
  { lng: -105.6932, lat: 39.941239 },
  { lng: -105.689627, lat: 39.938634 },
  { lng: -105.686616, lat: 39.937469 },
  { lng: -105.682943, lat: 39.933789 },
  { lng: -105.678941, lat: 39.93286 },
  { lng: -105.675265, lat: 39.933323 },
  { lng: -105.675798, lat: 39.932445 },
  { lng: -105.62877, lat: 39.932086 },
  { lng: -105.625668, lat: 39.932386 },
  { lng: -105.520353, lat: 39.932387 },
  { lng: -105.519031, lat: 39.93229 },
  { lng: -105.518249, lat: 39.932387 },
  { lng: -105.515434, lat: 39.932387 },
  { lng: -105.510981, lat: 39.932294 },
  { lng: -105.501154, lat: 39.932388 },
  { lng: -105.457484, lat: 39.932187 },
  { lng: -105.455651, lat: 39.932286 },
  { lng: -105.453215, lat: 39.934229 },
  { lng: -105.449483, lat: 39.935206 },
  { lng: -105.449, lat: 39.935516 },
  { lng: -105.447997, lat: 39.936493 },
  { lng: -105.444779, lat: 39.937207 },
  { lng: -105.443416, lat: 39.93796 },
  { lng: -105.44042, lat: 39.938372 },
  { lng: -105.438472, lat: 39.937531 },
  { lng: -105.438404, lat: 39.937332 },
  { lng: -105.43255, lat: 39.933406 },
  { lng: -105.430831, lat: 39.931764 },
  { lng: -105.429325, lat: 39.931209 },
  { lng: -105.425886, lat: 39.931078 },
  { lng: -105.422063, lat: 39.931626 },
  { lng: -105.420814, lat: 39.930901 },
  { lng: -105.41911, lat: 39.928819 },
  { lng: -105.417326, lat: 39.930669 },
  { lng: -105.415506, lat: 39.93157 },
  { lng: -105.415027, lat: 39.933245 },
  { lng: -105.413172, lat: 39.934497 },
  { lng: -105.411525, lat: 39.93269 },
  { lng: -105.406039, lat: 39.931743 },
  { lng: -105.400687, lat: 39.935072 },
  { lng: -105.399224, lat: 39.934817 },
  { lng: -105.397971, lat: 39.934867 },
  { lng: -105.397955, lat: 39.932985 },
  { lng: -105.398167, lat: 39.929543 },
  { lng: -105.397849, lat: 39.912886 },
  { lng: -105.357666, lat: 39.913485 },
  { lng: -105.342281, lat: 39.913737 },
  { lng: -105.339547, lat: 39.913786 },
  { lng: -105.314702, lat: 39.913883 },
  { lng: -105.306845, lat: 39.913986 },
  { lng: -105.282656, lat: 39.913687 },
  { lng: -105.264945, lat: 39.914186 },
  { lng: -105.263945, lat: 39.913986 },
  { lng: -105.24527, lat: 39.914084 },
  { lng: -105.238933, lat: 39.914367 },
  { lng: -105.231343, lat: 39.914085 },
  { lng: -105.212475, lat: 39.914236 },
  { lng: -105.20345, lat: 39.91404 },
  { lng: -105.201254, lat: 39.914002 },
  { lng: -105.200796, lat: 39.913994 },
  { lng: -105.199494, lat: 39.914028 },
  { lng: -105.196647, lat: 39.914186 },
  { lng: -105.192439, lat: 39.914107 },
  { lng: -105.191895, lat: 39.914097 },
  { lng: -105.167072, lat: 39.913893 },
  { lng: -105.16615, lat: 39.914047 },
  { lng: -105.165349, lat: 39.914133 },
  { lng: -105.164097, lat: 39.913891 },
  { lng: -105.147143, lat: 39.913889 },
  { lng: -105.147041, lat: 39.928938 },
  { lng: -105.137604, lat: 39.928931 },
  { lng: -105.137578, lat: 39.937109 },
  { lng: -105.137579, lat: 39.939688 },
  { lng: -105.137578, lat: 39.939712 },
  { lng: -105.128165, lat: 39.939618 },
  { lng: -105.128024, lat: 39.950764 },
  { lng: -105.128022, lat: 39.950972 },
  { lng: -105.128002, lat: 39.954173 },
  { lng: -105.127317, lat: 39.954172 },
  { lng: -105.121943, lat: 39.95416 },
  { lng: -105.121258, lat: 39.952389 },
  { lng: -105.114389, lat: 39.955458 },
  { lng: -105.109517, lat: 39.956996 },
  { lng: -105.109528, lat: 39.956457 },
  { lng: -105.115972, lat: 39.953907 },
  { lng: -105.120837, lat: 39.951293 },
  { lng: -105.109333, lat: 39.95064 },
  { lng: -105.109353, lat: 39.943336 },
  { lng: -105.118562, lat: 39.943323 },
  { lng: -105.118657, lat: 39.932965 },
  { lng: -105.114484, lat: 39.930126 },
  { lng: -105.113762, lat: 39.928615 },
  { lng: -105.109414, lat: 39.925475 },
  { lng: -105.109409, lat: 39.925859 },
  { lng: -105.109406, lat: 39.926101 },
  { lng: -105.109406, lat: 39.926114 },
  { lng: -105.111873, lat: 39.92805 },
  { lng: -105.11325, lat: 39.930656 },
  { lng: -105.113027, lat: 39.932755 },
  { lng: -105.109236, lat: 39.936135 },
  { lng: -105.090854, lat: 39.936386 },
  { lng: -105.091072, lat: 39.943281 },
  { lng: -105.076637, lat: 39.943329 },
  { lng: -105.076568, lat: 39.950538 },
  { lng: -105.071899, lat: 39.950198 },
  { lng: -105.07192, lat: 39.950585 },
  { lng: -105.072002, lat: 39.95767 },
  { lng: -105.083117, lat: 39.957796 },
  { lng: -105.084439, lat: 39.958489 },
  { lng: -105.088613, lat: 39.95803 },
  { lng: -105.088693, lat: 39.958424 },
  { lng: -105.087215, lat: 39.959989 },
  { lng: -105.08203, lat: 39.96085 },
  { lng: -105.080023, lat: 39.961452 },
  { lng: -105.076572, lat: 39.963608 },
  { lng: -105.07189, lat: 39.96785 },
  { lng: -105.068065, lat: 39.970932 },
  { lng: -105.058553, lat: 39.97526 },
  { lng: -105.052797, lat: 39.977674 },
  { lng: -105.052794, lat: 39.978581 },
  { lng: -105.052774, lat: 39.986398 },
  { lng: -105.052823, lat: 40.00026 },
  { lng: -105.055518, lat: 40.000251 },
  { lng: -105.055642, lat: 40.00025 },
  { lng: -105.055569, lat: 40.005659 },
  { lng: -105.055515, lat: 40.00948 },
  { lng: -105.055509, lat: 40.009899 },
  { lng: -105.055448, lat: 40.014774 },
  { lng: -105.055392, lat: 40.021971 },
  { lng: -105.055372, lat: 40.029309 },
  { lng: -105.055462, lat: 40.036636 },
  { lng: -105.055469, lat: 40.046335 },
  { lng: -105.055465, lat: 40.046576 },
  { lng: -105.055476, lat: 40.051468 },
  { lng: -105.055477, lat: 40.062879 },
  { lng: -105.055471, lat: 40.065828 },
  { lng: -105.055466, lat: 40.069364 },
  { lng: -105.05543, lat: 40.087512 },
  { lng: -105.055432, lat: 40.087832 },
  { lng: -105.055393, lat: 40.138263 },
  { lng: -105.055376, lat: 40.14457 },
  { lng: -105.055334, lat: 40.152024 },
  { lng: -105.055333, lat: 40.152287 },
  { lng: -105.055355, lat: 40.15339 },
  { lng: -105.055336, lat: 40.155576 },
  { lng: -105.055332, lat: 40.160192 },
  { lng: -105.055251, lat: 40.174589 },
  { lng: -105.05523, lat: 40.177452 },
  { lng: -105.055198, lat: 40.181851 },
  { lng: -105.055199, lat: 40.181935 },
  { lng: -105.0552, lat: 40.182045 },
  { lng: -105.055202, lat: 40.182224 },
  { lng: -105.055203, lat: 40.18445 },
  { lng: -105.055214, lat: 40.189149 },
  { lng: -105.055201, lat: 40.191162 },
  { lng: -105.055184, lat: 40.20368 },
  { lng: -105.055074, lat: 40.247157 },
  { lng: -105.0551, lat: 40.25612 },
  { lng: -105.055089, lat: 40.261793 },
  { lng: -105.109974, lat: 40.261284 },
  { lng: -105.130179, lat: 40.261584 },
  { lng: -105.131059, lat: 40.261079 },
  { lng: -105.142904, lat: 40.26113 },
  { lng: -105.15906, lat: 40.261219 },
  { lng: -105.208144, lat: 40.261185 },
  { lng: -105.212623, lat: 40.261185 },
  { lng: -105.21483, lat: 40.261299 },
  { lng: -105.237319, lat: 40.261895 },
  { lng: -105.289224, lat: 40.262781 },
  { lng: -105.335177, lat: 40.261781 },
  { lng: -105.336248, lat: 40.261685 },
  { lng: -105.336352, lat: 40.261685 },
  { lng: -105.354747, lat: 40.261784 },
  { lng: -105.375226, lat: 40.261291 },
  { lng: -105.390996, lat: 40.257884 },
  { lng: -105.462752, lat: 40.259384 },
  { lng: -105.465394, lat: 40.259357 },
  { lng: -105.495321, lat: 40.259726 },
  { lng: -105.500481, lat: 40.260277 },
  { lng: -105.518083, lat: 40.260753 },
  { lng: -105.535354, lat: 40.261284 },
  { lng: -105.539455, lat: 40.261284 },
  { lng: -105.542003, lat: 40.261327 },
  { lng: -105.542801, lat: 40.26134 },
  { lng: -105.56174, lat: 40.261507 },
  { lng: -105.653321, lat: 40.260457 },
  { lng: -105.651197, lat: 40.257257 },
  { lng: -105.651199, lat: 40.254247 },
  { lng: -105.649789, lat: 40.252877 },
  { lng: -105.644262, lat: 40.249793 },
  { lng: -105.647074, lat: 40.249132 },
  { lng: -105.650396, lat: 40.249379 },
  { lng: -105.658932, lat: 40.249413 },
  { lng: -105.660006, lat: 40.248833 },
  { lng: -105.660204, lat: 40.245177 },
  { lng: -105.66495, lat: 40.238555 },
  { lng: -105.665437, lat: 40.23727 },
  { lng: -105.664479, lat: 40.235101 },
  { lng: -105.664378, lat: 40.233213 },
  { lng: -105.667354, lat: 40.230959 },
  { lng: -105.668444, lat: 40.22814 },
  { lng: -105.667767, lat: 40.226719 },
  { lng: -105.668915, lat: 40.223725 },
  { lng: -105.663462, lat: 40.219929 },
  { lng: -105.66766, lat: 40.22037 },
  { lng: -105.669458, lat: 40.220041 },
  { lng: -105.671255, lat: 40.217079 },
  { lng: -105.670946, lat: 40.214602 },
  { lng: -105.670192, lat: 40.213608 },
  { lng: -105.671119, lat: 40.212163 },
  { lng: -105.671194, lat: 40.209692 },
  { lng: -105.670559, lat: 40.207802 },
  { lng: -105.675252, lat: 40.203213 },
  { lng: -105.677645, lat: 40.202331 },
  { lng: -105.677688, lat: 40.201673 },
  { lng: -105.676405, lat: 40.199503 },
  { lng: -105.677644, lat: 40.197211 },
  { lng: -105.677554, lat: 40.188507 },
  { lng: -105.675469, lat: 40.186392 },
  { lng: -105.670626, lat: 40.185393 },
  { lng: -105.670315, lat: 40.183417 },
  { lng: -105.671682, lat: 40.182004 },
  { lng: -105.671791, lat: 40.180029 },
  { lng: -105.670771, lat: 40.178489 },
  { lng: -105.668281, lat: 40.176914 },
  { lng: -105.669733, lat: 40.174258 },
  { lng: -105.668573, lat: 40.17358 },
  { lng: -105.668115, lat: 40.170858 },
  { lng: -105.666919, lat: 40.169887 },
  { lng: -105.669506, lat: 40.168625 },
  { lng: -105.669591, lat: 40.167485 },
  { lng: -105.671336, lat: 40.166497 },
  { lng: -105.67058, lat: 40.162691 },
  { lng: -105.667536, lat: 40.161186 },
  { lng: -105.667729, lat: 40.158276 },
  { lng: -105.666399, lat: 40.157335 },
  { lng: -105.664684, lat: 40.156743 },
  { lng: -105.661343, lat: 40.156524 },
  { lng: -105.660248, lat: 40.154735 },
  { lng: -105.656862, lat: 40.152937 },
  { lng: -105.655996, lat: 40.151281 },
  { lng: -105.648744, lat: 40.150505 },
  { lng: -105.64729, lat: 40.150968 },
  { lng: -105.647341, lat: 40.149213 },
  { lng: -105.644809, lat: 40.148076 },
  { lng: -105.641089, lat: 40.147549 },
  { lng: -105.635656, lat: 40.146006 },
  { lng: -105.635097, lat: 40.14419 },
  { lng: -105.635468, lat: 40.140417 },
  { lng: -105.634178, lat: 40.139125 },
  { lng: -105.630156, lat: 40.138099 },
  { lng: -105.629388, lat: 40.136151 },
  { lng: -105.630048, lat: 40.134588 },
  { lng: -105.629983, lat: 40.130434 },
  { lng: -105.629133, lat: 40.129158 },
  { lng: -105.626571, lat: 40.127858 },
  { lng: -105.62609, lat: 40.127346 },
  { lng: -105.628185, lat: 40.124987 },
  { lng: -105.628782, lat: 40.123512 },
  { lng: -105.627574, lat: 40.122062 },
  { lng: -105.62821, lat: 40.120353 },
  { lng: -105.626566, lat: 40.118654 },
  { lng: -105.629121, lat: 40.117036 },
  { lng: -105.629973, lat: 40.114158 },
  { lng: -105.63163, lat: 40.113489 },
  { lng: -105.634652, lat: 40.113232 },
  { lng: -105.636681, lat: 40.106994 },
  { lng: -105.636104, lat: 40.104741 },
  { lng: -105.63661, lat: 40.102536 },
  { lng: -105.635386, lat: 40.100443 },
  { lng: -105.632022, lat: 40.097191 },
  { lng: -105.633266, lat: 40.095454 },
  { lng: -105.634539, lat: 40.095485 },
  { lng: -105.634719, lat: 40.093848 },
  { lng: -105.63376, lat: 40.088379 },
  { lng: -105.635211, lat: 40.087183 },
  { lng: -105.635147, lat: 40.084976 },
  { lng: -105.636004, lat: 40.084553 },
  { lng: -105.635633, lat: 40.082871 },
  { lng: -105.634253, lat: 40.081509 },
  { lng: -105.63262, lat: 40.081447 },
  { lng: -105.634566, lat: 40.079975 },
  { lng: -105.634947, lat: 40.075799 },
  { lng: -105.635429, lat: 40.074947 },
  { lng: -105.638838, lat: 40.073405 },
  { lng: -105.640911, lat: 40.073117 },
  { lng: -105.640739, lat: 40.069871 },
  { lng: -105.638873, lat: 40.067031 },
  { lng: -105.641814, lat: 40.06787 },
  { lng: -105.646692, lat: 40.06522 },
  { lng: -105.648578, lat: 40.06198 },
  { lng: -105.649419, lat: 40.059992 },
  { lng: -105.651061, lat: 40.058445 },
  { lng: -105.648425, lat: 40.053937 },
  { lng: -105.645582, lat: 40.05266 },
  { lng: -105.645099, lat: 40.05067 },
  { lng: -105.640709, lat: 40.047138 },
  { lng: -105.638507, lat: 40.046739 },
  { lng: -105.637412, lat: 40.045509 },
  { lng: -105.638601, lat: 40.043552 },
  { lng: -105.639149, lat: 40.040469 },
  { lng: -105.638797, lat: 40.038787 },
  { lng: -105.640193, lat: 40.037255 },
  { lng: -105.641197, lat: 40.034172 },
  { lng: -105.643938, lat: 40.033301 },
  { lng: -105.649574, lat: 40.027742 },
  { lng: -105.652793, lat: 40.023772 },
  { lng: -105.652779, lat: 40.02177 },
  { lng: -105.65191, lat: 40.020905 },
  { lng: -105.657408, lat: 40.019528 },
  { lng: -105.66019, lat: 40.018349 },
  { lng: -105.661606, lat: 40.016466 },
  { lng: -105.667098, lat: 40.016317 },
  { lng: -105.670136, lat: 40.016879 },
  { lng: -105.67451, lat: 40.016186 },
  { lng: -105.677977, lat: 40.014541 },
  { lng: -105.680431, lat: 40.01402 },
  { lng: -105.683052, lat: 40.014142 },
  { lng: -105.687826, lat: 40.015979 },
  { lng: -105.689818, lat: 40.015108 },
  { lng: -105.690015, lat: 40.009317 },
  { lng: -105.688469, lat: 40.006946 },
  { lng: -105.689767, lat: 40.005955 },
  { lng: -105.691052, lat: 40.003764 },
  { lng: -105.690497, lat: 40.000869 },
  { lng: -105.69117, lat: 39.999521 },
  { lng: -105.69059, lat: 39.996281 },
  { lng: -105.689036, lat: 39.995374 },
  { lng: -105.685013, lat: 39.994517 },
  { lng: -105.68321, lat: 39.99465 },
  { lng: -105.683164, lat: 39.992881 },
  { lng: -105.685968, lat: 39.989743 },
  { lng: -105.686048, lat: 39.987477 },
  { lng: -105.68748, lat: 39.985418 },
  { lng: -105.685127, lat: 39.9831 },
  { lng: -105.685462, lat: 39.981387 },
  { lng: -105.684938, lat: 39.97933 },
  { lng: -105.688414, lat: 39.975426 },
  { lng: -105.690321, lat: 39.969768 },
  { lng: -105.688827, lat: 39.967762 },
  { lng: -105.689255, lat: 39.96473 },
  { lng: -105.688331, lat: 39.96288 },
  { lng: -105.688185, lat: 39.959663 },
  { lng: -105.685504, lat: 39.958532 },
  { lng: -105.688383, lat: 39.95653 },
  { lng: -105.6894, lat: 39.953506 },
  { lng: -105.691199, lat: 39.952991 },
  { lng: -105.693662, lat: 39.951154 },
];

export const arapahoe = [
  { lng: -105.054211, lat: 39.570856 },
  { lng: -105.05424, lat: 39.569788 },
  { lng: -105.054169, lat: 39.56771 },
  { lng: -105.054145, lat: 39.567085 },
  { lng: -105.05414, lat: 39.566088 },
  { lng: -105.04874, lat: 39.566088 },
  { lng: -105.04085, lat: 39.566145 },
  { lng: -105.040285, lat: 39.56619 },
  { lng: -105.039633, lat: 39.566189 },
  { lng: -105.035936, lat: 39.566189 },
  { lng: -105.033315, lat: 39.566187 },
  { lng: -105.03232, lat: 39.566184 },
  { lng: -105.030292, lat: 39.566187 },
  { lng: -105.021377, lat: 39.566147 },
  { lng: -105.015621, lat: 39.566096 },
  { lng: -105.002068, lat: 39.566233 },
  { lng: -104.993298, lat: 39.566096 },
  { lng: -104.992942, lat: 39.566117 },
  { lng: -104.988529, lat: 39.566124 },
  { lng: -104.982365, lat: 39.566102 },
  { lng: -104.979187, lat: 39.566091 },
  { lng: -104.977449, lat: 39.566083 },
  { lng: -104.973255, lat: 39.566069 },
  { lng: -104.971802, lat: 39.566064 },
  { lng: -104.960469, lat: 39.566129 },
  { lng: -104.955644, lat: 39.566111 },
  { lng: -104.951697, lat: 39.566077 },
  { lng: -104.946466, lat: 39.566082 },
  { lng: -104.945072, lat: 39.566035 },
  { lng: -104.941883, lat: 39.566026 },
  { lng: -104.941705, lat: 39.566025 },
  { lng: -104.929106, lat: 39.566038 },
  { lng: -104.922963, lat: 39.566053 },
  { lng: -104.913831, lat: 39.566029 },
  { lng: -104.911872, lat: 39.566024 },
  { lng: -104.904415, lat: 39.565982 },
  { lng: -104.904175, lat: 39.565982 },
  { lng: -104.897067, lat: 39.565971 },
  { lng: -104.888861, lat: 39.565991 },
  { lng: -104.88571, lat: 39.565979 },
  { lng: -104.872366, lat: 39.565997 },
  { lng: -104.8695, lat: 39.565983 },
  { lng: -104.867124, lat: 39.56598 },
  { lng: -104.857406, lat: 39.566029 },
  { lng: -104.836228, lat: 39.565988 },
  { lng: -104.832934, lat: 39.565988 },
  { lng: -104.815686, lat: 39.565988 },
  { lng: -104.813131, lat: 39.565988 },
  { lng: -104.801371, lat: 39.565887 },
  { lng: -104.801095, lat: 39.565885 },
  { lng: -104.800939, lat: 39.565887 },
  { lng: -104.800612, lat: 39.565893 },
  { lng: -104.791368, lat: 39.566087 },
  { lng: -104.785432, lat: 39.566088 },
  { lng: -104.785308, lat: 39.566088 },
  { lng: -104.784965, lat: 39.566088 },
  { lng: -104.765886, lat: 39.56609 },
  { lng: -104.746043, lat: 39.566025 },
  { lng: -104.745929, lat: 39.566022 },
  { lng: -104.736628, lat: 39.565888 },
  { lng: -104.73584, lat: 39.565888 },
  { lng: -104.717781, lat: 39.565984 },
  { lng: -104.708327, lat: 39.565988 },
  { lng: -104.707781, lat: 39.565988 },
  { lng: -104.707023, lat: 39.565988 },
  { lng: -104.686759, lat: 39.565988 },
  { lng: -104.679505, lat: 39.565995 },
  { lng: -104.679306, lat: 39.565991 },
  { lng: -104.660626, lat: 39.565909 },
  { lng: -104.642125, lat: 39.56589 },
  { lng: -104.60084, lat: 39.565846 },
  { lng: -104.569154, lat: 39.565283 },
  { lng: -104.531676, lat: 39.564678 },
  { lng: -104.474295, lat: 39.564102 },
  { lng: -104.457367, lat: 39.563976 },
  { lng: -104.456156, lat: 39.563949 },
  { lng: -104.442895, lat: 39.56376 },
  { lng: -104.427452, lat: 39.563601 },
  { lng: -104.421404, lat: 39.563694 },
  { lng: -104.398707, lat: 39.56395 },
  { lng: -104.381131, lat: 39.564097 },
  { lng: -104.37598, lat: 39.563949 },
  { lng: -104.361934, lat: 39.564306 },
  { lng: -104.328272, lat: 39.564395 },
  { lng: -104.296062, lat: 39.564657 },
  { lng: -104.285044, lat: 39.564718 },
  { lng: -104.193813, lat: 39.565232 },
  { lng: -104.174259, lat: 39.565278 },
  { lng: -104.014407, lat: 39.565999 },
  { lng: -103.986976, lat: 39.566151 },
  { lng: -103.985114, lat: 39.566312 },
  { lng: -103.981891, lat: 39.566267 },
  { lng: -103.978639, lat: 39.56636 },
  { lng: -103.714355, lat: 39.566331 },
  { lng: -103.707072, lat: 39.566295 },
  { lng: -103.706547, lat: 39.739894 },
  { lng: -103.972303, lat: 39.740401 },
  { lng: -103.974341, lat: 39.739984 },
  { lng: -103.977636, lat: 39.740267 },
  { lng: -104.060078, lat: 39.73997 },
  { lng: -104.096249, lat: 39.739846 },
  { lng: -104.190677, lat: 39.73918 },
  { lng: -104.209525, lat: 39.739018 },
  { lng: -104.22712, lat: 39.738982 },
  { lng: -104.227935, lat: 39.73898 },
  { lng: -104.24705, lat: 39.738908 },
  { lng: -104.283825, lat: 39.738745 },
  { lng: -104.297964, lat: 39.73868 },
  { lng: -104.302586, lat: 39.738492 },
  { lng: -104.30357, lat: 39.738515 },
  { lng: -104.340209, lat: 39.738247 },
  { lng: -104.377229, lat: 39.738121 },
  { lng: -104.37736, lat: 39.73812 },
  { lng: -104.380113, lat: 39.738108 },
  { lng: -104.394586, lat: 39.73804 },
  { lng: -104.414183, lat: 39.73807 },
  { lng: -104.414664, lat: 39.738073 },
  { lng: -104.415289, lat: 39.738074 },
  { lng: -104.420816, lat: 39.738086 },
  { lng: -104.43242, lat: 39.738116 },
  { lng: -104.442784, lat: 39.738132 },
  { lng: -104.470927, lat: 39.738198 },
  { lng: -104.526958, lat: 39.738669 },
  { lng: -104.582488, lat: 39.739636 },
  { lng: -104.602222, lat: 39.739878 },
  { lng: -104.603078, lat: 39.739887 },
  { lng: -104.613603, lat: 39.739982 },
  { lng: -104.616197, lat: 39.740004 },
  { lng: -104.616507, lat: 39.740007 },
  { lng: -104.626065, lat: 39.740061 },
  { lng: -104.632424, lat: 39.739501 },
  { lng: -104.633384, lat: 39.739547 },
  { lng: -104.634448, lat: 39.739594 },
  { lng: -104.635347, lat: 39.739631 },
  { lng: -104.640723, lat: 39.739886 },
  { lng: -104.64293, lat: 39.739782 },
  { lng: -104.652803, lat: 39.739778 },
  { lng: -104.659953, lat: 39.740477 },
  { lng: -104.678305, lat: 39.739995 },
  { lng: -104.715945, lat: 39.740271 },
  { lng: -104.725637, lat: 39.740267 },
  { lng: -104.731955, lat: 39.740822 },
  { lng: -104.734834, lat: 39.740321 },
  { lng: -104.734969, lat: 39.740302 },
  { lng: -104.735396, lat: 39.740265 },
  { lng: -104.745854, lat: 39.74024 },
  { lng: -104.763038, lat: 39.740251 },
  { lng: -104.77247, lat: 39.740251 },
  { lng: -104.780629, lat: 39.74026 },
  { lng: -104.787422, lat: 39.740266 },
  { lng: -104.791128, lat: 39.740269 },
  { lng: -104.791219, lat: 39.740269 },
  { lng: -104.800625, lat: 39.740281 },
  { lng: -104.810022, lat: 39.740141 },
  { lng: -104.810127, lat: 39.740141 },
  { lng: -104.819416, lat: 39.740266 },
  { lng: -104.82032, lat: 39.740297 },
  { lng: -104.82546, lat: 39.74029 },
  { lng: -104.837603, lat: 39.740302 },
  { lng: -104.843254, lat: 39.740307 },
  { lng: -104.847013, lat: 39.740206 },
  { lng: -104.847091, lat: 39.740207 },
  { lng: -104.851719, lat: 39.740206 },
  { lng: -104.856413, lat: 39.740214 },
  { lng: -104.858778, lat: 39.740207 },
  { lng: -104.865803, lat: 39.7402 },
  { lng: -104.875227, lat: 39.740212 },
  { lng: -104.884646, lat: 39.740156 },
  { lng: -104.884659, lat: 39.737274 },
  { lng: -104.88468, lat: 39.735625 },
  { lng: -104.884708, lat: 39.732846 },
  { lng: -104.884933, lat: 39.730442 },
  { lng: -104.886613, lat: 39.729219 },
  { lng: -104.881934, lat: 39.72496 },
  { lng: -104.879735, lat: 39.725623 },
  { lng: -104.87533, lat: 39.725576 },
  { lng: -104.87534, lat: 39.720134 },
  { lng: -104.87534, lat: 39.719711 },
  { lng: -104.874947, lat: 39.71845 },
  { lng: -104.87432, lat: 39.718201 },
  { lng: -104.870831, lat: 39.718176 },
  { lng: -104.870653, lat: 39.710169 },
  { lng: -104.865971, lat: 39.711053 },
  { lng: -104.865967, lat: 39.704318 },
  { lng: -104.868932, lat: 39.702987 },
  { lng: -104.870648, lat: 39.700535 },
  { lng: -104.870662, lat: 39.698475 },
  { lng: -104.865964, lat: 39.699086 },
  { lng: -104.865962, lat: 39.696559 },
  { lng: -104.874196, lat: 39.696472 },
  { lng: -104.875309, lat: 39.696505 },
  { lng: -104.875316, lat: 39.697996 },
  { lng: -104.877698, lat: 39.697988 },
  { lng: -104.877611, lat: 39.696506 },
  { lng: -104.882313, lat: 39.696573 },
  { lng: -104.884057, lat: 39.696491 },
  { lng: -104.885773, lat: 39.696523 },
  { lng: -104.891715, lat: 39.69659 },
  { lng: -104.893324, lat: 39.696595 },
  { lng: -104.893402, lat: 39.695695 },
  { lng: -104.894225, lat: 39.696597 },
  { lng: -104.897402, lat: 39.69627 },
  { lng: -104.898806, lat: 39.695091 },
  { lng: -104.899285, lat: 39.696976 },
  { lng: -104.903544, lat: 39.698535 },
  { lng: -104.903507, lat: 39.696622 },
  { lng: -104.900115, lat: 39.692985 },
  { lng: -104.90351, lat: 39.692581 },
  { lng: -104.903512, lat: 39.689354 },
  { lng: -104.89881, lat: 39.689341 },
  { lng: -104.898784, lat: 39.690702 },
  { lng: -104.898128, lat: 39.690697 },
  { lng: -104.898766, lat: 39.691352 },
  { lng: -104.898766, lat: 39.691545 },
  { lng: -104.898761, lat: 39.692992 },
  { lng: -104.894201, lat: 39.692994 },
  { lng: -104.894092, lat: 39.692874 },
  { lng: -104.894061, lat: 39.686428 },
  { lng: -104.894058, lat: 39.685694 },
  { lng: -104.889328, lat: 39.685684 },
  { lng: -104.889314, lat: 39.682727 },
  { lng: -104.889308, lat: 39.682056 },
  { lng: -104.894046, lat: 39.682061 },
  { lng: -104.894528, lat: 39.678455 },
  { lng: -104.897446, lat: 39.678505 },
  { lng: -104.898317, lat: 39.678473 },
  { lng: -104.90344, lat: 39.678598 },
  { lng: -104.903443, lat: 39.684857 },
  { lng: -104.904996, lat: 39.685906 },
  { lng: -104.903915, lat: 39.683877 },
  { lng: -104.903888, lat: 39.680332 },
  { lng: -104.908096, lat: 39.680306 },
  { lng: -104.908094, lat: 39.679726 },
  { lng: -104.906253, lat: 39.678442 },
  { lng: -104.905631, lat: 39.678314 },
  { lng: -104.903579, lat: 39.676675 },
  { lng: -104.90352, lat: 39.674456 },
  { lng: -104.903522, lat: 39.671225 },
  { lng: -104.903509, lat: 39.667624 },
  { lng: -104.898817, lat: 39.667626 },
  { lng: -104.893941, lat: 39.667589 },
  { lng: -104.890824, lat: 39.664903 },
  { lng: -104.890764, lat: 39.664956 },
  { lng: -104.890188, lat: 39.664393 },
  { lng: -104.888033, lat: 39.665787 },
  { lng: -104.892269, lat: 39.668189 },
  { lng: -104.890133, lat: 39.667587 },
  { lng: -104.884133, lat: 39.667487 },
  { lng: -104.879277, lat: 39.663891 },
  { lng: -104.876233, lat: 39.663987 },
  { lng: -104.875832, lat: 39.669787 },
  { lng: -104.878233, lat: 39.672787 },
  { lng: -104.87531, lat: 39.672784 },
  { lng: -104.875291, lat: 39.674766 },
  { lng: -104.871874, lat: 39.674744 },
  { lng: -104.870882, lat: 39.674764 },
  { lng: -104.868216, lat: 39.672621 },
  { lng: -104.866051, lat: 39.670847 },
  { lng: -104.866174, lat: 39.667515 },
  { lng: -104.866334, lat: 39.660309 },
  { lng: -104.861022, lat: 39.660281 },
  { lng: -104.858978, lat: 39.66034 },
  { lng: -104.856441, lat: 39.66034 },
  { lng: -104.855437, lat: 39.660804 },
  { lng: -104.853716, lat: 39.660072 },
  { lng: -104.848332, lat: 39.659161 },
  { lng: -104.848336, lat: 39.657891 },
  { lng: -104.84773, lat: 39.657101 },
  { lng: -104.847119, lat: 39.65678 },
  { lng: -104.852944, lat: 39.655706 },
  { lng: -104.856038, lat: 39.654178 },
  { lng: -104.857203, lat: 39.653246 },
  { lng: -104.876224, lat: 39.644301 },
  { lng: -104.876155, lat: 39.645907 },
  { lng: -104.876306, lat: 39.652295 },
  { lng: -104.877497, lat: 39.653052 },
  { lng: -104.88014, lat: 39.653057 },
  { lng: -104.880313, lat: 39.645902 },
  { lng: -104.880313, lat: 39.645889 },
  { lng: -104.880368, lat: 39.642193 },
  { lng: -104.880329, lat: 39.641491 },
  { lng: -104.885112, lat: 39.639262 },
  { lng: -104.885231, lat: 39.631345 },
  { lng: -104.885265, lat: 39.624083 },
  { lng: -104.887799, lat: 39.624007 },
  { lng: -104.889668, lat: 39.624051 },
  { lng: -104.894529, lat: 39.62403 },
  { lng: -104.901658, lat: 39.623722 },
  { lng: -104.904024, lat: 39.623827 },
  { lng: -104.905551, lat: 39.624001 },
  { lng: -104.913407, lat: 39.624083 },
  { lng: -104.913257, lat: 39.638691 },
  { lng: -104.914264, lat: 39.639428 },
  { lng: -104.92246, lat: 39.645849 },
  { lng: -104.931873, lat: 39.645826 },
  { lng: -104.931703, lat: 39.653044 },
  { lng: -104.940683, lat: 39.653013 },
  { lng: -104.955903, lat: 39.652998 },
  { lng: -104.954952, lat: 39.655797 },
  { lng: -104.953541, lat: 39.655957 },
  { lng: -104.953788, lat: 39.656683 },
  { lng: -104.959485, lat: 39.656691 },
  { lng: -104.959449, lat: 39.660344 },
  { lng: -104.959443, lat: 39.661526 },
  { lng: -104.963149, lat: 39.66156 },
  { lng: -104.963224, lat: 39.66037 },
  { lng: -104.967619, lat: 39.660409 },
  { lng: -104.968785, lat: 39.660417 },
  { lng: -104.968805, lat: 39.665839 },
  { lng: -104.973489, lat: 39.665866 },
  { lng: -104.973486, lat: 39.667684 },
  { lng: -104.974586, lat: 39.667687 },
  { lng: -104.978292, lat: 39.667698 },
  { lng: -104.982835, lat: 39.667696 },
  { lng: -104.987572, lat: 39.667682 },
  { lng: -104.991132, lat: 39.667674 },
  { lng: -104.999906, lat: 39.667884 },
  { lng: -105.004374, lat: 39.666306 },
  { lng: -105.004862, lat: 39.664463 },
  { lng: -105.004882, lat: 39.660455 },
  { lng: -105.010665, lat: 39.660507 },
  { lng: -105.007717, lat: 39.66422 },
  { lng: -105.006471, lat: 39.666913 },
  { lng: -105.006576, lat: 39.676778 },
  { lng: -105.008637, lat: 39.67678 },
  { lng: -105.008664, lat: 39.678627 },
  { lng: -105.015696, lat: 39.678684 },
  { lng: -105.015699, lat: 39.671795 },
  { lng: -105.015722, lat: 39.666947 },
  { lng: -105.015773, lat: 39.663729 },
  { lng: -105.0158, lat: 39.660508 },
  { lng: -105.022932, lat: 39.660496 },
  { lng: -105.023003, lat: 39.65683 },
  { lng: -105.025269, lat: 39.656817 },
  { lng: -105.032038, lat: 39.657087 },
  { lng: -105.032089, lat: 39.655905 },
  { lng: -105.032157, lat: 39.653775 },
  { lng: -105.032162, lat: 39.653276 },
  { lng: -105.034017, lat: 39.653465 },
  { lng: -105.034171, lat: 39.65329 },
  { lng: -105.034646, lat: 39.646921 },
  { lng: -105.034659, lat: 39.643991 },
  { lng: -105.031238, lat: 39.643987 },
  { lng: -105.029979, lat: 39.642245 },
  { lng: -105.029998, lat: 39.638575 },
  { lng: -105.034707, lat: 39.638593 },
  { lng: -105.034739, lat: 39.635089 },
  { lng: -105.034752, lat: 39.63359 },
  { lng: -105.030057, lat: 39.633492 },
  { lng: -105.029563, lat: 39.632355 },
  { lng: -105.02536, lat: 39.631445 },
  { lng: -105.025369, lat: 39.628598 },
  { lng: -105.027608, lat: 39.62824 },
  { lng: -105.029127, lat: 39.627838 },
  { lng: -105.034821, lat: 39.628098 },
  { lng: -105.034822, lat: 39.627809 },
  { lng: -105.036802, lat: 39.628129 },
  { lng: -105.038005, lat: 39.626836 },
  { lng: -105.041839, lat: 39.627887 },
  { lng: -105.042314, lat: 39.628194 },
  { lng: -105.044048, lat: 39.628016 },
  { lng: -105.044339, lat: 39.631587 },
  { lng: -105.0457, lat: 39.631587 },
  { lng: -105.053411, lat: 39.631586 },
  { lng: -105.053457, lat: 39.629526 },
  { lng: -105.05333, lat: 39.627989 },
  { lng: -105.053287, lat: 39.627265 },
  { lng: -105.053367, lat: 39.627065 },
  { lng: -105.053341, lat: 39.626301 },
  { lng: -105.053439, lat: 39.624387 },
  { lng: -105.053437, lat: 39.620984 },
  { lng: -105.053653, lat: 39.6159 },
  { lng: -105.053641, lat: 39.613976 },
  { lng: -105.053625, lat: 39.609665 },
  { lng: -105.053807, lat: 39.601387 },
  { lng: -105.053836, lat: 39.600885 },
  { lng: -105.05382, lat: 39.600836 },
  { lng: -105.053816, lat: 39.600821 },
  { lng: -105.053703, lat: 39.59522 },
  { lng: -105.053889, lat: 39.587404 },
  { lng: -105.05395, lat: 39.580739 },
  { lng: -105.05404, lat: 39.575768 },
  { lng: -105.053968, lat: 39.574768 },
  { lng: -105.054104, lat: 39.573774 },
  { lng: -105.054176, lat: 39.572183 },
  { lng: -105.054211, lat: 39.570856 },
];

export const adams = [
  { lng: -105.053289, lat: 39.856764 },
  { lng: -105.053339, lat: 39.855978 },
  { lng: -105.052847, lat: 39.853229 },
  { lng: -105.0529, lat: 39.852042 },
  { lng: -105.053188, lat: 39.849649 },
  { lng: -105.05321, lat: 39.845763 },
  { lng: -105.053236, lat: 39.841807 },
  { lng: -105.053259, lat: 39.839969 },
  { lng: -105.053266, lat: 39.834527 },
  { lng: -105.053262, lat: 39.827273 },
  { lng: -105.053271, lat: 39.823666 },
  { lng: -105.053281, lat: 39.821859 },
  { lng: -105.053282, lat: 39.821163 },
  { lng: -105.05327, lat: 39.820028 },
  { lng: -105.05325, lat: 39.81277 },
  { lng: -105.053256, lat: 39.806497 },
  { lng: -105.052839, lat: 39.802535 },
  { lng: -105.052886, lat: 39.800863 },
  { lng: -105.052949, lat: 39.800437 },
  { lng: -105.053102, lat: 39.799788 },
  { lng: -105.053162, lat: 39.799239 },
  { lng: -105.053164, lat: 39.799109 },
  { lng: -105.053246, lat: 39.791063 },
  { lng: -105.034618, lat: 39.791142 },
  { lng: -105.034642, lat: 39.79187 },
  { lng: -105.029982, lat: 39.79188 },
  { lng: -105.030038, lat: 39.791086 },
  { lng: -105.025109, lat: 39.790937 },
  { lng: -105.025149, lat: 39.794003 },
  { lng: -105.021339, lat: 39.793984 },
  { lng: -105.020787, lat: 39.791089 },
  { lng: -105.015832, lat: 39.791072 },
  { lng: -105.006416, lat: 39.791049 },
  { lng: -104.998437, lat: 39.791186 },
  { lng: -104.997789, lat: 39.791097 },
  { lng: -104.996736, lat: 39.791086 },
  { lng: -104.993436, lat: 39.791186 },
  { lng: -104.990809, lat: 39.792139 },
  { lng: -104.988835, lat: 39.791086 },
  { lng: -104.97595, lat: 39.791038 },
  { lng: -104.976057, lat: 39.792301 },
  { lng: -104.973235, lat: 39.79247 },
  { lng: -104.973245, lat: 39.794131 },
  { lng: -104.974838, lat: 39.794226 },
  { lng: -104.974847, lat: 39.79561 },
  { lng: -104.972213, lat: 39.795564 },
  { lng: -104.973243, lat: 39.796302 },
  { lng: -104.973143, lat: 39.798292 },
  { lng: -104.968716, lat: 39.798396 },
  { lng: -104.968765, lat: 39.79384 },
  { lng: -104.966636, lat: 39.793837 },
  { lng: -104.966889, lat: 39.793321 },
  { lng: -104.967504, lat: 39.793322 },
  { lng: -104.968861, lat: 39.790974 },
  { lng: -104.960268, lat: 39.790966 },
  { lng: -104.955356, lat: 39.794686 },
  { lng: -104.949658, lat: 39.794687 },
  { lng: -104.940476, lat: 39.794601 },
  { lng: -104.940484, lat: 39.791919 },
  { lng: -104.939882, lat: 39.790994 },
  { lng: -104.927034, lat: 39.791054 },
  { lng: -104.927002, lat: 39.783778 },
  { lng: -104.922274, lat: 39.783776 },
  { lng: -104.912887, lat: 39.783818 },
  { lng: -104.903438, lat: 39.783808 },
  { lng: -104.903479, lat: 39.786798 },
  { lng: -104.903561, lat: 39.798391 },
  { lng: -104.901405, lat: 39.798383 },
  { lng: -104.900452, lat: 39.798377 },
  { lng: -104.900394, lat: 39.801135 },
  { lng: -104.896933, lat: 39.801159 },
  { lng: -104.896905, lat: 39.798364 },
  { lng: -104.896191, lat: 39.798344 },
  { lng: -104.884627, lat: 39.798321 },
  { lng: -104.884585, lat: 39.812975 },
  { lng: -104.866075, lat: 39.812986 },
  { lng: -104.866041, lat: 39.798386 },
  { lng: -104.847181, lat: 39.798236 },
  { lng: -104.835673, lat: 39.798108 },
  { lng: -104.829131, lat: 39.798059 },
  { lng: -104.827186, lat: 39.798216 },
  { lng: -104.820038, lat: 39.79826 },
  { lng: -104.80969, lat: 39.798317 },
  { lng: -104.790895, lat: 39.798251 },
  { lng: -104.790859, lat: 39.841802 },
  { lng: -104.786472, lat: 39.841793 },
  { lng: -104.772186, lat: 39.841761 },
  { lng: -104.762317, lat: 39.841788 },
  { lng: -104.762279, lat: 39.84539 },
  { lng: -104.753063, lat: 39.845339 },
  { lng: -104.750513, lat: 39.845434 },
  { lng: -104.732138, lat: 39.845086 },
  { lng: -104.73155, lat: 39.898611 },
  { lng: -104.724103, lat: 39.898577 },
  { lng: -104.705568, lat: 39.89849 },
  { lng: -104.705375, lat: 39.906418 },
  { lng: -104.692606, lat: 39.906614 },
  { lng: -104.692934, lat: 39.914183 },
  { lng: -104.680261, lat: 39.914247 },
  { lng: -104.680213, lat: 39.906599 },
  { lng: -104.64037, lat: 39.906241 },
  { lng: -104.633858, lat: 39.906067 },
  { lng: -104.633969, lat: 39.899289 },
  { lng: -104.607003, lat: 39.899413 },
  { lng: -104.600435, lat: 39.899459 },
  { lng: -104.600302, lat: 39.882289 },
  { lng: -104.605853, lat: 39.882245 },
  { lng: -104.605791, lat: 39.873827 },
  { lng: -104.619841, lat: 39.873767 },
  { lng: -104.619906, lat: 39.826633 },
  { lng: -104.619938, lat: 39.822421 },
  { lng: -104.625566, lat: 39.82249 },
  { lng: -104.640346, lat: 39.822553 },
  { lng: -104.655511, lat: 39.82247 },
  { lng: -104.655455, lat: 39.814048 },
  { lng: -104.659123, lat: 39.814033 },
  { lng: -104.666196, lat: 39.814005 },
  { lng: -104.666277, lat: 39.82285 },
  { lng: -104.716084, lat: 39.823207 },
  { lng: -104.753084, lat: 39.823048 },
  { lng: -104.76247, lat: 39.82298 },
  { lng: -104.762901, lat: 39.813038 },
  { lng: -104.762658, lat: 39.805574 },
  { lng: -104.762834, lat: 39.799878 },
  { lng: -104.762606, lat: 39.798433 },
  { lng: -104.734399, lat: 39.798442 },
  { lng: -104.734519, lat: 39.782949 },
  { lng: -104.734534, lat: 39.78173 },
  { lng: -104.734581, lat: 39.774838 },
  { lng: -104.734601, lat: 39.769268 },
  { lng: -104.734601, lat: 39.769211 },
  { lng: -104.734601, lat: 39.769181 },
  { lng: -104.735816, lat: 39.769412 },
  { lng: -104.753351, lat: 39.769487 },
  { lng: -104.760799, lat: 39.769487 },
  { lng: -104.764576, lat: 39.769391 },
  { lng: -104.76456, lat: 39.771598 },
  { lng: -104.772166, lat: 39.771466 },
  { lng: -104.772157, lat: 39.776409 },
  { lng: -104.781818, lat: 39.776485 },
  { lng: -104.781727, lat: 39.773387 },
  { lng: -104.809879, lat: 39.773187 },
  { lng: -104.828229, lat: 39.773174 },
  { lng: -104.828131, lat: 39.772371 },
  { lng: -104.828029, lat: 39.771494 },
  { lng: -104.828323, lat: 39.769156 },
  { lng: -104.833009, lat: 39.769177 },
  { lng: -104.832961, lat: 39.766195 },
  { lng: -104.837588, lat: 39.766873 },
  { lng: -104.856523, lat: 39.768391 },
  { lng: -104.856631, lat: 39.760286 },
  { lng: -104.856431, lat: 39.758387 },
  { lng: -104.85122, lat: 39.756982 },
  { lng: -104.84823, lat: 39.756485 },
  { lng: -104.84694, lat: 39.754687 },
  { lng: -104.85404, lat: 39.75471 },
  { lng: -104.86594, lat: 39.75474 },
  { lng: -104.875192, lat: 39.754738 },
  { lng: -104.877889, lat: 39.754728 },
  { lng: -104.879901, lat: 39.754607 },
  { lng: -104.880138, lat: 39.747428 },
  { lng: -104.884664, lat: 39.747467 },
  { lng: -104.884652, lat: 39.743807 },
  { lng: -104.884646, lat: 39.740156 },
  { lng: -104.875227, lat: 39.740212 },
  { lng: -104.865803, lat: 39.7402 },
  { lng: -104.858778, lat: 39.740207 },
  { lng: -104.856413, lat: 39.740214 },
  { lng: -104.851719, lat: 39.740206 },
  { lng: -104.847091, lat: 39.740207 },
  { lng: -104.847013, lat: 39.740206 },
  { lng: -104.843254, lat: 39.740307 },
  { lng: -104.837603, lat: 39.740302 },
  { lng: -104.82546, lat: 39.74029 },
  { lng: -104.82032, lat: 39.740297 },
  { lng: -104.819416, lat: 39.740266 },
  { lng: -104.810127, lat: 39.740141 },
  { lng: -104.810022, lat: 39.740141 },
  { lng: -104.800625, lat: 39.740281 },
  { lng: -104.791219, lat: 39.740269 },
  { lng: -104.791128, lat: 39.740269 },
  { lng: -104.787422, lat: 39.740266 },
  { lng: -104.780629, lat: 39.74026 },
  { lng: -104.77247, lat: 39.740251 },
  { lng: -104.763038, lat: 39.740251 },
  { lng: -104.745854, lat: 39.74024 },
  { lng: -104.735396, lat: 39.740265 },
  { lng: -104.734969, lat: 39.740302 },
  { lng: -104.734834, lat: 39.740321 },
  { lng: -104.731955, lat: 39.740822 },
  { lng: -104.725637, lat: 39.740267 },
  { lng: -104.715945, lat: 39.740271 },
  { lng: -104.678305, lat: 39.739995 },
  { lng: -104.659953, lat: 39.740477 },
  { lng: -104.652803, lat: 39.739778 },
  { lng: -104.64293, lat: 39.739782 },
  { lng: -104.640723, lat: 39.739886 },
  { lng: -104.635347, lat: 39.739631 },
  { lng: -104.634448, lat: 39.739594 },
  { lng: -104.633384, lat: 39.739547 },
  { lng: -104.632424, lat: 39.739501 },
  { lng: -104.626065, lat: 39.740061 },
  { lng: -104.616507, lat: 39.740007 },
  { lng: -104.616197, lat: 39.740004 },
  { lng: -104.613603, lat: 39.739982 },
  { lng: -104.603078, lat: 39.739887 },
  { lng: -104.602222, lat: 39.739878 },
  { lng: -104.582488, lat: 39.739636 },
  { lng: -104.526958, lat: 39.738669 },
  { lng: -104.470927, lat: 39.738198 },
  { lng: -104.442784, lat: 39.738132 },
  { lng: -104.43242, lat: 39.738116 },
  { lng: -104.420816, lat: 39.738086 },
  { lng: -104.415289, lat: 39.738074 },
  { lng: -104.414664, lat: 39.738073 },
  { lng: -104.414183, lat: 39.73807 },
  { lng: -104.394586, lat: 39.73804 },
  { lng: -104.380113, lat: 39.738108 },
  { lng: -104.37736, lat: 39.73812 },
  { lng: -104.377229, lat: 39.738121 },
  { lng: -104.340209, lat: 39.738247 },
  { lng: -104.30357, lat: 39.738515 },
  { lng: -104.302586, lat: 39.738492 },
  { lng: -104.297964, lat: 39.73868 },
  { lng: -104.283825, lat: 39.738745 },
  { lng: -104.24705, lat: 39.738908 },
  { lng: -104.227935, lat: 39.73898 },
  { lng: -104.22712, lat: 39.738982 },
  { lng: -104.209525, lat: 39.739018 },
  { lng: -104.190677, lat: 39.73918 },
  { lng: -104.096249, lat: 39.739846 },
  { lng: -104.060078, lat: 39.73997 },
  { lng: -103.977636, lat: 39.740267 },
  { lng: -103.974341, lat: 39.739984 },
  { lng: -103.972303, lat: 39.740401 },
  { lng: -103.706547, lat: 39.739894 },
  { lng: -103.706546, lat: 39.769045 },
  { lng: -103.705826, lat: 39.999984 },
  { lng: -103.705701, lat: 40.001366 },
  { lng: -103.729358, lat: 40.001218 },
  { lng: -103.73346, lat: 40.001331 },
  { lng: -103.791626, lat: 40.001308 },
  { lng: -103.861985, lat: 40.001241 },
  { lng: -103.864275, lat: 40.001456 },
  { lng: -103.8661, lat: 40.001233 },
  { lng: -103.980936, lat: 40.001143 },
  { lng: -104.000505, lat: 40.001059 },
  { lng: -104.037848, lat: 40.001054 },
  { lng: -104.066944, lat: 40.001044 },
  { lng: -104.150332, lat: 40.000856 },
  { lng: -104.207951, lat: 40.000832 },
  { lng: -104.37683, lat: 40.000739 },
  { lng: -104.41463, lat: 40.000742 },
  { lng: -104.489757, lat: 40.000613 },
  { lng: -104.60186, lat: 40.000529 },
  { lng: -104.602364, lat: 40.000546 },
  { lng: -104.604064, lat: 40.00056 },
  { lng: -104.604649, lat: 40.000587 },
  { lng: -104.629424, lat: 40.000542 },
  { lng: -104.706544, lat: 40.000506 },
  { lng: -104.713619, lat: 40.000508 },
  { lng: -104.714754, lat: 40.000511 },
  { lng: -104.71621, lat: 40.000511 },
  { lng: -104.726572, lat: 40.000508 },
  { lng: -104.73073, lat: 40.000506 },
  { lng: -104.753728, lat: 40.000471 },
  { lng: -104.75762, lat: 40.000468 },
  { lng: -104.771199, lat: 40.000451 },
  { lng: -104.79705, lat: 40.000454 },
  { lng: -104.816048, lat: 40.000418 },
  { lng: -104.816959, lat: 40.000421 },
  { lng: -104.821478, lat: 40.000412 },
  { lng: -104.821965, lat: 40.000412 },
  { lng: -104.826659, lat: 40.000403 },
  { lng: -104.827414, lat: 40.000403 },
  { lng: -104.867116, lat: 40.00037 },
  { lng: -104.885967, lat: 40.000356 },
  { lng: -104.922153, lat: 40.00033 },
  { lng: -104.923315, lat: 40.000328 },
  { lng: -104.941144, lat: 40.000335 },
  { lng: -104.959306, lat: 40.00033 },
  { lng: -104.961405, lat: 40.000337 },
  { lng: -104.970849, lat: 40.000326 },
  { lng: -104.980944, lat: 40.000325 },
  { lng: -104.981208, lat: 39.999499 },
  { lng: -104.984723, lat: 39.992914 },
  { lng: -104.987052, lat: 39.988529 },
  { lng: -104.987594, lat: 39.987062 },
  { lng: -104.987598, lat: 39.986619 },
  { lng: -104.987762, lat: 39.981534 },
  { lng: -104.987784, lat: 39.975746 },
  { lng: -104.98811, lat: 39.975747 },
  { lng: -104.994411, lat: 39.975803 },
  { lng: -104.99438, lat: 39.973953 },
  { lng: -104.99708, lat: 39.973954 },
  { lng: -104.997065, lat: 39.972149 },
  { lng: -104.988108, lat: 39.972175 },
  { lng: -104.988159, lat: 39.968466 },
  { lng: -104.997118, lat: 39.968528 },
  { lng: -104.997379, lat: 39.975785 },
  { lng: -104.998389, lat: 39.975805 },
  { lng: -104.998404, lat: 39.977586 },
  { lng: -104.999476, lat: 39.978416 },
  { lng: -104.997398, lat: 39.97842 },
  { lng: -104.997423, lat: 39.981213 },
  { lng: -105.015845, lat: 39.981193 },
  { lng: -105.015722, lat: 39.972276 },
  { lng: -105.013409, lat: 39.972265 },
  { lng: -105.013423, lat: 39.970448 },
  { lng: -105.015691, lat: 39.970437 },
  { lng: -105.015653, lat: 39.957724 },
  { lng: -105.01568, lat: 39.956665 },
  { lng: -105.015816, lat: 39.954864 },
  { lng: -105.017117, lat: 39.952814 },
  { lng: -105.015039, lat: 39.951457 },
  { lng: -105.012225, lat: 39.950446 },
  { lng: -105.006298, lat: 39.950439 },
  { lng: -105.006265, lat: 39.946805 },
  { lng: -104.997152, lat: 39.946771 },
  { lng: -104.9971, lat: 39.943239 },
  { lng: -105.015657, lat: 39.943131 },
  { lng: -105.015657, lat: 39.943093 },
  { lng: -105.015586, lat: 39.937276 },
  { lng: -105.015524, lat: 39.933421 },
  { lng: -105.015491, lat: 39.928544 },
  { lng: -105.015539, lat: 39.925253 },
  { lng: -105.015469, lat: 39.921402 },
  { lng: -105.021591, lat: 39.921367 },
  { lng: -105.021552, lat: 39.919207 },
  { lng: -105.023545, lat: 39.919225 },
  { lng: -105.025003, lat: 39.92125 },
  { lng: -105.034395, lat: 39.921305 },
  { lng: -105.034392, lat: 39.921155 },
  { lng: -105.034383, lat: 39.917107 },
  { lng: -105.034347, lat: 39.914181 },
  { lng: -105.052892, lat: 39.914224 },
  { lng: -105.052968, lat: 39.907049 },
  { lng: -105.053005, lat: 39.899806 },
  { lng: -105.053036, lat: 39.892187 },
  { lng: -105.053091, lat: 39.885356 },
  { lng: -105.05323, lat: 39.869395 },
  { lng: -105.053191, lat: 39.86362 },
  { lng: -105.053284, lat: 39.860436 },
  { lng: -105.053263, lat: 39.859948 },
  { lng: -105.053298, lat: 39.858762 },
  { lng: -105.053228, lat: 39.857111 },
  { lng: -105.053289, lat: 39.856764 },
];

export const denver = [
  { lng: -105.109902, lat: 39.634246 },
  { lng: -105.109883, lat: 39.629897 },
  { lng: -105.109617, lat: 39.627153 },
  { lng: -105.107496, lat: 39.627133 },
  { lng: -105.107503, lat: 39.624218 },
  { lng: -105.098516, lat: 39.624222 },
  { lng: -105.091256, lat: 39.624198 },
  { lng: -105.091164, lat: 39.627081 },
  { lng: -105.088626, lat: 39.627087 },
  { lng: -105.087384, lat: 39.62709 },
  { lng: -105.087391, lat: 39.626046 },
  { lng: -105.085626, lat: 39.626107 },
  { lng: -105.08558, lat: 39.628527 },
  { lng: -105.083789, lat: 39.628527 },
  { lng: -105.083813, lat: 39.627318 },
  { lng: -105.08173, lat: 39.627313 },
  { lng: -105.081811, lat: 39.63015 },
  { lng: -105.083735, lat: 39.630155 },
  { lng: -105.083712, lat: 39.631327 },
  { lng: -105.08178, lat: 39.631325 },
  { lng: -105.08167, lat: 39.631326 },
  { lng: -105.081581, lat: 39.630086 },
  { lng: -105.080181, lat: 39.629419 },
  { lng: -105.076278, lat: 39.624962 },
  { lng: -105.076001, lat: 39.624225 },
  { lng: -105.065915, lat: 39.62084 },
  { lng: -105.065141, lat: 39.618582 },
  { lng: -105.074796, lat: 39.618511 },
  { lng: -105.084297, lat: 39.618584 },
  { lng: -105.091362, lat: 39.618336 },
  { lng: -105.093172, lat: 39.618324 },
  { lng: -105.09696, lat: 39.618362 },
  { lng: -105.096955, lat: 39.617969 },
  { lng: -105.09691, lat: 39.614515 },
  { lng: -105.091405, lat: 39.614455 },
  { lng: -105.064199, lat: 39.614574 },
  { lng: -105.058125, lat: 39.61443 },
  { lng: -105.05808, lat: 39.617503 },
  { lng: -105.056252, lat: 39.620082 },
  { lng: -105.056995, lat: 39.621072 },
  { lng: -105.053437, lat: 39.620984 },
  { lng: -105.053439, lat: 39.624387 },
  { lng: -105.05584, lat: 39.624193 },
  { lng: -105.055836, lat: 39.625994 },
  { lng: -105.05333, lat: 39.627989 },
  { lng: -105.053457, lat: 39.629526 },
  { lng: -105.057009, lat: 39.631679 },
  { lng: -105.059541, lat: 39.635375 },
  { lng: -105.059138, lat: 39.638243 },
  { lng: -105.059045, lat: 39.63876 },
  { lng: -105.053342, lat: 39.63877 },
  { lng: -105.053294, lat: 39.634902 },
  { lng: -105.053411, lat: 39.631586 },
  { lng: -105.0457, lat: 39.631587 },
  { lng: -105.044339, lat: 39.631587 },
  { lng: -105.044048, lat: 39.628016 },
  { lng: -105.042314, lat: 39.628194 },
  { lng: -105.041839, lat: 39.627887 },
  { lng: -105.038005, lat: 39.626836 },
  { lng: -105.036802, lat: 39.628129 },
  { lng: -105.034822, lat: 39.627809 },
  { lng: -105.034821, lat: 39.628098 },
  { lng: -105.029127, lat: 39.627838 },
  { lng: -105.027608, lat: 39.62824 },
  { lng: -105.025369, lat: 39.628598 },
  { lng: -105.02536, lat: 39.631445 },
  { lng: -105.029563, lat: 39.632355 },
  { lng: -105.030057, lat: 39.633492 },
  { lng: -105.034752, lat: 39.63359 },
  { lng: -105.034739, lat: 39.635089 },
  { lng: -105.034707, lat: 39.638593 },
  { lng: -105.029998, lat: 39.638575 },
  { lng: -105.029979, lat: 39.642245 },
  { lng: -105.031238, lat: 39.643987 },
  { lng: -105.034659, lat: 39.643991 },
  { lng: -105.034646, lat: 39.646921 },
  { lng: -105.034171, lat: 39.65329 },
  { lng: -105.034017, lat: 39.653465 },
  { lng: -105.032162, lat: 39.653276 },
  { lng: -105.032157, lat: 39.653775 },
  { lng: -105.032089, lat: 39.655905 },
  { lng: -105.032038, lat: 39.657087 },
  { lng: -105.025269, lat: 39.656817 },
  { lng: -105.023003, lat: 39.65683 },
  { lng: -105.022932, lat: 39.660496 },
  { lng: -105.0158, lat: 39.660508 },
  { lng: -105.015773, lat: 39.663729 },
  { lng: -105.015722, lat: 39.666947 },
  { lng: -105.015699, lat: 39.671795 },
  { lng: -105.015696, lat: 39.678684 },
  { lng: -105.008664, lat: 39.678627 },
  { lng: -105.008637, lat: 39.67678 },
  { lng: -105.006576, lat: 39.676778 },
  { lng: -105.006471, lat: 39.666913 },
  { lng: -105.007717, lat: 39.66422 },
  { lng: -105.010665, lat: 39.660507 },
  { lng: -105.004882, lat: 39.660455 },
  { lng: -105.004862, lat: 39.664463 },
  { lng: -105.004374, lat: 39.666306 },
  { lng: -104.999906, lat: 39.667884 },
  { lng: -104.991132, lat: 39.667674 },
  { lng: -104.987572, lat: 39.667682 },
  { lng: -104.982835, lat: 39.667696 },
  { lng: -104.978292, lat: 39.667698 },
  { lng: -104.974586, lat: 39.667687 },
  { lng: -104.973486, lat: 39.667684 },
  { lng: -104.973489, lat: 39.665866 },
  { lng: -104.968805, lat: 39.665839 },
  { lng: -104.968785, lat: 39.660417 },
  { lng: -104.967619, lat: 39.660409 },
  { lng: -104.963224, lat: 39.66037 },
  { lng: -104.963149, lat: 39.66156 },
  { lng: -104.959443, lat: 39.661526 },
  { lng: -104.959449, lat: 39.660344 },
  { lng: -104.959485, lat: 39.656691 },
  { lng: -104.953788, lat: 39.656683 },
  { lng: -104.953541, lat: 39.655957 },
  { lng: -104.954952, lat: 39.655797 },
  { lng: -104.955903, lat: 39.652998 },
  { lng: -104.940683, lat: 39.653013 },
  { lng: -104.931703, lat: 39.653044 },
  { lng: -104.931873, lat: 39.645826 },
  { lng: -104.92246, lat: 39.645849 },
  { lng: -104.914264, lat: 39.639428 },
  { lng: -104.913257, lat: 39.638691 },
  { lng: -104.913407, lat: 39.624083 },
  { lng: -104.905551, lat: 39.624001 },
  { lng: -104.904024, lat: 39.623827 },
  { lng: -104.901658, lat: 39.623722 },
  { lng: -104.894529, lat: 39.62403 },
  { lng: -104.889668, lat: 39.624051 },
  { lng: -104.887799, lat: 39.624007 },
  { lng: -104.885265, lat: 39.624083 },
  { lng: -104.885231, lat: 39.631345 },
  { lng: -104.885112, lat: 39.639262 },
  { lng: -104.880329, lat: 39.641491 },
  { lng: -104.880368, lat: 39.642193 },
  { lng: -104.880313, lat: 39.645889 },
  { lng: -104.880313, lat: 39.645902 },
  { lng: -104.88014, lat: 39.653057 },
  { lng: -104.877497, lat: 39.653052 },
  { lng: -104.876306, lat: 39.652295 },
  { lng: -104.876155, lat: 39.645907 },
  { lng: -104.876224, lat: 39.644301 },
  { lng: -104.857203, lat: 39.653246 },
  { lng: -104.856038, lat: 39.654178 },
  { lng: -104.852944, lat: 39.655706 },
  { lng: -104.847119, lat: 39.65678 },
  { lng: -104.84773, lat: 39.657101 },
  { lng: -104.848336, lat: 39.657891 },
  { lng: -104.848332, lat: 39.659161 },
  { lng: -104.853716, lat: 39.660072 },
  { lng: -104.855437, lat: 39.660804 },
  { lng: -104.856441, lat: 39.66034 },
  { lng: -104.858978, lat: 39.66034 },
  { lng: -104.861022, lat: 39.660281 },
  { lng: -104.866334, lat: 39.660309 },
  { lng: -104.866174, lat: 39.667515 },
  { lng: -104.866051, lat: 39.670847 },
  { lng: -104.868216, lat: 39.672621 },
  { lng: -104.870882, lat: 39.674764 },
  { lng: -104.871874, lat: 39.674744 },
  { lng: -104.875291, lat: 39.674766 },
  { lng: -104.87531, lat: 39.672784 },
  { lng: -104.878233, lat: 39.672787 },
  { lng: -104.875832, lat: 39.669787 },
  { lng: -104.876233, lat: 39.663987 },
  { lng: -104.879277, lat: 39.663891 },
  { lng: -104.884133, lat: 39.667487 },
  { lng: -104.890133, lat: 39.667587 },
  { lng: -104.892269, lat: 39.668189 },
  { lng: -104.888033, lat: 39.665787 },
  { lng: -104.890188, lat: 39.664393 },
  { lng: -104.890764, lat: 39.664956 },
  { lng: -104.890824, lat: 39.664903 },
  { lng: -104.893941, lat: 39.667589 },
  { lng: -104.898817, lat: 39.667626 },
  { lng: -104.903509, lat: 39.667624 },
  { lng: -104.903522, lat: 39.671225 },
  { lng: -104.90352, lat: 39.674456 },
  { lng: -104.903579, lat: 39.676675 },
  { lng: -104.905631, lat: 39.678314 },
  { lng: -104.906253, lat: 39.678442 },
  { lng: -104.908094, lat: 39.679726 },
  { lng: -104.908096, lat: 39.680306 },
  { lng: -104.903888, lat: 39.680332 },
  { lng: -104.903915, lat: 39.683877 },
  { lng: -104.904996, lat: 39.685906 },
  { lng: -104.903443, lat: 39.684857 },
  { lng: -104.90344, lat: 39.678598 },
  { lng: -104.898317, lat: 39.678473 },
  { lng: -104.897446, lat: 39.678505 },
  { lng: -104.894528, lat: 39.678455 },
  { lng: -104.894046, lat: 39.682061 },
  { lng: -104.889308, lat: 39.682056 },
  { lng: -104.889314, lat: 39.682727 },
  { lng: -104.889328, lat: 39.685684 },
  { lng: -104.894058, lat: 39.685694 },
  { lng: -104.894061, lat: 39.686428 },
  { lng: -104.894092, lat: 39.692874 },
  { lng: -104.894201, lat: 39.692994 },
  { lng: -104.898761, lat: 39.692992 },
  { lng: -104.898766, lat: 39.691545 },
  { lng: -104.898766, lat: 39.691352 },
  { lng: -104.898128, lat: 39.690697 },
  { lng: -104.898784, lat: 39.690702 },
  { lng: -104.89881, lat: 39.689341 },
  { lng: -104.903512, lat: 39.689354 },
  { lng: -104.90351, lat: 39.692581 },
  { lng: -104.900115, lat: 39.692985 },
  { lng: -104.903507, lat: 39.696622 },
  { lng: -104.903544, lat: 39.698535 },
  { lng: -104.899285, lat: 39.696976 },
  { lng: -104.898806, lat: 39.695091 },
  { lng: -104.897402, lat: 39.69627 },
  { lng: -104.894225, lat: 39.696597 },
  { lng: -104.893402, lat: 39.695695 },
  { lng: -104.893324, lat: 39.696595 },
  { lng: -104.891715, lat: 39.69659 },
  { lng: -104.885773, lat: 39.696523 },
  { lng: -104.884057, lat: 39.696491 },
  { lng: -104.882313, lat: 39.696573 },
  { lng: -104.877611, lat: 39.696506 },
  { lng: -104.877698, lat: 39.697988 },
  { lng: -104.875316, lat: 39.697996 },
  { lng: -104.875309, lat: 39.696505 },
  { lng: -104.874196, lat: 39.696472 },
  { lng: -104.865962, lat: 39.696559 },
  { lng: -104.865964, lat: 39.699086 },
  { lng: -104.870662, lat: 39.698475 },
  { lng: -104.870648, lat: 39.700535 },
  { lng: -104.868932, lat: 39.702987 },
  { lng: -104.865967, lat: 39.704318 },
  { lng: -104.865971, lat: 39.711053 },
  { lng: -104.870653, lat: 39.710169 },
  { lng: -104.870831, lat: 39.718176 },
  { lng: -104.87432, lat: 39.718201 },
  { lng: -104.874947, lat: 39.71845 },
  { lng: -104.87534, lat: 39.719711 },
  { lng: -104.87534, lat: 39.720134 },
  { lng: -104.87533, lat: 39.725576 },
  { lng: -104.879735, lat: 39.725623 },
  { lng: -104.881934, lat: 39.72496 },
  { lng: -104.886613, lat: 39.729219 },
  { lng: -104.884933, lat: 39.730442 },
  { lng: -104.884708, lat: 39.732846 },
  { lng: -104.88468, lat: 39.735625 },
  { lng: -104.884659, lat: 39.737274 },
  { lng: -104.884646, lat: 39.740156 },
  { lng: -104.884652, lat: 39.743807 },
  { lng: -104.884664, lat: 39.747467 },
  { lng: -104.880138, lat: 39.747428 },
  { lng: -104.879901, lat: 39.754607 },
  { lng: -104.877889, lat: 39.754728 },
  { lng: -104.875192, lat: 39.754738 },
  { lng: -104.86594, lat: 39.75474 },
  { lng: -104.85404, lat: 39.75471 },
  { lng: -104.84694, lat: 39.754687 },
  { lng: -104.84823, lat: 39.756485 },
  { lng: -104.85122, lat: 39.756982 },
  { lng: -104.856431, lat: 39.758387 },
  { lng: -104.856631, lat: 39.760286 },
  { lng: -104.856523, lat: 39.768391 },
  { lng: -104.837588, lat: 39.766873 },
  { lng: -104.832961, lat: 39.766195 },
  { lng: -104.833009, lat: 39.769177 },
  { lng: -104.828323, lat: 39.769156 },
  { lng: -104.828029, lat: 39.771494 },
  { lng: -104.828131, lat: 39.772371 },
  { lng: -104.828229, lat: 39.773174 },
  { lng: -104.809879, lat: 39.773187 },
  { lng: -104.781727, lat: 39.773387 },
  { lng: -104.781818, lat: 39.776485 },
  { lng: -104.772157, lat: 39.776409 },
  { lng: -104.772166, lat: 39.771466 },
  { lng: -104.76456, lat: 39.771598 },
  { lng: -104.764576, lat: 39.769391 },
  { lng: -104.760799, lat: 39.769487 },
  { lng: -104.753351, lat: 39.769487 },
  { lng: -104.735816, lat: 39.769412 },
  { lng: -104.734601, lat: 39.769181 },
  { lng: -104.734601, lat: 39.769211 },
  { lng: -104.734601, lat: 39.769268 },
  { lng: -104.734581, lat: 39.774838 },
  { lng: -104.734534, lat: 39.78173 },
  { lng: -104.734519, lat: 39.782949 },
  { lng: -104.734399, lat: 39.798442 },
  { lng: -104.762606, lat: 39.798433 },
  { lng: -104.762834, lat: 39.799878 },
  { lng: -104.762658, lat: 39.805574 },
  { lng: -104.762901, lat: 39.813038 },
  { lng: -104.76247, lat: 39.82298 },
  { lng: -104.753084, lat: 39.823048 },
  { lng: -104.716084, lat: 39.823207 },
  { lng: -104.666277, lat: 39.82285 },
  { lng: -104.666196, lat: 39.814005 },
  { lng: -104.659123, lat: 39.814033 },
  { lng: -104.655455, lat: 39.814048 },
  { lng: -104.655511, lat: 39.82247 },
  { lng: -104.640346, lat: 39.822553 },
  { lng: -104.625566, lat: 39.82249 },
  { lng: -104.619938, lat: 39.822421 },
  { lng: -104.619906, lat: 39.826633 },
  { lng: -104.619841, lat: 39.873767 },
  { lng: -104.605791, lat: 39.873827 },
  { lng: -104.605853, lat: 39.882245 },
  { lng: -104.600302, lat: 39.882289 },
  { lng: -104.600435, lat: 39.899459 },
  { lng: -104.607003, lat: 39.899413 },
  { lng: -104.633969, lat: 39.899289 },
  { lng: -104.633858, lat: 39.906067 },
  { lng: -104.64037, lat: 39.906241 },
  { lng: -104.680213, lat: 39.906599 },
  { lng: -104.680261, lat: 39.914247 },
  { lng: -104.692934, lat: 39.914183 },
  { lng: -104.692606, lat: 39.906614 },
  { lng: -104.705375, lat: 39.906418 },
  { lng: -104.705568, lat: 39.89849 },
  { lng: -104.724103, lat: 39.898577 },
  { lng: -104.73155, lat: 39.898611 },
  { lng: -104.732138, lat: 39.845086 },
  { lng: -104.750513, lat: 39.845434 },
  { lng: -104.753063, lat: 39.845339 },
  { lng: -104.762279, lat: 39.84539 },
  { lng: -104.762317, lat: 39.841788 },
  { lng: -104.772186, lat: 39.841761 },
  { lng: -104.786472, lat: 39.841793 },
  { lng: -104.790859, lat: 39.841802 },
  { lng: -104.790895, lat: 39.798251 },
  { lng: -104.80969, lat: 39.798317 },
  { lng: -104.820038, lat: 39.79826 },
  { lng: -104.827186, lat: 39.798216 },
  { lng: -104.829131, lat: 39.798059 },
  { lng: -104.835673, lat: 39.798108 },
  { lng: -104.847181, lat: 39.798236 },
  { lng: -104.866041, lat: 39.798386 },
  { lng: -104.866075, lat: 39.812986 },
  { lng: -104.884585, lat: 39.812975 },
  { lng: -104.884627, lat: 39.798321 },
  { lng: -104.896191, lat: 39.798344 },
  { lng: -104.896905, lat: 39.798364 },
  { lng: -104.896933, lat: 39.801159 },
  { lng: -104.900394, lat: 39.801135 },
  { lng: -104.900452, lat: 39.798377 },
  { lng: -104.901405, lat: 39.798383 },
  { lng: -104.903561, lat: 39.798391 },
  { lng: -104.903479, lat: 39.786798 },
  { lng: -104.903438, lat: 39.783808 },
  { lng: -104.912887, lat: 39.783818 },
  { lng: -104.922274, lat: 39.783776 },
  { lng: -104.927002, lat: 39.783778 },
  { lng: -104.927034, lat: 39.791054 },
  { lng: -104.939882, lat: 39.790994 },
  { lng: -104.940484, lat: 39.791919 },
  { lng: -104.940476, lat: 39.794601 },
  { lng: -104.949658, lat: 39.794687 },
  { lng: -104.955356, lat: 39.794686 },
  { lng: -104.960268, lat: 39.790966 },
  { lng: -104.968861, lat: 39.790974 },
  { lng: -104.967504, lat: 39.793322 },
  { lng: -104.966889, lat: 39.793321 },
  { lng: -104.966636, lat: 39.793837 },
  { lng: -104.968765, lat: 39.79384 },
  { lng: -104.968716, lat: 39.798396 },
  { lng: -104.973143, lat: 39.798292 },
  { lng: -104.973243, lat: 39.796302 },
  { lng: -104.972213, lat: 39.795564 },
  { lng: -104.974847, lat: 39.79561 },
  { lng: -104.974838, lat: 39.794226 },
  { lng: -104.973245, lat: 39.794131 },
  { lng: -104.973235, lat: 39.79247 },
  { lng: -104.976057, lat: 39.792301 },
  { lng: -104.97595, lat: 39.791038 },
  { lng: -104.988835, lat: 39.791086 },
  { lng: -104.990809, lat: 39.792139 },
  { lng: -104.993436, lat: 39.791186 },
  { lng: -104.996736, lat: 39.791086 },
  { lng: -104.997789, lat: 39.791097 },
  { lng: -104.998437, lat: 39.791186 },
  { lng: -105.006416, lat: 39.791049 },
  { lng: -105.015832, lat: 39.791072 },
  { lng: -105.020787, lat: 39.791089 },
  { lng: -105.021339, lat: 39.793984 },
  { lng: -105.025149, lat: 39.794003 },
  { lng: -105.025109, lat: 39.790937 },
  { lng: -105.030038, lat: 39.791086 },
  { lng: -105.029982, lat: 39.79188 },
  { lng: -105.034642, lat: 39.79187 },
  { lng: -105.034618, lat: 39.791142 },
  { lng: -105.053246, lat: 39.791063 },
  { lng: -105.06148, lat: 39.791075 },
  { lng: -105.063298, lat: 39.790081 },
  { lng: -105.064541, lat: 39.79108 },
  { lng: -105.065091, lat: 39.78836 },
  { lng: -105.062944, lat: 39.788087 },
  { lng: -105.062659, lat: 39.783847 },
  { lng: -105.053183, lat: 39.783797 },
  { lng: -105.053184, lat: 39.783533 },
  { lng: -105.053245, lat: 39.776583 },
  { lng: -105.053241, lat: 39.772938 },
  { lng: -105.05324, lat: 39.769333 },
  { lng: -105.053243, lat: 39.764814 },
  { lng: -105.053241, lat: 39.763903 },
  { lng: -105.053244, lat: 39.762084 },
  { lng: -105.053239, lat: 39.758472 },
  { lng: -105.053236, lat: 39.754848 },
  { lng: -105.053227, lat: 39.751249 },
  { lng: -105.053219, lat: 39.747616 },
  { lng: -105.053212, lat: 39.74398 },
  { lng: -105.05321, lat: 39.743273 },
  { lng: -105.053206, lat: 39.740411 },
  { lng: -105.053228, lat: 39.734965 },
  { lng: -105.055567, lat: 39.734977 },
  { lng: -105.055591, lat: 39.734508 },
  { lng: -105.055588, lat: 39.733105 },
  { lng: -105.053227, lat: 39.73311 },
  { lng: -105.053253, lat: 39.725828 },
  { lng: -105.053253, lat: 39.7258 },
  { lng: -105.053271, lat: 39.720414 },
  { lng: -105.053278, lat: 39.718427 },
  { lng: -105.053271, lat: 39.715004 },
  { lng: -105.05325, lat: 39.711343 },
  { lng: -105.053246, lat: 39.705814 },
  { lng: -105.053247, lat: 39.703985 },
  { lng: -105.053245, lat: 39.703268 },
  { lng: -105.053225, lat: 39.696889 },
  { lng: -105.053225, lat: 39.696769 },
  { lng: -105.053194, lat: 39.691364 },
  { lng: -105.053266, lat: 39.689559 },
  { lng: -105.053183, lat: 39.683498 },
  { lng: -105.053221, lat: 39.682291 },
  { lng: -105.057265, lat: 39.682272 },
  { lng: -105.057797, lat: 39.682272 },
  { lng: -105.057794, lat: 39.678697 },
  { lng: -105.060335, lat: 39.677954 },
  { lng: -105.061005, lat: 39.678041 },
  { lng: -105.06262, lat: 39.67928 },
  { lng: -105.062622, lat: 39.675008 },
  { lng: -105.053251, lat: 39.675037 },
  { lng: -105.053234, lat: 39.674299 },
  { lng: -105.053261, lat: 39.67328 },
  { lng: -105.053256, lat: 39.667785 },
  { lng: -105.06011, lat: 39.66778 },
  { lng: -105.067297, lat: 39.667775 },
  { lng: -105.071991, lat: 39.667763 },
  { lng: -105.071997, lat: 39.669344 },
  { lng: -105.074504, lat: 39.67025 },
  { lng: -105.07667, lat: 39.669061 },
  { lng: -105.081348, lat: 39.670133 },
  { lng: -105.081451, lat: 39.667743 },
  { lng: -105.081455, lat: 39.665551 },
  { lng: -105.081453, lat: 39.662056 },
  { lng: -105.079163, lat: 39.662041 },
  { lng: -105.076676, lat: 39.659312 },
  { lng: -105.071972, lat: 39.659951 },
  { lng: -105.070016, lat: 39.659071 },
  { lng: -105.070144, lat: 39.656874 },
  { lng: -105.067288, lat: 39.656879 },
  { lng: -105.067283, lat: 39.653503 },
  { lng: -105.067026, lat: 39.653327 },
  { lng: -105.07094, lat: 39.650486 },
  { lng: -105.071029, lat: 39.649579 },
  { lng: -105.063926, lat: 39.64962 },
  { lng: -105.062053, lat: 39.651264 },
  { lng: -105.058227, lat: 39.651279 },
  { lng: -105.05715, lat: 39.652946 },
  { lng: -105.053754, lat: 39.651727 },
  { lng: -105.053727, lat: 39.651159 },
  { lng: -105.05703, lat: 39.651203 },
  { lng: -105.057078, lat: 39.649699 },
  { lng: -105.063197, lat: 39.649633 },
  { lng: -105.063223, lat: 39.645992 },
  { lng: -105.072049, lat: 39.645815 },
  { lng: -105.072326, lat: 39.64535 },
  { lng: -105.074454, lat: 39.644104 },
  { lng: -105.076803, lat: 39.638735 },
  { lng: -105.073271, lat: 39.63874 },
  { lng: -105.073307, lat: 39.636563 },
  { lng: -105.079722, lat: 39.636705 },
  { lng: -105.080344, lat: 39.636216 },
  { lng: -105.081565, lat: 39.636166 },
  { lng: -105.081676, lat: 39.638759 },
  { lng: -105.085844, lat: 39.6387 },
  { lng: -105.091023, lat: 39.638718 },
  { lng: -105.091582, lat: 39.635178 },
  { lng: -105.099641, lat: 39.634987 },
  { lng: -105.100841, lat: 39.635187 },
  { lng: -105.109558, lat: 39.635188 },
  { lng: -105.109874, lat: 39.635186 },
  { lng: -105.109902, lat: 39.634246 },
];
