// @flow
function isTouchDevice() {
  return (
    typeof window !== 'undefined' &&
    typeof navigator !== 'undefined' &&
    // works on most browsers
    ('ontouchstart' in window ||
      // works on IE10/11 and Surface
      navigator.maxTouchPoints)
  );
}

export default {
  touch: isTouchDevice(),

  bgYellow: '#ffd72f',
  bgBlue: '#243966',

  textHard: '#1B2952',
  textSoft: '#687491',
  textSofter: '#8c96af',
  textSpecial: '#25BFB7',
  textGrey: '#aaadb7',
};
