// @flow
import * as React from 'react';
import Head from 'next/head';
import styled from 'styled-components';

import { homeTexts } from '../../constants';
import { BREAKPOINTS } from '../../globalStyles';

import HomeHero from './components/HomeHeroNew';
import HomePackage from './components/HomePackage';
import HomePreNeed from './components/HomePreNeed';
import HomeService from './components/HomeService';
import HomeTestimonials from './components/HomeTestimonials';
import HomeDifference from './components/HomeDifference';
import HomeGuides from './components/HomeGuides';
import { Heading, LinkButton } from '../../components/index';

const testimonials = [
  {
    name: 'Selena M.',
    avatar: '/img/brochure/testimonial-sf.jpg',
    quote:
      "I can't say enough about Tulip. Cremations are confusing, particularly when you are consumed by stress and emotion. I contacted a few providers and felt rushed by others. Tulip you brought compassion and care at a stressful time. Your attention to detail didn't go unnoticed. Thank you",
    location: 'San Francisco',
    link: 'yelp',
  },
  {
    name: 'Todd D.',
    avatar: '/img/brochure/testimonial-sanjose.jpg',
    quote:
      "Tulip was (is) such a great company to work with during our time of loss. We live in Ohio, and my father passed in San Jose. We just wanted something simple and affordable. We are having a private ceremony here in Ohio just with family. We found Tulip online and feel really fortunate that all worked out so well. All the folks at Tulip treated us with respect and professionalism. The process was simple and efficient. I can't recommend more highly!!",
    location: 'San Jose',
    link: 'google',
  },
  {
    name: 'Andrea S.',
    avatar: '/img/brochure/testimonial-sacramento.jpg',
    quote:
      'Excellent caring service. I was so impressed with the service and speed and delivery of my Loved one. I have already instructed my family, Tulip when my time comes. My friend that was with me when my husband passed was also impressed and has advised her family Tulip for her also. Thanks so much during this difficult time.',
    location: 'Sacramento',
    link: 'yelp',
  },
];

const Home = () => {
  const [texts] = React.useState(homeTexts);
  return (
    <React.Fragment>
      <Head>
        <link rel={'canonical'} href={'https://www.tulipcremation.com'} />
        <title>
          Online Cremation Services: Dignified, Personal & Reliable | Tulip
          Cremation
        </title>
        <meta
          name="description"
          content="Tulip provides simple, low cost direct cremation services and a dedicated care team that helps you each step of the way. Arrange online or over the phone, no funeral home visit required."
        />
        <meta
          name="ahrefs-site-verification"
          content="8ad320b88748e0071070646c03c132fe29c14225fd85d8c1ec6675b1f48a1905"
        />
      </Head>

      <HomeHero
        heading={texts.heroHeader}
        text={texts.heroText}
        specialText={texts.heroSpecialText}
        heroImageURL={'/img/Hero-Home.png'}
      />

      {/* Below hero can be used when new quote form integrated */}
      {/* <Hero
        title={`Cremation services your${"\n"}family can trust`}
        body={`Tulip provides a simple direct cremation package that${"\n"}you can arrange online or over the phone. No funeral${"\n"}home visit required.`}
        url={`/img/Hero-Home.png`}
        mobileUrl={`/img/Hero-Home-mobile.png`}
      /> */}

      <HomeDifference differences={texts.tulipDifference} />

      <HomePackage content={texts.packageContent} />

      <HomeService />

      <HomePreNeed content={texts.preNeedContent} />

      <HomeTestimonials testimonials={testimonials} />

      {/* Service Area section */}
      <SectionWrapper>
        <ServiceAreaText>
          <Heading>Our service area</Heading>
          <p>
            We are the nation’s largest online provider of cremation services.
            We currently serve California, Washington, Idaho, Minnesota, Nevada,
            Colorado, Wisconsin, Florida and Oregon and are bringing our
            services to more states in the coming months.
          </p>
          <p>
            Tulip believes that Direct Cremation should be dignified, simple and
            transparent.
          </p>
          <p>
            Our dedicated, local care teams are here for you 24 hours a day, 7
            days a week.
          </p>
          <StyledButton
            mt={64}
            to="/locations"
            label="See if we serve your area"
          />
        </ServiceAreaText>
        <MapWrapper>
          <Img src="/img/ServiceAreaMap.png" />
          {/* <ImgComingSoon src="/img/coming-soon.png" /> */}
        </MapWrapper>
      </SectionWrapper>

      <HomeGuides />
    </React.Fragment>
  );
};

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: auto;

  @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
    width: 100%;
  }
`;

const SectionWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 128px 70px;
  background-color: ${({ theme }) => theme.faintBlue};

  @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
    flex-direction: column;
    padding: 64px 16px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
    width: 100%;
  }
`;

// const ImgComingSoon = styled.img`
//   max-width: 258px;
//   height: auto;
//   margin-top: 30px;

//   @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
//     width: 100%;
//   }
// `;

const ServiceAreaText = styled.div`
  margin-right: 20px;

  @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
    margin-right: 0;
  }
`;

const StyledButton = styled(LinkButton)`
  display: block;
  margin-top: 32px;
  font-size: 16px;
  margin-bottom: 0;

  @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
    margin-top: 0;
    margin-bottom: 64px;
  }
`;

export default Home;
