// @flow
import React, { Fragment } from 'react';

import { BulletList, Heading, TextLink } from '../components';

export default {
  tulipDifferenceTextContent: function TulipDifferenceTextContent() {
    return (
      <Fragment>
        <p>
          Few things in life are as hard as losing a loved one. But even during
          a difficult time, someone has to make arrangements and sign paperwork.
          Add to this the financial burden of post-life services and things can
          get stressful quickly during a time that should be spent honoring your
          loved one with family and friends.
        </p>
        <p>
          Common questions the Tulip Cremation Family Care Team hears are:
          <BulletList
            items={[
              'How much should cremation cost in the Bay Area?',
              'If I don’t live in the area, do I need to travel to San Jose to make arrangements?',
              'Can I speak with an expert on the phone or online without visiting a funeral parlor?',
            ]}
          />
        </p>
        <p>
          Asking these kinds of questions is completely normal. What you need
          are answers from a knowledgeable source with none of the sales talk.
          You need a cremation service delivered by a trusted team that won’t
          surprise you with hidden fees.
        </p>
      </Fragment>
    );
  },

  tulipAffordableCremationsTitle:
    'Tulip Offers the Lowest Cost Cremation in San Jose, CA',
  tulipAffordableCremationsText: function TulipAffordableCremationsText() {
    return (
      <Fragment>
        <p>
          If a loved one has passed in San Jose, you shouldn’t have to worry
          about a cremation bill you can’t afford. We help families just like
          yours every day by offering the lowest cost cremation in the Bay Area,
          without sacrificing quality and care.
        </p>
        <p>
          Tulip is not the type of company to quote you a low, all-inclusive
          price only to tack on additional costs later. We{' '}
          <TextLink to="/our-service">specialize in direct cremation</TextLink>,
          so we can tell you exactly how much you need to spend right up front.
          We offer a dignified, low-cost alternative to a traditional funeral
          home that you and your whole family can feel good about as you
          consider how to honor and memorialize a loved one who has passed.
        </p>
      </Fragment>
    );
  },

  packageContent: function PackageContent() {
    return (
      <Fragment>
        <Heading>The Tulip Package</Heading>
        <p>
          Direct cremation takes place in the days immediately following the
          passing and without a funeral service beforehand.
        </p>
        <p>
          <strong>
            Our direct cremation package includes everything you need:
          </strong>
        </p>

        <BulletList
          items={[
            'Services of a licensed funeral director',
            'Collection & care of your loved one',
            'Filing of all necessary paperwork & permits',
            'A dignified private cremation',
            'The ashes returned to you in a simple container',
          ]}
        />
      </Fragment>
    );
  },

  tulipDignifiedServiceTitle: 'We Make Affordable Direct Cremation Easy',
  tulipDignifiedServiceText: function TulipDignifiedServiceText() {
    return (
      <Fragment>
        <p>
          How do you want to make your loved one’s cremation arrangements?
          Whether you use our online quote tool or give us a call is totally up
          to you. Our Family Care Team is here for you on your own terms. We
          know you’re going through a difficult time, so we try to lighten your
          burden by making our cremation service in San Jose as easy to arrange
          as possible.
        </p>
        <p>
          Our streamlined process won’t weigh you down with paperwork. And you
          won’t have to do anything you don’t want to do, and will be able to
          organize a memorial in your own time. You just get all your questions
          expertly and compassionately answered. And, most importantly, your
          loved one’s remains are handled with care at{' '}
          <TextLink to="/pricing">a price you can afford</TextLink>.
        </p>
      </Fragment>
    );
  },

  tulipSanJosesBestTitle:
    'We Offer the Best Direct Cremation Experience in the Bay Area',
  tulipSanJosesBestText: function TulipSanJosesBestText() {
    return (
      <Fragment>
        <p>
          Every day, our Family Care Team patiently gives our families the
          attention and information they need to make the best decisions about
          direct cremation for a loved one who has passed in San Jose. If you’ve
          never arranged a cremation before, we have the right people waiting to
          help you. They will{' '}
          <TextLink to="/faq">
            answer all your questions about cremation
          </TextLink>{' '}
          and walk you through{' '}
          <TextLink to="/our-service">every step of the process</TextLink> so
          you can feel at ease with our cremation service.
        </p>
        <p>
          Our Family Care Team can help you navigate situations that seem
          complicated. For instance, if you don’t live in San Jose, the Bay
          Area, or even California, you may be wondering how to arrange a
          cremation long distance. With Tulip, you can order complete post-life
          services that include pick up of your loved one and delivery of their
          ashes back home to you, no matter where in the country home may be.
        </p>
        <p>
          Our Family Care Team will never try to upsell you products you don’t
          need and will always explain all the options you have available. This
          straightforward approach means that 95% of families who use Tulip{' '}
          <TextLink to="/reviews">would recommend us</TextLink> to a friend in
          need. Let our outstanding team of professionals help you through a
          difficult time, too.
        </p>
      </Fragment>
    );
  },

  rightCremationTitle:
    'Finding the Right Cremation Service Provider in San Jose',
  rightCremationText: function RightCremationText() {
    return (
      <Fragment>
        <p>
          Give your loved one the send-off they deserve. Save your family money
          with the cremation service provider that offers quality care you can
          afford. And, make a hard time less difficult by making cremation
          arrangements the easy way, either online or by phone.
        </p>
        <p>
          Choose <TextLink to="/">Tulip Cremation</TextLink> for all your
          post-life service needs and get caring help, right when you need it
          most. Our Family Care Team is ready to take your call, 24 hours a day.
        </p>
      </Fragment>
    );
  },
};
