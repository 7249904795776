// @flow
import * as React from 'react';
import Head from 'next/head';
import styled from 'styled-components';

import Star from '../../components/icons/Star';

import Location from './Location';

const BREAKPOINT = 800;

type Props = {
  name: string,
  avatar?: string,
  quote: string, // React.ChildrenArray<React.Element<'p'>>,
  location?: string,
  className?: string,
  link?: React.Node,
  hideUrl?: boolean,
  boxShadow?: Boolean,
};

const Avatar = styled('img')`
  display: block;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-right: 10px;
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
`;

const Name = styled('span')`
  display: block;
  font-size: 18px;
  margin-bottom: ${p => (p.isLocationAtTop ? 0 : '30px')};
  color: ${({ theme }) => theme.textHard};
`;

const P = styled.p`
  margin-bottom: 30px;
`;

const Logo = styled.a`
  margin-left: auto;
  ${p =>
    p.hideUrl &&
    `
      pointer-events: none;
    `}
  & > img {
    height: 35px;
    @media (max-width: ${BREAKPOINT}px) {
      height: 25px;
    }
  }
`;

const Rating = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

const structuredJson = (name: string, quote: string): Object => ({
  '@context': 'http://schema.org/',
  '@type': 'Review',
  itemReviewed: {
    '@type': 'Service',
    image: '/img/brochure/photo-holding-flowers.jpg',
    name: 'Tulip Direct Cremation Package',
  },
  reviewRating: {
    '@type': 'Rating',
    ratingValue: '5',
  },
  author: {
    '@type': 'Person',
    name: name,
  },
  reviewBody: quote,
});

const yelpUrl = 'https://www.yelp.com/biz/tulip-cremation-san-francisco';

const googleUrl =
  'https://www.google.com/maps/place/Tulip+Cremation/@37.317805,-122.9952567,8z/data=!4m7!3m6!1s0x808580621cba18f9:0x3fe8d5152eaffec4!8m2!3d37.791917!4d-122.400662!9m1!1b1';

export default function Testimonial({
  hideUrl,
  name,
  avatar,
  quote,
  location,
  className,
  link,
  boxShadow,
}: Props) {
  const Wrapper = styled('div')`
    background: #fff;
    border-radius: 8px;
    padding: 50px 50px;
    text-align: left;
    margin: 0 10px;
    box-sizing: border-box;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    ${boxShadow ? 'box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.11);' : null}
    ${'' /* justify-content: space-between; */};
    @media (max-width: ${BREAKPOINT}px) {
      padding: 15px;
    }
  `;
  return (
    <Wrapper className={className}>
      <Rating>
        {[1, 2, 3, 4, 5].map(index => (
          <Star key={index} color={'#FFD72E'} />
        ))}
      </Rating>
      <P>“{quote}”</P>
      <Details>
        {avatar && <Avatar src={avatar} />}
        <div>
          <Name isLocationAtTop={!!link}>{name}</Name>
          {location && <Location>{location}</Location>}
        </div>
        <Logo
          href={link === 'yelp' ? yelpUrl : googleUrl}
          target="_blank"
          hideUrl={hideUrl}
        >
          <img
            src={
              link === 'yelp'
                ? '/img/yelp-logo.png'
                : link === 'google'
                ? '/img/google-logo.png'
                : '/img/logo-square.png'
            }
          />
        </Logo>
      </Details>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredJson(name, quote)),
          }}
        />
      </Head>
    </Wrapper>
  );
}
