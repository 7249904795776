// @flow
export const sfCounty = [
  // San Francisco
  { lat: 37.70772645289049, lng: -122.53257751464844 },
  { lat: 37.74682893940135, lng: -122.53532409667969 },
  { lat: 37.795678008523424, lng: -122.54219055175781 },

  // Marin
  { lat: 37.819548028632376, lng: -122.56004333496094 },
  { lat: 37.85100126460795, lng: -122.59712219238281 },
  { lat: 37.866180785296685, lng: -122.63969421386719 },
  { lat: 37.88352498087131, lng: -122.67265319824219 },
  { lat: 37.87268533717655, lng: -122.69874572753906 },
  { lat: 37.88027325525864, lng: -122.71934509277344 },
  { lat: 37.903032319353656, lng: -122.74818420410156 },
  { lat: 37.921451170095295, lng: -122.77702331542969 },
  { lat: 37.938782346134424, lng: -122.80311584472656 },
  { lat: 37.97451499202459, lng: -122.82783508300781 },
  { lat: 38.00049145082287, lng: -122.86766052246094 },
  { lat: 38.005902055387054, lng: -122.91435241699219 },
  { lat: 38.00373786146967, lng: -122.94181823730469 },
  { lat: 37.984257241851275, lng: -122.93632507324219 },
  { lat: 37.97451499202459, lng: -122.94593811035156 },
  { lat: 37.97559752809123, lng: -122.99674987792969 },
  { lat: 37.98750437106374, lng: -123.05030822753906 },
  { lat: 38.024295124443995, lng: -123.02970886230469 },
  { lat: 38.070798163726785, lng: -123.01048278808594 },
  { lat: 38.124834313825076, lng: -122.99263000488281 },
  { lat: 38.1680344597114, lng: -122.98439025878906 },
  { lat: 38.21120901834016, lng: -123.01322937011719 },
  { lat: 38.25435798036572, lng: -123.01460266113281 },
  { lat: 38.260288960391925, lng: -123.00464630126953 },
  { lat: 38.279696055403846, lng: -123.02387237548828 },

  // Sonoma,
  { lat: 38.28454701883166, lng: -123.05854797363281 },
  { lat: 38.299636831993, lng: -123.08738708496094 },
  { lat: 38.33411604971082, lng: -123.10661315917969 },
  { lat: 38.352426464461445, lng: -123.09013366699219 },
  { lat: 38.38688056984749, lng: -123.11759948730469 },
  { lat: 38.435303897636786, lng: -123.15193176269531 },
  { lat: 38.46649284538942, lng: -123.20549011230469 },
  { lat: 38.48046972138692, lng: -123.24943542480469 },
  { lat: 38.51271370850396, lng: -123.29612731933594 },
  { lat: 38.543869175876154, lng: -123.34968566894531 },
  { lat: 38.586820096127674, lng: -123.39500427246094 },
  { lat: 38.64047263931151, lng: -123.44718933105469 },
  { lat: 38.687653678261704, lng: -123.48838806152344 },
  { lat: 38.724090458956965, lng: -123.53919982910156 },
  { lat: 38.76050866911151, lng: -123.56391906738281 },
  { lat: 38.7637211488416, lng: -123.53233337402344 },
  { lat: 38.758366935612784, lng: -123.51585388183594 },
  { lat: 38.770145674423965, lng: -123.49662780761719 },
  { lat: 38.76907496033498, lng: -123.39912414550781 },
  { lat: 38.799584013897054, lng: -123.39895248413086 },
  { lat: 38.800119143924285, lng: -123.13545227050781 },
  { lat: 38.82740544644436, lng: -123.13579559326172 },
  { lat: 38.82687052138064, lng: -123.0582046508789 },
  { lat: 38.84666007174492, lng: -123.0582046508789 },
  { lat: 38.84559050700921, lng: -123.0245590209961 },
  { lat: 38.846125291387025, lng: -122.78526306152344 },
  { lat: 38.83863794429496, lng: -122.77976989746094 },
  { lat: 38.832219591146746, lng: -122.77153015136719 },
  { lat: 38.81938114846882, lng: -122.76466369628906 },
  { lat: 38.80868067783546, lng: -122.75917053222656 },
  { lat: 38.79690830348427, lng: -122.75367736816406 },
  { lat: 38.78620445725866, lng: -122.74681091308594 },
  { lat: 38.77978137804918, lng: -122.73582458496094 },
  { lat: 38.76479194327964, lng: -122.72071838378906 },
  { lat: 38.756225137839074, lng: -122.70698547363281 },
  { lat: 38.74658625248268, lng: -122.69874572753906 },
  { lat: 38.73373238087942, lng: -122.69599914550781 },
  { lat: 38.720876195817276, lng: -122.68775939941406 },
  { lat: 38.713375686254714, lng: -122.67677307128906 },
  { lat: 38.71123253895224, lng: -122.66166687011719 },
  { lat: 38.70587438959806, lng: -122.65342712402344 },
  { lat: 38.699444080321705, lng: -122.64518737792969 },
  { lat: 38.6833657775237, lng: -122.63832092285156 },
  { lat: 38.67264490154078, lng: -122.63420104980469 },

  // Napa
  { lat: 38.66942832560808, lng: -122.62458801269531 },
  { lat: 38.70399894245582, lng: -122.46305465698242 },
  { lat: 38.72757241420326, lng: -122.4452018737793 },
  { lat: 38.723822609217414, lng: -122.44382858276367 },
  { lat: 38.72623322072787, lng: -122.4400520324707 },
  { lat: 38.73533924135184, lng: -122.43627548217773 },
  { lat: 38.73641046158445, lng: -122.44142532348633 },
  { lat: 38.76907496033498, lng: -122.41567611694336 },
  { lat: 38.77309005530619, lng: -122.40331649780273 },
  { lat: 38.77710492428489, lng: -122.40880966186523 },
  { lat: 38.786739687742525, lng: -122.41155624389648 },
  { lat: 38.79075378829443, lng: -122.4070930480957 },
  { lat: 38.800119143924285, lng: -122.4015998840332 },

  // Yolo
  { lat: 38.86626144915396, lng: -122.39808082580566 },
  { lat: 38.870003767467196, lng: -122.39138603210449 },
  { lat: 38.87454774627417, lng: -122.39361763000488 },
  { lat: 38.87548323526176, lng: -122.39773750305176 },
  { lat: 38.88069501991765, lng: -122.40254402160645 },
  { lat: 38.88416933066613, lng: -122.40838050842285 },
  { lat: 38.88657452320194, lng: -122.41284370422363 },
  { lat: 38.89151827436085, lng: -122.41147041320801 },
  { lat: 38.893656006136744, lng: -122.41524696350098 },
  { lat: 38.89566007124151, lng: -122.41799354553223 },
  { lat: 38.90020240944661, lng: -122.42245674133301 },
  { lat: 38.902607059102216, lng: -122.42606163024902 },
  { lat: 38.907416114098794, lng: -122.42554664611816 },
  { lat: 38.908618326947995, lng: -122.4191951751709 },
  { lat: 38.91262555606401, lng: -122.41730690002441 },
  { lat: 38.91876953464495, lng: -122.41473197937012 },
  { lat: 38.92290974192712, lng: -122.4107837677002 },
  { lat: 38.9247794337706, lng: -122.40752220153809 },
  { lat: 38.9250465285831, lng: -122.39378929138184 },
  { lat: 38.924111692339956, lng: -122.37936973571777 },
  { lat: 38.92464588598724, lng: -122.36100196838379 },
  { lat: 38.92357749467039, lng: -122.3258113861084 },
  { lat: 38.92491298130255, lng: -121.99965476989746 },
  { lat: 38.92384459400793, lng: -121.83537483215332 },
  { lat: 38.919437326353034, lng: -121.83039665222168 },
  { lat: 38.917433932375175, lng: -121.82198524475098 },
  { lat: 38.91529691645683, lng: -121.81460380554199 },
  { lat: 38.915029784942256, lng: -121.80636405944824 },
  { lat: 38.912224843332794, lng: -121.80481910705566 },
  { lat: 38.91035482067422, lng: -121.80808067321777 },
  { lat: 38.911957700255115, lng: -121.81357383728027 },
  { lat: 38.909553367310004, lng: -121.81408882141113 },
  { lat: 38.90621388088944, lng: -121.8065357208252 },
  { lat: 38.9050116273201, lng: -121.79348945617676 },
  { lat: 38.904076527134684, lng: -121.79160118103027 },
  { lat: 38.90100396838035, lng: -121.79434776306152 },
  { lat: 38.90207269954984, lng: -121.80327415466309 },
  { lat: 38.899534436756454, lng: -121.80344581604004 },
  { lat: 38.89606087747635, lng: -121.80499076843262 },
  { lat: 38.890983831363656, lng: -121.8065357208252 },
  { lat: 38.88684176179066, lng: -121.81271553039551 },
  { lat: 38.8851046929947, lng: -121.81649208068848 },
  { lat: 38.876017794868986, lng: -121.81443214416504 },
  { lat: 38.871206613657094, lng: -121.8068790435791 },
  { lat: 38.86412289309486, lng: -121.80172920227051 },
  { lat: 38.86586047479284, lng: -121.79640769958496 },
  { lat: 38.862251603774766, lng: -121.79228782653809 },
  { lat: 38.86385556906444, lng: -121.7878246307373 },
  { lat: 38.86024659627971, lng: -121.7849063873291 },
  { lat: 38.85690479148655, lng: -121.78524971008301 },
  { lat: 38.85703846669347, lng: -121.78215980529785 },
  { lat: 38.86038026520495, lng: -121.77821159362793 },
  { lat: 38.861583274224905, lng: -121.77031517028809 },
  { lat: 38.86372190667233, lng: -121.76516532897949 },
  { lat: 38.86372190667233, lng: -121.75829887390137 },
  { lat: 38.86866724782818, lng: -121.75675392150879 },
  { lat: 38.86987011663409, lng: -121.75143241882324 },
  { lat: 38.86906820635841, lng: -121.74765586853027 },
  { lat: 38.86465753814036, lng: -121.74628257751465 },
  { lat: 38.86318725459107, lng: -121.74216270446777 },
  { lat: 38.85931090676748, lng: -121.73083305358887 },
  { lat: 38.85316178368054, lng: -121.72911643981934 },
  { lat: 38.85209231669761, lng: -121.72482490539551 },
  { lat: 38.85008702276324, lng: -121.72482490539551 },
  { lat: 38.84741320957975, lng: -121.72928810119629 },
  { lat: 38.84473929589793, lng: -121.72945976257324 },
  { lat: 38.841396762473025, lng: -121.72757148742676 },
  { lat: 38.840193412001014, lng: -121.73066139221191 },
  { lat: 38.836449524839594, lng: -121.73151969909668 },
  { lat: 38.83377519913608, lng: -121.73048973083496 },
  { lat: 38.82922861890253, lng: -121.72619819641113 },
  { lat: 38.82428053541875, lng: -121.72482490539551 },
  { lat: 38.81906461568253, lng: -121.72533988952637 },
  { lat: 38.81411582583427, lng: -121.72482490539551 },
  { lat: 38.80461853634836, lng: -121.7241382598877 },
  { lat: 38.801140338969276, lng: -121.72207832336426 },
  { lat: 38.80234434958348, lng: -121.71692848205566 },
  { lat: 38.800471435393135, lng: -121.71109199523926 },
  { lat: 38.8019430149725, lng: -121.70353889465332 },
  { lat: 38.79792954454049, lng: -121.69821739196777 },
  { lat: 38.793782054297765, lng: -121.69238090515137 },
  { lat: 38.7900357266344, lng: -121.6944408416748 },
  { lat: 38.78548634977544, lng: -121.69272422790527 },
  { lat: 38.77973231036177, lng: -121.68843269348145 },
  { lat: 38.7714349710405, lng: -121.69049263000488 },
  { lat: 38.76822283861667, lng: -121.69392585754395 },
  { lat: 38.763270517772376, lng: -121.68929100036621 },
  { lat: 38.76367207010573, lng: -121.6831111907959 },
  { lat: 38.76072730051818, lng: -121.67778968811035 },
  { lat: 38.7593887287147, lng: -121.66783332824707 },
  { lat: 38.76554595111559, lng: -121.66388511657715 },
  { lat: 38.76929356549474, lng: -121.66062355041504 },
  { lat: 38.76648287316558, lng: -121.65736198425293 },
  { lat: 38.76474286528029, lng: -121.65358543395996 },
  { lat: 38.7660813366507, lng: -121.64517402648926 },
  { lat: 38.76715209566861, lng: -121.63556098937988 },
  { lat: 38.77170264221393, lng: -121.63195610046387 },
  { lat: 38.77839409511453, lng: -121.62903785705566 },
  { lat: 38.78227485014897, lng: -121.63144111633301 },
  { lat: 38.784683488429515, lng: -121.62594795227051 },
  { lat: 38.782676295512644, lng: -121.61822319030762 },
  { lat: 38.77919702727625, lng: -121.60689353942871 },
  { lat: 38.76969508393097, lng: -121.59728050231934 },
  { lat: 38.757246961605375, lng: -121.59436225891113 },
  { lat: 38.750018023091414, lng: -121.59642219543457 },
  { lat: 38.73649526556081, lng: -121.60397529602051 },

  // Sacramento
  { lat: 38.73051855149164, lng: -121.53985977172852 },
  { lat: 38.72837591823979, lng: -121.39257431030273 },
  { lat: 38.71980474264237, lng: -121.29610061645508 },
  { lat: 38.712036226720215, lng: -121.16975784301758 },
  { lat: 38.710160941206645, lng: -121.16117477416992 },
  { lat: 38.70614230660284, lng: -121.15293502807617 },
  { lat: 38.70480271154007, lng: -121.14675521850586 },
  { lat: 38.698372305893294, lng: -121.1436653137207 },
  { lat: 38.69676461413586, lng: -121.14023208618164 },
  { lat: 38.701587580994214, lng: -121.11448287963867 },
  { lat: 38.6895295540336, lng: -121.10452651977539 },
  { lat: 38.652807047773784, lng: -121.09045028686523 },
  { lat: 38.58467315983427, lng: -121.05989456176758 },
  { lat: 38.50760937220559, lng: -121.02624893188477 },
  { lat: 38.298828528689924, lng: -121.02727890014648 },

  // San Joaquin
  { lat: 38.29640356474841, lng: -121.02556228637695 },
  { lat: 38.2400677223917, lng: -121.00461959838867 },
  { lat: 38.233056380025005, lng: -121.01079940795898 },
  { lat: 38.2255049475924, lng: -121.02144241333008 },
  { lat: 38.21525532102998, lng: -121.0224723815918 },
  { lat: 38.209050898250595, lng: -121.01423263549805 },
  { lat: 38.20662293666146, lng: -120.99981307983398 },
  { lat: 38.208241586719446, lng: -120.99397659301758 },
  { lat: 38.089174937729794, lng: -120.9404182434082 },
  { lat: 38.07944663265489, lng: -120.93011856079102 },

  // Stanislaus
  { lat: 38.07890613330849, lng: -120.9261703491211 },
  { lat: 38.004819966413194, lng: -120.85132598876953 },
  { lat: 37.820632846207864, lng: -120.6467056274414 },
  { lat: 37.63380988687156, lng: -120.38578033447266 },

  // Merced
  { lat: 37.627828123247475, lng: -120.37960052490234 },
  { lat: 37.52279705525959, lng: -120.31917572021484 },
  { lat: 37.5132661890097, lng: -120.31557083129883 },
  { lat: 37.50455118891613, lng: -120.31625747680664 },
  { lat: 37.498014271137265, lng: -120.31076431274414 },
  { lat: 37.49229399862877, lng: -120.30458450317383 },
  { lat: 37.489297490553696, lng: -120.29977798461914 },
  { lat: 37.48030724508602, lng: -120.2951431274414 },
  { lat: 37.46831856835604, lng: -120.29102325439453 },
  { lat: 37.45360256419911, lng: -120.28759002685547 },
  { lat: 37.44597090338356, lng: -120.28278350830078 },
  { lat: 37.43806586248877, lng: -120.28226852416992 },
  { lat: 37.432613623580046, lng: -120.2863883972168 },
  { lat: 37.425797766420004, lng: -120.28741836547852 },
  { lat: 37.418708616699824, lng: -120.28226852416992 },
  { lat: 37.33768110483531, lng: -120.22939682006836 },
  { lat: 37.32812664308382, lng: -120.22029876708984 },
  { lat: 37.3101063519868, lng: -120.2072525024414 },
  { lat: 37.29809042443851, lng: -120.19763946533203 },
  { lat: 37.290442925478196, lng: -120.19214630126953 },
  { lat: 37.28552626562424, lng: -120.18321990966797 },
  { lat: 37.28060928450999, lng: -120.17858505249023 },
  { lat: 37.274872400526334, lng: -120.17892837524414 },
  { lat: 37.2702279361121, lng: -120.18004417419434 },
  { lat: 37.26599302767281, lng: -120.17746925354004 },
  { lat: 37.26162125946191, lng: -120.17626762390137 },
  { lat: 37.25847889430797, lng: -120.17437934875488 },
  { lat: 37.24126187205382, lng: -120.16536712646484 },
  { lat: 37.22048689588553, lng: -120.14751434326172 },
  { lat: 37.20462845803212, lng: -120.1241683959961 },
  { lat: 37.19533058280065, lng: -120.0973892211914 },
  { lat: 37.189860709456596, lng: -120.0747299194336 },
  { lat: 37.18767264916781, lng: -120.0527572631836 },
  { lat: 37.18165515647851, lng: -120.04451751708984 },
  { lat: 37.17673139677148, lng: -120.05619049072266 },
  { lat: 37.17235445206048, lng: -120.07061004638672 },
  { lat: 37.17071303242321, lng: -120.0864028930664 },
  { lat: 37.17126017626408, lng: -120.10562896728516 },
  { lat: 37.16797725379289, lng: -120.1248550415039 },
  { lat: 37.17071303242321, lng: -120.13996124267578 },
  { lat: 37.165241376121266, lng: -120.16742706298828 },
  { lat: 37.16359980197957, lng: -120.17704010009766 },
  { lat: 37.16414699732166, lng: -120.20450592041016 },
  { lat: 37.165241376121266, lng: -120.23059844970703 },
  { lat: 37.157033148885176, lng: -120.24707794189453 },
  { lat: 37.15648590204551, lng: -120.26561737060547 },
  { lat: 37.15320233782284, lng: -120.2834701538086 },
  { lat: 37.15210778472644, lng: -120.29651641845703 },
  { lat: 37.130760832708155, lng: -120.3493881225586 },
  { lat: 37.12364384342357, lng: -120.3768539428711 },

  // Fresno
  { lat: 37.04539957307365, lng: -120.5431079864502 },
  { lat: 37.04192392671876, lng: -120.5390739440918 },
  { lat: 37.04000563308476, lng: -120.53770065307617 },
  { lat: 37.03808729098178, lng: -120.53838729858398 },
  { lat: 37.03452452705973, lng: -120.53838729858398 },
  { lat: 37.031509750101606, lng: -120.54113388061523 },
  { lat: 37.02712440634066, lng: -120.54525375366211 },
  { lat: 37.02493163951206, lng: -120.54010391235352 },
  { lat: 37.01999768272064, lng: -120.53838729858398 },
  { lat: 37.01972356461467, lng: -120.53117752075195 },
  { lat: 37.013418575229664, lng: -120.53014755249023 },
  { lat: 37.013418575229664, lng: -120.52190780639648 },
  { lat: 37.011225412818256, lng: -120.51950454711914 },
  { lat: 37.01040296059743, lng: -120.51229476928711 },
  { lat: 37.00574222990682, lng: -120.50851821899414 },
  { lat: 37.00025865146568, lng: -120.50886154174805 },
  { lat: 37.000807027107996, lng: -120.50302505493164 },
  { lat: 36.99779091213136, lng: -120.50302505493164 },
  { lat: 36.996145708081485, lng: -120.5006217956543 },
  { lat: 36.99038721360594, lng: -120.5009651184082 },
  { lat: 36.98380554318093, lng: -120.5006217956543 },
  { lat: 36.979691709995315, lng: -120.49821853637695 },
  { lat: 36.979691709995315, lng: -120.4951286315918 },
  { lat: 36.97557765438231, lng: -120.4951286315918 },
  { lat: 36.97530337609982, lng: -120.49135208129883 },
  { lat: 36.97365768564589, lng: -120.48860549926758 },
  { lat: 36.97256053890645, lng: -120.4844856262207 },
  { lat: 36.97036619797995, lng: -120.4844856262207 },
  { lat: 36.96734887591083, lng: -120.48894882202148 },
  { lat: 36.96268550655567, lng: -120.4844856262207 },
  { lat: 36.95747316748037, lng: -120.48139572143555 },
  { lat: 36.95390662464134, lng: -120.47761917114258 },
  { lat: 36.948419309517995, lng: -120.4789924621582 },
  { lat: 36.94485234270567, lng: -120.47590255737305 },
  { lat: 36.939089966601564, lng: -120.47590255737305 },
  { lat: 36.93744349338668, lng: -120.47006607055664 },
  { lat: 36.93360158421256, lng: -120.46560287475586 },
  { lat: 36.929759481369324, lng: -120.46491622924805 },
  { lat: 36.92591718486453, lng: -120.46113967895508 },
  { lat: 36.9231725687969, lng: -120.45736312866211 },
  { lat: 36.91987889909955, lng: -120.46182632446289 },
  { lat: 36.915487118198456, lng: -120.46148300170898 },
  { lat: 36.914389133455664, lng: -120.45598983764648 },
  { lat: 36.91301663029911, lng: -120.4511833190918 },
  { lat: 36.90889897264547, lng: -120.45392990112305 },
  { lat: 36.90615374405985, lng: -120.45564651489258 },
  { lat: 36.90560468648865, lng: -120.4621696472168 },
  { lat: 36.903957490067235, lng: -120.46319961547852 },
  { lat: 36.90203571596352, lng: -120.46113967895508 },
  { lat: 36.900662990541356, lng: -120.4621696472168 },
  { lat: 36.89681922798584, lng: -120.46422958374023 },
  { lat: 36.894348135540035, lng: -120.46525955200195 },
  { lat: 36.89215154175683, lng: -120.46731948852539 },
  { lat: 36.88720897462658, lng: -120.46594619750977 },
  { lat: 36.88556138113869, lng: -120.46113967895508 },
  { lat: 36.88336453451452, lng: -120.45907974243164 },
  { lat: 36.88116762468361, lng: -120.45461654663086 },
  { lat: 36.8784213985127, lng: -120.4570198059082 },
  { lat: 36.87457651596472, lng: -120.45873641967773 },
  { lat: 36.87292864990701, lng: -120.45461654663086 },
  { lat: 36.87100609457512, lng: -120.45049667358398 },
  { lat: 36.86771017286244, lng: -120.4511833190918 },
  { lat: 36.86331538939142, lng: -120.4570198059082 },
  { lat: 36.86001913590375, lng: -120.45427322387695 },
  { lat: 36.857272149386404, lng: -120.44912338256836 },
  { lat: 36.85617332713372, lng: -120.44088363647461 },
  { lat: 36.8545250641348, lng: -120.43848037719727 },
  { lat: 36.85205260299283, lng: -120.4405403137207 },
  { lat: 36.84985479261975, lng: -120.44088363647461 },
  { lat: 36.850404251136695, lng: -120.43642044067383 },
  { lat: 36.84710744079944, lng: -120.43195724487305 },
  { lat: 36.84381048829962, lng: -120.43024063110352 },
  { lat: 36.840788156959626, lng: -120.42783737182617 },
  { lat: 36.84051339364195, lng: -120.4237174987793 },
  { lat: 36.83749093199486, lng: -120.42062759399414 },
  { lat: 36.836391825417785, lng: -120.41753768920898 },
  { lat: 36.83968909776596, lng: -120.41204452514648 },
  { lat: 36.838040479360544, lng: -120.40895462036133 },
  { lat: 36.83529270304658, lng: -120.40929794311523 },
  { lat: 36.83309441092257, lng: -120.40689468383789 },
  { lat: 36.82952205148266, lng: -120.40449142456055 },
  { lat: 36.82347614010687, lng: -120.40346145629883 },
  { lat: 36.82265166063331, lng: -120.40105819702148 },
  { lat: 36.8229264881115, lng: -120.39350509643555 },
  { lat: 36.82430061069742, lng: -120.38732528686523 },
  { lat: 36.8193536539104, lng: -120.38286209106445 },
  { lat: 36.81742975102225, lng: -120.37874221801758 },
  { lat: 36.814681234435135, lng: -120.37771224975586 },
  { lat: 36.809733655878226, lng: -120.37771224975586 },
  { lat: 36.80863415055414, lng: -120.37393569946289 },
  { lat: 36.81083314541248, lng: -120.36947250366211 },
  { lat: 36.80753462944047, lng: -120.36844253540039 },
  { lat: 36.80368618120182, lng: -120.37256240844727 },
  { lat: 36.80066226475731, lng: -120.37359237670898 },
  { lat: 36.79571378030265, lng: -120.37118911743164 },
  { lat: 36.7896651984804, lng: -120.37221908569336 },
  { lat: 36.78444103896544, lng: -120.36844253540039 },
  { lat: 36.7869156852394, lng: -120.35985946655273 },
  { lat: 36.78554089162006, lng: -120.34955978393555 },
  { lat: 36.7866407284888, lng: -120.34440994262695 },
  { lat: 36.79131485906556, lng: -120.34852981567383 },
  { lat: 36.79681347114355, lng: -120.3526496887207 },
  { lat: 36.79818806249318, lng: -120.34749984741211 },
  { lat: 36.79488900181141, lng: -120.34543991088867 },
  { lat: 36.79186473803389, lng: -120.34269332885742 },
  { lat: 36.792964484130366, lng: -120.33685684204102 },
  { lat: 36.7896651984804, lng: -120.33342361450195 },
  { lat: 36.78554089162006, lng: -120.3358268737793 },
  { lat: 36.7819663127764, lng: -120.33273696899414 },
  { lat: 36.78526592993634, lng: -120.32587051391602 },
  { lat: 36.783616139115125, lng: -120.32243728637695 },
  { lat: 36.77976648907633, lng: -120.32346725463867 },
  { lat: 36.77784159154438, lng: -120.31557083129883 },
  { lat: 36.78086640881892, lng: -120.31076431274414 },
  { lat: 36.778666553548796, lng: -120.30252456665039 },
  { lat: 36.77591664567203, lng: -120.29977798461914 },
  { lat: 36.77261662599958, lng: -120.30527114868164 },
  { lat: 36.76959148317589, lng: -120.30424118041992 },
  { lat: 36.772066608909896, lng: -120.2973747253418 },
  { lat: 36.772066608909896, lng: -120.29325485229492 },
  { lat: 36.7679413549626, lng: -120.29325485229492 },
  { lat: 36.76354083944902, lng: -120.2922248840332 },
  { lat: 36.76299075725449, lng: -120.28879165649414 },
  { lat: 36.773166639143305, lng: -120.28776168823242 },
  { lat: 36.773166639143305, lng: -120.28364181518555 },
  { lat: 36.770966562892795, lng: -120.2812385559082 },
  { lat: 36.765741128771126, lng: -120.28192520141602 },
  { lat: 36.76876642350827, lng: -120.2754020690918 },
  { lat: 36.77344164423544, lng: -120.27162551879883 },
  { lat: 36.77179159888533, lng: -120.26887893676758 },
  { lat: 36.77179159888533, lng: -120.26372909545898 },
  { lat: 36.769866501092196, lng: -120.25960922241211 },
  { lat: 36.77289163306469, lng: -120.25239944458008 },
  { lat: 36.77234161794798, lng: -120.24484634399414 },
  { lat: 36.769866501092196, lng: -120.23660659790039 },
  { lat: 36.78059143036312, lng: -120.23214340209961 },
  { lat: 36.781691338266896, lng: -120.2259635925293 },
  { lat: 36.78581585231718, lng: -120.21978378295898 },
  { lat: 36.78939025159624, lng: -120.21394729614258 },
  { lat: 36.79021508928879, lng: -120.20467758178711 },
  { lat: 36.787465595780745, lng: -120.20296096801758 },
  { lat: 36.782791230385406, lng: -120.20021438598633 },
  { lat: 36.78004147049181, lng: -120.19643783569336 },
  { lat: 36.77949150667427, lng: -120.18854141235352 },
  { lat: 36.78554089162006, lng: -120.18098831176758 },
  { lat: 36.79653854991341, lng: -120.17343521118164 },
  { lat: 36.803411284640816, lng: -120.16794204711914 },
  { lat: 36.798737892125835, lng: -120.15867233276367 },
  { lat: 36.80176188455212, lng: -120.1490592956543 },
  { lat: 36.80588531816476, lng: -120.13704299926758 },
  { lat: 36.81220748512563, lng: -120.12639999389648 },
  { lat: 36.813032077110904, lng: -120.11301040649414 },
  { lat: 36.814406377348355, lng: -120.10099411010742 },
  { lat: 36.82402578815423, lng: -120.08554458618164 },
  { lat: 36.8229264881115, lng: -120.07387161254883 },
  { lat: 36.818803972305695, lng: -120.06769180297852 },
  { lat: 36.8218271722768, lng: -120.05670547485352 },
  { lat: 36.817704597252835, lng: -120.04640579223633 },
  { lat: 36.81550579977397, lng: -120.03507614135742 },
  { lat: 36.81907881360153, lng: -120.02340316772461 },
  { lat: 36.825674708608084, lng: -120.0172233581543 },
  { lat: 36.829247247693154, lng: -120.00417709350586 },
  { lat: 36.829247247693154, lng: -119.99490737915039 },
  { lat: 36.83281961996509, lng: -119.9901008605957 },
  { lat: 36.84023862933714, lng: -119.98529434204102 },
  { lat: 36.83996386404514, lng: -119.97808456420898 },
  { lat: 36.834743135938254, lng: -119.97499465942383 },
  { lat: 36.83419356488146, lng: -119.96435165405273 },
  { lat: 36.83556748512009, lng: -119.95920181274414 },
  { lat: 36.83556748512009, lng: -119.93928909301758 },
  { lat: 36.842986227962314, lng: -119.93379592895508 },
  { lat: 36.84958006188038, lng: -119.92624282836914 },
  { lat: 36.84820639337462, lng: -119.92143630981445 },
  { lat: 36.84545898231956, lng: -119.91697311401367 },
  { lat: 36.85122843150753, lng: -119.90324020385742 },
  { lat: 36.852876765592534, lng: -119.89294052124023 },
  { lat: 36.85837095584161, lng: -119.88744735717773 },
  { lat: 36.8531514844845, lng: -119.86616134643555 },
  { lat: 36.855898619102206, lng: -119.86101150512695 },
  { lat: 36.850953705704526, lng: -119.85414505004883 },
  { lat: 36.859195050315805, lng: -119.8469352722168 },
  { lat: 36.860843212605346, lng: -119.83972549438477 },
  { lat: 36.854250350179356, lng: -119.8359489440918 },
  { lat: 36.84985479261975, lng: -119.82221603393555 },
  { lat: 36.8545250641348, lng: -119.8084831237793 },
  { lat: 36.86825950302249, lng: -119.8029899597168 },
  { lat: 36.876773615407316, lng: -119.79578018188477 },
  { lat: 36.88034376720183, lng: -119.78719711303711 },
  { lat: 36.89544640872654, lng: -119.78925704956055 },
  { lat: 36.90615374405985, lng: -119.77895736694336 },
  { lat: 36.911369593910486, lng: -119.76831436157227 },
  { lat: 36.91713406567477, lng: -119.7535514831543 },
  { lat: 36.93113168318815, lng: -119.75011825561523 },
  { lat: 36.94265720319494, lng: -119.74531173706055 },
  { lat: 36.94540111770124, lng: -119.73501205444336 },
  { lat: 36.95500404022784, lng: -119.73947525024414 },
  { lat: 36.96625163823219, lng: -119.74187850952148 },
  { lat: 36.97448053532116, lng: -119.7374153137207 },
  { lat: 36.98161152649568, lng: -119.72814559936523 },
  { lat: 36.997242514736364, lng: -119.70823287963867 },
  { lat: 37.009580499476755, lng: -119.69175338745117 },
  { lat: 37.01040296059743, lng: -119.67390060424805 },
  { lat: 37.013418575229664, lng: -119.65810775756836 },
  { lat: 37.03562077991692, lng: -119.65570449829102 },
  { lat: 37.04466426211527, lng: -119.64471817016602 },
  { lat: 37.0391834924041, lng: -119.63235855102539 },
  { lat: 37.03288011810079, lng: -119.62995529174805 },
  { lat: 37.02246470117044, lng: -119.63579177856445 },
  { lat: 37.023835232361364, lng: -119.62583541870117 },
  { lat: 37.03178382567962, lng: -119.6165657043457 },
  { lat: 37.04904859299321, lng: -119.61244583129883 },
  { lat: 37.06987070605197, lng: -119.60454940795898 },
  { lat: 37.06959676798217, lng: -119.59527969360352 },
  { lat: 37.06713128082879, lng: -119.58635330200195 },
  { lat: 37.064117798804276, lng: -119.5616340637207 },
  { lat: 37.07425358060113, lng: -119.56369400024414 },
  { lat: 37.08411412214884, lng: -119.55957412719727 },
  { lat: 37.092877971292815, lng: -119.55099105834961 },
  { lat: 37.10273609020525, lng: -119.53760147094727 },
  { lat: 37.11286670905789, lng: -119.54275131225586 },
  { lat: 37.11642579397878, lng: -119.55545425415039 },
  { lat: 37.11943719670526, lng: -119.56918716430664 },
  { lat: 37.12710204494757, lng: -119.56335067749023 },
  { lat: 37.13887155061187, lng: -119.56266403198242 },
  { lat: 37.143524106036125, lng: -119.55270767211914 },
  { lat: 37.138597861969735, lng: -119.54137802124023 },
  { lat: 37.1333975896479, lng: -119.53279495239258 },
  { lat: 37.12792324100665, lng: -119.5231819152832 },
  { lat: 37.140787351735135, lng: -119.5176887512207 },
  { lat: 37.15118652303378, lng: -119.51082229614258 },
  { lat: 37.14708175780485, lng: -119.49777603149414 },
  { lat: 37.140787351735135, lng: -119.49056625366211 },
  { lat: 37.130660469006244, lng: -119.49056625366211 },
  { lat: 37.117520871729276, lng: -119.4898796081543 },
  { lat: 37.11040263754686, lng: -119.47683334350586 },
  { lat: 37.1161520325098, lng: -119.46722030639648 },
  { lat: 37.13148161810025, lng: -119.46687698364258 },
  { lat: 37.14543978932954, lng: -119.46172714233398 },
  { lat: 37.14680809886783, lng: -119.44147109985352 },
  { lat: 37.15830092162213, lng: -119.43391799926758 },
  { lat: 37.16486746459025, lng: -119.42464828491211 },
  { lat: 37.16240507782609, lng: -119.40919876098633 },
  { lat: 37.155838320944746, lng: -119.40061569213867 },
  { lat: 37.151733808230254, lng: -119.39615249633789 },
  { lat: 37.15529106545646, lng: -119.37932968139648 },
  { lat: 37.16514105816771, lng: -119.37108993530273 },
  { lat: 37.16897126426067, lng: -119.3635368347168 },
  { lat: 37.182101925643, lng: -119.35529708862305 },
  { lat: 37.18893990792153, lng: -119.34362411499023 },
  { lat: 37.205621986212414, lng: -119.33126449584961 },
  { lat: 37.217105864316224, lng: -119.33435440063477 },
  { lat: 37.23241498275827, lng: -119.33263778686523 },
  { lat: 37.24717440360124, lng: -119.32233810424805 },
  { lat: 37.25974496388779, lng: -119.32268142700195 },
  { lat: 37.27395263663328, lng: -119.3309211730957 },
  { lat: 37.28925020917685, lng: -119.32714462280273 },
  { lat: 37.300721347938534, lng: -119.32989120483398 },
  { lat: 37.31219073736054, lng: -119.33366775512695 },
  { lat: 37.32065511686764, lng: -119.32680130004883 },
  { lat: 37.33567021853739, lng: -119.32439804077148 },
  { lat: 37.33867287878127, lng: -119.31135177612305 },
  { lat: 37.34904478145799, lng: -119.31581497192383 },
  { lat: 37.352592735062586, lng: -119.30379867553711 },
  { lat: 37.36432707929021, lng: -119.29658889770508 },
  { lat: 37.58521225635587, lng: -119.02596473693848 },
  { lat: 37.58012899509355, lng: -119.01163101196289 },
  { lat: 37.57604762780391, lng: -119.00545120239258 },
  { lat: 37.564618609280735, lng: -118.99343490600586 },
  { lat: 37.557270457012706, lng: -118.97970199584961 },
  { lat: 37.55536526219943, lng: -118.96734237670898 },
  { lat: 37.56244145453326, lng: -118.9570426940918 },
  { lat: 37.56380218370658, lng: -118.9460563659668 },
  { lat: 37.55781478944109, lng: -118.93472671508789 },
  { lat: 37.55318783705394, lng: -118.92820358276367 },
  { lat: 37.54393307044398, lng: -118.91481399536133 },
  { lat: 37.54175531130946, lng: -118.90485763549805 },
  { lat: 37.53930525624585, lng: -118.89970779418945 },
  { lat: 37.53249912532158, lng: -118.8969612121582 },
  { lat: 37.52895969175346, lng: -118.89352798461914 },
  { lat: 37.525964655240294, lng: -118.88734817504883 },
  { lat: 37.516978824211144, lng: -118.88254165649414 },
  { lat: 37.51044299466023, lng: -118.87292861938477 },
  { lat: 37.50663016310586, lng: -118.86777877807617 },
  { lat: 37.49873153536774, lng: -118.86880874633789 },
  { lat: 37.488925179863074, lng: -118.85438919067383 },
  { lat: 37.48047978646371, lng: -118.85370254516602 },
  { lat: 37.471760959689576, lng: -118.84786605834961 },
  { lat: 37.46740116486926, lng: -118.84271621704102 },
  { lat: 37.47121600755851, lng: -118.82658004760742 },
  { lat: 37.47394077005854, lng: -118.81284713745117 },
  { lat: 37.46876363638152, lng: -118.8032341003418 },
  { lat: 37.47339582550503, lng: -118.77851486206055 },
  { lat: 37.47094352584533, lng: -118.76993179321289 },
  { lat: 37.46331363460804, lng: -118.75860214233398 },
  { lat: 37.45186733773126, lng: -118.76100540161133 },
  { lat: 37.44805151612806, lng: -118.7647819519043 },
  { lat: 37.434967221541726, lng: -118.77817153930664 },
  { lat: 37.41997198812445, lng: -118.7757682800293 },
  { lat: 37.403882853443065, lng: -118.78332138061523 },
  { lat: 37.393245765703576, lng: -118.79499435424805 },
  { lat: 37.38178875209504, lng: -118.79533767700195 },
  { lat: 37.3733312619308, lng: -118.78263473510742 },
  { lat: 37.36132544493717, lng: -118.78229141235352 },
  { lat: 37.35886947298069, lng: -118.79087448120117 },
  { lat: 37.353138559196104, lng: -118.7925910949707 },
  { lat: 37.343586063890065, lng: -118.78572463989258 },
  { lat: 37.33621616569182, lng: -118.7757682800293 },
  { lat: 37.32857254444109, lng: -118.76306533813477 },
  { lat: 37.340583600073884, lng: -118.74933242797852 },
  { lat: 37.33785398334649, lng: -118.74212265014648 },
  { lat: 37.33075651558583, lng: -118.73353958129883 },
  { lat: 37.31874388868111, lng: -118.72838973999023 },
  { lat: 37.30946008903583, lng: -118.72392654418945 },
  { lat: 37.30044824591592, lng: -118.71500015258789 },
  { lat: 37.2944397505265, lng: -118.7153434753418 },
  { lat: 37.29034277394582, lng: -118.72049331665039 },
  { lat: 37.2867918804395, lng: -118.73079299926758 },
  { lat: 37.27723094964672, lng: -118.73353958129883 },
  { lat: 37.26357037025048, lng: -118.73800277709961 },
  { lat: 37.25264012231638, lng: -118.73044967651367 },
  { lat: 37.23952173133383, lng: -118.73285293579102 },
  { lat: 37.22804126602871, lng: -118.73285293579102 },
  { lat: 37.21819947583416, lng: -118.7263298034668 },
  { lat: 37.205895432193266, lng: -118.73010635375977 },
  { lat: 37.18675182095229, lng: -118.73319625854492 },
  { lat: 37.17690464505932, lng: -118.7321662902832 },
  { lat: 37.16295228181735, lng: -118.72220993041992 },
  { lat: 37.15337564005344, lng: -118.7098503112793 },
  { lat: 37.13969261894972, lng: -118.69440078735352 },
  { lat: 37.13996629527908, lng: -118.67895126342773 },
  { lat: 37.12545964280612, lng: -118.64187240600586 },
  { lat: 37.1120453580112, lng: -118.61063003540039 },
  { lat: 37.10848606719677, lng: -118.59037399291992 },
  { lat: 37.09890252971379, lng: -118.56874465942383 },
  { lat: 37.08904391198702, lng: -118.55878829956055 },
  { lat: 37.07836229543263, lng: -118.53681564331055 },
  { lat: 37.06439175666265, lng: -118.51964950561523 },
  { lat: 37.061104197062434, lng: -118.49699020385742 },
  { lat: 37.0487745797326, lng: -118.47055435180664 },
  { lat: 37.03644295917403, lng: -118.46437454223633 },
  { lat: 37.00903218711865, lng: -118.45647811889648 },
  { lat: 36.98353129455535, lng: -118.44205856323242 },
  { lat: 36.972011959605744, lng: -118.4245491027832 },
  { lat: 36.95226047161237, lng: -118.4025764465332 },
  { lat: 36.94293159909249, lng: -118.39502334594727 },
  { lat: 36.91384013515677, lng: -118.38335037231445 },
  { lat: 36.89132780279111, lng: -118.37888717651367 },
  { lat: 36.878146770464, lng: -118.3692741394043 },
  { lat: 36.84133768063342, lng: -118.36755752563477 },
  { lat: 36.83227003508886, lng: -118.37854385375977 },
  { lat: 36.81825428675315, lng: -118.39056015014648 },
  { lat: 36.80863415055414, lng: -118.39879989624023 },
  { lat: 36.79516392896187, lng: -118.38678359985352 },
  { lat: 36.7871906410034, lng: -118.38232040405273 },
  { lat: 36.77014151802206, lng: -118.38335037231445 },
  { lat: 36.75473905088298, lng: -118.38335037231445 },
  { lat: 36.74235982696141, lng: -118.38369369506836 },
  { lat: 36.74483583151471, lng: -118.56565475463867 },
  { lat: 36.740984234359885, lng: -118.56565475463867 },
  { lat: 36.74153447435901, lng: -118.98279190063477 },
  { lat: 36.65620020875642, lng: -118.98382186889648 },
  { lat: 36.65978064517359, lng: -119.30482864379883 },
  { lat: 36.575633131613614, lng: -119.30500030517578 },
  { lat: 36.57839020774761, lng: -119.4649887084961 },
  { lat: 36.41997477139967, lng: -119.66548919677734 },
  { lat: 36.42620436286966, lng: -119.67158317565918 },
  { lat: 36.431452843264296, lng: -119.67038154602051 },
  { lat: 36.43062415942375, lng: -119.67587471008301 },
  { lat: 36.429519233875276, lng: -119.68205451965332 },
  { lat: 36.42620436286966, lng: -119.68703269958496 },
  { lat: 36.425513746928424, lng: -119.69527244567871 },
  { lat: 36.42413249661695, lng: -119.70145225524902 },
  { lat: 36.426756851199634, lng: -119.70522880554199 },
  { lat: 36.423718116732, lng: -119.7114086151123 },
  { lat: 36.41929792702685, lng: -119.71261024475098 },
  { lat: 36.41833097698979, lng: -119.72050666809082 },
  { lat: 36.420402998041226, lng: -119.72908973693848 },
  { lat: 36.416673320344856, lng: -119.73475456237793 },
  { lat: 36.4180547033393, lng: -119.73973274230957 },
  { lat: 36.41639704079759, lng: -119.7469425201416 },
  { lat: 36.41183828638001, lng: -119.7524356842041 },
  { lat: 36.40824635203449, lng: -119.75329399108887 },
  { lat: 36.402167315574964, lng: -119.75192070007324 },
  { lat: 36.402844309221564, lng: -119.96074676513672 },
  { lat: 36.177587838949094, lng: -119.9593734741211 },
  { lat: 35.90330830298613, lng: -120.3164291381836 },

  // Montery
  { lat: 35.7944246009799, lng: -120.26115417480469 },
  { lat: 35.78439882519529, lng: -120.23643493652344 },
  { lat: 35.78439882519529, lng: -121.33918762207031 },
  { lat: 35.782170703266075, lng: -121.38175964355469 },
  { lat: 35.836741707973786, lng: -121.43669128417969 },
  { lat: 35.86456960744962, lng: -121.49299621582031 },
  { lat: 35.92686865304137, lng: -121.51222229003906 },
  { lat: 35.97800618085566, lng: -121.53144836425781 },
  { lat: 35.994674303450985, lng: -121.58363342285156 },
  { lat: 36.050209274876195, lng: -121.63032531738281 },
  { lat: 36.113471382052175, lng: -121.67701721191406 },
  { lat: 36.16781389727332, lng: -121.74705505371094 },
  { lat: 36.20328256455562, lng: -121.83082580566406 },
  { lat: 36.24316561280259, lng: -121.88575744628906 },
  { lat: 36.296311367952875, lng: -121.93931579589844 },
  { lat: 36.36048087912437, lng: -121.95167541503906 },
  { lat: 36.42680750026265, lng: -121.95854187011719 },
  { lat: 36.49859745028132, lng: -121.98463439941406 },
  { lat: 36.54163950596125, lng: -121.97364807128906 },
  { lat: 36.58024660149866, lng: -122.00935363769531 },
  { lat: 36.63646810846805, lng: -121.99150085449219 },
  { lat: 36.66731738757093, lng: -121.94343566894531 },
  { lat: 36.64969074141501, lng: -121.88850402832031 },
  { lat: 36.68934501922664, lng: -121.85554504394531 },
  { lat: 36.76419177390199, lng: -121.84181213378906 },
  { lat: 36.81808022778526, lng: -121.83906555175781 },

  // Santa Cruz
  { lat: 36.86094394142341, lng: -121.85829162597656 },
  { lat: 36.89939091854292, lng: -121.87477111816406 },
  { lat: 36.9367208722872, lng: -121.90773010253906 },
  { lat: 36.94769679250732, lng: -121.93794250488281 },
  { lat: 36.9367208722872, lng: -121.96952819824219 },
  { lat: 36.923547681089296, lng: -122.02308654785156 },
  { lat: 36.925743371044966, lng: -122.06840515136719 },
  { lat: 36.93342778791388, lng: -122.12745666503906 },
  { lat: 36.96415770803826, lng: -122.18650817871094 },
  { lat: 36.99816565700228, lng: -122.24830627441406 },
  { lat: 37.045312802603355, lng: -122.28538513183594 },
  { lat: 37.08038005833324, lng: -122.32795715332031 },

  // San Mateo
  { lat: 37.10338413422305, lng: -122.35679626464844 },
  { lat: 37.14170874010794, lng: -122.37739562988281 },
  { lat: 37.17344871200958, lng: -122.41859436035156 },
  { lat: 37.22595454983972, lng: -122.43644714355469 },
  { lat: 37.28388730761434, lng: -122.43644714355469 },
  { lat: 37.34286730373346, lng: -122.43232727050781 },
  { lat: 37.39634613318923, lng: -122.45704650878906 },
  { lat: 37.44978683111485, lng: -122.47352600097656 },
  { lat: 37.48684571271661, lng: -122.51747131347656 },
  { lat: 37.52170787501457, lng: -122.54631042480469 },
  { lat: 37.583765767186236, lng: -122.54219055175781 },
  { lat: 37.63598495426961, lng: -122.52433776855469 },
  { lat: 37.68055980320511, lng: -122.52571105957031 },
];

export const calaverasConty = [
  // Calaveras
  { lng: -121, lat: 38.245 },
  { lng: -120.965, lat: 38.24 },
  { lng: -120.93, lat: 38.25 },
  { lng: -120.9, lat: 38.245 },
  { lng: -120.855, lat: 38.285 },
  { lng: -120.84, lat: 38.285 },
  { lng: -120.825, lat: 38.305 },
  { lng: -120.765, lat: 38.31 },
  { lng: -120.725, lat: 38.335 },
  { lng: -120.705, lat: 38.335 },
  { lng: -120.685, lat: 38.35 },
  { lng: -120.65, lat: 38.355 },
  { lng: -120.635, lat: 38.375 },
  { lng: -120.635, lat: 38.4 },
  { lng: -120.62, lat: 38.415 },
  { lng: -120.59, lat: 38.42 },
  { lng: -120.585, lat: 38.43 },
  { lng: -120.545, lat: 38.445 },
  { lng: -120.525, lat: 38.445 },
  { lng: -120.525, lat: 38.455 },
  { lng: -120.505, lat: 38.47 },
  { lng: -120.46, lat: 38.47 },
  { lng: -120.455, lat: 38.48 },
  { lng: -120.425, lat: 38.495 },
  { lng: -120.41, lat: 38.495 },
  { lng: -120.4, lat: 38.485 },
  { lng: -120.33, lat: 38.485 },
  { lng: -120.31, lat: 38.495 },
  { lng: -120.28, lat: 38.495 },
  { lng: -120.26, lat: 38.51 },
  { lng: -120.245, lat: 38.505 },
  { lng: -120.22, lat: 38.52 },
  { lng: -120.125, lat: 38.515 },
  { lng: -120.115, lat: 38.525 },
  { lng: -120.065, lat: 38.53 },
  { lng: -120.055, lat: 38.52 },
  { lng: -120.055, lat: 38.475 },
  { lng: -120, lat: 38.445 },
  { lng: -120, lat: 38.425 },
  { lng: -120.015, lat: 38.41 },
  { lng: -120.095, lat: 38.38 },
  { lng: -120.135, lat: 38.375 },
  { lng: -120.16, lat: 38.36 },
  { lng: -120.185, lat: 38.32 },
  { lng: -120.215, lat: 38.3 },
  { lng: -120.23, lat: 38.275 },
  { lng: -120.245, lat: 38.27 },
  { lng: -120.26, lat: 38.24 },
  { lng: -120.27, lat: 38.24 },
  { lng: -120.27, lat: 38.225 },
  { lng: -120.29, lat: 38.215 },
  { lng: -120.32, lat: 38.175 },
  { lng: -120.32, lat: 38.165 },
  { lng: -120.34, lat: 38.15 },
  { lng: -120.34, lat: 38.14 },
  { lng: -120.39, lat: 38.09 },
  { lng: -120.415, lat: 38.045 },
  { lng: -120.43, lat: 38.04 },
  { lng: -120.445, lat: 38.015 },
  { lng: -120.445, lat: 38 },
  { lng: -120.455, lat: 37.99 },
  { lng: -120.48, lat: 37.99 },
  { lng: -120.5, lat: 37.97 },
  { lng: -120.495, lat: 37.945 },
  { lng: -120.515, lat: 37.93 },
  { lng: -120.52, lat: 37.915 },
  { lng: -120.535, lat: 37.9 },
  { lng: -120.545, lat: 37.9 },
  { lng: -120.56, lat: 37.875 },
  { lng: -120.58, lat: 37.875 },
  { lng: -120.595, lat: 37.855 },
  { lng: -120.615, lat: 37.85 },
  { lng: -120.64, lat: 37.815 },
  { lng: -120.665, lat: 37.815 },
  { lng: -120.955, lat: 38.075 },
  { lng: -121.015, lat: 38.22 },
  { lng: -121.015, lat: 38.235 },
  { lng: -121, lat: 38.245 },
];

export const santaClaraCounty = [
  { lng: -122.2, lat: 37.35 },
  { lng: -122.2, lat: 37.45 },
  { lng: -121.5, lat: 37.5 },
  { lng: -121.4, lat: 37.4 },
  { lng: -121.4, lat: 37.15 },
  { lng: -121.3, lat: 37.2 },
  { lng: -121.2, lat: 37.15 },
  { lng: -121.2, lat: 37.05 },
  { lng: -121.25, lat: 36.95 },
  { lng: -121.5, lat: 37 },
  { lng: -121.6, lat: 36.9 },
  { lng: -121.8, lat: 37.1 },
  { lng: -122.05, lat: 37.15 },
  { lng: -122.2, lat: 37.35 },
];

export const sacramentoCounty = [
  { lng: -121.865, lat: 38.065 },
  { lng: -121.855, lat: 38.075 },
  { lng: -121.835, lat: 38.075 },
  { lng: -121.83, lat: 38.065 },
  { lng: -121.79, lat: 38.06 },
  { lng: -121.75, lat: 38.085 },
  { lng: -121.735, lat: 38.08 },
  { lng: -121.71, lat: 38.085 },
  { lng: -121.71, lat: 38.105 },
  { lng: -121.69, lat: 38.13 },
  { lng: -121.69, lat: 38.155 },
  { lng: -121.665, lat: 38.18 },
  { lng: -121.61, lat: 38.2 },
  { lng: -121.61, lat: 38.215 },
  { lng: -121.6, lat: 38.22 },
  { lng: -121.605, lat: 38.3 },
  { lng: -121.595, lat: 38.305 },
  { lng: -121.6, lat: 38.31 },
  { lng: -121.59, lat: 38.315 },
  { lng: -121.59, lat: 38.325 },
  { lng: -121.52, lat: 38.36 },
  { lng: -121.525, lat: 38.38 },
  { lng: -121.515, lat: 38.385 },
  { lng: -121.515, lat: 38.41 },
  { lng: -121.525, lat: 38.415 },
  { lng: -121.53, lat: 38.435 },
  { lng: -121.505, lat: 38.435 },
  { lng: -121.5, lat: 38.455 },
  { lng: -121.51, lat: 38.475 },
  { lng: -121.545, lat: 38.475 },
  { lng: -121.56, lat: 38.505 },
  { lng: -121.555, lat: 38.515 },
  { lng: -121.53, lat: 38.515 },
  { lng: -121.53, lat: 38.53 },
  { lng: -121.51, lat: 38.54 },
  { lng: -121.52, lat: 38.57 },
  { lng: -121.505, lat: 38.59 },
  { lng: -121.515, lat: 38.605 },
  { lng: -121.54, lat: 38.6 },
  { lng: -121.56, lat: 38.605 },
  { lng: -121.565, lat: 38.645 },
  { lng: -121.6, lat: 38.645 },
  { lng: -121.605, lat: 38.66 },
  { lng: -121.635, lat: 38.68 },
  { lng: -121.635, lat: 38.695 },
  { lng: -121.61, lat: 38.71 },
  { lng: -121.605, lat: 38.735 },
  { lng: -121.38, lat: 38.73 },
  { lng: -121.135, lat: 38.705 },
  { lng: -121.12, lat: 38.715 },
  { lng: -121.115, lat: 38.71 },
  { lng: -121.025, lat: 38.51 },
  { lng: -121.025, lat: 38.3 },
  { lng: -121.04, lat: 38.3 },
  { lng: -121.045, lat: 38.29 },
  { lng: -121.07, lat: 38.3 },
  { lng: -121.095, lat: 38.28 },
  { lng: -121.1, lat: 38.285 },
  { lng: -121.12, lat: 38.275 },
  { lng: -121.13, lat: 38.28 },
  { lng: -121.135, lat: 38.27 },
  { lng: -121.17, lat: 38.255 },
  { lng: -121.2, lat: 38.255 },
  { lng: -121.215, lat: 38.245 },
  { lng: -121.285, lat: 38.25 },
  { lng: -121.295, lat: 38.235 },
  { lng: -121.33, lat: 38.23 },
  { lng: -121.39, lat: 38.235 },
  { lng: -121.4, lat: 38.225 },
  { lng: -121.42, lat: 38.235 },
  { lng: -121.43, lat: 38.255 },
  { lng: -121.475, lat: 38.26 },
  { lng: -121.49, lat: 38.225 },
  { lng: -121.5, lat: 38.23 },
  { lng: -121.505, lat: 38.21 },
  { lng: -121.53, lat: 38.195 },
  { lng: -121.525, lat: 38.17 },
  { lng: -121.535, lat: 38.165 },
  { lng: -121.535, lat: 38.15 },
  { lng: -121.55, lat: 38.15 },
  { lng: -121.56, lat: 38.145 },
  { lng: -121.56, lat: 38.135 },
  { lng: -121.58, lat: 38.13 },
  { lng: -121.585, lat: 38.11 },
  { lng: -121.57, lat: 38.11 },
  { lng: -121.565, lat: 38.095 },
  { lng: -121.6, lat: 38.105 },
  { lng: -121.63, lat: 38.1 },
  { lng: -121.63, lat: 38.09 },
  { lng: -121.64, lat: 38.085 },
  { lng: -121.655, lat: 38.095 },
  { lng: -121.675, lat: 38.095 },
  { lng: -121.68, lat: 38.06 },
  { lng: -121.7, lat: 38.04 },
  { lng: -121.77, lat: 38.02 },
  { lng: -121.82, lat: 38.02 },
  { lng: -121.835, lat: 38.03 },
  { lng: -121.845, lat: 38.06 },
  { lng: -121.865, lat: 38.065 },
];

export const fresnoCounty = [
  { lat: 37.04539957307365, lng: -120.5431079864502 },
  { lat: 37.04192392671876, lng: -120.5390739440918 },
  { lat: 37.04000563308476, lng: -120.53770065307617 },
  { lat: 37.03808729098178, lng: -120.53838729858398 },
  { lat: 37.03452452705973, lng: -120.53838729858398 },
  { lat: 37.031509750101606, lng: -120.54113388061523 },
  { lat: 37.02712440634066, lng: -120.54525375366211 },
  { lat: 37.02493163951206, lng: -120.54010391235352 },
  { lat: 37.01999768272064, lng: -120.53838729858398 },
  { lat: 37.01972356461467, lng: -120.53117752075195 },
  { lat: 37.013418575229664, lng: -120.53014755249023 },
  { lat: 37.013418575229664, lng: -120.52190780639648 },
  { lat: 37.011225412818256, lng: -120.51950454711914 },
  { lat: 37.01040296059743, lng: -120.51229476928711 },
  { lat: 37.00574222990682, lng: -120.50851821899414 },
  { lat: 37.00025865146568, lng: -120.50886154174805 },
  { lat: 37.000807027107996, lng: -120.50302505493164 },
  { lat: 36.99779091213136, lng: -120.50302505493164 },
  { lat: 36.996145708081485, lng: -120.5006217956543 },
  { lat: 36.99038721360594, lng: -120.5009651184082 },
  { lat: 36.98380554318093, lng: -120.5006217956543 },
  { lat: 36.979691709995315, lng: -120.49821853637695 },
  { lat: 36.979691709995315, lng: -120.4951286315918 },
  { lat: 36.97557765438231, lng: -120.4951286315918 },
  { lat: 36.97530337609982, lng: -120.49135208129883 },
  { lat: 36.97365768564589, lng: -120.48860549926758 },
  { lat: 36.97256053890645, lng: -120.4844856262207 },
  { lat: 36.97036619797995, lng: -120.4844856262207 },
  { lat: 36.96734887591083, lng: -120.48894882202148 },
  { lat: 36.96268550655567, lng: -120.4844856262207 },
  { lat: 36.95747316748037, lng: -120.48139572143555 },
  { lat: 36.95390662464134, lng: -120.47761917114258 },
  { lat: 36.948419309517995, lng: -120.4789924621582 },
  { lat: 36.94485234270567, lng: -120.47590255737305 },
  { lat: 36.939089966601564, lng: -120.47590255737305 },
  { lat: 36.93744349338668, lng: -120.47006607055664 },
  { lat: 36.93360158421256, lng: -120.46560287475586 },
  { lat: 36.929759481369324, lng: -120.46491622924805 },
  { lat: 36.92591718486453, lng: -120.46113967895508 },
  { lat: 36.9231725687969, lng: -120.45736312866211 },
  { lat: 36.91987889909955, lng: -120.46182632446289 },
  { lat: 36.915487118198456, lng: -120.46148300170898 },
  { lat: 36.914389133455664, lng: -120.45598983764648 },
  { lat: 36.91301663029911, lng: -120.4511833190918 },
  { lat: 36.90889897264547, lng: -120.45392990112305 },
  { lat: 36.90615374405985, lng: -120.45564651489258 },
  { lat: 36.90560468648865, lng: -120.4621696472168 },
  { lat: 36.903957490067235, lng: -120.46319961547852 },
  { lat: 36.90203571596352, lng: -120.46113967895508 },
  { lat: 36.900662990541356, lng: -120.4621696472168 },
  { lat: 36.89681922798584, lng: -120.46422958374023 },
  { lat: 36.894348135540035, lng: -120.46525955200195 },
  { lat: 36.89215154175683, lng: -120.46731948852539 },
  { lat: 36.88720897462658, lng: -120.46594619750977 },
  { lat: 36.88556138113869, lng: -120.46113967895508 },
  { lat: 36.88336453451452, lng: -120.45907974243164 },
  { lat: 36.88116762468361, lng: -120.45461654663086 },
  { lat: 36.8784213985127, lng: -120.4570198059082 },
  { lat: 36.87457651596472, lng: -120.45873641967773 },
  { lat: 36.87292864990701, lng: -120.45461654663086 },
  { lat: 36.87100609457512, lng: -120.45049667358398 },
  { lat: 36.86771017286244, lng: -120.4511833190918 },
  { lat: 36.86331538939142, lng: -120.4570198059082 },
  { lat: 36.86001913590375, lng: -120.45427322387695 },
  { lat: 36.857272149386404, lng: -120.44912338256836 },
  { lat: 36.85617332713372, lng: -120.44088363647461 },
  { lat: 36.8545250641348, lng: -120.43848037719727 },
  { lat: 36.85205260299283, lng: -120.4405403137207 },
  { lat: 36.84985479261975, lng: -120.44088363647461 },
  { lat: 36.850404251136695, lng: -120.43642044067383 },
  { lat: 36.84710744079944, lng: -120.43195724487305 },
  { lat: 36.84381048829962, lng: -120.43024063110352 },
  { lat: 36.840788156959626, lng: -120.42783737182617 },
  { lat: 36.84051339364195, lng: -120.4237174987793 },
  { lat: 36.83749093199486, lng: -120.42062759399414 },
  { lat: 36.836391825417785, lng: -120.41753768920898 },
  { lat: 36.83968909776596, lng: -120.41204452514648 },
  { lat: 36.838040479360544, lng: -120.40895462036133 },
  { lat: 36.83529270304658, lng: -120.40929794311523 },
  { lat: 36.83309441092257, lng: -120.40689468383789 },
  { lat: 36.82952205148266, lng: -120.40449142456055 },
  { lat: 36.82347614010687, lng: -120.40346145629883 },
  { lat: 36.82265166063331, lng: -120.40105819702148 },
  { lat: 36.8229264881115, lng: -120.39350509643555 },
  { lat: 36.82430061069742, lng: -120.38732528686523 },
  { lat: 36.8193536539104, lng: -120.38286209106445 },
  { lat: 36.81742975102225, lng: -120.37874221801758 },
  { lat: 36.814681234435135, lng: -120.37771224975586 },
  { lat: 36.809733655878226, lng: -120.37771224975586 },
  { lat: 36.80863415055414, lng: -120.37393569946289 },
  { lat: 36.81083314541248, lng: -120.36947250366211 },
  { lat: 36.80753462944047, lng: -120.36844253540039 },
  { lat: 36.80368618120182, lng: -120.37256240844727 },
  { lat: 36.80066226475731, lng: -120.37359237670898 },
  { lat: 36.79571378030265, lng: -120.37118911743164 },
  { lat: 36.7896651984804, lng: -120.37221908569336 },
  { lat: 36.78444103896544, lng: -120.36844253540039 },
  { lat: 36.7869156852394, lng: -120.35985946655273 },
  { lat: 36.78554089162006, lng: -120.34955978393555 },
  { lat: 36.7866407284888, lng: -120.34440994262695 },
  { lat: 36.79131485906556, lng: -120.34852981567383 },
  { lat: 36.79681347114355, lng: -120.3526496887207 },
  { lat: 36.79818806249318, lng: -120.34749984741211 },
  { lat: 36.79488900181141, lng: -120.34543991088867 },
  { lat: 36.79186473803389, lng: -120.34269332885742 },
  { lat: 36.792964484130366, lng: -120.33685684204102 },
  { lat: 36.7896651984804, lng: -120.33342361450195 },
  { lat: 36.78554089162006, lng: -120.3358268737793 },
  { lat: 36.7819663127764, lng: -120.33273696899414 },
  { lat: 36.78526592993634, lng: -120.32587051391602 },
  { lat: 36.783616139115125, lng: -120.32243728637695 },
  { lat: 36.77976648907633, lng: -120.32346725463867 },
  { lat: 36.77784159154438, lng: -120.31557083129883 },
  { lat: 36.78086640881892, lng: -120.31076431274414 },
  { lat: 36.778666553548796, lng: -120.30252456665039 },
  { lat: 36.77591664567203, lng: -120.29977798461914 },
  { lat: 36.77261662599958, lng: -120.30527114868164 },
  { lat: 36.76959148317589, lng: -120.30424118041992 },
  { lat: 36.772066608909896, lng: -120.2973747253418 },
  { lat: 36.772066608909896, lng: -120.29325485229492 },
  { lat: 36.7679413549626, lng: -120.29325485229492 },
  { lat: 36.76354083944902, lng: -120.2922248840332 },
  { lat: 36.76299075725449, lng: -120.28879165649414 },
  { lat: 36.773166639143305, lng: -120.28776168823242 },
  { lat: 36.773166639143305, lng: -120.28364181518555 },
  { lat: 36.770966562892795, lng: -120.2812385559082 },
  { lat: 36.765741128771126, lng: -120.28192520141602 },
  { lat: 36.76876642350827, lng: -120.2754020690918 },
  { lat: 36.77344164423544, lng: -120.27162551879883 },
  { lat: 36.77179159888533, lng: -120.26887893676758 },
  { lat: 36.77179159888533, lng: -120.26372909545898 },
  { lat: 36.769866501092196, lng: -120.25960922241211 },
  { lat: 36.77289163306469, lng: -120.25239944458008 },
  { lat: 36.77234161794798, lng: -120.24484634399414 },
  { lat: 36.769866501092196, lng: -120.23660659790039 },
  { lat: 36.78059143036312, lng: -120.23214340209961 },
  { lat: 36.781691338266896, lng: -120.2259635925293 },
  { lat: 36.78581585231718, lng: -120.21978378295898 },
  { lat: 36.78939025159624, lng: -120.21394729614258 },
  { lat: 36.79021508928879, lng: -120.20467758178711 },
  { lat: 36.787465595780745, lng: -120.20296096801758 },
  { lat: 36.782791230385406, lng: -120.20021438598633 },
  { lat: 36.78004147049181, lng: -120.19643783569336 },
  { lat: 36.77949150667427, lng: -120.18854141235352 },
  { lat: 36.78554089162006, lng: -120.18098831176758 },
  { lat: 36.79653854991341, lng: -120.17343521118164 },
  { lat: 36.803411284640816, lng: -120.16794204711914 },
  { lat: 36.798737892125835, lng: -120.15867233276367 },
  { lat: 36.80176188455212, lng: -120.1490592956543 },
  { lat: 36.80588531816476, lng: -120.13704299926758 },
  { lat: 36.81220748512563, lng: -120.12639999389648 },
  { lat: 36.813032077110904, lng: -120.11301040649414 },
  { lat: 36.814406377348355, lng: -120.10099411010742 },
  { lat: 36.82402578815423, lng: -120.08554458618164 },
  { lat: 36.8229264881115, lng: -120.07387161254883 },
  { lat: 36.818803972305695, lng: -120.06769180297852 },
  { lat: 36.8218271722768, lng: -120.05670547485352 },
  { lat: 36.817704597252835, lng: -120.04640579223633 },
  { lat: 36.81550579977397, lng: -120.03507614135742 },
  { lat: 36.81907881360153, lng: -120.02340316772461 },
  { lat: 36.825674708608084, lng: -120.0172233581543 },
  { lat: 36.829247247693154, lng: -120.00417709350586 },
  { lat: 36.829247247693154, lng: -119.99490737915039 },
  { lat: 36.83281961996509, lng: -119.9901008605957 },
  { lat: 36.84023862933714, lng: -119.98529434204102 },
  { lat: 36.83996386404514, lng: -119.97808456420898 },
  { lat: 36.834743135938254, lng: -119.97499465942383 },
  { lat: 36.83419356488146, lng: -119.96435165405273 },
  { lat: 36.83556748512009, lng: -119.95920181274414 },
  { lat: 36.83556748512009, lng: -119.93928909301758 },
  { lat: 36.842986227962314, lng: -119.93379592895508 },
  { lat: 36.84958006188038, lng: -119.92624282836914 },
  { lat: 36.84820639337462, lng: -119.92143630981445 },
  { lat: 36.84545898231956, lng: -119.91697311401367 },
  { lat: 36.85122843150753, lng: -119.90324020385742 },
  { lat: 36.852876765592534, lng: -119.89294052124023 },
  { lat: 36.85837095584161, lng: -119.88744735717773 },
  { lat: 36.8531514844845, lng: -119.86616134643555 },
  { lat: 36.855898619102206, lng: -119.86101150512695 },
  { lat: 36.850953705704526, lng: -119.85414505004883 },
  { lat: 36.859195050315805, lng: -119.8469352722168 },
  { lat: 36.860843212605346, lng: -119.83972549438477 },
  { lat: 36.854250350179356, lng: -119.8359489440918 },
  { lat: 36.84985479261975, lng: -119.82221603393555 },
  { lat: 36.8545250641348, lng: -119.8084831237793 },
  { lat: 36.86825950302249, lng: -119.8029899597168 },
  { lat: 36.876773615407316, lng: -119.79578018188477 },
  { lat: 36.88034376720183, lng: -119.78719711303711 },
  { lat: 36.89544640872654, lng: -119.78925704956055 },
  { lat: 36.90615374405985, lng: -119.77895736694336 },
  { lat: 36.911369593910486, lng: -119.76831436157227 },
  { lat: 36.91713406567477, lng: -119.7535514831543 },
  { lat: 36.93113168318815, lng: -119.75011825561523 },
  { lat: 36.94265720319494, lng: -119.74531173706055 },
  { lat: 36.94540111770124, lng: -119.73501205444336 },
  { lat: 36.95500404022784, lng: -119.73947525024414 },
  { lat: 36.96625163823219, lng: -119.74187850952148 },
  { lat: 36.97448053532116, lng: -119.7374153137207 },
  { lat: 36.98161152649568, lng: -119.72814559936523 },
  { lat: 36.997242514736364, lng: -119.70823287963867 },
  { lat: 37.009580499476755, lng: -119.69175338745117 },
  { lat: 37.01040296059743, lng: -119.67390060424805 },
  { lat: 37.013418575229664, lng: -119.65810775756836 },
  { lat: 37.03562077991692, lng: -119.65570449829102 },
  { lat: 37.04466426211527, lng: -119.64471817016602 },
  { lat: 37.0391834924041, lng: -119.63235855102539 },
  { lat: 37.03288011810079, lng: -119.62995529174805 },
  { lat: 37.02246470117044, lng: -119.63579177856445 },
  { lat: 37.023835232361364, lng: -119.62583541870117 },
  { lat: 37.03178382567962, lng: -119.6165657043457 },
  { lat: 37.04904859299321, lng: -119.61244583129883 },
  { lat: 37.06987070605197, lng: -119.60454940795898 },
  { lat: 37.06959676798217, lng: -119.59527969360352 },
  { lat: 37.06713128082879, lng: -119.58635330200195 },
  { lat: 37.064117798804276, lng: -119.5616340637207 },
  { lat: 37.07425358060113, lng: -119.56369400024414 },
  { lat: 37.08411412214884, lng: -119.55957412719727 },
  { lat: 37.092877971292815, lng: -119.55099105834961 },
  { lat: 37.10273609020525, lng: -119.53760147094727 },
  { lat: 37.11286670905789, lng: -119.54275131225586 },
  { lat: 37.11642579397878, lng: -119.55545425415039 },
  { lat: 37.11943719670526, lng: -119.56918716430664 },
  { lat: 37.12710204494757, lng: -119.56335067749023 },
  { lat: 37.13887155061187, lng: -119.56266403198242 },
  { lat: 37.143524106036125, lng: -119.55270767211914 },
  { lat: 37.138597861969735, lng: -119.54137802124023 },
  { lat: 37.1333975896479, lng: -119.53279495239258 },
  { lat: 37.12792324100665, lng: -119.5231819152832 },
  { lat: 37.140787351735135, lng: -119.5176887512207 },
  { lat: 37.15118652303378, lng: -119.51082229614258 },
  { lat: 37.14708175780485, lng: -119.49777603149414 },
  { lat: 37.140787351735135, lng: -119.49056625366211 },
  { lat: 37.130660469006244, lng: -119.49056625366211 },
  { lat: 37.117520871729276, lng: -119.4898796081543 },
  { lat: 37.11040263754686, lng: -119.47683334350586 },
  { lat: 37.1161520325098, lng: -119.46722030639648 },
  { lat: 37.13148161810025, lng: -119.46687698364258 },
  { lat: 37.14543978932954, lng: -119.46172714233398 },
  { lat: 37.14680809886783, lng: -119.44147109985352 },
  { lat: 37.15830092162213, lng: -119.43391799926758 },
  { lat: 37.16486746459025, lng: -119.42464828491211 },
  { lat: 37.16240507782609, lng: -119.40919876098633 },
  { lat: 37.155838320944746, lng: -119.40061569213867 },
  { lat: 37.151733808230254, lng: -119.39615249633789 },
  { lat: 37.15529106545646, lng: -119.37932968139648 },
  { lat: 37.16514105816771, lng: -119.37108993530273 },
  { lat: 37.16897126426067, lng: -119.3635368347168 },
  { lat: 37.182101925643, lng: -119.35529708862305 },
  { lat: 37.18893990792153, lng: -119.34362411499023 },
  { lat: 37.205621986212414, lng: -119.33126449584961 },
  { lat: 37.217105864316224, lng: -119.33435440063477 },
  { lat: 37.23241498275827, lng: -119.33263778686523 },
  { lat: 37.24717440360124, lng: -119.32233810424805 },
  { lat: 37.25974496388779, lng: -119.32268142700195 },
  { lat: 37.27395263663328, lng: -119.3309211730957 },
  { lat: 37.28925020917685, lng: -119.32714462280273 },
  { lat: 37.300721347938534, lng: -119.32989120483398 },
  { lat: 37.31219073736054, lng: -119.33366775512695 },
  { lat: 37.32065511686764, lng: -119.32680130004883 },
  { lat: 37.33567021853739, lng: -119.32439804077148 },
  { lat: 37.33867287878127, lng: -119.31135177612305 },
  { lat: 37.34904478145799, lng: -119.31581497192383 },
  { lat: 37.352592735062586, lng: -119.30379867553711 },
  { lat: 37.36432707929021, lng: -119.29658889770508 },
  { lat: 37.58521225635587, lng: -119.02596473693848 },
  { lat: 37.58012899509355, lng: -119.01163101196289 },
  { lat: 37.57604762780391, lng: -119.00545120239258 },
  { lat: 37.564618609280735, lng: -118.99343490600586 },
  { lat: 37.557270457012706, lng: -118.97970199584961 },
  { lat: 37.55536526219943, lng: -118.96734237670898 },
  { lat: 37.56244145453326, lng: -118.9570426940918 },
  { lat: 37.56380218370658, lng: -118.9460563659668 },
  { lat: 37.55781478944109, lng: -118.93472671508789 },
  { lat: 37.55318783705394, lng: -118.92820358276367 },
  { lat: 37.54393307044398, lng: -118.91481399536133 },
  { lat: 37.54175531130946, lng: -118.90485763549805 },
  { lat: 37.53930525624585, lng: -118.89970779418945 },
  { lat: 37.53249912532158, lng: -118.8969612121582 },
  { lat: 37.52895969175346, lng: -118.89352798461914 },
  { lat: 37.525964655240294, lng: -118.88734817504883 },
  { lat: 37.516978824211144, lng: -118.88254165649414 },
  { lat: 37.51044299466023, lng: -118.87292861938477 },
  { lat: 37.50663016310586, lng: -118.86777877807617 },
  { lat: 37.49873153536774, lng: -118.86880874633789 },
  { lat: 37.488925179863074, lng: -118.85438919067383 },
  { lat: 37.48047978646371, lng: -118.85370254516602 },
  { lat: 37.471760959689576, lng: -118.84786605834961 },
  { lat: 37.46740116486926, lng: -118.84271621704102 },
  { lat: 37.47121600755851, lng: -118.82658004760742 },
  { lat: 37.47394077005854, lng: -118.81284713745117 },
  { lat: 37.46876363638152, lng: -118.8032341003418 },
  { lat: 37.47339582550503, lng: -118.77851486206055 },
  { lat: 37.47094352584533, lng: -118.76993179321289 },
  { lat: 37.46331363460804, lng: -118.75860214233398 },
  { lat: 37.45186733773126, lng: -118.76100540161133 },
  { lat: 37.44805151612806, lng: -118.7647819519043 },
  { lat: 37.434967221541726, lng: -118.77817153930664 },
  { lat: 37.41997198812445, lng: -118.7757682800293 },
  { lat: 37.403882853443065, lng: -118.78332138061523 },
  { lat: 37.393245765703576, lng: -118.79499435424805 },
  { lat: 37.38178875209504, lng: -118.79533767700195 },
  { lat: 37.3733312619308, lng: -118.78263473510742 },
  { lat: 37.36132544493717, lng: -118.78229141235352 },
  { lat: 37.35886947298069, lng: -118.79087448120117 },
  { lat: 37.353138559196104, lng: -118.7925910949707 },
  { lat: 37.343586063890065, lng: -118.78572463989258 },
  { lat: 37.33621616569182, lng: -118.7757682800293 },
  { lat: 37.32857254444109, lng: -118.76306533813477 },
  { lat: 37.340583600073884, lng: -118.74933242797852 },
  { lat: 37.33785398334649, lng: -118.74212265014648 },
  { lat: 37.33075651558583, lng: -118.73353958129883 },
  { lat: 37.31874388868111, lng: -118.72838973999023 },
  { lat: 37.30946008903583, lng: -118.72392654418945 },
  { lat: 37.30044824591592, lng: -118.71500015258789 },
  { lat: 37.2944397505265, lng: -118.7153434753418 },
  { lat: 37.29034277394582, lng: -118.72049331665039 },
  { lat: 37.2867918804395, lng: -118.73079299926758 },
  { lat: 37.27723094964672, lng: -118.73353958129883 },
  { lat: 37.26357037025048, lng: -118.73800277709961 },
  { lat: 37.25264012231638, lng: -118.73044967651367 },
  { lat: 37.23952173133383, lng: -118.73285293579102 },
  { lat: 37.22804126602871, lng: -118.73285293579102 },
  { lat: 37.21819947583416, lng: -118.7263298034668 },
  { lat: 37.205895432193266, lng: -118.73010635375977 },
  { lat: 37.18675182095229, lng: -118.73319625854492 },
  { lat: 37.17690464505932, lng: -118.7321662902832 },
  { lat: 37.16295228181735, lng: -118.72220993041992 },
  { lat: 37.15337564005344, lng: -118.7098503112793 },
  { lat: 37.13969261894972, lng: -118.69440078735352 },
  { lat: 37.13996629527908, lng: -118.67895126342773 },
  { lat: 37.12545964280612, lng: -118.64187240600586 },
  { lat: 37.1120453580112, lng: -118.61063003540039 },
  { lat: 37.10848606719677, lng: -118.59037399291992 },
  { lat: 37.09890252971379, lng: -118.56874465942383 },
  { lat: 37.08904391198702, lng: -118.55878829956055 },
  { lat: 37.07836229543263, lng: -118.53681564331055 },
  { lat: 37.06439175666265, lng: -118.51964950561523 },
  { lat: 37.061104197062434, lng: -118.49699020385742 },
  { lat: 37.0487745797326, lng: -118.47055435180664 },
  { lat: 37.03644295917403, lng: -118.46437454223633 },
  { lat: 37.00903218711865, lng: -118.45647811889648 },
  { lat: 36.98353129455535, lng: -118.44205856323242 },
  { lat: 36.972011959605744, lng: -118.4245491027832 },
  { lat: 36.95226047161237, lng: -118.4025764465332 },
  { lat: 36.94293159909249, lng: -118.39502334594727 },
  { lat: 36.91384013515677, lng: -118.38335037231445 },
  { lat: 36.89132780279111, lng: -118.37888717651367 },
  { lat: 36.878146770464, lng: -118.3692741394043 },
  { lat: 36.84133768063342, lng: -118.36755752563477 },
  { lat: 36.83227003508886, lng: -118.37854385375977 },
  { lat: 36.81825428675315, lng: -118.39056015014648 },
  { lat: 36.80863415055414, lng: -118.39879989624023 },
  { lat: 36.79516392896187, lng: -118.38678359985352 },
  { lat: 36.7871906410034, lng: -118.38232040405273 },
  { lat: 36.77014151802206, lng: -118.38335037231445 },
  { lat: 36.75473905088298, lng: -118.38335037231445 },
  { lat: 36.74235982696141, lng: -118.38369369506836 },
  { lat: 36.74483583151471, lng: -118.56565475463867 },
  { lat: 36.740984234359885, lng: -118.56565475463867 },
  { lat: 36.74153447435901, lng: -118.98279190063477 },
  { lat: 36.65620020875642, lng: -118.98382186889648 },
  { lat: 36.65978064517359, lng: -119.30482864379883 },
  { lat: 36.575633131613614, lng: -119.30500030517578 },
  { lat: 36.57839020774761, lng: -119.4649887084961 },
  { lat: 36.41997477139967, lng: -119.66548919677734 },
  { lat: 36.42620436286966, lng: -119.67158317565918 },
  { lat: 36.431452843264296, lng: -119.67038154602051 },
  { lat: 36.43062415942375, lng: -119.67587471008301 },
  { lat: 36.429519233875276, lng: -119.68205451965332 },
  { lat: 36.42620436286966, lng: -119.68703269958496 },
  { lat: 36.425513746928424, lng: -119.69527244567871 },
  { lat: 36.42413249661695, lng: -119.70145225524902 },
  { lat: 36.426756851199634, lng: -119.70522880554199 },
  { lat: 36.423718116732, lng: -119.7114086151123 },
  { lat: 36.41929792702685, lng: -119.71261024475098 },
  { lat: 36.41833097698979, lng: -119.72050666809082 },
  { lat: 36.420402998041226, lng: -119.72908973693848 },
  { lat: 36.416673320344856, lng: -119.73475456237793 },
  { lat: 36.4180547033393, lng: -119.73973274230957 },
  { lat: 36.41639704079759, lng: -119.7469425201416 },
  { lat: 36.41183828638001, lng: -119.7524356842041 },
  { lat: 36.40824635203449, lng: -119.75329399108887 },
  { lat: 36.402167315574964, lng: -119.75192070007324 },
  { lat: 36.402844309221564, lng: -119.96074676513672 },
  { lat: 36.177587838949094, lng: -119.9593734741211 },
  { lat: 35.90330830298613, lng: -120.3164291381836 },
];

export const maderaCounty = [
  { lng: -120.565, lat: 37.02 },
  { lng: -120.56, lat: 37.055 },
  { lng: -120.485, lat: 37.115 },
  { lng: -120.415, lat: 37.13 },
  { lng: -120.39, lat: 37.145 },
  { lng: -120.36, lat: 37.14 },
  { lng: -120.29, lat: 37.175 },
  { lng: -120.255, lat: 37.175 },
  { lng: -120.235, lat: 37.185 },
  { lng: -120.19, lat: 37.18 },
  { lng: -120.11, lat: 37.185 },
  { lng: -120.095, lat: 37.195 },
  { lng: -120.06, lat: 37.2 },
  { lng: -119.775, lat: 37.435 },
  { lng: -119.67, lat: 37.435 },
  { lng: -119.67, lat: 37.47 },
  { lng: -119.66, lat: 37.48 },
  { lng: -119.635, lat: 37.48 },
  { lng: -119.635, lat: 37.505 },
  { lng: -119.625, lat: 37.515 },
  { lng: -119.605, lat: 37.515 },
  { lng: -119.6, lat: 37.575 },
  { lng: -119.31, lat: 37.8 },
  { lng: -119.285, lat: 37.785 },
  { lng: -119.27, lat: 37.76 },
  { lng: -119.225, lat: 37.74 },
  { lng: -119.195, lat: 37.76 },
  { lng: -119.115, lat: 37.755 },
  { lng: -119.07, lat: 37.71 },
  { lng: -119.05, lat: 37.7 },
  { lng: -119.035, lat: 37.655 },
  { lng: -119.02, lat: 37.65 },
  { lng: -119.01, lat: 37.63 },
  { lng: -119.005, lat: 37.575 },
  { lng: -119.29, lat: 37.35 },
  { lng: -119.295, lat: 37.32 },
  { lng: -119.31, lat: 37.305 },
  { lng: -119.31, lat: 37.275 },
  { lng: -119.3, lat: 37.255 },
  { lng: -119.305, lat: 37.235 },
  { lng: -119.315, lat: 37.23 },
  { lng: -119.31, lat: 37.2 },
  { lng: -119.34, lat: 37.17 },
  { lng: -119.34, lat: 37.16 },
  { lng: -119.375, lat: 37.13 },
  { lng: -119.41, lat: 37.13 },
  { lng: -119.415, lat: 37.14 },
  { lng: -119.42, lat: 37.13 },
  { lng: -119.445, lat: 37.125 },
  { lng: -119.45, lat: 37.1 },
  { lng: -119.46, lat: 37.09 },
  { lng: -119.49, lat: 37.09 },
  { lng: -119.51, lat: 37.115 },
  { lng: -119.52, lat: 37.11 },
  { lng: -119.52, lat: 37.09 },
  { lng: -119.54, lat: 37.075 },
  { lng: -119.55, lat: 37.045 },
  { lng: -119.59, lat: 37.045 },
  { lng: -119.605, lat: 37.01 },
  { lng: -119.66, lat: 36.99 },
  { lng: -119.69, lat: 36.99 },
  { lng: -119.705, lat: 36.97 },
  { lng: -119.72, lat: 36.965 },
  { lng: -119.72, lat: 36.93 },
  { lng: -119.735, lat: 36.92 },
  { lng: -119.74, lat: 36.905 },
  { lng: -119.765, lat: 36.895 },
  { lng: -119.77, lat: 36.865 },
  { lng: -119.795, lat: 36.85 },
  { lng: -119.805, lat: 36.83 },
  { lng: -119.885, lat: 36.835 },
  { lng: -119.92, lat: 36.825 },
  { lng: -119.93, lat: 36.815 },
  { lng: -119.98, lat: 36.815 },
  { lng: -120.015, lat: 36.795 },
  { lng: -120.055, lat: 36.8 },
  { lng: -120.12, lat: 36.795 },
  { lng: -120.145, lat: 36.78 },
  { lng: -120.16, lat: 36.775 },
  { lng: -120.16, lat: 36.78 },
  { lng: -120.185, lat: 36.755 },
  { lng: -120.215, lat: 36.76 },
  { lng: -120.22, lat: 36.75 },
  { lng: -120.305, lat: 36.745 },
  { lng: -120.335, lat: 36.765 },
  { lng: -120.385, lat: 36.77 },
  { lng: -120.395, lat: 36.8 },
  { lng: -120.415, lat: 36.805 },
  { lng: -120.42, lat: 36.815 },
  { lng: -120.475, lat: 36.85 },
  { lng: -120.485, lat: 36.88 },
  { lng: -120.48, lat: 36.92 },
  { lng: -120.495, lat: 36.93 },
  { lng: -120.52, lat: 36.985 },
  { lng: -120.53, lat: 36.995 },
  { lng: -120.545, lat: 36.995 },
  { lng: -120.565, lat: 37.02 },
];

export const tuolumneCounty = [
  { lng: -120.67, lat: 37.84 },
  { lng: -120.645, lat: 37.88 },
  { lng: -120.625, lat: 37.885 },
  { lng: -120.595, lat: 37.915 },
  { lng: -120.58, lat: 37.915 },
  { lng: -120.58, lat: 37.925 },
  { lng: -120.555, lat: 37.945 },
  { lng: -120.55, lat: 37.955 },
  { lng: -120.565, lat: 37.97 },
  { lng: -120.555, lat: 38 },
  { lng: -120.535, lat: 38.01 },
  { lng: -120.525, lat: 38.025 },
  { lng: -120.485, lat: 38.03 },
  { lng: -120.475, lat: 38.04 },
  { lng: -120.475, lat: 38.05 },
  { lng: -120.45, lat: 38.075 },
  { lng: -120.445, lat: 38.09 },
  { lng: -120.43, lat: 38.095 },
  { lng: -120.425, lat: 38.12 },
  { lng: -120.405, lat: 38.125 },
  { lng: -120.395, lat: 38.155 },
  { lng: -120.36, lat: 38.185 },
  { lng: -120.29, lat: 38.28 },
  { lng: -120.265, lat: 38.305 },
  { lng: -120.255, lat: 38.305 },
  { lng: -120.24, lat: 38.335 },
  { lng: -120.22, lat: 38.34 },
  { lng: -120.185, lat: 38.395 },
  { lng: -120.13, lat: 38.42 },
  { lng: -120.105, lat: 38.42 },
  { lng: -120.08, lat: 38.435 },
  { lng: -120.035, lat: 38.445 },
  { lng: -120.025, lat: 38.455 },
  { lng: -120.01, lat: 38.455 },
  { lng: -119.88, lat: 38.385 },
  { lng: -119.82, lat: 38.405 },
  { lng: -119.81, lat: 38.42 },
  { lng: -119.775, lat: 38.425 },
  { lng: -119.76, lat: 38.435 },
  { lng: -119.705, lat: 38.44 },
  { lng: -119.68, lat: 38.42 },
  { lng: -119.675, lat: 38.375 },
  { lng: -119.62, lat: 38.335 },
  { lng: -119.625, lat: 38.29 },
  { lng: -119.595, lat: 38.275 },
  { lng: -119.595, lat: 38.255 },
  { lng: -119.585, lat: 38.245 },
  { lng: -119.585, lat: 38.225 },
  { lng: -119.605, lat: 38.21 },
  { lng: -119.575, lat: 38.205 },
  { lng: -119.56, lat: 38.175 },
  { lng: -119.54, lat: 38.175 },
  { lng: -119.53, lat: 38.165 },
  { lng: -119.51, lat: 38.18 },
  { lng: -119.485, lat: 38.175 },
  { lng: -119.47, lat: 38.15 },
  { lng: -119.45, lat: 38.14 },
  { lng: -119.45, lat: 38.13 },
  { lng: -119.425, lat: 38.14 },
  { lng: -119.41, lat: 38.125 },
  { lng: -119.39, lat: 38.125 },
  { lng: -119.355, lat: 38.105 },
  { lng: -119.34, lat: 38.105 },
  { lng: -119.325, lat: 38.085 },
  { lng: -119.31, lat: 38.08 },
  { lng: -119.29, lat: 38.05 },
  { lng: -119.285, lat: 38.015 },
  { lng: -119.295, lat: 37.965 },
  { lng: -119.26, lat: 37.95 },
  { lng: -119.245, lat: 37.93 },
  { lng: -119.2, lat: 37.92 },
  { lng: -119.18, lat: 37.895 },
  { lng: -119.18, lat: 37.875 },
  { lng: -119.19, lat: 37.87 },
  { lng: -119.18, lat: 37.85 },
  { lng: -119.18, lat: 37.795 },
  { lng: -119.21, lat: 37.76 },
  { lng: -119.23, lat: 37.755 },
  { lng: -119.24, lat: 37.73 },
  { lng: -119.255, lat: 37.72 },
  { lng: -119.295, lat: 37.725 },
  { lng: -119.33, lat: 37.775 },
  { lng: -119.365, lat: 37.79 },
  { lng: -119.375, lat: 37.81 },
  { lng: -119.42, lat: 37.82 },
  { lng: -119.425, lat: 37.835 },
  { lng: -119.435, lat: 37.84 },
  { lng: -119.505, lat: 37.845 },
  { lng: -119.545, lat: 37.88 },
  { lng: -119.565, lat: 37.88 },
  { lng: -119.57, lat: 37.86 },
  { lng: -119.59, lat: 37.84 },
  { lng: -119.625, lat: 37.83 },
  { lng: -119.63, lat: 37.805 },
  { lng: -119.66, lat: 37.78 },
  { lng: -119.68, lat: 37.78 },
  { lng: -119.705, lat: 37.765 },
  { lng: -119.72, lat: 37.77 },
  { lng: -119.74, lat: 37.755 },
  { lng: -119.79, lat: 37.745 },
  { lng: -119.8, lat: 37.735 },
  { lng: -119.82, lat: 37.735 },
  { lng: -119.83, lat: 37.745 },
  { lng: -119.935, lat: 37.74 },
  { lng: -120.025, lat: 37.79 },
  { lng: -120.055, lat: 37.79 },
  { lng: -120.075, lat: 37.8 },
  { lng: -120.12, lat: 37.76 },
  { lng: -120.165, lat: 37.77 },
  { lng: -120.175, lat: 37.75 },
  { lng: -120.215, lat: 37.745 },
  { lng: -120.235, lat: 37.735 },
  { lng: -120.25, lat: 37.715 },
  { lng: -120.28, lat: 37.71 },
  { lng: -120.305, lat: 37.715 },
  { lng: -120.31, lat: 37.705 },
  { lng: -120.285, lat: 37.67 },
  { lng: -120.29, lat: 37.645 },
  { lng: -120.315, lat: 37.63 },
  { lng: -120.34, lat: 37.63 },
  { lng: -120.355, lat: 37.645 },
  { lng: -120.375, lat: 37.615 },
  { lng: -120.395, lat: 37.615 },
  { lng: -120.64, lat: 37.795 },
  { lng: -120.67, lat: 37.82 },
  { lng: -120.67, lat: 37.84 },
];
