// @flow
import * as React from 'react';
import styled from 'styled-components';
import { Container, Heading, Section, LinkButton } from 'components';
import Testimonial from 'pages/Reviews/Testimonial';

const BREAKPOINT = 960;
const Wrapper = styled('div')`
  padding: 100px 0 220px;
  text-align: center;
  @media (max-width: ${BREAKPOINT}px) {
    padding: 25px 0 40px;
  }
`;

const Cols = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: ${BREAKPOINT}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Col = styled.div`
  flex-grow: 1;
  width: 30%;
  @media (max-width: ${BREAKPOINT}px) {
    &:first-child,
    &:last-child {
      display: none;
    }
    width: 90%;
    align-items: center;
    margin-bottom: 10px;
  }
`;

type TestimonialType = {
  name: string,
  avatar: string,
  quote: string,
  location?: string,
  link: string,
};

type Props = {
  testimonials: Array<TestimonialType>,
  title?: string,
  isSpanish?: boolean,
};

const HomeTestimonials = ({
  testimonials,
  title = 'We pride ourselves on going above and beyond for the families we serve.',
  isSpanish,
}: Props) => (
  <Section coverUrl="/img/brochure/photo-frames.jpg">
    <Container h="standard">
      <Wrapper>
        <Heading size="large">
          {isSpanish ? 'Cuidando a ti en tu momento de necesidad' : title}
        </Heading>

        <Cols breakpoint={BREAKPOINT} style={{ marginTop: 60 }}>
          {testimonials.map((testimonial, index) => (
            <Col key={index}>
              <Testimonial {...testimonial} />
            </Col>
          ))}
        </Cols>

        {!isSpanish && (
          <LinkButton
            tertiary
            to="/reviews"
            disabled={isSpanish}
            label={isSpanish ? 'Más recomendaciones' : 'More reviews'}
            style={{ marginTop: 30 }}
          />
        )}
      </Wrapper>
    </Container>
  </Section>
);

export default HomeTestimonials;
