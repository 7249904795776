// @flow
import React, { Fragment } from 'react';
import { IPRegionContext } from 'context';

import { BulletList, Heading } from '../components';

const getTexts = {
  heroHeader: 'Cremation Services your family can trust',
  heroText:
    'Tulip provides a simple direct cremation package that you can arrange remotely with the help of our care team. No funeral home visit required.',
  tulipDifference: [
    {
      image: '/img/brochure/promise-simple.jpg',
      title: 'Convenient',
      text:
        'Our experienced team will take care of every aspect of the cremation process making arrangements easy, convenient and completely online.',
      href: '/our-service',
    },
    {
      image: '/img/brochure/promise-transparent.jpg',
      title: 'Complete',
      text:
        'We take care of every aspect of the cremation process so you can focus on the right way to honor your loved one’s life.',
      href: '/pricing',
    },
    {
      image: '/img/brochure/promise-care.jpg',
      title: 'Caring',
      text:
        'Our funeral directors and family care specialists are available 24 hours a day, 7 days a week to answer your questions and assist you. We pride ourselves on going above and beyond for the families we serve.',
      last: true,
    },
  ],
  packageContent: function PackageContent() {
    const region = React.useContext(IPRegionContext);
    const items = [
      'Services of a licensed funeral director',
      'Collection & care of your loved one',
      'Filling all necessary paperwork & permits',
      'A dignified private cremation',
      'The ashes returned to you in a simple container or an urn of your choosing',
    ];
    return (
      <Fragment>
        <Heading>The Tulip Package</Heading>
        <p>
          Direct cremation takes place in the days immediately following the
          passing so you can plan the right memorial.
        </p>
        <p>
          <strong>
            Our direct cremation package includes everything you need:
          </strong>
        </p>
        <BulletList items={region === 'FL' ? items.slice(1) : items} />
      </Fragment>
    );
  },

  preNeedContent: function PreNeedContent() {
    return (
      <Fragment>
        <Heading>Plan Ahead with Tulip</Heading>
        <p>
          The Tulip cremation package includes everything you need so you can
          plan ahead and protect your family from financial burden and difficult
          decisions.
        </p>

        <BulletList
          items={[
            'Confirm your funeral preference ',
            'Lock-in today’s prices',
            'Ease the burden on family and friends',
            'Worldwide protection available',
          ]}
        />
      </Fragment>
    );
  },
};

export default getTexts;
