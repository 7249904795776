// @flow
export const laCounty = [
  { lat: 34.26754, lng: -118.643357 },
  { lat: 34.816166, lng: -118.889176 },
  { lat: 34.815582, lng: -118.875823 },
  { lat: 34.804307, lng: -118.871703 },
  { lat: 34.806562, lng: -118.856597 },
  { lat: 34.818331, lng: -118.859116 },
  { lat: 34.821958, lng: -117.658097 },
  { lat: 34.288992, lng: -117.647095 },
  { lat: 34.022198, lng: -117.742023 },
  { lat: 34.02106, lng: -117.766742 },
  { lat: 34.014231, lng: -117.768116 },
  { lat: 34.008539, lng: -117.792835 },
  { lat: 33.996016, lng: -117.792835 },
  { lat: 33.997154, lng: -117.814808 },
  { lat: 33.970963, lng: -117.795581 },
  { lat: 33.946474, lng: -117.783222 },
  { lat: 33.939638, lng: -117.975483 },
  { lat: 33.907161, lng: -117.979602 },
  { lat: 33.907731, lng: -117.993335 },
  { lat: 33.899183, lng: -117.993335 },
  { lat: 33.900322, lng: -118.008442 },
  { lat: 33.892343, lng: -118.007755 },
  { lat: 33.892343, lng: -118.020115 },
  { lat: 33.883793, lng: -118.020115 },
  { lat: 33.880943, lng: -118.029728 },
  { lat: 33.873532, lng: -118.031788 },
  { lat: 33.867831, lng: -118.033161 },
  { lat: 33.86612, lng: -118.04552 },
  { lat: 33.858708, lng: -118.046207 },
  { lat: 33.859278, lng: -118.05582 },
  { lat: 33.852435, lng: -118.055133 },
  { lat: 33.854146, lng: -118.063373 },
  { lat: 33.847303, lng: -118.06406 },
  { lat: 33.817644, lng: -118.069553 },
  { lat: 33.805663, lng: -118.079853 },
  { lat: 33.801669, lng: -118.092899 },
  { lat: 33.789115, lng: -118.095646 },
  { lat: 33.774847, lng: -118.103199 },
  { lat: 33.758865, lng: -118.099765 },
  { lat: 33.739453, lng: -118.096332 },
  { lat: 33.735456, lng: -118.099079 },
  { lat: 33.75601, lng: -118.140964 },
  { lat: 33.76229, lng: -118.171177 },
  { lat: 33.76229, lng: -118.185596 },
  { lat: 33.760006, lng: -118.194523 },
  { lat: 33.746876, lng: -118.186969 },
  { lat: 33.736598, lng: -118.183536 },
  { lat: 33.731459, lng: -118.184909 },
  { lat: 33.734314, lng: -118.199329 },
  { lat: 33.741166, lng: -118.210315 },
  { lat: 33.741737, lng: -118.228855 },
  { lat: 33.741166, lng: -118.238468 },
  { lat: 33.73203, lng: -118.237095 },
  { lat: 33.723464, lng: -118.248768 },
  { lat: 33.716039, lng: -118.244648 },
  { lat: 33.714326, lng: -118.252201 },
  { lat: 33.718895, lng: -118.26044 },
  { lat: 33.718324, lng: -118.267307 },
  { lat: 33.714897, lng: -118.272113 },
  { lat: 33.708043, lng: -118.27898 },
  { lat: 33.709185, lng: -118.300953 },
  { lat: 33.72691, lng: -118.351954 },
  { lat: 33.736047, lng: -118.362254 },
  { lat: 33.737189, lng: -118.412379 },
  { lat: 33.772584, lng: -118.429545 },
  { lat: 33.820516, lng: -118.39178 },
  { lat: 33.872981, lng: -118.408259 },
  { lat: 33.948202, lng: -118.447398 },
  { lat: 34.012543, lng: -118.503016 },
  { lat: 34.03747, lng: -118.540474 },
  { lat: 34.036901, lng: -118.609139 },
  { lat: 34.029504, lng: -118.707329 },
  { lat: 34.033487, lng: -118.745781 },
  { lat: 34.024951, lng: -118.754708 },
  { lat: 34.020398, lng: -118.78492 },
  { lat: 34.007308, lng: -118.79316 },
  { lat: 34.000477, lng: -118.804146 },
  { lat: 34.033487, lng: -118.861138 },
  { lat: 34.044298, lng: -118.942162 },
  { lat: 34.075585, lng: -118.942162 },
  { lat: 34.170512, lng: -118.7911 },
  { lat: 34.167956, lng: -118.667504 },
  // { lat: 34.267441, lng: -118.643082 },
];

export const sanDiegoCounty = [
  { lng: -117.61, lat: 33.335 },
  { lng: -117.58, lat: 33.455 },
  { lng: -117.56, lat: 33.45 },
  { lng: -117.51, lat: 33.47 },
  { lng: -117.51, lat: 33.505 },
  { lng: -117.365, lat: 33.505 },
  { lng: -117.37, lat: 33.49 },
  { lng: -117.36, lat: 33.485 },
  { lng: -117.24, lat: 33.45 },
  { lng: -117.24, lat: 33.43 },
  { lng: -117.14, lat: 33.435 },
  { lng: -117.03, lat: 33.425 },
  { lng: -116.99, lat: 33.43 },
  { lng: -116.085, lat: 33.425 },
  { lng: -116.08, lat: 33.075 },
  { lng: -116.105, lat: 33.075 },
  { lng: -116.105, lat: 32.62 },
  { lng: -117.205, lat: 32.53 },
  { lng: -117.225, lat: 32.62 },
  { lng: -117.275, lat: 32.62 },
  { lng: -117.31, lat: 32.655 },
  { lng: -117.34, lat: 32.825 },
  { lng: -117.34, lat: 32.86 },
  { lng: -117.325, lat: 32.905 },
  { lng: -117.34, lat: 33 },
  { lng: -117.36, lat: 33.05 },
  { lng: -117.435, lat: 33.18 },
  { lng: -117.51, lat: 33.25 },
  { lng: -117.52, lat: 33.27 },
  { lng: -117.61, lat: 33.335 },
];

export const riversideCounty = [
  { lng: -117.675, lat: 33.87 },
  { lng: -117.675, lat: 33.89 },
  { lng: -117.655, lat: 33.89 },
  { lng: -117.655, lat: 33.925 },
  { lng: -117.61, lat: 33.925 },
  { lng: -117.61, lat: 33.97 },
  { lng: -117.56, lat: 33.99 },
  { lng: -117.56, lat: 34.035 },
  { lng: -117.375, lat: 34.035 },
  { lng: -117.375, lat: 34.02 },
  { lng: -117.225, lat: 34.02 },
  { lng: -117.225, lat: 34.005 },
  { lng: -116.93, lat: 34.005 },
  { lng: -116.93, lat: 34.035 },
  { lng: -115.315, lat: 34.035 },
  { lng: -115.315, lat: 34.08 },
  { lng: -114.435, lat: 34.08 },
  { lng: -114.435, lat: 34.025 },
  { lng: -114.45, lat: 34.01 },
  { lng: -114.465, lat: 34.01 },
  { lng: -114.46, lat: 33.995 },
  { lng: -114.47, lat: 33.995 },
  { lng: -114.5, lat: 33.96 },
  { lng: -114.525, lat: 33.955 },
  { lng: -114.535, lat: 33.94 },
  { lng: -114.535, lat: 33.925 },
  { lng: -114.525, lat: 33.925 },
  { lng: -114.51, lat: 33.91 },
  { lng: -114.51, lat: 33.9 },
  { lng: -114.525, lat: 33.9 },
  { lng: -114.525, lat: 33.89 },
  { lng: -114.505, lat: 33.88 },
  { lng: -114.505, lat: 33.865 },
  { lng: -114.53, lat: 33.86 },
  { lng: -114.52, lat: 33.83 },
  { lng: -114.52, lat: 33.82 },
  { lng: -114.53, lat: 33.815 },
  { lng: -114.505, lat: 33.77 },
  { lng: -114.51, lat: 33.73 },
  { lng: -114.495, lat: 33.72 },
  { lng: -114.495, lat: 33.7 },
  { lng: -114.505, lat: 33.69 },
  { lng: -114.53, lat: 33.685 },
  { lng: -114.53, lat: 33.665 },
  { lng: -114.515, lat: 33.665 },
  { lng: -114.515, lat: 33.655 },
  { lng: -114.535, lat: 33.65 },
  { lng: -114.525, lat: 33.645 },
  { lng: -114.53, lat: 33.62 },
  { lng: -114.52, lat: 33.61 },
  { lng: -114.53, lat: 33.61 },
  { lng: -114.54, lat: 33.595 },
  { lng: -114.54, lat: 33.575 },
  { lng: -114.525, lat: 33.56 },
  { lng: -114.525, lat: 33.55 },
  { lng: -114.56, lat: 33.535 },
  { lng: -114.565, lat: 33.51 },
  { lng: -114.595, lat: 33.5 },
  { lng: -114.6, lat: 33.48 },
  { lng: -114.625, lat: 33.455 },
  { lng: -114.625, lat: 33.435 },
  { lng: -115.465, lat: 33.425 },
  { lng: -116.165, lat: 33.43 },
  { lng: -117.03, lat: 33.425 },
  { lng: -117.14, lat: 33.435 },
  { lng: -117.24, lat: 33.43 },
  { lng: -117.24, lat: 33.45 },
  { lng: -117.36, lat: 33.485 },
  { lng: -117.37, lat: 33.49 },
  { lng: -117.365, lat: 33.505 },
  { lng: -117.51, lat: 33.505 },
  { lng: -117.505, lat: 33.545 },
  { lng: -117.49, lat: 33.555 },
  { lng: -117.415, lat: 33.66 },
  { lng: -117.475, lat: 33.7 },
  { lng: -117.535, lat: 33.71 },
  { lng: -117.535, lat: 33.755 },
  { lng: -117.58, lat: 33.765 },
  { lng: -117.675, lat: 33.87 },
];

export const sanBernardinoCounty = [
  { lng: -117.785, lat: 33.945 },
  { lng: -117.795, lat: 33.955 },
  { lng: -117.795, lat: 33.97 },
  { lng: -117.805, lat: 33.975 },
  { lng: -117.785, lat: 34.005 },
  { lng: -117.765, lat: 34.005 },
  { lng: -117.77, lat: 34.025 },
  { lng: -117.73, lat: 34.02 },
  { lng: -117.72, lat: 34.065 },
  { lng: -117.68, lat: 34.155 },
  { lng: -117.645, lat: 34.29 },
  { lng: -117.665, lat: 34.56 },
  { lng: -117.665, lat: 34.82 },
  { lng: -117.635, lat: 34.825 },
  { lng: -117.63, lat: 35.155 },
  { lng: -117.635, lat: 35.65 },
  { lng: -117.615, lat: 35.65 },
  { lng: -117.62, lat: 35.68 },
  { lng: -117.655, lat: 35.68 },
  { lng: -117.655, lat: 35.71 },
  { lng: -117.635, lat: 35.71 },
  { lng: -117.635, lat: 35.795 },
  { lng: -115.735, lat: 35.795 },
  { lng: -115.735, lat: 35.81 },
  { lng: -115.65, lat: 35.81 },
  { lng: -114.63, lat: 35 },
  { lng: -114.635, lat: 34.87 },
  { lng: -114.58, lat: 34.83 },
  { lng: -114.575, lat: 34.8 },
  { lng: -114.555, lat: 34.765 },
  { lng: -114.525, lat: 34.75 },
  { lng: -114.515, lat: 34.735 },
  { lng: -114.495, lat: 34.73 },
  { lng: -114.49, lat: 34.715 },
  { lng: -114.47, lat: 34.715 },
  { lng: -114.465, lat: 34.685 },
  { lng: -114.45, lat: 34.67 },
  { lng: -114.46, lat: 34.655 },
  { lng: -114.44, lat: 34.645 },
  { lng: -114.44, lat: 34.62 },
  { lng: -114.425, lat: 34.615 },
  { lng: -114.425, lat: 34.6 },
  { lng: -114.435, lat: 34.59 },
  { lng: -114.4, lat: 34.565 },
  { lng: -114.38, lat: 34.53 },
  { lng: -114.385, lat: 34.455 },
  { lng: -114.375, lat: 34.445 },
  { lng: -114.335, lat: 34.45 },
  { lng: -114.325, lat: 34.435 },
  { lng: -114.295, lat: 34.425 },
  { lng: -114.29, lat: 34.405 },
  { lng: -114.27, lat: 34.405 },
  { lng: -114.235, lat: 34.38 },
  { lng: -114.23, lat: 34.365 },
  { lng: -114.18, lat: 34.355 },
  { lng: -114.16, lat: 34.32 },
  { lng: -114.14, lat: 34.305 },
  { lng: -114.13, lat: 34.265 },
  { lng: -114.165, lat: 34.26 },
  { lng: -114.165, lat: 34.25 },
  { lng: -114.175, lat: 34.25 },
  { lng: -114.18, lat: 34.235 },
  { lng: -114.19, lat: 34.235 },
  { lng: -114.21, lat: 34.21 },
  { lng: -114.225, lat: 34.205 },
  { lng: -114.23, lat: 34.185 },
  { lng: -114.26, lat: 34.17 },
  { lng: -114.285, lat: 34.17 },
  { lng: -114.315, lat: 34.14 },
  { lng: -114.355, lat: 34.135 },
  { lng: -114.36, lat: 34.12 },
  { lng: -114.37, lat: 34.115 },
  { lng: -114.415, lat: 34.11 },
  { lng: -114.435, lat: 34.09 },
  { lng: -114.435, lat: 34.08 },
  { lng: -115.315, lat: 34.08 },
  { lng: -115.315, lat: 34.035 },
  { lng: -116.93, lat: 34.035 },
  { lng: -116.93, lat: 34.005 },
  { lng: -117.225, lat: 34.005 },
  { lng: -117.225, lat: 34.02 },
  { lng: -117.375, lat: 34.02 },
  { lng: -117.375, lat: 34.035 },
  { lng: -117.56, lat: 34.035 },
  { lng: -117.56, lat: 33.99 },
  { lng: -117.61, lat: 33.97 },
  { lng: -117.61, lat: 33.925 },
  { lng: -117.655, lat: 33.925 },
  { lng: -117.655, lat: 33.89 },
  { lng: -117.675, lat: 33.89 },
  { lng: -117.675, lat: 33.875 },
  { lng: -117.785, lat: 33.945 },
];

export const orangeCounty = [
  { lng: -118.126, lat: 33.697 },
  { lng: -118.124, lat: 33.717 },
  { lng: -118.12, lat: 33.736 },
  { lng: -118.112, lat: 33.749 },
  { lng: -118.092, lat: 33.758 },
  { lng: -118.092, lat: 33.765 },
  { lng: -118.098, lat: 33.776 },
  { lng: -118.086, lat: 33.797 },
  { lng: -118.085, lat: 33.803 },
  { lng: -118.073, lat: 33.815 },
  { lng: -118.063, lat: 33.82 },
  { lng: -118.063, lat: 33.832 },
  { lng: -118.061, lat: 33.833 },
  { lng: -118.061, lat: 33.835 },
  { lng: -118.059, lat: 33.835 },
  { lng: -118.059, lat: 33.846 },
  { lng: -118.055, lat: 33.846 },
  { lng: -118.055, lat: 33.85 },
  { lng: -118.05, lat: 33.85 },
  { lng: -118.05, lat: 33.854 },
  { lng: -118.046, lat: 33.854 },
  { lng: -118.046, lat: 33.859 },
  { lng: -118.042, lat: 33.859 },
  { lng: -118.042, lat: 33.862 },
  { lng: -118.038, lat: 33.862 },
  { lng: -118.038, lat: 33.866 },
  { lng: -118.029, lat: 33.866 },
  { lng: -118.029, lat: 33.873 },
  { lng: -118.011, lat: 33.873 },
  { lng: -118.011, lat: 33.881 },
  { lng: -118.003, lat: 33.881 },
  { lng: -118.003, lat: 33.888 },
  { lng: -117.994, lat: 33.888 },
  { lng: -117.994, lat: 33.895 },
  { lng: -117.985, lat: 33.896 },
  { lng: -117.985, lat: 33.903 },
  { lng: -117.977, lat: 33.903 },
  { lng: -117.977, lat: 33.946 },
  { lng: -117.92, lat: 33.946 },
  { lng: -117.92, lat: 33.948 },
  { lng: -117.912, lat: 33.946 },
  { lng: -117.783, lat: 33.947 },
  { lng: -117.743, lat: 33.917 },
  { lng: -117.679, lat: 33.878 },
  { lng: -117.61, lat: 33.8 },
  { lng: -117.578, lat: 33.767 },
  { lng: -117.536, lat: 33.756 },
  { lng: -117.534, lat: 33.711 },
  { lng: -117.475, lat: 33.702 },
  { lng: -117.413, lat: 33.659 },
  { lng: -117.458, lat: 33.603 },
  { lng: -117.51, lat: 33.534 },
  { lng: -117.51, lat: 33.52 },
  { lng: -117.503, lat: 33.52 },
  { lng: -117.503, lat: 33.509 },
  { lng: -117.51, lat: 33.509 },
  { lng: -117.509, lat: 33.47 },
  { lng: -117.539, lat: 33.455 },
  { lng: -117.56, lat: 33.451 },
  { lng: -117.579, lat: 33.454 },
  { lng: -117.611, lat: 33.334 },
  { lng: -117.632, lat: 33.346 },
  { lng: -117.639, lat: 33.352 },
  { lng: -117.648, lat: 33.364 },
  { lng: -117.654, lat: 33.365 },
  { lng: -117.714, lat: 33.409 },
  { lng: -117.735, lat: 33.407 },
  { lng: -117.741, lat: 33.41 },
  { lng: -117.765, lat: 33.432 },
  { lng: -117.79, lat: 33.461 },
  { lng: -117.793, lat: 33.47 },
  { lng: -117.811, lat: 33.482 },
  { lng: -117.815, lat: 33.487 },
  { lng: -117.816, lat: 33.491 },
  { lng: -117.934, lat: 33.549 },
  { lng: -117.996, lat: 33.59 },
  { lng: -118.034, lat: 33.609 },
  { lng: -118.05, lat: 33.62 },
  { lng: -118.075, lat: 33.64 },
  { lng: -118.096, lat: 33.654 },
  { lng: -118.126, lat: 33.697 },
];

export const venturaCounty = [
  { lng: -119.515, lat: 34.315 },
  { lng: -119.52, lat: 34.335 },
  { lng: -119.495, lat: 34.39 },
  { lng: -119.485, lat: 34.395 },
  { lng: -119.485, lat: 34.405 },
  { lng: -119.475, lat: 34.405 },
  { lng: -119.475, lat: 34.435 },
  { lng: -119.46, lat: 34.455 },
  { lng: -119.46, lat: 34.915 },
  { lng: -119.375, lat: 34.92 },
  { lng: -119.36, lat: 34.9 },
  { lng: -119.26, lat: 34.895 },
  { lng: -119.255, lat: 34.875 },
  { lng: -119.245, lat: 34.88 },
  { lng: -119.225, lat: 34.87 },
  { lng: -119.225, lat: 34.835 },
  { lng: -118.965, lat: 34.83 },
  { lng: -118.96, lat: 34.81 },
  { lng: -118.87, lat: 34.81 },
  { lng: -118.615, lat: 34.295 },
  { lng: -118.615, lat: 34.23 },
  { lng: -118.625, lat: 34.22 },
  { lng: -118.65, lat: 34.22 },
  { lng: -118.655, lat: 34.15 },
  { lng: -118.785, lat: 34.15 },
  { lng: -118.92, lat: 34.065 },
  { lng: -118.935, lat: 33.98 },
  { lng: -118.97, lat: 33.975 },
  { lng: -119.03, lat: 33.995 },
  { lng: -119.165, lat: 34.02 },
  { lng: -119.23, lat: 34.045 },
  { lng: -119.265, lat: 34.095 },
  { lng: -119.305, lat: 34.13 },
  { lng: -119.345, lat: 34.22 },
  { lng: -119.385, lat: 34.23 },
  { lng: -119.41, lat: 34.255 },
  { lng: -119.46, lat: 34.265 },
  { lng: -119.495, lat: 34.29 },
  { lng: -119.515, lat: 34.315 },
];

export const venturaIsland = [
  { lng: -119.675, lat: 33.29 },
  { lng: -119.73, lat: 33.295 },
  { lng: -119.76, lat: 33.315 },
  { lng: -119.775, lat: 33.34 },
  { lng: -119.78, lat: 33.37 },
  { lng: -119.77, lat: 33.395 },
  { lng: -119.755, lat: 33.415 },
  { lng: -119.715, lat: 33.435 },
  { lng: -119.66, lat: 33.43 },
  { lng: -119.635, lat: 33.415 },
  { lng: -119.615, lat: 33.385 },
  { lng: -119.61, lat: 33.365 },
  { lng: -119.615, lat: 33.345 },
  { lng: -119.515, lat: 33.355 },
  { lng: -119.455, lat: 33.335 },
  { lng: -119.445, lat: 33.325 },
  { lng: -119.415, lat: 33.32 },
  { lng: -119.35, lat: 33.265 },
  { lng: -119.34, lat: 33.225 },
  { lng: -119.355, lat: 33.185 },
  { lng: -119.4, lat: 33.155 },
  { lng: -119.435, lat: 33.145 },
  { lng: -119.525, lat: 33.15 },
  { lng: -119.6, lat: 33.18 },
  { lng: -119.635, lat: 33.215 },
  { lng: -119.645, lat: 33.215 },
  { lng: -119.675, lat: 33.25 },
  { lng: -119.675, lat: 33.29 },
];
