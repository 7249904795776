// @flow
import * as React from 'react';
import styled from 'styled-components';
import { Col, Cols, Container, LinkButton, Section } from 'components';

import { css } from '../../../util';

const BREAKPOINT = 800;

const Item = styled('div')`
  margin: 40px 0 20px;
  padding: 0 18%;
  text-align: center;

  > img {
    display: block;
    width: 100%;
    max-width: 170px;
    margin: 0 auto 10px;
  }

  > span {
    display: block;
    color: ${({ theme }) => theme.textSpecial};
  }

  > strong {
    display: block;
    margin: 15px 0;
    font-weight: normal;
    ${css.fontSize(20)};
    color: ${({ theme }) => theme.textHard};
  }

  @media (max-width: ${BREAKPOINT}px) {
    padding: 0;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
`;
const H2 = styled.h2`
  font-family: 'Tiempos Headline';
  font-weight: 500;
  color: rgb(9, 57, 105);
  font-size: 32px;
  line-height: 1.2;
  margin: 0px;

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 1.33;
  }
`;

type Props = {
  altCity?: string,
  soon?: boolean,
  isSpanish?: boolean,
};

const HomeService = ({ altCity, soon, isSpanish }: Props) => {
  const text = (english, spanish) => (isSpanish ? spanish : english);
  return (
    <Section style={{ borderTop: '2px #eaf2f4 solid' }}>
      <Container
        h="standard"
        v="standalone"
        vBreakpoint={BREAKPOINT}
        style={{ paddingBottom: '50px', marginBottom: '0' }}
      >
        <div style={{ maxWidth: 650, textAlign: 'center', margin: '0 auto' }}>
          <H2 size="large">
            {text(
              'Our care team will guide you through the cremation process, each step of the way',
              'Le ayudamos por el proceso, paso por paso'
            )}
          </H2>
          <p style={{ margin: '35px 0' }}>
            {text(
              'Direct cremation typically takes two weeks',
              'Nuestro servicios se cumplen en aproximadamente dos semanas.'
            )}
          </p>
          <LinkButton
            tertiary
            to="/our-service"
            disabled={isSpanish}
            label={isSpanish ? 'Precios desde $950 ' : 'Learn more'}
          />
        </div>

        <Cols breakpoint={BREAKPOINT}>
          <Col joinedCss="position: relative; top: -120px;">
            <Item>
              <img src="/img/brochure/step-arrangements.jpg" />
              <span>{text('Step 1', 'Paso 1')}</span>
              <strong>{text('You arrange', 'Usted arregla')}</strong>
              <p>
                {text(
                  'Make cremation arrangements online with the help of our dedicated care team.',
                  'Habla con nuestro equipo o cumple los arreglos por nuestro sitio de web.'
                )}
              </p>
            </Item>
          </Col>
          <Col>
            <Item>
              <img src="/img/brochure/step-collection.jpg" />
              <span>{text('Step 2', 'Paso 2')}</span>
              <strong>{text('We collect', 'Transportamos')}</strong>
              <p>
                {isSpanish
                  ? 'Transportamos a su ser querido a nuestro cuidado.'
                  : soon
                  ? "Call us when you need us and we'll bring your loved one into our care."
                  : !altCity
                  ? 'We bring your loved one into our care and prepare them for cremation.'
                  : altCity === 'Los Angeles'
                  ? 'We bring your loved one into our care from anywhere in LA County.'
                  : `We collect your loved one from anywhere in ${
                      altCity === 'San Francisco'
                        ? altCity
                        : `${altCity} County`
                    }.`}
              </p>
            </Item>
          </Col>
          <Col>
            <Item>
              <img src="/img/brochure/step-certification.jpg" />
              <span>{text('Step 3', 'Paso 3')}</span>
              <strong>{text('We register', 'Regristramos')}</strong>
              <p>
                {text(
                  'We register the death and obtain copies of the death certificate on your behalf.',
                  'Nosotros registramos y obtenemos los certificados de defunción por de usted.'
                )}
              </p>
            </Item>
          </Col>
          <Col joinedCss="position: relative; top: -120px;">
            <Item>
              <img src="/img/brochure/step-return.jpg" />
              <span>{text('Step 4', 'Paso 4')}</span>
              <strong>{text('We return', 'Regresamos')}</strong>
              <p>
                {text(
                  'We return your loved one’s ashes to any US postal address via USPS Priority Mail Express.',
                  'Puedes obtener las cenizas en persona, por correo postal, o entregadas por un miembro de nuestro equipo.'
                )}
              </p>
            </Item>
          </Col>
        </Cols>
      </Container>
    </Section>
  );
};

export default HomeService;
