// @flow
const coordinates = [
  { lat: 45.56763, lng: -122.41071 },
  { lat: 45.56358, lng: -122.43867 },
  { lat: 45.56772, lng: -122.4546 },
  { lat: 45.57042, lng: -122.45955 },
  { lat: 45.57771, lng: -122.47349 },
  { lat: 45.5803, lng: -122.48139 },
  { lat: 45.58328, lng: -122.49226 },
  { lat: 45.5858, lng: -122.50421 },
  { lat: 45.59619, lng: -122.54588 },
  { lat: 45.59727, lng: -122.55032 },
  { lat: 45.60424, lng: -122.58313 },
  { lat: 45.60814, lng: -122.60771 },
  { lat: 45.61804, lng: -122.67504 },
  { lat: 45.62474, lng: -122.69101 },
  { lat: 45.63142, lng: -122.70274 },
  { lat: 45.64734, lng: -122.74451 },
  { lat: 45.65714, lng: -122.76381 },
  { lat: 45.70762, lng: -122.76975 },
  { lat: 45.72824, lng: -122.76261 },
  { lat: 45.72948, lng: -122.76211 },
  { lat: 45.7332, lng: -122.7606 },
  { lat: 45.73441, lng: -122.76011 },
  { lat: 45.73445, lng: -122.76011 },
  { lat: 45.73753, lng: -122.76028 },
  { lat: 45.74676, lng: -122.76076 },
  { lat: 45.74984, lng: -122.76093 },
  { lat: 45.74997, lng: -122.76093 },
  { lat: 45.75038, lng: -122.76096 },
  { lat: 45.75051, lng: -122.76096 },
  { lat: 45.75657, lng: -122.7613 },
  { lat: 45.75916, lng: -122.76145 },
  { lat: 45.76585, lng: -122.764 },
  { lat: 45.77375, lng: -122.76697 },
  { lat: 45.7743, lng: -122.76717 },
  { lat: 45.77943, lng: -122.7691 },
  { lat: 45.77998, lng: -122.7693 },
  { lat: 45.78058, lng: -122.76953 },
  { lat: 45.78184, lng: -122.77065 },
  { lat: 45.78994, lng: -122.77782 },
  { lat: 45.79635, lng: -122.78351 },
  { lat: 45.80952, lng: -122.79518 },
  { lat: 45.81522, lng: -122.79573 },
  { lat: 45.82502, lng: -122.79596 },
  { lat: 45.84422, lng: -122.7857 },
  { lat: 45.84979, lng: -122.78554 },
  { lat: 45.85054, lng: -122.78551 },
  { lat: 45.88, lng: -122.79462 },
  { lat: 45.89232, lng: -122.80186 },
  { lat: 45.90966, lng: -122.81006 },
  { lat: 45.95446, lng: -122.81221 },
  { lat: 45.98082, lng: -122.83764 },
  { lat: 46.01141, lng: -122.85447 },
  { lat: 46.01636, lng: -122.85859 },
  { lat: 46.03085, lng: -122.87738 },
  { lat: 46.04597, lng: -122.88133 },
  { lat: 46.05425, lng: -122.88315 },
  { lat: 46.06233, lng: -122.88629 },
  { lat: 46.08867, lng: -122.91764 },
  { lat: 46.09581, lng: -122.93739 },
  { lat: 46.0966, lng: -122.93959 },
  { lat: 46.10461, lng: -122.96212 },
  { lat: 46.11386, lng: -122.97592 },
  { lat: 46.12467, lng: -122.9914 },
  { lat: 46.1284, lng: -122.99666 },
  { lat: 46.13604, lng: -123.00944 },
  { lat: 46.13941, lng: -123.02292 },
  { lat: 46.14434, lng: -123.03382 },
  { lat: 46.14897, lng: -123.04531 },
  { lat: 46.16337, lng: -123.07295 },
  { lat: 46.18231, lng: -123.11193 },
  { lat: 46.18552, lng: -123.11906 },
  { lat: 46.1889, lng: -123.16665 },
  { lat: 46.17254, lng: -123.21305 },
  { lat: 46.17269, lng: -123.21306 },
  { lat: 46.17618, lng: -123.21317 },
  { lat: 46.18109, lng: -123.21332 },
  { lat: 46.18278, lng: -123.21337 },
  { lat: 46.18579, lng: -123.21347 },
  { lat: 46.19402, lng: -123.21373 },
  { lat: 46.20017, lng: -123.21392 },
  { lat: 46.21343, lng: -123.21613 },
  { lat: 46.23531, lng: -123.21667 },
  { lat: 46.24983, lng: -123.21703 },
  { lat: 46.25923, lng: -123.21667 },
  { lat: 46.26747, lng: -123.21607 },
  { lat: 46.27873, lng: -123.21484 },
  { lat: 46.2819, lng: -123.21461 },
  { lat: 46.28591, lng: -123.21442 },
  { lat: 46.29803, lng: -123.21424 },
  { lat: 46.30039, lng: -123.21438 },
  { lat: 46.32414, lng: -123.21533 },
  { lat: 46.33583, lng: -123.21581 },
  { lat: 46.3392, lng: -123.21595 },
  { lat: 46.35079, lng: -123.21643 },
  { lat: 46.35654, lng: -123.21666 },
  { lat: 46.35709, lng: -123.21669 },
  { lat: 46.36882, lng: -123.21717 },
  { lat: 46.37662, lng: -123.21749 },
  { lat: 46.38503, lng: -123.21784 },
  { lat: 46.38513, lng: -123.21784 },
  { lat: 46.38512, lng: -123.21803 },
  { lat: 46.38509, lng: -123.22035 },
  { lat: 46.38497, lng: -123.22855 },
  { lat: 46.38489, lng: -123.23364 },
  { lat: 46.38473, lng: -123.24417 },
  { lat: 46.3847, lng: -123.24623 },
  { lat: 46.38463, lng: -123.25124 },
  { lat: 46.38462, lng: -123.25401 },
  { lat: 46.38459, lng: -123.26002 },
  { lat: 46.38455, lng: -123.2715 },
  { lat: 46.38455, lng: -123.27219 },
  { lat: 46.38452, lng: -123.28057 },
  { lat: 46.3845, lng: -123.28475 },
  { lat: 46.38448, lng: -123.29113 },
  { lat: 46.38446, lng: -123.29637 },
  { lat: 46.38442, lng: -123.30697 },
  { lat: 46.38438, lng: -123.31757 },
  { lat: 46.38437, lng: -123.32107 },
  { lat: 46.38436, lng: -123.32161 },
  { lat: 46.38436, lng: -123.32285 },
  { lat: 46.38435, lng: -123.32474 },
  { lat: 46.38435, lng: -123.32607 },
  { lat: 46.38432, lng: -123.33279 },
  { lat: 46.38429, lng: -123.34161 },
  { lat: 46.38426, lng: -123.34814 },
  { lat: 46.38403, lng: -123.35825 },
  { lat: 46.38403, lng: -123.35833 },
  { lat: 46.38475, lng: -123.35834 },
  { lat: 46.40644, lng: -123.35847 },
  { lat: 46.42368, lng: -123.35804 },
  { lat: 46.4402, lng: -123.35817 },
  { lat: 46.45301, lng: -123.3579 },
  { lat: 46.47012, lng: -123.35739 },
  { lat: 46.48407, lng: -123.35761 },
  { lat: 46.49618, lng: -123.35824 },
  { lat: 46.51655, lng: -123.35848 },
  { lat: 46.53192, lng: -123.35846 },
  { lat: 46.55143, lng: -123.35888 },
  { lat: 46.55964, lng: -123.35934 },
  { lat: 46.57782, lng: -123.36553 },
  { lat: 46.59832, lng: -123.36497 },
  { lat: 46.61997, lng: -123.36541 },
  { lat: 46.63817, lng: -123.36503 },
  { lat: 46.64779, lng: -123.36508 },
  { lat: 46.66651, lng: -123.36609 },
  { lat: 46.67938, lng: -123.36684 },
  { lat: 46.69348, lng: -123.36792 },
  { lat: 46.71315, lng: -123.36967 },
  { lat: 46.7328, lng: -123.37113 },
  { lat: 46.75012, lng: -123.372 },
  { lat: 46.76642, lng: -123.37127 },
  { lat: 46.77749, lng: -123.37123 },
  { lat: 46.79185, lng: -123.37096 },
  { lat: 46.79213, lng: -123.37095 },
  { lat: 46.79245, lng: -123.37089 },
  { lat: 46.7927, lng: -123.36625 },
  { lat: 46.79315, lng: -123.35929 },
  { lat: 46.79345, lng: -123.3517 },
  { lat: 46.79339, lng: -123.34094 },
  { lat: 46.79332, lng: -123.33049 },
  { lat: 46.79323, lng: -123.31419 },
  { lat: 46.79319, lng: -123.30815 },
  { lat: 46.79313, lng: -123.29827 },
  { lat: 46.7931, lng: -123.29303 },
  { lat: 46.79307, lng: -123.28779 },
  { lat: 46.79301, lng: -123.2826 },
  { lat: 46.79297, lng: -123.27741 },
  { lat: 46.79288, lng: -123.26379 },
  { lat: 46.79286, lng: -123.26027 },
  { lat: 46.79285, lng: -123.2601 },
  { lat: 46.79281, lng: -123.25305 },
  { lat: 46.7935, lng: -123.24599 },
  { lat: 46.79344, lng: -123.23593 },
  { lat: 46.79362, lng: -123.22006 },
  { lat: 46.79413, lng: -123.20859 },
  { lat: 46.79431, lng: -123.19765 },
  { lat: 46.79415, lng: -123.18721 },
  { lat: 46.79391, lng: -123.17811 },
  { lat: 46.7937, lng: -123.1713 },
  { lat: 46.79346, lng: -123.16252 },
  { lat: 46.79343, lng: -123.16059 },
  { lat: 46.79618, lng: -123.16064 },
  { lat: 46.79925, lng: -123.1607 },
  { lat: 46.80106, lng: -123.16059 },
  { lat: 46.80498, lng: -123.16059 },
  { lat: 46.80871, lng: -123.16059 },
  { lat: 46.81541, lng: -123.16055 },
  { lat: 46.82715, lng: -123.1605 },
  { lat: 46.82884, lng: -123.16049 },
  { lat: 46.83229, lng: -123.16061 },
  { lat: 46.83863, lng: -123.16078 },
  { lat: 46.85139, lng: -123.16088 },
  { lat: 46.8671, lng: -123.16031 },
  { lat: 46.87482, lng: -123.16026 },
  { lat: 46.88234, lng: -123.16015 },
  { lat: 46.91491, lng: -123.15965 },
  { lat: 46.93277, lng: -123.15937 },
  { lat: 46.95335, lng: -123.15906 },
  { lat: 46.98028, lng: -123.15865 },
  { lat: 46.99634, lng: -123.15973 },
  { lat: 46.99702, lng: -123.18769 },
  { lat: 47.00093, lng: -123.20121 },
  { lat: 47.01727, lng: -123.2001 },
  { lat: 47.03985, lng: -123.20119 },
  { lat: 47.05339, lng: -123.20085 },
  { lat: 47.05779, lng: -123.20101 },
  { lat: 47.07355, lng: -123.20162 },
  { lat: 47.08489, lng: -123.20207 },
  { lat: 47.08481, lng: -123.25097 },
  { lat: 47.08493, lng: -123.26021 },
  { lat: 47.08486, lng: -123.27421 },
  { lat: 47.08457, lng: -123.29781 },
  { lat: 47.08402, lng: -123.32918 },
  { lat: 47.08264, lng: -123.36849 },
  { lat: 47.08255, lng: -123.39026 },
  { lat: 47.08223, lng: -123.44279 },
  { lat: 47.082, lng: -123.47347 },
  { lat: 47.08277, lng: -123.48904 },
  { lat: 47.09004, lng: -123.48933 },
  { lat: 47.12403, lng: -123.48955 },
  { lat: 47.13644, lng: -123.48964 },
  { lat: 47.14689, lng: -123.48969 },
  { lat: 47.18805, lng: -123.49004 },
  { lat: 47.2175, lng: -123.49026 },
  { lat: 47.21773, lng: -123.49026 },
  { lat: 47.22773, lng: -123.49035 },
  { lat: 47.25793, lng: -123.506 },
  { lat: 47.3089, lng: -123.50602 },
  { lat: 47.32997, lng: -123.50602 },
  { lat: 47.35214, lng: -123.50603 },
  { lat: 47.3737, lng: -123.50604 },
  { lat: 47.39215, lng: -123.50577 },
  { lat: 47.42713, lng: -123.50528 },
  { lat: 47.49982, lng: -123.50434 },
  { lat: 47.51761, lng: -123.50438 },
  { lat: 47.51771, lng: -123.50438 },
  { lat: 47.52285, lng: -123.50438 },
  { lat: 47.57006, lng: -123.50438 },
  { lat: 47.60178, lng: -123.50438 },
  { lat: 47.60711, lng: -123.50129 },
  { lat: 47.60711, lng: -123.49271 },
  { lat: 47.6071, lng: -123.45929 },
  { lat: 47.60709, lng: -123.40268 },
  { lat: 47.60707, lng: -123.36283 },
  { lat: 47.60708, lng: -123.34795 },
  { lat: 47.60708, lng: -123.30303 },
  { lat: 47.60707, lng: -123.26898 },
  { lat: 47.60707, lng: -123.26305 },
  { lat: 47.60706, lng: -123.25843 },
  { lat: 47.607, lng: -123.22916 },
  { lat: 47.6069, lng: -123.19114 },
  { lat: 47.60727, lng: -123.13637 },
  { lat: 47.60712, lng: -123.12629 },
  { lat: 47.60711, lng: -123.08598 },
  { lat: 47.60666, lng: -123.06857 },
  { lat: 47.60658, lng: -123.04174 },
  { lat: 47.60652, lng: -123.01157 },
  { lat: 47.60651, lng: -123.00838 },
  { lat: 47.60611, lng: -122.99267 },
  { lat: 47.60612, lng: -122.98782 },
  { lat: 47.60614, lng: -122.98277 },
  { lat: 47.60627, lng: -122.95047 },
  { lat: 47.60848, lng: -122.94692 },
  { lat: 47.6253, lng: -122.9334 },
  { lat: 47.6339, lng: -122.91127 },
  { lat: 47.64604, lng: -122.88531 },
  { lat: 47.65821, lng: -122.86804 },
  { lat: 47.66882, lng: -122.83522 },
  { lat: 47.67099, lng: -122.80842 },
  { lat: 47.67197, lng: -122.78771 },
  { lat: 47.67851, lng: -122.76873 },
  { lat: 47.69236, lng: -122.75749 },
  { lat: 47.7051, lng: -122.76462 },
  { lat: 47.72655, lng: -122.76173 },
  { lat: 47.74095, lng: -122.74933 },
  { lat: 47.74981, lng: -122.74169 },
  { lat: 47.76949, lng: -122.73325 },
  { lat: 47.78781, lng: -122.72184 },
  { lat: 47.81233, lng: -122.69212 },
  { lat: 47.83293, lng: -122.66288 },
  { lat: 47.87738, lng: -122.59727 },
  { lat: 47.88958, lng: -122.59659 },
  { lat: 47.89978, lng: -122.60902 },
  { lat: 47.90769, lng: -122.62495 },
  { lat: 47.93266, lng: -122.63637 },
  { lat: 47.94845, lng: -122.63554 },
  { lat: 47.96318, lng: -122.6242 },
  { lat: 47.97133, lng: -122.60984 },
  { lat: 47.97295, lng: -122.60676 },
  { lat: 47.97181, lng: -122.60537 },
  { lat: 47.96291, lng: -122.59393 },
  { lat: 47.95917, lng: -122.58899 },
  { lat: 47.95513, lng: -122.58287 },
  { lat: 47.94952, lng: -122.56985 },
  { lat: 47.9443, lng: -122.55165 },
  { lat: 47.94028, lng: -122.53567 },
  { lat: 47.93575, lng: -122.51796 },
  { lat: 47.93367, lng: -122.51065 },
  { lat: 47.93091, lng: -122.50405 },
  { lat: 47.92902, lng: -122.50125 },
  { lat: 47.92481, lng: -122.49632 },
  { lat: 47.92176, lng: -122.4936 },
  { lat: 47.915, lng: -122.48978 },
  { lat: 47.91247, lng: -122.48878 },
  { lat: 47.90536, lng: -122.48656 },
  { lat: 47.90043, lng: -122.48473 },
  { lat: 47.8946, lng: -122.4823 },
  { lat: 47.8897, lng: -122.47969 },
  { lat: 47.88551, lng: -122.4766 },
  { lat: 47.88151, lng: -122.47354 },
  { lat: 47.8781, lng: -122.47057 },
  { lat: 47.87541, lng: -122.46728 },
  { lat: 47.87483, lng: -122.46672 },
  { lat: 47.85247, lng: -122.44133 },
  { lat: 47.84204, lng: -122.43268 },
  { lat: 47.82761, lng: -122.42952 },
  { lat: 47.85062, lng: -122.41205 },
  { lat: 47.87628, lng: -122.37625 },
  { lat: 47.89967, lng: -122.3532 },
  { lat: 47.92132, lng: -122.34325 },
  { lat: 47.95394, lng: -122.32968 },
  { lat: 47.97689, lng: -122.33212 },
  { lat: 47.99881, lng: -122.34554 },
  { lat: 48.0224, lng: -122.35065 },
  { lat: 48.04302, lng: -122.34044 },
  { lat: 48.059, lng: -122.3413 },
  { lat: 48.08031, lng: -122.35029 },
  { lat: 48.09646, lng: -122.36302 },
  { lat: 48.11206, lng: -122.38066 },
  { lat: 48.13759, lng: -122.40317 },
  { lat: 48.15725, lng: -122.41484 },
  { lat: 48.17383, lng: -122.41798 },
  { lat: 48.201, lng: -122.41379 },
  { lat: 48.21743, lng: -122.40189 },
  { lat: 48.22981, lng: -122.3947 },
  { lat: 48.23556, lng: -122.39271 },
  { lat: 48.23884, lng: -122.39325 },
  { lat: 48.23975, lng: -122.39452 },
  { lat: 48.2429, lng: -122.39966 },
  { lat: 48.24633, lng: -122.40105 },
  { lat: 48.2505, lng: -122.40477 },
  { lat: 48.27526, lng: -122.42509 },
  { lat: 48.29759, lng: -122.45833 },
  { lat: 48.29861, lng: -122.46205 },
  { lat: 48.31386, lng: -122.50013 },
  { lat: 48.32761, lng: -122.51869 },
  { lat: 48.341, lng: -122.53961 },
  { lat: 48.35139, lng: -122.55104 },
  { lat: 48.36434, lng: -122.55912 },
  { lat: 48.36798, lng: -122.56087 },
  { lat: 48.37502, lng: -122.57061 },
  { lat: 48.38069, lng: -122.57437 },
  { lat: 48.39103, lng: -122.57705 },
  { lat: 48.39507, lng: -122.57916 },
  { lat: 48.40143, lng: -122.57744 },
  { lat: 48.40779, lng: -122.57955 },
  { lat: 48.41263, lng: -122.58514 },
  { lat: 48.41776, lng: -122.59194 },
  { lat: 48.41461, lng: -122.60296 },
  { lat: 48.41307, lng: -122.61092 },
  { lat: 48.40941, lng: -122.62628 },
  { lat: 48.40857, lng: -122.63396 },
  { lat: 48.4063, lng: -122.63917 },
  { lat: 48.40614, lng: -122.64354 },
  { lat: 48.40582, lng: -122.65097 },
  { lat: 48.40593, lng: -122.66075 },
  { lat: 48.41191, lng: -122.66494 },
  { lat: 48.41241, lng: -122.67535 },
  { lat: 48.40561, lng: -122.73888 },
  { lat: 48.40551, lng: -122.73974 },
  { lat: 48.40732, lng: -122.73947 },
  { lat: 48.42198, lng: -122.74012 },
  { lat: 48.44076, lng: -122.74853 },
  { lat: 48.44799, lng: -122.75249 },
  { lat: 48.46386, lng: -122.76023 },
  { lat: 48.47834, lng: -122.76006 },
  { lat: 48.48549, lng: -122.7531 },
  { lat: 48.48842, lng: -122.74964 },
  { lat: 48.49418, lng: -122.74142 },
  { lat: 48.50238, lng: -122.73552 },
  { lat: 48.51072, lng: -122.73588 },
  { lat: 48.52601, lng: -122.74607 },
  { lat: 48.52938, lng: -122.75124 },
  { lat: 48.5325, lng: -122.7548 },
  { lat: 48.53287, lng: -122.75506 },
  { lat: 48.53869, lng: -122.75576 },
  { lat: 48.54647, lng: -122.75125 },
  { lat: 48.55796, lng: -122.74675 },
  { lat: 48.56176, lng: -122.74945 },
  { lat: 48.57451, lng: -122.75962 },
  { lat: 48.5937, lng: -122.76845 },
  { lat: 48.61209, lng: -122.76389 },
  { lat: 48.6248, lng: -122.74672 },
  { lat: 48.62982, lng: -122.73699 },
  { lat: 48.63986, lng: -122.72321 },
  { lat: 48.64397, lng: -122.71101 },
  { lat: 48.65194, lng: -122.69688 },
  { lat: 48.64983, lng: -122.69302 },
  { lat: 48.63366, lng: -122.56595 },
  { lat: 48.64531, lng: -122.48609 },
  { lat: 48.64512, lng: -122.40839 },
  { lat: 48.64513, lng: -122.35433 },
  { lat: 48.64535, lng: -122.329 },
  { lat: 48.6453, lng: -122.29952 },
  { lat: 48.64562, lng: -122.2308 },
  { lat: 48.64614, lng: -122.12626 },
  { lat: 48.6459, lng: -122.07936 },
  { lat: 48.64516, lng: -121.9848 },
  { lat: 48.64478, lng: -121.91158 },
  { lat: 48.64377, lng: -121.8216 },
  { lat: 48.64311, lng: -121.71644 },
  { lat: 48.64301, lng: -121.68761 },
  { lat: 48.64262, lng: -121.57962 },
  { lat: 48.64239, lng: -121.5141 },
  { lat: 48.64181, lng: -121.40237 },
  { lat: 48.64159, lng: -121.36112 },
  { lat: 48.6413, lng: -121.30724 },
  { lat: 48.64094, lng: -121.22552 },
  { lat: 48.64088, lng: -121.0692 },
  { lat: 48.64086, lng: -121.01821 },
  { lat: 48.64102, lng: -120.96203 },
  { lat: 48.6577, lng: -120.86187 },
  { lat: 48.65753, lng: -120.75528 },
  { lat: 48.65754, lng: -120.75202 },
  { lat: 48.65713, lng: -120.75187 },
  { lat: 48.6549, lng: -120.7508 },
  { lat: 48.65219, lng: -120.75172 },
  { lat: 48.64766, lng: -120.7533 },
  { lat: 48.6442, lng: -120.76027 },
  { lat: 48.644, lng: -120.76914 },
  { lat: 48.64083, lng: -120.77287 },
  { lat: 48.63678, lng: -120.77615 },
  { lat: 48.62984, lng: -120.78145 },
  { lat: 48.6263, lng: -120.78709 },
  { lat: 48.62196, lng: -120.78729 },
  { lat: 48.61213, lng: -120.78323 },
  { lat: 48.59719, lng: -120.77653 },
  { lat: 48.58946, lng: -120.76565 },
  { lat: 48.58129, lng: -120.75519 },
  { lat: 48.57875, lng: -120.74549 },
  { lat: 48.58558, lng: -120.7273 },
  { lat: 48.59393, lng: -120.72314 },
  { lat: 48.59505, lng: -120.717 },
  { lat: 48.59147, lng: -120.70964 },
  { lat: 48.58386, lng: -120.69963 },
  { lat: 48.57549, lng: -120.6886 },
  { lat: 48.56202, lng: -120.69018 },
  { lat: 48.55975, lng: -120.70024 },
  { lat: 48.54614, lng: -120.70473 },
  { lat: 48.53247, lng: -120.70161 },
  { lat: 48.53162, lng: -120.70156 },
  { lat: 48.52688, lng: -120.64897 },
  { lat: 48.49306, lng: -120.64131 },
  { lat: 48.46856, lng: -120.63731 },
  { lat: 48.45176, lng: -120.66321 },
  { lat: 48.43166, lng: -120.65131 },
  { lat: 48.40406, lng: -120.64471 },
  { lat: 48.39586, lng: -120.61691 },
  { lat: 48.38406, lng: -120.58881 },
  { lat: 48.36556, lng: -120.56291 },
  { lat: 48.34156, lng: -120.57931 },
  { lat: 48.31866, lng: -120.57451 },
  { lat: 48.30736, lng: -120.53691 },
  { lat: 48.29856, lng: -120.50211 },
  { lat: 48.26486, lng: -120.4592 },
  { lat: 48.24656, lng: -120.4077 },
  { lat: 48.21686, lng: -120.3544 },
  { lat: 48.15791, lng: -120.35971 },
  { lat: 48.12566, lng: -120.3131 },
  { lat: 48.11626, lng: -120.2726 },
  { lat: 48.10046, lng: -120.24419 },
  { lat: 48.07116, lng: -120.17379 },
  { lat: 48.05076, lng: -120.14889 },
  { lat: 48.02106, lng: -120.09919 },
  { lat: 47.98595, lng: -120.07154 },
  { lat: 47.96036, lng: -119.99972 },
  { lat: 47.9604, lng: -119.99595 },
  { lat: 47.96033, lng: -119.87031 },
  { lat: 47.95929, lng: -119.86924 },
  { lat: 47.93378, lng: -119.86714 },
  { lat: 47.9039, lng: -119.91298 },
  { lat: 47.86232, lng: -119.91929 },
  { lat: 47.80821, lng: -119.9766 },
  { lat: 47.78025, lng: -120.01061 },
  { lat: 47.76467, lng: -120.0815 },
  { lat: 47.77453, lng: -120.141 },
  { lat: 47.74187, lng: -120.21481 },
  { lat: 47.69241, lng: -120.20201 },
  { lat: 47.64768, lng: -120.22629 },
  { lat: 47.58164, lng: -120.24132 },
  { lat: 47.5436, lng: -120.2868 },
  { lat: 47.49955, lng: -120.30838 },
  { lat: 47.48505, lng: -120.31198 },
  { lat: 47.47375, lng: -120.31568 },
  { lat: 47.45945, lng: -120.31798 },
  { lat: 47.44655, lng: -120.31268 },
  { lat: 47.43455, lng: -120.30938 },
  { lat: 47.39633, lng: -120.28903 },
  { lat: 47.37708, lng: -120.20867 },
  { lat: 47.36665, lng: -120.14687 },
  { lat: 47.36365, lng: -120.12567 },
  { lat: 47.35155, lng: -120.10927 },
  { lat: 47.34235, lng: -120.09507 },
  { lat: 47.26437, lng: -120.09332 },
  { lat: 47.26215, lng: -120.09447 },
  { lat: 47.26205, lng: -120.09437 },
  { lat: 47.25855, lng: -120.09487 },
  { lat: 47.25425, lng: -120.09637 },
  { lat: 47.24985, lng: -120.09492 },
  { lat: 47.24805, lng: -120.09427 },
  { lat: 47.24525, lng: -120.09317 },
  { lat: 47.24195, lng: -120.09207 },
  { lat: 47.23895, lng: -120.08997 },
  { lat: 47.23585, lng: -120.08707 },
  { lat: 47.23345, lng: -120.08347 },
  { lat: 47.23205, lng: -120.08177 },
  { lat: 47.22985, lng: -120.07967 },
  { lat: 47.22635, lng: -120.07687 },
  { lat: 47.22445, lng: -120.07217 },
  { lat: 47.22365, lng: -120.06527 },
  { lat: 47.22395, lng: -120.05837 },
  { lat: 47.22445, lng: -120.05177 },
  { lat: 47.22525, lng: -120.04817 },
  { lat: 47.22575, lng: -120.04217 },
  { lat: 47.22645, lng: -120.03397 },
  { lat: 47.22665, lng: -120.02507 },
  { lat: 47.22665, lng: -120.02037 },
  { lat: 47.22585, lng: -120.01537 },
  { lat: 47.22455, lng: -120.01267 },
  { lat: 47.22285, lng: -120.01017 },
  { lat: 47.22155, lng: -120.00857 },
  { lat: 47.22005, lng: -120.00706 },
  { lat: 47.21798, lng: -120.00532 },
  { lat: 47.1698, lng: -120.01092 },
  { lat: 47.13719, lng: -120.00004 },
  { lat: 47.12415, lng: -120.00377 },
  { lat: 47.11575, lng: -120.00967 },
  { lat: 47.10685, lng: -120.02607 },
  { lat: 47.08905, lng: -120.03667 },
  { lat: 47.07345, lng: -120.04287 },
  { lat: 47.05775, lng: -120.03657 },
  { lat: 47.04495, lng: -120.01896 },
  { lat: 47.03305, lng: -120.00716 },
  { lat: 47.01935, lng: -120.00586 },
  { lat: 47.00675, lng: -120.00126 },
  { lat: 47.00005, lng: -120.00236 },
  { lat: 46.98661, lng: -119.9963 },
  { lat: 46.96615, lng: -119.98282 },
  { lat: 46.94366, lng: -119.96606 },
  { lat: 46.9337, lng: -119.96413 },
  { lat: 46.92023, lng: -119.96337 },
  { lat: 46.89037, lng: -119.96568 },
  { lat: 46.86891, lng: -119.96495 },
  { lat: 46.84183, lng: -119.95134 },
  { lat: 46.81627, lng: -119.9271 },
  { lat: 46.78844, lng: -119.93367 },
  { lat: 46.76189, lng: -119.96182 },
  { lat: 46.73721, lng: -119.97301 },
  { lat: 46.73717, lng: -119.97302 },
  { lat: 46.73626, lng: -119.97313 },
  { lat: 46.73045, lng: -119.97337 },
  { lat: 46.72428, lng: -119.97336 },
  { lat: 46.72177, lng: -119.97277 },
  { lat: 46.71351, lng: -119.96856 },
  { lat: 46.70971, lng: -119.96618 },
  { lat: 46.70602, lng: -119.96379 },
  { lat: 46.69689, lng: -119.95861 },
  { lat: 46.69279, lng: -119.9562 },
  { lat: 46.69024, lng: -119.95415 },
  { lat: 46.68683, lng: -119.95221 },
  { lat: 46.68072, lng: -119.94845 },
  { lat: 46.67479, lng: -119.9455 },
  { lat: 46.66933, lng: -119.93855 },
  { lat: 46.66456, lng: -119.93309 },
  { lat: 46.66101, lng: -119.92703 },
  { lat: 46.65542, lng: -119.9173 },
  { lat: 46.65206, lng: -119.91534 },
  { lat: 46.6469, lng: -119.91242 },
  { lat: 46.64453, lng: -119.91027 },
  { lat: 46.6441, lng: -119.90988 },
  { lat: 46.63868, lng: -119.90464 },
  { lat: 46.63327, lng: -119.89758 },
  { lat: 46.63107, lng: -119.8853 },
  { lat: 46.62959, lng: -119.87722 },
  { lat: 46.62887, lng: -119.87484 },
  { lat: 46.62858, lng: -119.87406 },
  { lat: 46.62849, lng: -119.87405 },
  { lat: 46.60534, lng: -119.87457 },
  { lat: 46.56555, lng: -119.87644 },
  { lat: 46.52451, lng: -119.87462 },
  { lat: 46.50462, lng: -119.87449 },
  { lat: 46.44759, lng: -119.87346 },
  { lat: 46.4309, lng: -119.87326 },
  { lat: 46.39157, lng: -119.87311 },
  { lat: 46.36357, lng: -119.87331 },
  { lat: 46.33789, lng: -119.8736 },
  { lat: 46.33004, lng: -119.87352 },
  { lat: 46.32192, lng: -119.87353 },
  { lat: 46.31282, lng: -119.87358 },
  { lat: 46.30209, lng: -119.87369 },
  { lat: 46.29366, lng: -119.8737 },
  { lat: 46.27662, lng: -119.87365 },
  { lat: 46.26706, lng: -119.8735 },
  { lat: 46.25427, lng: -119.87341 },
  { lat: 46.24617, lng: -119.87348 },
  { lat: 46.22942, lng: -119.87336 },
  { lat: 46.21458, lng: -119.87042 },
  { lat: 46.20439, lng: -119.86685 },
  { lat: 46.19497, lng: -119.86694 },
  { lat: 46.18147, lng: -119.86663 },
  { lat: 46.09278, lng: -119.86559 },
  { lat: 46.04082, lng: -119.86626 },
  { lat: 46.04066, lng: -119.86626 },
  { lat: 46.04061, lng: -119.87017 },
  { lat: 46.04072, lng: -119.96866 },
  { lat: 46.04084, lng: -120.05232 },
  { lat: 46.04116, lng: -120.14428 },
  { lat: 46.04136, lng: -120.16425 },
  { lat: 46.04111, lng: -120.17888 },
  { lat: 46.0411, lng: -120.22679 },
  { lat: 46.04157, lng: -120.2714 },
  { lat: 46.04143, lng: -120.35722 },
  { lat: 46.04099, lng: -120.44195 },
  { lat: 46.04157, lng: -120.49439 },
  { lat: 46.04148, lng: -120.57312 },
  { lat: 46.04127, lng: -120.64898 },
  { lat: 46.04138, lng: -120.74356 },
  { lat: 46.04184, lng: -120.84618 },
  { lat: 46.04245, lng: -120.90737 },
  { lat: 46.04233, lng: -120.96978 },
  { lat: 46.04257, lng: -121.06667 },
  { lat: 46.04337, lng: -121.0969 },
  { lat: 46.04475, lng: -121.15394 },
  { lat: 46.0447, lng: -121.23372 },
  { lat: 46.04492, lng: -121.3129 },
  { lat: 46.04491, lng: -121.35112 },
  { lat: 46.04456, lng: -121.42117 },
  { lat: 46.04409, lng: -121.48891 },
  { lat: 46.04404, lng: -121.52291 },
  { lat: 46.04405, lng: -121.52394 },
  { lat: 46.04512, lng: -121.52393 },
  { lat: 46.06236, lng: -121.52388 },
  { lat: 46.07781, lng: -121.52384 },
  { lat: 46.08473, lng: -121.52383 },
  { lat: 46.10353, lng: -121.52379 },
  { lat: 46.11429, lng: -121.52377 },
  { lat: 46.12291, lng: -121.52375 },
  { lat: 46.16192, lng: -121.52373 },
  { lat: 46.16755, lng: -121.52372 },
  { lat: 46.18825, lng: -121.52368 },
  { lat: 46.20211, lng: -121.52366 },
  { lat: 46.20734, lng: -121.52365 },
  { lat: 46.20799, lng: -121.52366 },
  { lat: 46.22053, lng: -121.52364 },
  { lat: 46.23017, lng: -121.52362 },
  { lat: 46.23599, lng: -121.52361 },
  { lat: 46.24337, lng: -121.5236 },
  { lat: 46.25754, lng: -121.52362 },
  { lat: 46.26023, lng: -121.52363 },
  { lat: 46.29894, lng: -121.52363 },
  { lat: 46.31105, lng: -121.52363 },
  { lat: 46.33706, lng: -121.52364 },
  { lat: 46.3492, lng: -121.52365 },
  { lat: 46.35059, lng: -121.52365 },
  { lat: 46.35431, lng: -121.52366 },
  { lat: 46.38454, lng: -121.52356 },
  { lat: 46.38871, lng: -121.52355 },
  { lat: 46.38871, lng: -121.52421 },
  { lat: 46.38865, lng: -121.55811 },
  { lat: 46.3885, lng: -121.60378 },
  { lat: 46.38855, lng: -121.64228 },
  { lat: 46.38858, lng: -121.70782 },
  { lat: 46.38806, lng: -121.74091 },
  { lat: 46.38821, lng: -121.76617 },
  { lat: 46.38904, lng: -121.82902 },
  { lat: 46.38905, lng: -121.85471 },
  { lat: 46.38753, lng: -121.87946 },
  { lat: 46.38764, lng: -121.93121 },
  { lat: 46.38753, lng: -121.95769 },
  { lat: 46.3876, lng: -121.97741 },
  { lat: 46.38805, lng: -122.01018 },
  { lat: 46.38784, lng: -122.03532 },
  { lat: 46.38797, lng: -122.07 },
  { lat: 46.38822, lng: -122.09238 },
  { lat: 46.38795, lng: -122.11444 },
  { lat: 46.38624, lng: -122.15163 },
  { lat: 46.38635, lng: -122.18627 },
  { lat: 46.38617, lng: -122.20978 },
  { lat: 46.38607, lng: -122.21618 },
  { lat: 46.38599, lng: -122.22323 },
  { lat: 46.38599, lng: -122.23027 },
  { lat: 46.38589, lng: -122.23716 },
  { lat: 46.38547, lng: -122.24097 },
  { lat: 46.38537, lng: -122.24097 },
  { lat: 46.38524, lng: -122.24056 },
  { lat: 46.38342, lng: -122.23996 },
  { lat: 46.3729, lng: -122.24019 },
  { lat: 46.3504, lng: -122.24047 },
  { lat: 46.3386, lng: -122.24058 },
  { lat: 46.33139, lng: -122.24065 },
  { lat: 46.32484, lng: -122.24101 },
  { lat: 46.32078, lng: -122.24105 },
  { lat: 46.30222, lng: -122.2411 },
  { lat: 46.2873, lng: -122.24115 },
  { lat: 46.2675, lng: -122.2412 },
  { lat: 46.26538, lng: -122.24121 },
  { lat: 46.23478, lng: -122.24126 },
  { lat: 46.21753, lng: -122.24139 },
  { lat: 46.21127, lng: -122.24365 },
  { lat: 46.21115, lng: -122.24633 },
  { lat: 46.18627, lng: -122.24613 },
  { lat: 46.15364, lng: -122.24586 },
  { lat: 46.1411, lng: -122.24577 },
  { lat: 46.12543, lng: -122.24578 },
  { lat: 46.11721, lng: -122.24586 },
  { lat: 46.09258, lng: -122.24578 },
  { lat: 46.06516, lng: -122.24568 },
  { lat: 46.06082, lng: -122.24566 },
  { lat: 46.05885, lng: -122.24565 },
  { lat: 46.05429, lng: -122.24563 },
  { lat: 46.05393, lng: -122.24563 },
  { lat: 46.05388, lng: -122.24563 },
  { lat: 46.04711, lng: -122.24561 },
  { lat: 46.04295, lng: -122.24559 },
  { lat: 45.985, lng: -122.24597 },
  { lat: 45.96203, lng: -122.24589 },
  { lat: 45.93501, lng: -122.24545 },
  { lat: 45.89873, lng: -122.24498 },
  { lat: 45.84967, lng: -122.2494 },
  { lat: 45.8047, lng: -122.24955 },
  { lat: 45.74886, lng: -122.24951 },
  { lat: 45.73095, lng: -122.24934 },
  { lat: 45.69765, lng: -122.24951 },
  { lat: 45.67067, lng: -122.24965 },
  { lat: 45.65983, lng: -122.24951 },
  { lat: 45.6573, lng: -122.24953 },
  { lat: 45.65522, lng: -122.24932 },
  { lat: 45.65323, lng: -122.24941 },
  { lat: 45.63535, lng: -122.24923 },
  { lat: 45.62703, lng: -122.249 },
  { lat: 45.62211, lng: -122.24894 },
  { lat: 45.61718, lng: -122.24902 },
  { lat: 45.5968, lng: -122.24872 },
  { lat: 45.58629, lng: -122.24873 },
  { lat: 45.57421, lng: -122.24869 },
  { lat: 45.55958, lng: -122.24859 },
  { lat: 45.54943, lng: -122.24892 },
  { lat: 45.54774, lng: -122.24899 },
  { lat: 45.54642, lng: -122.25426 },
  { lat: 45.54354, lng: -122.2949 },
  { lat: 45.54699, lng: -122.32202 },
  { lat: 45.55333, lng: -122.33662 },
  { lat: 45.55774, lng: -122.34104 },
  { lat: 45.56727, lng: -122.35062 },
  { lat: 45.57558, lng: -122.38327 },
  { lat: 45.57276, lng: -122.39652 },
  { lat: 45.56763, lng: -122.41071 },
];
export default coordinates;
